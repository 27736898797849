import { Routes } from '@angular/router';
import { RatesCardComponent } from './pages/rate-cards/rates-card.component';
import { ReportsSettingsComponent } from './pages/reports-settings/reports-settings.component';

export enum RATE_CARDS_ROUTES {
  ROOT = 'billing',
  REPORT_SETTINGS = 'reports-settings',
}

export const RATE_CARDS_ROUTING: Routes = [
  {
    path: '',
    component: RatesCardComponent,
  },
  {
    path: RATE_CARDS_ROUTES.REPORT_SETTINGS,
    component: ReportsSettingsComponent,
  },
  {
    path: '**',
    redirectTo: `/${RATE_CARDS_ROUTES.ROOT}`,
    pathMatch: 'full',
  },
];
