<vdms-hq-ui-dialog-wrapper [loading]="isLoading" [sharedE2eId]="'policies-logs-dialog'" size="wide">
  <h1 title>{{ data.title | translate }}</h1>
  <div class="multi-data-container" content>
    <vdms-hq-ui-multiple-data-presentation
      (action)="handleAction($event)"
      [configuration]="viewConfiguration"
      [dataSource]="data.dataSource"
      [drawerVisible]="drawerVisible"
    >
      <vdms-hq-dynamic-form-group-renderer
        [fieldsConfig]="filtersConfig"
        [form]="filters"
        [hasLoader]="true"
        filters
      ></vdms-hq-dynamic-form-group-renderer>
      <vdms-hq-json-viewer [logsDataSource]="data.dataSource" drawer-content></vdms-hq-json-viewer>
    </vdms-hq-ui-multiple-data-presentation>
  </div>
  <div footer>
    <vdms-hq-ui-button (click)="closeDialog()" color="secondary">
      {{ 'common.global.cancel' | translate }}
    </vdms-hq-ui-button>
  </div>
</vdms-hq-ui-dialog-wrapper>
