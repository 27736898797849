<vdms-hq-ui-dialog-wrapper footerAlign="space-between">
  <ng-container title>
    {{ 'common.delivery_destinations.create.title' | translate }}
  </ng-container>

  <form [formGroup]="form" content>
    <vdms-hq-ui-form-section
      layout="grid-2-columns"
      [title]="'common.delivery_destinations.fields.add_new_delivery_details' | translate"
    >
      <vdms-hq-ui-form-input-text
        formControlName="name"
        [label]="'common.delivery_destinations.fields.name' | translate"
      ></vdms-hq-ui-form-input-text>
      <vdms-hq-ui-form-input-select
        formControlName="status"
        [selectOptions]="statusOptions"
        [label]="'common.delivery_destinations.fields.status' | translate"
      ></vdms-hq-ui-form-input-select>
      <vdms-hq-ui-form-input-chips
        formControlName="emailDelivery"
        [label]="'common.delivery_destinations.fields.email_delivery' | translate"
      ></vdms-hq-ui-form-input-chips>

      <vdms-hq-ui-form-input-chips
        formControlName="deliveryEmails"
        [label]="'common.delivery_destinations.fields.delivery_emails' | translate"
      ></vdms-hq-ui-form-input-chips>
      <vdms-hq-ui-form-input-select
        [selectOptions]="methodOptions"
        formControlName="method"
        [label]="'common.delivery_destinations.fields.method' | translate"
        (valueChange)="destinationCredentialsService.getCredentialsByType($event)"
      ></vdms-hq-ui-form-input-select>
      <vdms-hq-ui-form-input-text
        formControlName="type"
        [label]="'common.delivery_destinations.fields.type' | translate"
      ></vdms-hq-ui-form-input-text>
    </vdms-hq-ui-form-section>

    <vdms-hq-ui-form-section
      layout="grid-2-columns"
      [title]="'common.delivery_destinations.fields.add_new_delivery_config' | translate"
    >
      <vdms-hq-ui-form-input-text
        formControlName="defaultConfig"
        [label]="'common.delivery_destinations.fields.config_name' | translate"
      ></vdms-hq-ui-form-input-text>
    </vdms-hq-ui-form-section>

    <vdms-hq-ui-form-section
      layout="grid-2-columns"
      [title]="'common.delivery_destinations.fields.delivery_publish_credentials' | translate"
      [divider]="false"
      *ngIf="method !== DestinationAccessMethodEnum.VIDA"
    >
      <ng-container formGroupName="publish">
        <ng-container *ngIf="method === DestinationAccessMethodEnum.AWS">
          <vdms-hq-ui-form-input-select
            [selectOptions]="destinationCredentialsService.credentialsSelectorSourceAWS"
            [label]="'common.delivery_destinations.fields.credentials' | translate"
            [loading]="destinationCredentialsService.isLoading$ | async"
            formControlName="credentialUuid"
          ></vdms-hq-ui-form-input-select>
          <ng-container formGroupName="configData">
            <vdms-hq-ui-form-input-select
              [selectOptions]="AwsRegionsOptions"
              [label]="'common.delivery_destinations.fields.region' | translate"
              formControlName="region"
            ></vdms-hq-ui-form-input-select>
            <vdms-hq-ui-form-input-text
              [label]="'common.delivery_destinations.fields.bucket' | translate"
              formControlName="bucketName"
            ></vdms-hq-ui-form-input-text>
            <vdms-hq-ui-form-input-text
              [label]="'common.delivery_destinations.fields.prefix' | translate"
              formControlName="prefix"
            ></vdms-hq-ui-form-input-text>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="method === DestinationAccessMethodEnum.ASPERA_HSTS">
          <vdms-hq-ui-form-input-select
            [selectOptions]="destinationCredentialsService.credentialsSelectorSourceAsperaHSTS"
            [label]="'common.delivery_destinations.fields.credentials' | translate"
            formControlName="credentialUuid"
          ></vdms-hq-ui-form-input-select>
          <ng-container formGroupName="configData">
            <vdms-hq-ui-form-input-text
              [label]="'common.delivery_destinations.fields.prefix' | translate"
              formControlName="prefix"
            ></vdms-hq-ui-form-input-text>
            <vdms-hq-ui-form-input-text
              [label]="'common.delivery_destinations.fields.ear_passphrase' | translate"
              formControlName="earPassphrase"
            ></vdms-hq-ui-form-input-text>
          </ng-container>
        </ng-container>
      </ng-container>
    </vdms-hq-ui-form-section>
  </form>

  <ng-container footer>
    <vdms-hq-ui-button (click)="abort()" color="secondary">{{ 'common.global.cancel' | translate }}</vdms-hq-ui-button>
    <vdms-hq-ui-button (click)="save()" [loading]="loading$ | async" [disabled]="form.invalid" color="primary">{{
      'common.global.create' | translate
    }}</vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
