import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogsModel } from '../../logic/logs.model';
import { UILayoutModule } from '@vdms-hq/ui';
import { LogsDataSource } from '../logs-dialog/logs-dialog.component';
import { filter } from 'rxjs';

@Component({
  selector: 'vdms-hq-json-viewer',
  standalone: true,
  imports: [CommonModule, UILayoutModule],
  templateUrl: './json-viewer.component.html',
  styleUrls: ['./json-viewer.component.scss'],
})
export class JsonViewerComponent implements OnInit {
  @Input() logsDataSource!: LogsDataSource;
  baseObject!: LogsModel['base_object'];
  changeset!: LogsModel['changeset'];

  ngOnInit(): void {
    this.logsDataSource.selectedItem$.pipe(filter(Boolean)).subscribe((item) => {
      this.baseObject = JSON.parse(item.base_object);
      this.changeset = JSON.parse(item.changeset);
    });
  }
}
