<vdms-hq-ui-layout-page-wrapper [breadcrumbs]="[{ name: 'Email text templates' }]">
  <ng-container *ngIf="dataSource$ | async; let data" [formGroup]="form">
    <vdms-hq-ui-form-section
      [divider]="false"
      title="Template name: {{ item.template_name }}"
      *ngFor="let item of data"
    >
      <p header class="email-templates__header">
        Available parameters:
        <span
          *ngFor="let parameter of item.parameters"
          class="pill yellow"
          [class.done]="$castToString(form.get(item.uuid)?.value).includes(parameter)"
          (click)="setVariable(parameter, item.uuid)"
          >{{ parameter }}</span
        >
      </p>
      <vdms-hq-ui-form-input-text-area [formControlName]="item.uuid"></vdms-hq-ui-form-input-text-area>
    </vdms-hq-ui-form-section>
  </ng-container>
</vdms-hq-ui-layout-page-wrapper>

<vdms-hq-ui-actions-footer>
  <vdms-hq-ui-button right (click)="save()" [disabled]="form.pristine || loading" [loading]="loading" color="primary">
    Save Templates
  </vdms-hq-ui-button>
</vdms-hq-ui-actions-footer>
