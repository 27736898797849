import { Injectable } from '@angular/core';
import { AuthService } from '@vdms-hq/auth';
import { combineLatest, Observable, Subscription, switchMap } from 'rxjs';
import { filter, first, map, withLatestFrom } from 'rxjs/operators';
import { SavedLatestSearchesModel, UserContractService } from '@vdms-hq/firebase-contract';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { SelectOption } from '@vdms-hq/shared';
import { AssetSearchService } from '@vdms-hq/api-contract';

@Injectable({
  providedIn: 'root',
})
export class LastFiltersService {
  private readonly MAX_SEARCHES_SNAPSHOTS = 30;
  #listeners?: Subscription;

  constructor(
    private readonly auth: AuthService,
    private readonly activatedClientService: ActivatedClientService,
    private userContractService: UserContractService,
    private assetSearchService: AssetSearchService,
  ) {}

  registerListeners = () => {
    if (this.#listeners) {
      return;
    }
    this.#listeners = this.assetSearchService.currentParams$
      .pipe(
        filter((persistence) => persistence.initial),
        map((persistence) => persistence.query.text),
        filter((text) => !!text?.trim()),
        switchMap((text) => this.saveLastSearches(text)),
      )
      .subscribe();
  };

  #firebasePathElements$(): Observable<{ userEmail?: string; clientId: string }> {
    return this.auth.auth$.pipe(first(), withLatestFrom(this.activatedClientService.clientIdDefinite$)).pipe(
      first(),
      map(([firebaseUser, selectedClient]) => ({ userEmail: firebaseUser?.email, clientId: selectedClient })),
    );
  }

  async saveLastSearches(searchPhrase?: string) {
    if (!searchPhrase) {
      return;
    }

    const searchObj: SavedLatestSearchesModel = {
      createdAt: Date.now(),
      phrase: searchPhrase,
    };
    this.getSavedSearches()
      .pipe(first(), withLatestFrom(this.#firebasePathElements$()))
      .subscribe(([searchObjects, firebasePathElements]) => {
        const isExist = !!searchObjects.filter((searchObject) => searchObject.phrase === searchPhrase).length;
        if (!isExist) {
          this.userContractService.saveUserSearches(
            firebasePathElements.userEmail,
            firebasePathElements.clientId,
            searchObj,
          );
        }
      });
  }

  getSavedSearches(limitToLast: number = this.MAX_SEARCHES_SNAPSHOTS): Observable<SavedLatestSearchesModel[]> {
    return combineLatest([this.#firebasePathElements$(), this.activatedClientService.clientIdDefinite$]).pipe(
      map(([element, clientId]) => ({ ...element, clientId })),
      switchMap((data) => this.userContractService.getSavedUserSearches(data.userEmail, data.clientId, limitToLast)),
    );
  }

  getSavedSearchesSelectOption$(): Observable<SelectOption[]> {
    return this.getSavedSearches().pipe(
      map((items) => items.map((item) => ({ key: item.id ?? '', label: item.phrase }))),
    );
  }

  removeSavedSearchPhrase(searchItem: SelectOption) {
    this.#firebasePathElements$()
      .pipe(first())
      .subscribe((data) => {
        this.userContractService.removeUserSearchItem(searchItem.key as string, data.userEmail, data.clientId);
      });
  }
}
