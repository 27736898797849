import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';
import { Column, ValueColumn } from '../logic/column-config';
import { IsColumnValuePipe } from './is-column-value.pipe';
import { ValuePillApplierPipe } from '../../ui-value-renderer/pipes/value-pill-applier.pipe';
import { RendererValue } from '@vdms-hq/shared';

@Pipe({
  name: 'rowClasses',
  standalone: true,
})
export class RowClassesPipe implements PipeTransform {
  transform(row: Record<string, unknown>, columns: Column[], enabled: string[], highlighted = false): string {
    const viewColumns = (columns.filter((column) => IsColumnValuePipe.isColumnValue(column)) as ValueColumn[])
      .filter((column) => enabled.includes(column.id))
      .sort((a, b) => enabled.indexOf(a.id) - enabled.indexOf(b.id));

    const firstPill = viewColumns.find((column) => column?.viewFormat?.pills);
    const greyLineIf = viewColumns.find((column) => column?.viewFormat?.greyLineIf);

    const classes: string[] = [];

    if (highlighted) {
      classes.push('bold');
    }

    if (firstPill) {
      const firstPillValue = get(row, firstPill.valuePath, '') as unknown as RendererValue;

      classes.push(...ValuePillApplierPipe.getPillClasses(firstPillValue, firstPill.viewFormat));
    }

    if (greyLineIf) {
      const greyLineIfValue = get(row, greyLineIf.valuePath, '') as unknown as RendererValue;
      const greyIf = greyLineIf.viewFormat?.greyLineIf;
      if (greyIf === 'empty' || greyIf === 'false' || greyIf === '0') {
        const isEmpty = !greyLineIfValue;
        isEmpty && classes.push('grey');
      }

      if (greyIf === 'past') {
        const isPast = new Date(greyLineIfValue as unknown as Date).getTime() < new Date().getTime();
        isPast && classes.push('grey');
      }

      if (greyIf === greyLineIfValue) {
        classes.push('grey');
      }
    }

    return classes.join(' ');
  }
}
