import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Client, ClientLogo, GroupDefaultPolicyType, GroupDefaultPolicyTypes } from '@vdms-hq/api-contract';
import { emailsArrayValidator } from '@vdms-hq/shared';

@Injectable()
export class ClientFormService {
  #form = new FormGroup({
    uuid: new FormControl<string | null>(null),
    name: new FormControl<string | null>('', Validators.required),
    fa_is_required: new FormControl<boolean>(false, { nonNullable: true }),
    custom_languages_enabled: new FormControl<boolean>(false, { nonNullable: true }),
    enable_cold_storage: new FormControl<boolean>(false, { nonNullable: true }),
    enable_stay_in_hot: new FormControl<boolean>(false, { nonNullable: true }),
    asset_qa_enabled: new FormControl<boolean>(false, { nonNullable: true }),
    enable_directly_cold: new FormControl<boolean>(false, { nonNullable: true }),
    useHybrikData: new FormControl<boolean>(false, { nonNullable: true }),
    display_upload_date: new FormControl<boolean>(false, { nonNullable: true }),
    detect_labels_image: new FormControl<boolean>(false, { nonNullable: true }),
    detect_labels_video: new FormControl<boolean>(false, { nonNullable: true }),
    transcribe_uploaded_assets: new FormControl<boolean>(false, { nonNullable: true }),
    auto_audio_language_detection: new FormControl<boolean>(false, { nonNullable: true }),
    event_based_ingest_enabled: new FormControl<boolean>(false, { nonNullable: true }),
    internal_bucket_name: new FormControl<string | null>({
      value: null,
      disabled: true,
    }),
    internal_bucket_region: new FormControl<string | null>({
      value: null,
      disabled: true,
    }),
    purge_assets_safety_net_days: new FormControl<number | null>(null),
    external_bucket_credential_uuid: new FormControl<string | null>(null),
    aspera_upload_credential_uuid: new FormControl<string | null>(null),
    aspera_download_credential_uuid: new FormControl<string | null>(null),
    mediapulse_download_sqs_queue: new FormControl<string | null>(null),
    mediapulse_download_sqs_queue_la: new FormControl<string | null>(null),
    tedial_xml_sqs_queue: new FormControl<string | null>(null),
    tedial_xml_sqs_queue_la: new FormControl<string | null>(null),
    tedial_xml_sqs_queue_1mp: new FormControl<string | null>(null),
    mediapulse_download_sqs_queue_1mp: new FormControl<string | null>(null),
    client_id: new FormControl<string | null>(null),
    logo: new FormControl<ClientLogo | null>(null),
    job_id: new FormControl<string | null>(null),
    librarian_group_id: new FormControl<number | null>(null),
    external_supervisor_emails: new FormControl<string[] | null>(null, emailsArrayValidator(true)),
    default_policies: new FormGroup<{ [key in GroupDefaultPolicyType]?: FormControl<string | null> }>({}),
    tedial_sqs_priority: new FormGroup({
      new_asset: new FormControl<string | null>(null),
      edit_single_asset: new FormControl<string | null>(null),
      edit_batch_assets: new FormControl<string | null>(null),
      tedial_import: new FormControl<string | null>(null),
      csv_batch_update: new FormControl<string | null>(null),
    }),
    proxy_filename_source_property: new FormControl<string | null>(null),
    download_limit: new FormControl<number>(-1),
  });

  enableExternalBucketSelect = false;
  enableAsperaUploadSelect = false;
  enableAsperaDownloadSelect = false;

  get isValid(): boolean {
    return this.#form.valid;
  }

  constructor() {
    this.#initForm();
  }

  touch = () => this.#form.markAllAsTouched();

  reset = () => this.#form.reset();

  get isPristine(): boolean {
    return this.#form.pristine;
  }

  markAsPristine(): void {
    this.#form.markAsPristine();
  }

  disable(): void {
    this.#form.disable({ emitEvent: false });
  }

  enable(): void {
    this.#form.enable({ emitEvent: false });
  }

  get value() {
    return this.#form.getRawValue();
  }

  setForm(client: Client) {
    this.#form.patchValue({
      ...client,
      proxy_filename_source_property: client.proxy_filename_source_property ?? '',
      external_supervisor_emails: client.external_supervisor_emails?.split(';'),
    });
  }

  get(formControlName: string) {
    return this.#form.get(formControlName);
  }

  getGroup() {
    return this.#form;
  }

  #initForm() {
    const defaultPoliciesControls = GroupDefaultPolicyTypes.reduce((acc, key) => {
      acc[key] = new FormControl<string | null>('');
      return acc;
    }, {} as { [key in GroupDefaultPolicyType]?: FormControl<string | null> });

    this.#form.setControl('default_policies', new FormGroup(defaultPoliciesControls));
  }
}
