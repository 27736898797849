import Dexie, { Table } from 'dexie';
import { VideoAnalysisResultsResponse } from '@vdms-hq/api-contract';
import { MetadataListFilters } from './metadata-list-filters.service';
import { Injectable } from '@angular/core';
import {
  PlayerMetadataAssetViewLocalDB,
  PlayerMetadataItemLocalDB,
  PlayerMetadataListSource,
} from './metadata-list.model';
import { camelCaseToWords } from '@vdms-hq/shared';

@Injectable({ providedIn: 'root' })
export class LocalDatabaseService {
  #itemsTable: Table<PlayerMetadataItemLocalDB, number>;
  #assetsTable: Table<PlayerMetadataAssetViewLocalDB, number>;

  #dexie = new Dexie('player-metadata-list');
  #transformContentToString = (type: PlayerMetadataListSource, value?: string | number) => {
    if (typeof value === 'undefined') {
      return '';
    }

    if (type === PlayerMetadataListSource.VIDEO_SEGMENT_DETECTION && typeof value === 'number') {
      return `Shot ${value}`;
    }

    if (type === PlayerMetadataListSource.VIDEO_SEGMENT_DETECTION && typeof value === 'string') {
      return camelCaseToWords(value);
    }

    return String(value);
  };

  #normalizeContent = (value: string) => value.replace(/[^\w\s]/gi, '').toLowerCase() ?? '';

  #transformItem = (item: VideoAnalysisResultsResponse, assetUuid: string): PlayerMetadataItemLocalDB => {
    const type = item.type as unknown as PlayerMetadataListSource;
    return {
      id: item.id,
      assetUuid,
      searchableContent: this.#normalizeContent(this.#transformContentToString(type, item.data.content)),
      content: this.#transformContentToString(type, item.data.content),
      loggingType: item?.data?.loggingType,
      loggingUuid: item?.data?.loggingUuid,
      type,
      searchableType: type,
      start: item.start,
      end: item.end,
      confidence: item.confidence,
      instances: (item.data.instances ?? [])
        .filter((instance) => !!instance)
        .map((instance) => {
          return {
            width: instance.BoundingBox?.Width ?? instance.Width ?? 0,
            height: instance.BoundingBox?.Height ?? instance.Height ?? 0,
            left: instance.BoundingBox?.Left ?? instance.Left ?? 0,
            top: instance.BoundingBox?.Top ?? instance.Top ?? 0,
            start: instance.start ?? 0,
          };
        }),
      urls: Array.from(
        new Set(
          (item.data.instances ?? [])
            ?.map(
              (instance) =>
                instance.Urls?.map((url) => {
                  if (url.startsWith('http')) {
                    return url;
                  }

                  return `https://${url}`;
                }) ?? [],
            )
            .reduce((acc, val) => acc.concat(val), []),
        ),
      ),
    };
  };

  constructor() {
    this.#dexie.version(1).stores({
      items: '++i, [assetUuid+id], type',
      assets: '++i, uuid',
    });
    this.#itemsTable = this.#dexie.table('items');
    this.#assetsTable = this.#dexie.table('assets');
  }

  async isLoaded(assetUuid: string) {
    return !!(await this.#assetsTable.where('uuid').equals(assetUuid).first());
  }

  async findItems(assetUuid: string, filters: MetadataListFilters) {
    if (!Object.keys(filters)?.length) {
      return [];
    }

    const db = this.#itemsTable.where({
      assetUuid: assetUuid,
    });

    let allResults = await db.toArray();

    if (filters.type && filters.type.length > 0) {
      allResults = allResults.filter((item) => filters.type.includes(item.searchableType));
    }

    if (filters.confidence) {
      allResults = allResults.filter((item) => item.confidence >= filters.confidence);
    }

    if (filters.phrase && filters.phrase.length > 0) {
      const phraseToSearch = new RegExp(filters.phrase.join('|'), 'ig');

      allResults = allResults.filter((item) => phraseToSearch.exec(item.searchableContent) !== null);
    }

    allResults = allResults.sort((a, b) => {
      if (!b.end) {
        return 1;
      }
      return new Date(a.start) < new Date(b.end) ? -1 : 1;
    });

    return allResults;
  }

  async removeAsset(assetUuid: string) {
    await this.#assetsTable.where('uuid').equals(assetUuid).delete();
    await this.#itemsTable.where('assetUuid').equals(assetUuid).delete();
  }

  async import(list: VideoAnalysisResultsResponse[], assetUuid: string) {
    await this.removeAsset(assetUuid);

    const supportedTypes = Object.values(PlayerMetadataListSource);

    await this.#itemsTable.bulkAdd(
      list
        .filter((item) => supportedTypes.includes(item.type as unknown as PlayerMetadataListSource))
        .map((item) => {
          const masterItem = this.#transformItem(item, assetUuid);

          const translations = item.data.translations;
          if (!Array.isArray(translations) || translations.length === 0) {
            return [masterItem];
          }

          return [
            masterItem,
            ...translations.map((translation) => ({
              ...masterItem,
              searchableType: translation.language,
              language: translation.language,
              content: translation.content,
              searchableContent: this.#normalizeContent(translation.content ?? ''),
            })),
          ];
        })
        .reduce((acc, val) => acc.concat(val), []),
    );
    await this.#assetsTable.add({ uuid: assetUuid, fetchedAt: new Date().toISOString() });

    return true;
  }

  async importItem(item: VideoAnalysisResultsResponse, assetUuid: string) {
    let masterItem = this.#transformItem(item, assetUuid);

    const translations = item.data.translations;
    if (Array.isArray(translations)) {
      masterItem = {
        ...masterItem,
        ...translations.map((translation) => ({
          ...masterItem,
          searchableType: translation.language,
          language: translation.language,
          content: translation.content,
          searchableContent: this.#normalizeContent(translation.content ?? ''),
        })),
      };
    }

    await this.#itemsTable.add(masterItem);
  }

  async removeItem(id: number, assetUuid: string) {
    await this.#itemsTable.where('[assetUuid+id]').equals([assetUuid, id]).delete();
  }

  async updateItem(
    id: number,
    assetUuid: string,
    content: string | undefined = undefined,
    modifiedItem: Partial<PlayerMetadataItemLocalDB>,
  ) {
    await this.#itemsTable
      .where('[assetUuid+id]')
      .equals([assetUuid, id])
      .filter((item) => {
        if (content === undefined) {
          return true;
        }

        return item.content === content;
      })
      .modify(modifiedItem);
  }

  async getModifiedItems(assetUuid: string) {
    return this.#itemsTable
      .where('assetUuid')
      .equals(assetUuid)
      .filter((item) => !!item.isEdited)
      .toArray();
  }

  async changeModifiedItemsAsNormal(assetUuid: string) {
    return this.#itemsTable
      .where('assetUuid')
      .equals(assetUuid)
      .filter((item) => !!item.isEdited)
      .modify({ isEdited: false });
  }

  async cleanOldAssets(keepLast: number) {
    const assetsToKeep = keepLast - 1;
    const loadedAssets = await this.#assetsTable.toArray();

    if (loadedAssets.length <= assetsToKeep) {
      return;
    }

    const toBeRemoved = loadedAssets
      .sort((a, b) => {
        return new Date(b.fetchedAt).getTime() - new Date(a.fetchedAt).getTime();
      })
      .slice(assetsToKeep);

    for (const asset of toBeRemoved) {
      await this.removeAsset(asset.uuid);
    }
  }

  async getFetchedAt(assetUuid: string) {
    const asset = await this.#assetsTable.where('uuid').equals(assetUuid).first();

    return asset?.fetchedAt ? new Date(asset?.fetchedAt) : null;
  }
}
