import { GetTeamInterface } from '@vdms-hq/api-contract';

export type ClientTeamsFiltersView = {
  keyword: string;
};

export interface ClientTeamDialogData {
  teamUuid?: string;
  preview?: boolean;
}

export type ClientTeamsListViewModel = {
  props: GetTeamInterface;
  sharedCollectionsNames?: string[] | null;
  sharedCollectionUuids: string[] | null;
  usersCount: number;
  userUuids?: string[];
  createdByName: string;
} & GetTeamInterface;

export class ClientTeamsListView implements ClientTeamsListViewModel {
  constructor(public props: GetTeamInterface) {}

  static fromClientTeam(team: GetTeamInterface) {
    return new ClientTeamsListView(team);
  }

  get uuid() {
    return this.props.uuid;
  }

  get name() {
    return this.props.name;
  }

  get created_by() {
    return this.props.created_by;
  }

  get createdByName() {
    return this.props.created_by.name;
  }

  get shared_collections() {
    return this.props.shared_collections ?? [];
  }

  get sharedCollectionUuids() {
    if (!this.props.shared_collections) {
      return null;
    }
    return this.props.shared_collections.reduce((acc, collection) => {
      acc.push(collection.uuid);
      return acc;
    }, [] as string[]);
  }

  get sharedCollectionsNames() {
    if (!this.props.shared_collections) {
      return null;
    }
    return this.props.shared_collections.reduce((acc, collection) => {
      acc.push(collection.name);
      return acc;
    }, [] as string[]);
  }

  get users() {
    if (!this.props.users) {
      return [];
    }
    return this.props.users;
  }

  get userUuids() {
    if (!this.props.users || this.props.users.length === 0) {
      return undefined;
    }
    return this.props.users.reduce((acc, user) => {
      acc.push(user.uuid);
      return acc;
    }, [] as string[]);
  }

  get usersCount() {
    if (!this.props.shared_collections) {
      return 0;
    }
    return this.props.users.length;
  }
}
