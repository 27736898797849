import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { FormControlValueAccessorComponent } from '../../models/form/inputs/form-control-value-accessor.component';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'vdms-hq-ui-form-input-grid[textLength]',
  templateUrl: './form-input-grid.component.html',
  styleUrls: ['./form-input-grid.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormInputGridComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => FormInputGridComponent),
    },
  ],
})
export class FormInputGridComponent extends FormControlValueAccessorComponent implements OnInit {
  @Input() textLength!: number;
  @Input() inputType: 'number' | 'text' = 'number';

  #destroy = new Subject<void>();

  innerFormGroup = new FormGroup({});
  innerFormControl = new FormControl<string>('');

  ngOnInit() {
    this.#initControls();
    this.#listenControlClear();
    super.ngOnInit();
  }

  get controlsArrayLength() {
    return Array.from({ length: this.textLength }, () => '');
  }

  override validate(): ValidationErrors | null {
    return this.innerFormControl.value?.length === this.textLength ? null : { textLength: true };
  }

  onKeyUp(index: number, event: KeyboardEvent) {
    if (event.key == 'Backspace') {
      if (!this.innerFormGroup.get(index.toString())?.value) {
        this.#focusPrevInput(index);
      }
    } else {
      this.onWrite(index);
    }
  }

  onWrite(index: number) {
    const control = this.innerFormGroup.get(index.toString());
    const valueExist = control?.value;
    if (control?.value?.length > 1) {
      control?.value && this.#handlePaste(control?.value, index);
    }
    this.#updateValue();
    if (valueExist && index < this.controlsArrayLength.length - 1) {
      this.#focusNextInput(index);
    }
  }

  inputValid(index: number) {
    return Boolean(this.innerFormGroup.get(index.toString())?.value);
  }

  #focusNextInput(index: number) {
    const nextInput = document.querySelectorAll('.input-grid > input')[index + 1] as HTMLInputElement;
    if (nextInput) {
      nextInput.focus();
    }
  }

  #focusPrevInput(index: number) {
    const prevInput = document.querySelectorAll('.input-grid > input')[index - 1] as HTMLInputElement;
    if (prevInput) {
      prevInput.focus();
    }
  }

  #initControls() {
    for (let i = 0; i < this.textLength; i++) {
      this.innerFormGroup.addControl(i.toString(), new FormControl<string>(''));
    }

    this.innerFormControl.setValidators([Validators.pattern('^.{' + this.textLength + '}$')]);
    this.innerFormControl.updateValueAndValidity({ emitEvent: true });
  }

  #listenControlClear() {
    this.innerFormControl.valueChanges
      .pipe(
        takeUntil(this.destroy),
        filter((text) => !text?.length),
      )
      .subscribe(() => this.#clear());
  }

  #updateValue() {
    const text: string = Object.values(this.innerFormGroup?.value ?? {}).reduce((current: string, accumulator) => {
      if (current) {
        return current + accumulator;
      }
      return accumulator as string;
    }, '') as string;
    this.innerFormControl.setValue(text);
  }

  #clear() {
    Object.values(this.innerFormGroup.controls as FormControl[]).forEach((control: FormControl) =>
      control?.setValue(''),
    );
    this.#focusFirstInput();
  }

  #focusFirstInput() {
    const firstInput = document.querySelectorAll('.input-grid > input')[0] as HTMLInputElement;
    if (firstInput) {
      firstInput.focus();
    }
  }

  #handlePaste(value: string, startIndex: number) {
    value.split('').forEach((letter, letterIndex) => {
      const controlExist = this.innerFormGroup.get((startIndex + letterIndex).toString());
      controlExist && controlExist?.setValue(letter);
    });
  }
}
