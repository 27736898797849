<div class="search-input-outer search-hero-input">
  <mat-icon *ngIf="!loading">search</mat-icon>
  <vdms-hq-ui-loader *ngIf="loading" [centerPadding]="false" class="search-input-outer__loader"></vdms-hq-ui-loader>
  <vdms-hq-ui-form-input-text
    [alternativeAppearance]="alternativeAppearance"
    [(ngModel)]="currentQuery"
    (keyup)="keyUp($event)"
    (clickSuffix)="updateQuery()"
    [placeholder]="placeholder | translate"
    [suffixConfig]="{
      text: alternativeAppearance ? '' : 'Search',
      isIcon: alternativeAppearance ? 'search' : 'search',
      onFocus: false,
      type: 'primary'
    }"
    [withFooter]="false"
    class="search-input"
  >
  </vdms-hq-ui-form-input-text>
</div>
