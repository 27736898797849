<div class="search-input-outer" [bdcWalkTriggerFor]="searchBar">
  <vdms-hq-ui-form-input-text-area
    #input
    [(ngModel)]="currentQuery"
    [minRows]="1"
    [suffixConfig]="$castToSuffixConfig(advancedSearchStateService.suffixConfig)"
    [disableEnterBreakline]="true"
    [alternativeAppearance]="true"
    [autocompleteList]="(latestQueries$ | async) ?? undefined"
    (clickEnter)="updateQuery()"
    (clickSuffix)="advancedSearchOpen = !advancedSearchOpen"
    class="advanced--search__input textarea--no-padding"
    placeholder="Search Assets"
  ></vdms-hq-ui-form-input-text-area>
  <vdms-hq-ui-button
    *ngIf="returnButtonVisibility$ | async; let config"
    [color]="config.type"
    (click)="updateQuery()"
    >{{ config.text }}</vdms-hq-ui-button
  >
  <div class="advanced-search--icon__container">
    <span cdkOverlayOrigin #trigger="cdkOverlayOrigin"></span>
    <ng-template
      cdkConnectedOverlay
      cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayPositions]="advancedSearchStateService.overlayPositionConfig"
      [cdkConnectedOverlayOpen]="advancedSearchOpen"
      [cdkConnectedOverlayHasBackdrop]="true"
      (backdropClick)="advancedSearchOpen = false"
    >
      <vdms-hq-advanced-search-overlay></vdms-hq-advanced-search-overlay>
    </ng-template>
  </div>
</div>

<vdms-hq-tour-item #searchBar configName="searchBar"></vdms-hq-tour-item>
