import { Component, NO_ERRORS_SCHEMA } from '@angular/core';

@Component({
  selector: 'app-chat-agent',
  styleUrls: ['./chat-agent.component.scss'],
  templateUrl: './chat-agent.component.html',
  standalone: true,
  schemas: [NO_ERRORS_SCHEMA],
})
export class ChatAgentComponent {}
