import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AsperaDownloaderService } from '../aspera/aspera-downloader.service';
import { ASPERA_CONFIG, AsperaConfig } from '../config-token';
import { AsperaDownloadableFile, OrderService } from '@vdms-hq/api-contract';

@Injectable()
export class AssetDownloadService {
  completedTransfer$ = this.aspera.completedTransfer$;

  constructor(
    private router: Router,
    @Inject(ASPERA_CONFIG) private config: AsperaConfig,
    private aspera: AsperaDownloaderService,
    private orderService: OrderService,
  ) {}

  fetchOrderWithAspera(token: string): void {
    this.aspera.initAsperaConnect();
    this.#fetchOrder(token).subscribe((response) => {
      this.aspera.runOutsideAngular({
        remote_host: response.remoteHost ?? this.config.remoteHost,
        remote_user: response.remoteUser ?? this.config.remoteUser,
        direction: 'receive',
        authentication: 'token',
        paths: response.paths ?? [],
        cookie: response.cookie,
        token: response.token,
        ssh_port: response.sshPort ?? 33001,
        fasp_port: response.faspPort ?? 33001,
      });
      this.router.navigate(['/']);
    });
  }

  withAspera(response: AsperaDownloadableFile): void {
    this.aspera.initAsperaConnect();
    this.aspera.runOutsideAngular({
      remote_host: response.remoteHost ?? this.config.remoteHost,
      remote_user: response.remoteUser ?? this.config.remoteUser,
      direction: 'receive',
      authentication: 'token',
      paths: response.paths ?? [],
      cookie: response.cookie,
      token: response.token,
      ssh_port: response.sshPort ?? 33001,
      fasp_port: response.faspPort ?? 33001,
    });
  }

  #fetchOrder(token: string): Observable<AsperaDownloadableFile> {
    return this.orderService.downloadWithAspera(token).pipe(
      map((item) => item.data),
      catchError((error) => {
        this.router.navigate(['/']);
        return of(error);
      }),
    );
  }
}
