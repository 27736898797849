import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { UIButtonModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { FileUploadState, UploadState } from '../../logic/model/upload-state';

@Component({
  selector: 'vdms-hq-storage-shared-selected-file',
  templateUrl: './selected-file.component.html',
  styleUrls: ['./selected-file.component.scss'],
  imports: [CommonModule, MatProgressBarModule, UIButtonModule, TranslateModule],
  standalone: true,
})
export class SelectedFileComponent<STATUS> {
  @Input() disabled = false;
  @Input() noBorders = false;
  @Input() file: File | null = null;
  @Input() uploadState: FileUploadState<STATUS> | null = null;
  @Input() noBottomMargin = false;
  @Output() clear = new EventEmitter();

  get isError() {
    return this.uploadState?.state === UploadState.ERROR;
  }
}
