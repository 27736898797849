import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translateType',
  pure: true,
  standalone: true,
})
export class TranslateTypePipe implements PipeTransform {
  private translateService = inject(TranslateService);

  transform(type: string, languageKey?: string, loggingType?: string): string | null {
    if (!type) {
      return null;
    }

    if (languageKey) {
      return languageKey;
    }

    if (loggingType) {
      return loggingType;
    }

    return this.translateService.instant(`common.player_metadata_list.stream_type_label.${type}`);
  }
}
