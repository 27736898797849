import { AuthorizedClient, Permission } from '@vdms-hq/activated-client';
import { AnySidebarItem, SidebarItem } from '@vdms-hq/ui';
import { DISCOUNTS_ROUTER_BASE } from '@vdms-hq/discounts';
import { filterEmpty } from '@vdms-hq/shared';

export function adminNavItemsConfig(client: AuthorizedClient): AnySidebarItem[] {
  const configs = Object.entries(client.assetView).sort(([keyA], [keyB]) => keyA.localeCompare(keyB));
  const libraryConfigs = client.libraryConfigs ?? [
    {
      id: 'legacy-default',
      isClient: false,
      name: 'Client legacy default',
      structure: client.library.structure,
    },
  ];

  const isSalesForceClient = client.integrations.salesforce;

  const tablesAndLists: SidebarItem[] = [
    {
      name: 'common.admin.sidebar.tables_and_lists.default',
      routerLink: '/admin/tables-and-lists/default',
    },
    {
      name: 'common.admin.sidebar.tables_and_lists.deleted',
      routerLink: '/admin/tables-and-lists/deleted',
    },
    {
      name: 'common.admin.sidebar.tables_and_lists.browse',
      routerLink: '/admin/tables-and-lists/browse',
    },
    {
      name: 'common.admin.sidebar.tables_and_lists.cart',
      routerLink: '/admin/tables-and-lists/cart',
    },
    {
      name: 'common.admin.sidebar.tables_and_lists.collections',
      routerLink: '/admin/tables-and-lists/collections',
    },
    {
      name: 'common.admin.sidebar.tables_and_lists.shared_packs',
      routerLink: '/admin/tables-and-lists/shared-packs',
    },
    {
      name: 'common.admin.sidebar.tables_and_lists.orders',
      routerLink: '/admin/tables-and-lists/orders',
    },
    {
      name: 'common.admin.sidebar.tables_and_lists.upload_jobs',
      routerLink: '/admin/tables-and-lists/upload-jobs',
    },
    {
      name: 'common.admin.sidebar.tables_and_lists.content_corner',
      routerLink: '/admin/tables-and-lists/content-corner',
    },
    {
      name: 'common.admin.sidebar.tables_and_lists.license_package',
      routerLink: '/admin/tables-and-lists/license-package',
    },
  ];

  if (isSalesForceClient) {
    tablesAndLists.push({
      name: 'common.admin.sidebar.tables_and_lists.preview_request',
      routerLink: '/admin/tables-and-lists/preview-request',
    });
  }

  const navItems: (AnySidebarItem | undefined)[] = [
    {
      name: 'View configuration',
      routerLink: '/admin/general',
    },
    {
      name: 'Storage and Database',
      routerLink: '/admin/storage',
    },
    'separator',
    {
      name: 'Asset Tables and Lists',
      routerLink: '/admin/tables-and-lists/default',
      children: tablesAndLists,
    },
    {
      name: 'Other Tables and Lists',
      routerLink: '/admin/tables-and-lists/other-rights-partners',
      children: [
        {
          name: 'common.admin.sidebar.tables_and_lists.other_rights_partners',
          routerLink: '/admin/tables-and-lists/other-rights-partners',
        },
        {
          name: 'common.admin.sidebar.tables_and_lists.other_rights_contracts',
          routerLink: '/admin/tables-and-lists/other-rights-contracts',
        },
        {
          name: 'common.admin.sidebar.tables_and_lists.other_licensed_packages',
          routerLink: '/admin/tables-and-lists/other-licensed-packages',
        },
        {
          name: 'common.admin.sidebar.tables_and_lists.other_licensed_packages_connect2',
          routerLink: '/admin/tables-and-lists/other-licensed-packages-connect2',
        },
        {
          name: 'common.admin.sidebar.tables_and_lists.other_collections',
          routerLink: '/admin/tables-and-lists/other-collections',
        },
        // {
        //   name: 'Orders',
        //   routerLink: 'orders',
        // },
        // {
        //   name: 'Orders approval',
        //   routerLink: 'orders-approval',
        // },
        // {
        //   name: 'Media Pulse Orders',
        //   routerLink: 'mediapulse-orders',
        // },
        // {
        //   name: 'Downloads',
        //   routerLink: 'shared-packs',
        // }
      ],
    },
    {
      name: 'Asset filters',
      routerLink: '/admin/asset-filters/browse',
      children: [
        {
          name: 'Browse',
          routerLink: '/admin/asset-filters/browse',
        },
        {
          name: 'Deleted',
          routerLink: '/admin/asset-filters/deleted',
        },
        {
          name: 'Quarantined',
          routerLink: '/admin/asset-filters/quarantined',
        },
      ],
    },
    {
      name: 'Details & Form',
      routerLink: '/admin/asset-details/',
      children: [
        ...configs.map(([key, config]) => ({
          name: config.name,
          routerLink: `/admin/asset-details/${key}`,
        })),
        'separator',
        {
          name: 'Add new',
          routerLink: '/admin/asset-details/add',
        },
      ],
    },
    {
      name: 'Library browser',
      routerLink:
        libraryConfigs.length > 0 ? `/admin/browse-library/${libraryConfigs[0].id}` : '/admin/browse-library/add',
      children: [
        ...libraryConfigs.map((schema) => ({
          name: schema.name,
          routerLink: `/admin/browse-library/${schema.id}`,
        })),
        'separator',
        {
          name: 'Add new',
          routerLink: '/admin/browse-library/add',
        },
      ],
    },
    'separator',
    {
      name: 'Field definitions',
      routerLink: '/admin/fields',
    },
    {
      name: 'Field sources',
      routerLink: '/admin/field-sources',
    },
    {
      name: 'Mandatory fields',
      routerLink: 'mandatory-fields',
    },
    {
      name: 'Client languages',
      routerLink: 'languages',
    },
    {
      name: 'Video Codecs',
      routerLink: 'video-codecs',
    },
    {
      name: 'Filename Conventions',
      routerLink: 'metadata-recognition',
    },
    {
      name: 'Reindex Automatization',
      routerLink: 'reindex-automatization',
    },
    {
      name: 'Metadata snapshots',
      routerLink: 'metadata-snapshots',
    },
    client.permissions.includes(Permission.PERMISSION_BROWSE_QA_CONFIGS)
      ? {
          name: 'QA Configurations',
          routerLink: 'quality-control',
        }
      : undefined,
    client.permissions.includes(Permission.BROWSE_LANDINGPAD_ASSETS)
      ? {
          name: 'LandingPad',
          routerLink: 'landingpad',
        }
      : undefined,

    'separator',
    client.permissions.includes(Permission.BROWSE_DISCOUNTS)
      ? {
          name: 'Discounts',
          routerLink: DISCOUNTS_ROUTER_BASE.ROOT,
        }
      : undefined,

    client.permissions.includes(Permission.BROWSE_RATES)
      ? {
          name: 'Billing configuration',
          routerLink: 'billing',
          children: [
            {
              name: 'Billing reports settings',
              routerLink: 'billing/reports-settings',
            },
          ],
        }
      : undefined,
    'separator',
    {
      name: 'Connect2',
      routerLink: 'connect2-admin',
    },
    {
      name: 'Manage Notifications',
      routerLink: 'notification-subscriptions',
    },
    client.permissions.includes(Permission.MANAGE_QUARANTINE_CONFIGS)
      ? {
          name: 'Quarantine',
          routerLink: 'quarantine',
        }
      : undefined,

    'separator',
    {
      name: 'Aspera Keys',
      routerLink: 'aspera-keys',
    },
    client.permissions.includes(Permission.BROWSE_CREDENTIALS)
      ? {
          name: 'Credentials',
          routerLink: 'credentials',
        }
      : undefined,

    {
      name: 'Settings snapshots',
      routerLink: 'settings-snapshots',
    },
    'separator',

    client.permissions.includes(Permission.BROWSE_THRESHOLDS)
      ? {
          name: 'Global configuration',
          routerLink: 'global',
        }
      : undefined,
    {
      name: 'Email text',
      routerLink: 'email-text',
    },
  ];
  return navItems.filter(filterEmpty) as AnySidebarItem[];
}
