import { Component, Input } from '@angular/core';
import { AssetSearchService, PersistenceSearchParams } from '@vdms-hq/api-contract';
import { switchMap, take, withLatestFrom } from 'rxjs/operators';
import { FieldsConfigService } from '@vdms-hq/config';
import { ToastService } from '@vdms-hq/toast';
import { ColumnSettingsScope } from '@vdms-hq/firebase-contract';
import { UIFormModule, UILoaderModule, UIModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'vdms-hq-simple-ai-query-input',
  templateUrl: './simple-ai-query-input.component.html',
  styleUrls: ['../search-hero-input/search-hero-input.component.scss'],
  imports: [UIModule, UILoaderModule, UIFormModule, TranslateModule],
  standalone: true,
})
export class SimpleAiQueryInputComponent {
  readonly #scope: ColumnSettingsScope = 'browse';

  currentQuery = '';
  loading = false;
  @Input() placeholder = 'pages.home.search.ai_hero.placeholder';
  @Input() alternativeAppearance = false;

  constructor(
    public assetSearchService: AssetSearchService,
    private configService: FieldsConfigService,
    private toastService: ToastService,
  ) {}

  updateQuery() {
    this.loading = true;
    this.configService.filterDefinitionsForAssetFilters$
      .pipe(
        take(1),
        withLatestFrom(this.configService.availableFieldDefinitionsColumnsForMultipleTableViews$(this.#scope)),
        switchMap(([filterDefinitions, resultsDefinitions]) =>
          this.assetSearchService.aiSearch(filterDefinitions, resultsDefinitions, this.currentQuery),
        ),
      )
      .subscribe({
        next: (params: PersistenceSearchParams) => {
          this.assetSearchService.applyParams(params);
          this.loading = false;
        },
        error: () => {
          this.toastService.warning({ id: 'ai_search_failed', message: 'common.notifications.generic.try_again' });
          this.loading = false;
        },
      });
  }

  keyUp($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.updateQuery();
    }
  }
}
