<vdms-hq-ui-dialog-wrapper [sharedE2eId]="'team-dialog-wrapper'">
  <h1 title class="mb-0" [sharedE2eId]="'team-name-preview'">
    {{ title | translate }}
  </h1>
  <div content class="relative p-scroll mh-15vh" [formGroup]="form">
    <vdms-hq-ui-loader
      *ngIf="isLoading$ | async"
      [backdrop]="true"
      mode="over-parent"
      [text]="'common.client-teams.dialogs.loading' | translate"
    ></vdms-hq-ui-loader>
    <ng-container *ngIf="!data.preview">
      <vdms-hq-ui-form-section layout="single" [divider]="true">
        <vdms-hq-ui-form-input-text
          formControlName="name"
          [label]="'common.client-teams.dialogs.fields.name' | translate"
          [sharedE2eId]="'team-name-input'"
        ></vdms-hq-ui-form-input-text>
      </vdms-hq-ui-form-section>
      <div *ngIf="initialized$ | async" class="relative">
        <ng-container *ngIf="(viewState$ | async) === 'users'; else manageCollections">
          <vdms-hq-ui-form-section
            layout="single"
            [divider]="false"
            [titleSize]="'medium'"
            [title]="'common.client-teams.dialogs.fields.users' | translate"
          >
            <vdms-hq-ui-add-dialog-selectable-wrapper
              [dataSource]="clientUsersDataSource"
              [filters]="clientUsersDataSource.filters"
              [fieldsConfig]="clientUsersFiltersConfig"
              [config]="clientUsersTilesConfig"
              [loadingText]="'common.client-teams.dialogs.fields.users-loading' | translate"
              [selected]="form.controls.user_uuids.value ?? []"
              [emptyMessage]="'Could not find any collections'"
              (selectedChangeEmit)="selectUsers($event)"
            >
            </vdms-hq-ui-add-dialog-selectable-wrapper>
          </vdms-hq-ui-form-section>
        </ng-container>
        <ng-template #manageCollections>
          <vdms-hq-ui-form-section
            layout="single"
            [divider]="false"
            [titleSize]="'medium'"
            [title]="'common.client-teams.dialogs.fields.collections' | translate"
          >
            <vdms-hq-ui-add-dialog-selectable-wrapper
              [dataSource]="collectionsDataSource"
              [filters]="collectionsDataSource.filters"
              [fieldsConfig]="collectionsFiltersConfig"
              [config]="collectionsTilesConfig"
              [loadingText]="'common.client-teams.dialogs.fields.collections-loading' | translate"
              [selected]="form.controls.shared_collection_uuids.value ?? []"
              [emptyMessage]="'Could not find any collections'"
              (selectedChangeEmit)="selectCollection($event)"
            >
            </vdms-hq-ui-add-dialog-selectable-wrapper>
          </vdms-hq-ui-form-section>
        </ng-template>
      </div>
    </ng-container>
    <ng-container *ngIf="data.preview">
      <div content class="relative p-scroll">
        <vdms-hq-ui-form-section layout="grid-2-columns-wide" [divider]="false">
          <vdms-hq-ui-form-section
            layout="single"
            class="border-right pb-1"
            [divider]="false"
            [titleSize]="'medium'"
            [title]="'common.client-teams.dialogs.fields.users' | translate"
            [icon]="'groups'"
          >
            <ul>
              <li *ngFor="let user of previewUsers">
                {{ user }}
              </li>
            </ul>
          </vdms-hq-ui-form-section>
          <vdms-hq-ui-form-section
            layout="single"
            class="pb-1"
            [divider]="false"
            [titleSize]="'medium'"
            [title]="'common.client-teams.dialogs.fields.collections' | translate"
            [icon]="'playlist_add'"
          >
            <ul>
              <li *ngFor="let collection of previewCollections">
                {{ collection }}
              </li>
            </ul>
          </vdms-hq-ui-form-section>
        </vdms-hq-ui-form-section>
      </div>
    </ng-container>
  </div>
  <div footer>
    <vdms-hq-ui-button color="secondary" (click)="closeDialog()" [sharedE2eId]="'team-create-edit-cancel-button'">
      {{ 'common.global.cancel' | translate }}
    </vdms-hq-ui-button>
    <ng-container *ngIf="(initialized$ | async) && !this.data.preview">
      <vdms-hq-ui-button *ngIf="viewState$ | async as viewState" color="secondary" (click)="toggleViewState(viewState)">
        {{ viewState === 'users' ? (collectionsBtn | translate) : (usersBtn | translate) }}
      </vdms-hq-ui-button>
      <vdms-hq-ui-button
        color="primary"
        [sharedE2eId]="'team-create-edit-save-button'"
        [loading]="isLoading$ | async"
        [disabled]="form.invalid"
        (click)="onSave()"
      >
        {{
          ((isEdit$ | async) ? 'common.client-teams.dialogs.edit.button' : 'common.client-teams.dialogs.create.button')
            | translate
        }}
      </vdms-hq-ui-button>
    </ng-container>
  </div>
</vdms-hq-ui-dialog-wrapper>
