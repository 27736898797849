import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormSectionComponent, InfoBarType, UIButtonModule, UIFormModule, UILayoutModule } from '@vdms-hq/ui';
import { CommonModule } from '@angular/common';
import { EmailTextApiService } from '@vdms-hq/api-contract';
import { forkJoin, shareReplay, take } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ToastService } from '@vdms-hq/toast';
import { castTo } from '@vdms-hq/shared';

@Component({
  selector: 'vdms-hq-email-text-template-creator',
  templateUrl: './email-text-template-creator.component.html',
  styleUrls: ['./email-text-template-creator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, UILayoutModule, UIFormModule, UIButtonModule, TranslateModule, FormSectionComponent],
  providers: [],
})
export class EmailTextTemplateCreatorComponent implements OnInit {
  protected readonly InfoBarType = InfoBarType;

  #api = inject(EmailTextApiService);
  #toast = inject(ToastService);

  form = new FormGroup({});
  dataSource$ = this.#api.getEmailTemplates().pipe(
    shareReplay({
      refCount: true,
      bufferSize: 1,
    }),
  );
  loading = false;
  $castToString = castTo<string>();

  ngOnInit() {
    this.#init();
  }

  save() {
    this.loading = true;
    this.#toast.processing({
      id: 'email-templates',
      message: 'Updating email templates',
    });

    const payload = Object.entries(this.form.value).map(([key, value]) => {
      const override_text = this.#changeIntoBreakLine(value as string);
      return this.#api.updateEmailTemplates(key, { override_text });
    });
    forkJoin([...payload]).subscribe(() => {
      this.loading = false;

      this.#toast.success({
        id: 'email-templates',
        message: 'Email templates updated',
      });
    });
  }

  setVariable(variable: string, uuid: string) {
    const control = this.form.get(uuid);
    if (!control) {
      return;
    }

    control.setValue(control.value + `{${variable}}`);
  }

  #init() {
    this.dataSource$.pipe(take(1)).subscribe((data) => {
      data.forEach((item) => {
        const overrideTextControl = new FormControl(this.#changeIntoNewLine(item.override_text ?? item.default_text));
        this.form.addControl(item.uuid, overrideTextControl);
      });
    });
  }

  #changeIntoNewLine(item: string) {
    return item.replace(/<br\s*\/?>/gi, '\n');
  }

  #changeIntoBreakLine(item: string) {
    return item.replace(/\n/g, '<br/>');
  }
}
