import { ChangeDetectionStrategy, Component, Input, Renderer2 } from '@angular/core';
import { StatsChartViewModel } from '../logic/models/stats-chart-view.model';
import { ChartDataset, ChartOptions, ChartType, Color } from 'chart.js';
import { axisLabelFont, defaultPallete } from '../logic/models/default.config';

@Component({
  selector: 'vdms-hq-ui-stats-chart-line',
  templateUrl: './stats-chart-line.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatsChartLineComponent {
  chartOptions!: ChartOptions;
  chartLegend = false;
  chartType: ChartType = 'line';
  chartPlugins = [];
  datasets: ChartDataset[] = [];
  labels: string[] = [];
  maxHeight?: string;

  @Input() set statsChartVM(value: StatsChartViewModel) {
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      backgroundColor: 'rgba(255, 255, 255, 0.0)',
      scales: {
        xAxes: {
          beginAtZero: value.beginAtZero ?? true,
          ticks: {
            ...axisLabelFont,
            color: value.labelsTextColor,
            autoSkip: true,
            maxTicksLimit: 8,
            maxRotation: 0,
            minRotation: 0,
          },
        },
        yAxes: {
          beginAtZero: value.beginAtZero ?? true,
          ticks: {
            ...axisLabelFont,
            color: value.labelsTextColor,
            callback: value.yAxisCallback ?? undefined,
          },
        },
      },
    };
    this.maxHeight = value.maxHeight ?? undefined;
    this.datasets = value.chartDataSets;
    this.labels = value.labels;
    this.chartLegend = value.legend ?? false;
    const canvas = this.renderer.createElement('canvas');
    const gradient = value.lineSettings?.gradientUnderLine;
    const ctx = canvas.getContext('2d');
    let gradientElement: CanvasGradient;

    if (ctx && gradient) {
      gradientElement = ctx.createLinearGradient(0, 0, 0, 150);

      gradient.forEach(({ color, step }) => {
        gradientElement.addColorStop(step, color);
      });
    }

    this.datasets = (value.chartDataSets as ChartDataset<'line'>[]).map((dataset, index) => {
      const borderJoinStyle = 'bevel';
      const borderColor = (dataset.borderColor ?? defaultPallete[index] ?? '#3573FF') as Color;
      const pointBackgroundColor = (dataset.pointBorderColor ?? defaultPallete[index] ?? '#3573FF') as Color;
      const pointBorderColor = (dataset.pointBorderColor ?? defaultPallete[index] ?? '#3573FF') as Color;
      const tension = dataset.tension ?? 0.4;

      return <ChartDataset>{
        ...dataset,
        backgroundColor: gradientElement,
        borderJoinStyle,
        borderColor,
        pointBackgroundColor,
        pointBorderColor,
        tension,
      };
    });

    this.labels = value.labels;
  }

  constructor(private renderer: Renderer2) {}
}
