import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  switchMap,
  takeUntil,
  take,
  catchError,
  withLatestFrom,
} from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import {
  Supplier,
  SupplierService,
  UserModel,
  ClientUserAdminService,
  ExternalApiService,
  PatchChangeUserPoliciesType,
} from '@vdms-hq/api-contract';
import { map } from 'rxjs/operators';
import { DialogResponse, SelectOption, SelectionIdentifier } from '@vdms-hq/shared';
import {
  FormSectionComponent,
  UIButtonModule,
  UIDialogWrapperModule,
  UIFormModule,
  PhoneTypeSelectOptions,
  PhoneType,
  UIConfirmationDialogService,
} from '@vdms-hq/ui';
import { v4 as uuidv4 } from 'uuid';

import { TranslateModule } from '@ngx-translate/core';
import { ToastService } from '@vdms-hq/toast';
import { ActivatedClientModule, WithPermissions } from '@vdms-hq/activated-client';
import { GenerateUserApiTokenComponent, PolicyTypeByView } from '@vdms-hq/users';
import { MatTabsModule } from '@angular/material/tabs';
import { ManagePoliciesListComponent } from '../manage-policies/manage-policies-list.component';
import { ClientUserAdminDialogDataSource } from '../../logic/client-user-admin-dialog-datasource';
import { ManageUserDialogData, ManageUserDialogResponse } from '../../models/manage-user-dialog.model';

type UserFormGroupType = {
  name: FormControl<string | null>;
  email: FormControl<string>;
  timezone: FormControl<string | null>;
  date_format: FormControl<string | null>;
  time_format: FormControl<string | null>;
  supplier: FormControl<string | null>;
};

@Component({
  selector: 'vdms-hq-add-user-dialog',
  standalone: true,
  imports: [
    CommonModule,
    UIFormModule,
    UIDialogWrapperModule,
    UIButtonModule,
    FormSectionComponent,
    TranslateModule,
    ActivatedClientModule,
    MatTabsModule,
    GenerateUserApiTokenComponent,
    ManagePoliciesListComponent,
  ],
  templateUrl: './manage-user-dialog.component.html',
  styleUrls: ['./manage-user-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageUserDialogComponent extends WithPermissions() implements OnInit, OnDestroy {
  private clientUserAdminService = inject(ClientUserAdminService);
  private supplierService = inject(SupplierService);
  private toast = inject(ToastService);
  private externalApiService = inject(ExternalApiService);
  private confirmationDialogService = inject(UIConfirmationDialogService);
  public dataSource = inject(ClientUserAdminDialogDataSource);
  public dialogRef = inject(MatDialogRef<ManageUserDialogComponent, ManageUserDialogResponse>);
  public data = inject(MAT_DIALOG_DATA) as ManageUserDialogData;

  @Input() cancelButton = false;

  protected readonly phoneTypes = PhoneTypeSelectOptions;
  private readonly minimalUserData = {
    picture: '',
    landing_page: '',
    theme: '',
  };
  readonly #uuid = uuidv4();
  #destroyed$ = new Subject<void>();

  userForm = new FormGroup<UserFormGroupType>({
    name: new FormControl<string | null>('', [Validators.required]),
    email: new FormControl<string>('', { nonNullable: true }),
    timezone: new FormControl<string | null>(null),
    date_format: new FormControl<string | null>(null),
    time_format: new FormControl<string | null>(null),
    supplier: new FormControl<string | null>(''),
  });

  timezones: SelectOption[] = [{ key: 'UTC+1', label: 'UTC+1' }];
  dateFormats: SelectOption[] = [{ key: 'ISO (2020-02-22)', label: 'ISO (2020-02-22)' }];
  timeFormats: SelectOption[] = [{ key: 'ISO (24H)', label: 'ISO (24H)' }];
  suppliers$: Observable<Supplier[]> = EMPTY;
  suppliersSelectOptions$: Observable<SelectOption[]> = EMPTY;
  isUpdating$ = new BehaviorSubject<boolean>(false);
  users: UserModel[] = [];
  userGroups = new FormControl<string[]>([], { nonNullable: true });
  userPhone = new FormGroup({
    type: new FormControl<PhoneType>(PhoneType.OFFICE, { nonNullable: true }),
    number: new FormControl<string | null>(null),
  });
  companyAddress = new FormGroup({
    address1: new FormControl<string | null>(null),
    address2: new FormControl<string | null>(null),
    postalCode: new FormControl<string | null>(null),
    country: new FormControl<string | null>(null),
  });
  get phoneTypeValue(): PhoneType {
    return this.userPhone.get('type')?.value || PhoneType.OFFICE;
  }

  get numberLabel(): string {
    return this.phoneTypeValue === PhoneType.MOBILE
      ? 'pages.settings.mobile_number'
      : 'pages.settings.office_phone_number';
  }

  get numberPlaceholder(): string {
    return this.phoneTypeValue === PhoneType.MOBILE
      ? 'pages.settings.mobile_number_placeholder'
      : 'pages.settings.office_phone_number_placeholder';
  }

  get phoneNumber() {
    return this.userPhone.get('number')?.value || '';
  }

  get officeAddress() {
    return Object.values(this.companyAddress.value).join(',');
  }

  countries$ = this.externalApiService.getCountries().pipe(
    takeUntil(this.#destroyed$),
    map((countries) => countries.map((c) => <SelectOption>{ label: c, key: c })),
  );

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.suppliers$ = this.supplierService.getSuppliers();
    this.suppliersSelectOptions$ = this.suppliers$.pipe(
      map((suppliers: Supplier[]) =>
        suppliers.map(({ uuid: key, name: label }: { uuid: string; name: string }) => ({ key, label })),
      ),
      map((suppliers) => {
        return [
          {
            key: null,
            label: 'N/A',
          },
          ...suppliers,
        ];
      }),
    );

    if (this.data.edit && this.data.users?.length === 1) {
      this.dataSource.emitSelectedPolicies(this.data.users[0].policies);
    }
  }

  ngOnDestroy() {
    this.dataSource.reset();
    this.#destroyed$.next();
    this.#destroyed$.complete();
  }

  onSubmit() {
    if (!this.data.edit && this.userForm.invalid) {
      return;
    }

    const user = {
      ...this.minimalUserData,
      ...this.userForm.value,
      default_policy_to_attach: PolicyTypeByView(window.location.href),
    };
    this.isUpdating$.next(true);

    const userData = {
      name: user?.name || '',
      email: user?.email || '',
      uuid: this.#uuid,
      groups: this.userGroups.value,
      policy_uuids: [] as string[],
      supplier: this.userForm.value.supplier || '',
      office_phone_number: this.phoneTypeValue === PhoneType.OFFICE ? this.phoneNumber : '',
      mobile_number: this.phoneTypeValue === PhoneType.MOBILE ? this.phoneNumber : '',
      office_address: this.officeAddress,
    };

    this.dataSource.selectedPoliciesUuids$
      .pipe(
        withLatestFrom(this.dataSource.connection$),
        take(1),
        switchMap(([policyUuids, { policies }]) => {
          userData.policy_uuids = policyUuids;

          const payload = { policy_changes: [] as { user_uuid: SelectionIdentifier; policy_uuids: string[] }[] };

          this.data.users?.forEach((user) => {
            const usersCrossGroupPolicyUuids =
              policies
                .filter(
                  (policy) =>
                    policy.isCrossGroup && user.policies.find((userPolicy) => userPolicy.uuid === policy.uuid),
                )
                .map((policy) => policy.uuid) || [];
            payload.policy_changes.push({
              user_uuid: user.uuid,
              policy_uuids: [...userData.policy_uuids, ...usersCrossGroupPolicyUuids],
            });
          });

          return payload.policy_changes.length
            ? this.clientUserAdminService.updateUsersForGroup(payload as PatchChangeUserPoliciesType)
            : this.clientUserAdminService.createUserForGroup(userData);
        }),
        catchError((error) => {
          if (error.status == 400 && String(error.error.error).startsWith('User exists')) {
            return this.confirmationDialogService
              .open({
                title: 'pages.users.create_existing_title',
                message: 'pages.users.create_existing_msg',
              })
              .pipe(
                switchMap((confirmed) => {
                  if (confirmed) {
                    const user = {
                      ...userData,
                      confirm: true,
                    };
                    return this.clientUserAdminService.createUserForGroup(user).pipe(
                      switchMap((user) => {
                        this.dialogRef.close({ status: DialogResponse.OK, user: user as UserModel });
                        return EMPTY;
                      }),
                    );
                  }
                  this.isUpdating$.next(false);
                  return EMPTY;
                }),
              );
          }

          const message = error.error?.error || 'An error occurred. Please try again.';

          this.toast.error({
            id: error.status ?? 'error',
            message,
          });
          this.isUpdating$.next(false);
          return EMPTY;
        }),
      )
      .subscribe((user) => {
        this.dialogRef.close({ status: DialogResponse.OK, user: user as UserModel, edited: this.data.edit });
      });
  }

  abort() {
    this.dialogRef.close({ status: DialogResponse.ABORT });
  }
}
