import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFiltersComponent } from './components/dynamic-filters/dynamic-filters.component';
import {
  UIButtonModule,
  UIDialogWrapperModule,
  UIFormModule,
  UILayoutModule,
  AdvancedSearchOverlayComponent,
} from '@vdms-hq/ui';
import { SelectorsModule } from '@vdms-hq/selectors';
import { TranslateModule } from '@ngx-translate/core';
import { AppliedDynamicFiltersComponent } from './components/applied-dynamic-filters/applied-dynamic-filters.component';
import { SharedModule } from '@vdms-hq/shared';
import { AppliedFilterFormatPipe } from './pipes/applied-filter-format.pipe';
import { AppliedFilterLabelPipe } from './pipes/applied-filter-label.pipe';
import { DynamicFormGroupRendererComponent } from './components/dynamic-form-group-renderer/dynamic-form-group-renderer.component';
import { BdcWalkModule } from 'bdc-walkthrough';
import { NewFeaturePipe } from './pipes/new-feature.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FiltersConfigDialogComponent } from './components/filters-config-dialog/filters-config-dialog.component';
import { FiltersConfigService } from './filters-config.service';
import { OverlayModule } from '@angular/cdk/overlay';

const components = [
  DynamicFiltersComponent,
  AppliedDynamicFiltersComponent,
  AppliedFilterFormatPipe,
  DynamicFormGroupRendererComponent,
  FiltersConfigDialogComponent,
];

@NgModule({
  imports: [
    CommonModule,
    UIFormModule,
    SelectorsModule,
    UILayoutModule,
    TranslateModule,
    SharedModule,
    BdcWalkModule,
    MatTooltipModule,
    UIButtonModule,
    UIDialogWrapperModule,
    AdvancedSearchOverlayComponent,
    OverlayModule,
  ],
  declarations: [...components, AppliedFilterLabelPipe, NewFeaturePipe],
  providers: [FiltersConfigService],
  exports: [...components],
})
export class DynamicFiltersModule {}
