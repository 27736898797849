<vdms-hq-ui-dialog-wrapper>
  <h1 title>Advanced Search Guide</h1>

  <div content class="instruction--container">
    <h2>Logical Operators:</h2>
    <ol>
      <li>AND: Finds results that match all the terms (e.g., apple AND orange).</li>
      <li>OR: Finds results that match any of the terms (e.g., apple OR orange).</li>
      <li>NOT: Excludes results that contain the specified term (e.g., apple NOT orange).</li>
    </ol>
    <h2>Grouping Terms:</h2>
    <ol>
      <li>Use parentheses () to group terms and operators to form sub-queries (e.g., (apple OR orange) AND banana).</li>
    </ol>
    <h2>Wildcards:</h2>
    <ol>
      <li>Single Character Wildcard (?): Matches any single character (e.g., appl? finds apple or apply).</li>
      <li>Multiple Character Wildcard (*): Matches zero or more characters (e.g., appl* finds apple, appliance).</li>
    </ol>
    <h2>Escaping Special</h2>
    <ol>
      <li>{{ SPECIAL_CHARACTERS_TEXT }}</li>
    </ol>
  </div>

  <div footer>
    <vdms-hq-ui-button color="secondary" (click)="close()">{{ 'common.global.cancel' | translate }}</vdms-hq-ui-button>
  </div>
</vdms-hq-ui-dialog-wrapper>
