import { inject, Injectable } from '@angular/core';
import { take } from 'rxjs';
import { PlayerMetadataListSource } from '@vdms-hq/firebase-contract';
import { ViewPlayerMetadataItem } from './metadata-list.model';
import { TimelineMixedSourceService, TimelineRow } from '@vdms-hq/player';
import { MetadataListDatasource } from './metadata-list.datasource';

export interface MetadataItemOnTimelineSelected {
  type: PlayerMetadataListSource;
  content: string;
  languageKey: string;
}

@Injectable({ providedIn: 'root' })
export class ShowOnTimelineService {
  private timelineMixedSourceService = inject(TimelineMixedSourceService);
  private datasource = inject(MetadataListDatasource);

  showOnTimeline(selectedItem: ViewPlayerMetadataItem) {
    if (selectedItem.type === PlayerMetadataListSource.TRANSCRIBE) {
      // todo toogle subtitles
      return;
    }

    this.datasource.connection$.pipe(take(1)).subscribe((list) => {
      const filteredItems = list.filter((item) => {
        switch (true) {
          case selectedItem.type === PlayerMetadataListSource.VIDEO_SEGMENT_DETECTION &&
            selectedItem.content.includes('Shot') &&
            item.content.includes('Shot'):
            return true;
        }

        return item.type === selectedItem.type && item.content === selectedItem.content;
      });

      const rowId = this.generateId(selectedItem);

      if (this.timelineMixedSourceService.hasCustomRow(rowId)) {
        this.timelineMixedSourceService.removeCustomRow(rowId);
      } else {
        this.timelineMixedSourceService.addCustomRows(
          this.#prepareTimelineData(rowId, this.generateLabel(selectedItem), filteredItems),
        );
      }
    });
  }

  #prepareTimelineData(id: string, label: string, instances: ViewPlayerMetadataItem[]): TimelineRow[] {
    return [
      {
        id: id,
        label: label,
        values: instances.map((item) => ({
          tcIn: item.timestamp.timecodeIn?.countSeconds() ?? 0,
          tcOut: item.timestamp.timecodeOut?.countSeconds(),
          tooltip: item.content,
          color: this.generateColorBasedOnPill(item.pill),
        })),
      },
    ];
  }

  generateId(row: ViewPlayerMetadataItem) {
    return `${row.type}_${this.generateLabel(row)}`;
  }

  generateLabel(row: ViewPlayerMetadataItem) {
    switch (true) {
      case row.type === PlayerMetadataListSource.VIDEO_SEGMENT_DETECTION && row.content.includes('Shot'):
        return 'Shots';

      case row.type === PlayerMetadataListSource.TRANSCRIBE && !!row.language:
        return `Transcription (${row.language})`;

      case row.type === PlayerMetadataListSource.TRANSCRIBE:
        return `Transcription`;
    }

    return row.content;
  }

  generateColorBasedOnPill(pill: ViewPlayerMetadataItem['pill']) {
    switch (pill) {
      case 'cold-blue':
        return '#24c1cb';
      case 'orange':
        return '#f06500';
      case 'white-dashed':
        return '#a4a9b7';
      case 'orange-light':
        return '#ee922d';
      case 'yellow':
        return '#eaa800';
      case 'done-light':
        return '#b9d133';
      case 'done':
        return '#319e0b';
      case 'failed':
        return '#bd2b3c';
      default:
        return '#A4A9B7';
    }
  }
}
