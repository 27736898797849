import { Injectable, inject } from '@angular/core';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { EgressStatsConfig } from './egress-stats.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StatsConfig } from '@vdms-hq/firebase-contract';

@Injectable({ providedIn: 'root' })
export class EgressStatsAdminService {
  private activatedClientService = inject(ActivatedClientService);

  getStatsSettings(): Observable<StatsConfig | undefined> {
    return this.activatedClientService.clientDefinite$.pipe(map((client) => client.statsConfig));
  }

  updateStatsSettings(settings: EgressStatsConfig): Observable<void> {
    return this.activatedClientService.update(settings);
  }
}
