import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import {
  GetNotificationsConfigInterface,
  NotificationsConfig,
  NotificationsConfigFilter,
  PatchEmailTemplateContentConfigInterface,
  UpdateNotificationsConfigInterface,
} from './notification-settings.model';
import { Observable, pluck } from 'rxjs';
import { ApiResponse } from '../api.model';
import { GetResponseData } from '../../operators/get-response-data.operator';

enum NOTIFICATION_SETTINGS_ENDPOINTS {
  ROOT = 'notifications-config',
  FILTERS = 'filters',
  EMAIL_TEMPLATES = 'email-template-content-config',
}

@Injectable({ providedIn: 'root' })
export class NotificationSettingsService {
  constructor(private readonly apiService: ApiService) {}

  getNotificationSettings(): Observable<GetNotificationsConfigInterface> {
    return this.apiService
      .get<ApiResponse<GetNotificationsConfigInterface>>(NOTIFICATION_SETTINGS_ENDPOINTS.ROOT)
      .pipe(GetResponseData);
  }

  saveNotificationSettings(payload: Partial<UpdateNotificationsConfigInterface>) {
    return this.apiService.post(NOTIFICATION_SETTINGS_ENDPOINTS.ROOT, payload);
  }

  updateNotificationSettings(uuid: string, payload: Partial<UpdateNotificationsConfigInterface>) {
    return this.apiService.patch(`${NOTIFICATION_SETTINGS_ENDPOINTS.ROOT}/${uuid}`, payload);
  }

  getFilters() {
    return this.apiService
      .get<ApiResponse<NotificationsConfig[]>>(
        `${NOTIFICATION_SETTINGS_ENDPOINTS.ROOT}/${NOTIFICATION_SETTINGS_ENDPOINTS.FILTERS}`,
      )
      .pipe(GetResponseData);
  }

  saveFilters(payload: NotificationsConfig[]) {
    return this.apiService.post(
      `${NOTIFICATION_SETTINGS_ENDPOINTS.ROOT}/${NOTIFICATION_SETTINGS_ENDPOINTS.FILTERS}`,
      payload,
    );
  }
}
