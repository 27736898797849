import { PAGE_SIZE_OPTIONS, PageableDataSource } from '@vdms-hq/shared';
import { inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, defer, map, Observable, shareReplay } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { ViewSettingsService } from '../logic/view-settings.service';

export abstract class ParamsPagination implements Partial<PageableDataSource> {
  protected router = inject(Router);
  protected activatedRoute = inject(ActivatedRoute);
  protected viewSettingsService = inject(ViewSettingsService);

  pageSizeOptions: number[] = PAGE_SIZE_OPTIONS;

  changePageIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  pageIndex$ = this.changePageIndex$.asObservable();
  changePerPageSize$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  perPageUserSettings$ = this.viewSettingsService.perPageUserSettings$.pipe(shareReplay(1));
  pageSize$: Observable<number> = combineLatest([this.changePerPageSize$, this.perPageUserSettings$]).pipe(
    map(([change, user]) => change || user),
  );

  pageChange($event: PageEvent) {
    this.changePageIndex$.next($event.pageIndex);
    this.changePerPageSize$.next($event.pageSize);
  }
}
