import { BehaviorSubject, combineLatest, map, Observable, ReplaySubject, shareReplay, Subject, switchMap } from 'rxjs';
import {
  ConnectableDataSource,
  DestroyComponent,
  InfiniteLoadAbleDataSource,
  LoadableDataSource,
  RefreshService,
} from '@vdms-hq/shared';
import { AssetApiService, Pagination } from '@vdms-hq/api-contract';
import { Injectable } from '@angular/core';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { HttpHeaders } from '@angular/common/http';
import { DataProviderService } from '@vdms-hq/selectors';
import { StorageUrlService } from '@vdms-hq/storage';
import { AssetFlatView2 } from '../transformation/asset-flat-view-2.model';
const GRID_PAGE_SIZE = 24;

@Injectable({
  providedIn: 'root',
})
export class LatestAssetsDataSource
  extends DestroyComponent
  implements ConnectableDataSource<AssetFlatView2>, LoadableDataSource, InfiniteLoadAbleDataSource
{
  #isLoading$: Subject<boolean> = new Subject<boolean>();
  isLoading$ = this.#isLoading$.asObservable();

  readonly pageSize = GRID_PAGE_SIZE;
  #total$: ReplaySubject<number> = new ReplaySubject<number>();
  total$: Observable<number> = this.#total$.asObservable();
  #currentPage$ = new BehaviorSubject(0);
  currentPage$ = this.#currentPage$.asObservable();
  refresh$ = this.refreshService.refresh$;
  allData$ = combineLatest([this.currentPage$, this.refresh$, this.activatedClientService.clientIdDefinite$]).pipe(
    switchMap(([page, refresh]) => {
      const headers = new HttpHeaders(new Pagination(page, this.pageSize).toHeaders()).set('order-by', 'uploaded_at');
      return this.assetService.getLatest(headers).pipe(
        map(({ data, total }) => {
          this.#total$.next(total);
          this.#isLoading$.next(false);

          this.#flatAssets = [
            ...data.map((item) =>
              AssetFlatView2.fromAssetFlat(item, {
                dataProvider: this.dataProvider,
                storageUrlService: this.storageUrlService,
                activatedClientService: this.activatedClientService,
              }),
            ),
          ];
          return this.#flatAssets;
        }),
      );
    }),
    shareReplay(),
  );

  connection$: Observable<AssetFlatView2[]> = this.allData$;

  #flatAssets: AssetFlatView2[] = [];

  constructor(
    private assetService: AssetApiService,
    private activatedClientService: ActivatedClientService,
    private dataProvider: DataProviderService,
    private storageUrlService: StorageUrlService,
    private refreshService: RefreshService,
  ) {
    super();
    this.isLoading$ = this.#isLoading$.asObservable();
    this.activatedClientService.clientIdDefinite$.pipe(this.takeUntilDestroyed()).subscribe(() => this.reset());
  }

  refresh() {
    this.#flatAssets = [];
    this.#currentPage$.next(0);
    this.refreshService.refresh();
  }

  reset() {
    setTimeout(() => {
      this.#currentPage$.next(0);
      this.#flatAssets = [];
    }, 500);
  }

  loadMore() {
    this.#currentPage$.next(this.#currentPage$.value + 1);
    this.#isLoading$.next(true);
  }
}
