import { FieldConfigId } from '@vdms-hq/shared';
export type TableView = 'table' | 'grid' | 'advanced';

export enum TitleFormat {
  truncatedEpisodeNameOrOriginalFilename = 'Truncated EpisodeName or OriginalFilename',
  truncatedEpisodeNameProgrammeNumber = 'Truncated Episode Name, Programme Number',
  seriesNameAndSxxExxOrEpisodeName = '(SeriesName + SxxExx) or EpisodeName',
  episodeNameAndSxExOrOriginalFilename = '(EpisodeName + SxEx) or OriginalFilename',
  programmeNumber = 'Programme Number',
  originalFilename = 'OriginalFilename',
}

export enum TileMetadataRef {
  uploadDate = 'uploaded',
  txDate = 'txDate',
  originalFilename = 'originalFilename',
  duration = 'duration',
}

export interface GridConfig {
  titleFormat: TitleFormat;
  /** @deprecated @todo Use metadataV2 from now on, exists only for backwards compatibility.**/
  metadata: TileMetadataRef[];
  metadataV2: Record<string, FieldConfigId[]>;
}

export interface SupportConfig {
  enabled: boolean;
  icon?: string;
  label: string;
  url: string;
  confirmationText?: string;
}

export interface ClientVidaModel {
  /** @deprecated @todo remove me as soon as you remove the usage of it, it has been replaced by grid.metadata */
  displayUploadDate: boolean;
  coldStorageEnabled: boolean;
  assetDefaultView?: 'thumb' | 'table';
  tableDefaultView: TableView;
  dateDefaultTimeZone: string;
  sharedPacks: boolean;
  hideUsersList: boolean;
  downloadLimit: number;
  advancedCheckoutEnabled: boolean;
  aiProcessingCheckoutEnabled: boolean;
  clientDiscountEnabled: boolean;
  advancedCheckoutDepartmentRequired: boolean;
  preview?: {
    advancedControls: boolean;
  };
  embargo?: boolean;
  clipsOnly?: boolean;
  shotDescriptionNoteRequired?: boolean;
  grid: Partial<GridConfig>;
  ordersFilterDateDuration?: number;
  vidaSupportButton?: SupportConfig;
  thirdPartySupport?: SupportConfig;
}
