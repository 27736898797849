export enum RIGHTS_PARTNERS_ENDPOINTS {
  ROOT = 'partner',
  ROOT_LIST = 'partners',
}

// GET /partners
export interface RightsPartnerListItem {
  uuid: string;
  name: string;
  partner_id: string | null;
  users_emails: string[];
}

// GET /partner/:uuid
export interface RightsPartnerGet {
  uuid: string;
  name: string;
  partner_id: string | null;
}

// POST /partner
export interface RightsPartnerCreate {
  name: string;
  partner_id: string | null;
}

// PATCH /partner/:uuid
export interface RightsPartnerUpdate {
  name: string;
  partner_id: string | null;
}

// PATCH /partner/{uuid}/attach-users
// PATCH /partner/{uuid}/detach-users
export interface RightsPartnerUsersPatch {
  users: {
    attach?: PatchPartnerUser[];
    detach?: PatchPartnerUser[];
  };
}

export interface PatchPartnerUser {
  email: string;
  policies?: {
    attach?: string[];
    detach?: string[];
  };
}
