import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { FlatOrder, OrderDeliveryStatus, OrderModel, OrderStatus } from '@vdms-hq/api-contract';
import { FormatBytesPipe, RenamePipe } from '@vdms-hq/shared';
import { SimpleDetailsList } from '@vdms-hq/ui';
import { DELIVERY_STATUS } from './orders-filters-provider';

export type Status = {
  label: string;
  color: 'success' | 'default' | 'alert';
  hideDot: boolean;
  tooltip?: string;
  icon?: string;
  hideIcon?: boolean;
  iconOnly?: boolean;
};

@Injectable()
export class OrderMetadataTransformerService {
  constructor(private datePipe: DatePipe, private formatBytes: FormatBytesPipe, private renamePipe: RenamePipe) {}

  getMetadata(
    order: FlatOrder | OrderModel,
    enabledMetadata: string[],
  ): {
    title: string;
    metadata: SimpleDetailsList[];
    statuses: Status[];
  } {
    const metadata: (SimpleDetailsList | false)[] = [
      enabledMetadata.includes('packageTitle') && {
        title: 'common.orders.package_title',
        value: order?.package_title ?? '',
      },
      enabledMetadata.includes('salesForceName') && {
        title: 'common.orders.sales_force_name',
        value: order?.sales_force_name ?? '',
      },
      enabledMetadata.includes('delivery_emails') && {
        title: 'common.orders.delivery_emails',
        value: order?.delivery_emails ?? '',
      },
      enabledMetadata.includes('totalAssets') && {
        title: 'common.orders.total_assets',
        value: order?.total_assets ?? '',
      },
      enabledMetadata.includes('purchaseOrderNo') && {
        title: 'common.orders.purchase_order_no',
        value: order?.purchase_order_no ?? '',
      },
      enabledMetadata.includes('orderSender') && {
        title: 'common.orders.order_sender',
        value: order?.shared_by ?? '',
      },
      enabledMetadata.includes('sharedBy') && {
        title: 'common.orders.shared_by',
        value: order?.shared_by ?? '',
      },
      enabledMetadata.includes('orderShared') && {
        title: 'common.orders.order_shared',
        value: order?.delivery_emails ?? '',
      },
      enabledMetadata.includes('sharedWith') && {
        title: 'common.orders.order_shared',
        value: order?.delivery_emails ?? '',
      },
      enabledMetadata.includes('deliveryDate') && {
        title: 'common.orders.delivery_date',
        value: this.datePipe.transform(order.delivery_date, 'dd-MM-yyyy hh:mm') ?? '',
      },
      enabledMetadata.includes('usedDepartment') && {
        title: 'common.orders.used_department',
        value: order?.used_department ?? '',
      },
      enabledMetadata.includes('mediaPulseId') && {
        title: 'common.orders.media_pulse_id',
        value: order?.media_pulse_id ?? '',
      },
      enabledMetadata.includes('status') && {
        title: 'common.orders.status',
        value: order?.status ?? '',
      },
      enabledMetadata.includes('expiryDate') && {
        title: 'common.orders.expiry_date',
        value: this.datePipe.transform(order.expires_at, 'dd-MM-yyyy HH:mm') ?? '',
      },
      enabledMetadata.includes('totalSize') && {
        title: 'common.orders.total_bytes',
        value: this.formatBytes.transform(order?.total_bytes),
      },
      enabledMetadata.includes('workflow') &&
        !!order?.type && {
          title: 'orders.workflow.label',
          value: this.renamePipe.transform(order?.type),
        },
      enabledMetadata.includes('comment') && {
        title: 'common.orders.comment',
        value: order?.comment ? order?.comment : '',
        size: 'wide',
      },
      enabledMetadata.includes('transcode_progress') &&
        order.status == OrderStatus.TRANSCODE && {
          title: 'common.orders.transcode_progress',
          value: order?.transcode_progress ? order?.transcode_progress + ' %' : '0 %',
          size: 'wide',
        },
    ];

    const titlePrefix = order.media_pulse_id ? order.media_pulse_id + ' - ' : '';
    const titleSuffix = order.package_title
      ? order.package_title
      : this.datePipe.transform(order.created_at, 'dd-MM-yyyy');

    const deliveryStatusLabel =
      DELIVERY_STATUS.filter((status) => status.key === order.delivery_status)[0]?.label ??
      'common.orders.delivery.created';
    const isDelivered = [OrderDeliveryStatus.DELIVERED].includes(order.delivery_status);
    const isDeliveryFailed = [OrderDeliveryStatus.FAILED, OrderDeliveryStatus.GETTYIMAGES_API_FAILED].includes(
      order.delivery_status,
    );

    const isDownloaded = order.total_downloadable_assets <= order.total_downloaded_assets;

    const statuses: (Status | false)[] = [
      enabledMetadata.includes('approvalStatus') && {
        color: order.approval_status ? 'success' : 'default',
        label: order.approval_status ? 'common.orders.approval.approved' : 'common.orders.approval.pending',
        tooltip: order.approval_status ? 'common.orders.approval.approved' : 'common.orders.approval.pending',
        hideDot: true,
        hideIcon: false,
        icon: order.approval_status ? 'thumb_up' : 'hourglass_top',
        iconOnly: true,
      },
      enabledMetadata.includes('downloadStatus') && {
        color: isDownloaded ? 'success' : 'default',
        label: isDownloaded ? 'common.orders.download.downloaded' : 'common.orders.download.active',
        hideDot: false,
      },
      enabledMetadata.includes('deliveryStatus') && {
        color: isDeliveryFailed ? 'alert' : isDelivered ? 'success' : 'default',
        label: deliveryStatusLabel,
        hideDot: false,
      },
    ];
    return {
      statuses: <Status[]>statuses.filter((item) => !!item),
      title: `${titlePrefix}${titleSuffix}`,
      metadata: <SimpleDetailsList[]>metadata.filter((item) => !!item),
    };
  }
}
