import { NgModule } from '@angular/core';
import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReplaceWhenExceedsPipe } from './logic/pipes/replace-when-exceeds.pipe';
import { ReactiveTzDatePipe } from './logic/pipes/reactive-tz-date.pipe';
import { FormatBitratePipe } from './logic/pipes/format-bitrate.pipe';
import { FormatBytesPipe } from './logic/pipes/format-bytes.pipe';
import { IsEmptyPipe } from './logic/pipes/is-empty.pipe';
import { ToIterablePipe } from './logic/pipes/to-iterable.pipe';
import { DestroyComponent } from './component/destroy.component';
import { SortByExceptNullPipe } from './logic/pipes/sort-by-except-null.pipe';
import { RenamePipe } from './logic/pipes/rename.pipe';
import { SetStatusColor } from './logic/pipes/status-color.pipe';
import { ClientCurrencyPipe } from './logic/pipes/client-currency.pipe';

const modules = [CommonModule, TranslateModule];
const components = [DestroyComponent];

@NgModule({
  imports: [modules],
  exports: [
    ...modules,
    ...components,
    ReplaceWhenExceedsPipe,
    FormatBitratePipe,
    FormatBytesPipe,
    IsEmptyPipe,
    ToIterablePipe,
    SortByExceptNullPipe,
    RenamePipe,
    ClientCurrencyPipe,
    SetStatusColor,
  ],
  providers: [DatePipe, AsyncPipe, ReactiveTzDatePipe, SetStatusColor, FormatBytesPipe],
  declarations: [
    ...components,
    ReplaceWhenExceedsPipe,
    ReactiveTzDatePipe,
    FormatBitratePipe,
    FormatBytesPipe,
    IsEmptyPipe,
    ToIterablePipe,
    SortByExceptNullPipe,
    RenamePipe,
    ClientCurrencyPipe,
    SetStatusColor,
  ],
})
export class SharedModule {}
