import { Injectable, inject } from '@angular/core';
import { PermissionService, Permission } from '@vdms-hq/activated-client';
import { map, Observable, tap, combineLatest } from 'rxjs';
import { MultipleViewConfiguration } from '@vdms-hq/ui';
import { PolicyService, VidaAppTypes, VidaAppType, ClientUserAdminView } from '@vdms-hq/api-contract';
import { DynamicFilterInput } from '@vdms-hq/dynamic-filters';
import { ResourceModel, ValueFormat, FilterType, SelectOption, PillColorEnum } from '@vdms-hq/shared';
import { ClientUserAdminActionService } from './client-user-admin-action.service';

export type VidaAppTypesPills = Record<VidaAppType, PillColorEnum>;

export enum CLIENT_USER_ADMIN_EVENT_KEYS {
  ADD_USER = 'add_user',
  EDIT = 'edit',
  DELETE = 'delete',
  CANCEL = 'cancel',
}

@Injectable({ providedIn: 'root' })
export class ClientUserAdminConfigService {
  private permissionService = inject(PermissionService);
  private policyService = inject(PolicyService);
  private actionService = inject(ClientUserAdminActionService);

  readonly viewConfiguration$ = combineLatest([
    this.permissionService.verifyWithOwnedPermissions$([Permission.USER_ADMIN_EDIT_POLICIES]),
    this.permissionService.verifyWithOwnedPermissions$([Permission.USER_ADMIN_DELETE]),
  ]).pipe(
    map(([canEdit, canDelete]) => {
      this.actionService.startLoading();
      return [canEdit, canDelete];
    }),
    map<boolean[], MultipleViewConfiguration<ClientUserAdminView>>(([canEdit, canDelete]) => ({
      tableAdvanced: {
        actions: [this.actionService.buildEditAction(canEdit), this.actionService.buildDeleteAction(canDelete)],
        columnsEnabled: [
          canEdit ? 'select' : '',
          'name',
          'email',
          'application_access',
          'policies',
          'teams',
          'actions',
        ],
        columns: [
          {
            id: 'select',
            type: 'select',
          },
          {
            id: 'name',
            label: 'Name',
            valuePath: 'name',
            sortable: false,
          },
          {
            id: 'email',
            label: 'Email',
            valuePath: 'email',
            sortable: false,
          },
          {
            id: 'application_access',
            label: 'Application Access',
            valuePath: 'app_accesses',
            sortable: false,
            viewFormat: {
              pills: VidaAppTypes.reduce((pills, app) => {
                pills[app] = PillColorEnum.ColdBlue;
                return pills;
              }, {} as VidaAppTypesPills),
            },
          },
          {
            id: 'policies',
            label: 'Policies',
            valuePath: 'policiesNames',
            sortable: false,
            foldValues: true,
          },
          {
            id: 'teams',
            label: 'Teams',
            valuePath: 'teamsNames',
            sortable: false,
            foldValues: true,
          },
          {
            id: 'actions',
            type: 'actions',
          },
        ],
      },
    })),
    tap(() => this.actionService.stopLoading()),
  );

  filtersConfig$: Observable<DynamicFilterInput[]> = this.policyService.getSimplePolicies().pipe(
    map((policies) => {
      this.actionService.startLoading();
      const policiesOptions = policies
        .map((policy) => ({ key: policy.uuid, label: policy.name } as SelectOption))
        .filter((policy) => Boolean(policy.key));
      policiesOptions.unshift({ key: '', label: 'All' });

      return [
        {
          id: 'text',
          label: 'Name or email',
          resource: [ResourceModel.CLIENT_USER],
          format: ValueFormat.AS_IS,
          filters: {
            objectPath: 'text',
            validFormat: 'keyword',
            type: FilterType.MASTER_TEXT,
          },
        },
        {
          id: 'app',
          label: 'Application Access',
          resource: [ResourceModel.CLIENT_USER],
          format: ValueFormat.AS_IS,
          filters: {
            objectPath: 'app',
            validFormat: 'keyword',
            type: FilterType.SELECTOR,
          },
          selectOptions: [
            { key: '', label: 'All' },
            { key: 'vida', label: 'Vida' },
            { key: 'launchpad', label: 'Launchpad' },
            { key: 'storefront', label: 'Storefront' },
            { key: 'connect2', label: 'Connect2' },
          ],
        },
        {
          id: 'policy',
          label: 'Policy',
          resource: [ResourceModel.CLIENT_USER],
          format: ValueFormat.AS_IS,
          filters: {
            objectPath: 'policies',
            validFormat: 'keyword',
            type: FilterType.SELECTOR,
          },
          selectOptions: policiesOptions,
        },
      ] as DynamicFilterInput[];
    }),
    tap(() => this.actionService.stopLoading()),
  );
}
