export interface AwsV4Signature {
  url: string;
  key: string;
  x_amz_algorithm: string;
  x_amz_credential: string;
  x_amz_date: string;
  x_amz_security_token: string;
  x_amz_signature: string;
  policy: string;
}

export interface FileUploadState<STATUSES> {
  state: UploadState;
  status: STATUSES;
  uploadParams?: CreateMultipartUploadOutput;
  signature?: AwsV4Signature;
  file?: FileUploadProgressState;
  error?: string;
}

export enum UploadState {
  IN_PROGRESS = 'in_progress',
  ERROR = 'error',
  COMPLETED = 'completed',
}

export interface FileUploadProgressState {
  uploaded: boolean;
  progress: number;
}

export interface CreateMultipartUploadOutput {
  Bucket?: string;
  Key?: string;
  UploadId?: string;
}
