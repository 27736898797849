import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { SelectorsDataSource } from '../../logic/services/selectors-data-source';
import { ActivatedClientService, Permission, PermissionService } from '@vdms-hq/activated-client';
import { FilterType, Option, ResourceModel, Type, ValueFormat } from '@vdms-hq/shared';
import { MatDialog } from '@angular/material/dialog';
import { SelectOptionsEditDialogComponent } from '../select-options-edit-dialog/select-options-edit-dialog.component';
import { SelectOptionsCreateDialogComponent } from '../select-options-create-dialog/select-options-create-dialog.component';
import { FieldsOptionsService } from '@vdms-hq/api-contract';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { ToastService } from '@vdms-hq/toast';
import { SelectOptionsCreateDialogResponse } from '../select-options-create-dialog/select-options-create-dialog.model';
import {
  ActionContextLess,
  ActionIdentifier,
  MultipleViewConfiguration,
  UIConfirmationDialogService,
} from '@vdms-hq/ui';
import { firstValueFrom, Observable, Subject, takeUntil, tap } from 'rxjs';
import { SelectOptionsTreeDialogComponent } from '../select-options-tree-dialog/select-options-tree-dialog.component';
import { FormControl, FormGroup } from '@angular/forms';
import { DynamicFilterInput } from '@vdms-hq/dynamic-filters';

@Component({
  selector: 'vdms-hq-selectors-select-options-table',
  templateUrl: './select-options-table.component.html',
  styleUrls: ['./select-options-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectOptionsTableComponent implements OnInit, OnDestroy {
  Permission = Permission;

  enabled: string[] = [
    'uuid',
    'type',
    'label',
    'enabled_in_forms',
    'enabled_in_search',
    'parent_name',
    'restricted',
    'actions',
  ];

  viewConfiguration: MultipleViewConfiguration<Option> = {
    tableAdvanced: {
      actions: [],
      columnsEnabled: this.enabled,
      columns: [
        {
          id: 'uuid',
          label: 'pages.fields.columns.uuid',
          valuePath: 'uuid',
        },
        {
          id: 'type',
          label: 'pages.fields.columns.type',
          valuePath: 'type_name',
          sortable: true,
        },
        {
          id: 'label',
          label: 'pages.fields.columns.label',
          valuePath: 'label',
          sortable: true,
        },
        {
          id: 'parent_name',
          label: 'pages.fields.columns.parent_name',
          valuePath: 'parent_field.label',
          sortable: true,
        },
        {
          id: 'restricted',
          label: 'pages.fields.columns.restricted',
          valuePath: 'extra.restricted',
          sortable: false,
          foldValues: true,
          viewFormat: {
            maxVisibleValues: 2,
          },
        },
        {
          id: 'enabled_in_search',
          label: 'pages.fields.columns.enabled_in_search',
          valuePath: 'enabled.in_search',
          sortable: true,
          viewFormat: {
            modifiers: {
              yesNo: true,
            },
          },
        },
        {
          id: 'enabled_in_forms',
          label: 'pages.fields.columns.enabled_in_forms',
          valuePath: 'enabled.in_forms',
          sortable: true,
          viewFormat: {
            modifiers: {
              yesNo: true,
            },
          },
        },
        {
          id: 'actions',
          type: 'actions',
        },
      ],
    },
  };

  filters = new FormGroup({
    keyword: new FormControl<string>(''),
  });

  readonly filtersConfig: DynamicFilterInput[] = [
    {
      id: 'keyword',
      label: 'common.fields_editor.table.filter',
      resource: [ResourceModel.SELECT_OPTION],
      format: ValueFormat.AS_IS,
      filters: {
        objectPath: 'name',
        validFormat: 'keyword',
        type: FilterType.MASTER_TEXT,
      },
    },
  ];

  headerActions$: Observable<ActionContextLess[]> = this.permissionService
    .verifyWithOwnedPermissions$([Permission.CREATE_FIELDS])
    .pipe(
      map((hasPermission) =>
        hasPermission
          ? [
              {
                key: 'new',
                label: 'pages.fields.new_field',
                color: 'primary',
              },
            ]
          : [],
      ),
    );

  #destroy$ = new Subject<void>();

  #popToast = {
    CREATE_SUCCESS: () =>
      this.toastService.success({
        id: 'create_field_success',
        message: 'notifications.fields.create.success',
      }),
    CREATE_FAILURE: (reason: string = 'Not specified') =>
      this.toastService.error({
        id: 'create_field_failure',
        message: 'notifications.fields.create.failure',
        interpolatedParams: { reason },
      }),
    UPDATE_SUCCESS: () =>
      this.toastService.success({
        id: 'update_field_success',
        message: 'notifications.fields.update.success',
      }),
    UPDATE_FAILURE: () =>
      this.toastService.error({
        id: 'update_field_failure',
        message: 'notifications.fields.update.failure',
      }),
    DELETE_SUCCESS: () =>
      this.toastService.success({
        id: 'delete_select_option_success',
        message: 'notifications.fields.delete.success',
      }),
    DELETE_FAILURE: () =>
      this.toastService.error({
        id: 'delete_select_option_failure',
        message: 'notifications.fields.delete.failure',
      }),
  };

  constructor(
    public selectorsDataSource: SelectorsDataSource,
    public readonly matDialog: MatDialog,
    public readonly fieldsOptionsService: FieldsOptionsService,
    public readonly confirmationDialog: UIConfirmationDialogService,
    public toastService: ToastService,
    public permissionService: PermissionService,
    public activatedClientService: ActivatedClientService,
  ) {}

  async ngOnInit() {
    this.verifyActionsPermissions();
    this.#refreshDataSource();
    this.#filterValueChanges();
  }

  ngOnDestroy() {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  handleAction($event: { key: ActionIdentifier; item: Option }) {
    switch ($event.key) {
      case 'edit':
        this.popEditSelectOptionDialog($event.item);
        break;
      case 'delete':
        this.popDeleteSelectOptionDialog($event.item);
        break;
      case 'new':
        this.popCreateSelectOptionDialog();
        break;
      case 'view-tree':
        this.popTreeDialog($event.item);
        break;
    }
  }

  popCreateSelectOptionDialog = () => {
    this.fieldsOptionsService
      .getTypes()
      .pipe(
        take(1),
        switchMap((types) => {
          return this.matDialog
            .open(SelectOptionsCreateDialogComponent, {
              data: {
                autocompleteOptions: types.map(({ name }) => ({ key: name, label: name })),
              },
            })
            .afterClosed()
            .pipe(
              filter((data: SelectOptionsCreateDialogResponse) => !!data),
              switchMap((requestedOption: SelectOptionsCreateDialogResponse) => {
                const alreadyExist = types.find(
                  (field) =>
                    field.name === requestedOption?.optionChanges?.type_name &&
                    field.fields.some((f) => f.label === requestedOption.optionChanges?.label),
                );

                if (alreadyExist) {
                  throw new Error(
                    `Field '${requestedOption.optionChanges.label}' already exists in the type '${requestedOption.optionChanges.type_name}'`,
                  );
                }

                const typeName = types.some((field) => field.name === requestedOption?.optionChanges?.type_name);
                if (!typeName) {
                  return this.fieldsOptionsService.postType({ name: requestedOption?.optionChanges?.type_name }).pipe(
                    switchMap(() =>
                      this.fieldsOptionsService
                        .postField({
                          ...requestedOption?.optionChanges,
                          key: requestedOption.optionChanges.label,
                          enabled: {
                            in_forms: true,
                            in_search: true,
                          },
                        })
                        .pipe(tap((option) => this.#triggerOptionChange(option))),
                    ),
                  );
                }
                return this.fieldsOptionsService
                  .postField({
                    ...requestedOption?.optionChanges,
                    enabled: {
                      in_forms: true,
                      in_search: true,
                    },
                  })
                  .pipe(tap((option) => this.#triggerOptionChange(option)));
              }),
            );
        }),
      )
      .subscribe({
        error: (err: Error) => {
          this.#popToast.CREATE_FAILURE(err.message);
          throw err;
        },
        next: () => {
          this.#popToast.CREATE_SUCCESS();
        },
      });
  };

  popEditSelectOptionDialog = (option: Option) => {
    this.fieldsOptionsService
      .getTypes()
      .pipe(
        take(1),
        switchMap((types) => {
          return this.matDialog
            .open(SelectOptionsEditDialogComponent, {
              data: {
                fieldTypes: types,
                option: {
                  ...option,
                },
              },
            })
            .afterClosed()
            .pipe(filter(Boolean));
        }),
      )
      .subscribe({
        next: () => {
          this.#triggerOptionChange(option);
          this.#popToast.UPDATE_SUCCESS();
        },
        error: (err) => {
          this.#popToast.UPDATE_FAILURE();
          throw err;
        },
      });
  };

  popDeleteSelectOptionDialog = async (option: Option) => {
    const resp = await firstValueFrom(
      this.confirmationDialog
        .openDelete({
          title: 'pages.fields.delete.confirmation_title',
          message: 'pages.fields.delete.confirmation_message',
        })
        .pipe(take(1)),
    );

    if (resp) {
      this.fieldsOptionsService
        .deleteField(option.uuid)
        .pipe(
          take(1),
          catchError((err, _) => {
            this.#popToast.DELETE_FAILURE();
            throw err;
          }),
          tap(() => this.#triggerOptionDelete()),
          tap(() => this.#popToast.DELETE_SUCCESS()),
        )
        .subscribe();
    }
  };

  #triggerOptionChange: (option: Option) => void = () => {
    this.selectorsDataSource.optionsChanged();
  };

  #triggerOptionDelete: () => void = () => {
    this.selectorsDataSource.optionsDeleted();
  };

  applyFilter($event: KeyboardEvent) {
    this.selectorsDataSource.applyFilter(($event.target as HTMLFormElement).value);
  }

  verifyActionsPermissions() {
    this.permissionService
      .verifyWithOwnedPermissions$([Permission.EDIT_FIELDS])
      .pipe(
        filter((hasPermission) => hasPermission),
        tap(
          () =>
            this.viewConfiguration.tableAdvanced?.actions?.push(
              {
                key: 'edit',
                icon: 'edit',
                label: 'Edit',
                onDoubleClick: true,
              },
              {
                key: 'view-tree',
                icon: 'account_tree',
                label: 'View tree',
              },
            ),
          take(1),
        ),
      )
      .subscribe();
    this.permissionService
      .verifyWithOwnedPermissions$([Permission.DELETE_FIELDS])
      .pipe(
        filter(Boolean),
        tap(
          () =>
            this.viewConfiguration.tableAdvanced?.actions?.push({
              key: 'delete',
              icon: 'delete',
              label: 'Delete',
              onDoubleClick: false,
            }),
          take(1),
        ),
      )
      .subscribe();
  }

  private popTreeDialog(option: Option) {
    this.matDialog.open(SelectOptionsTreeDialogComponent, {
      data: {
        option: option,
      },
    });
  }

  #refreshDataSource() {
    this.selectorsDataSource.optionsChanged();
  }

  #filterValueChanges() {
    this.selectorsDataSource.applyFilter('');
    this.filters.valueChanges.pipe(takeUntil(this.#destroy$)).subscribe((value) => {
      this.selectorsDataSource.applyFilter(value.keyword ?? '', ['type_name', 'label']);
    });
  }
}
