<div
  class="search-input-outer"
  [class.focus]="focus"
  [class.touched]="touched"
  matAutocompleteOrigin
  #outer="matAutocompleteOrigin"
>
  <div class="search-input-lines">
    <div class="search-input-line">
      <mat-icon class="icon">search</mat-icon>

      <input
        class="search-input"
        #input
        type="text"
        (focusin)="focus = true; touched = true"
        (focusout)="focus = false"
        [(ngModel)]="currentQuery"
        [matAutocomplete]="auto"
        [matAutocompletePosition]="'below'"
        [matAutocompleteConnectedTo]="outer"
        (keyup)="keyUp($event)"
        [placeholder]="(placeholder$ | async) ?? ''"
      />
    </div>
    <div class="search-input-additional-line" *ngIf="touched && availableFilters.length > 1">
      Search in
      <span
        class="filter-pill"
        *ngFor="let filter of availableFilters"
        [class.active]="filter.key === selectedFilter"
        (click)="selectedFilter = filter.key"
        >{{ filter.label }}</span
      >
    </div>
  </div>

  <vdms-hq-ui-button class="button" color="primary" (click)="!touched ? input.focus() : updateQuery()">
    Search
  </vdms-hq-ui-button>
</div>

<mat-autocomplete #auto="matAutocomplete">
  <mat-option
    class="autocomplete"
    *ngFor="let option of latestQueries$ | async"
    [value]="option.label"
    (click)="updateQuery()"
  >
    {{ option.label | truncate : 50 : '...' }}
    <vdms-hq-ui-button
      [iconOnly]="true"
      (click)="removeAutocompleteItem(option); $event.stopPropagation()"
      color="transparent"
      icon="clear"
    ></vdms-hq-ui-button>
  </mat-option>
</mat-autocomplete>
