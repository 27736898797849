import { Routes } from '@angular/router';
import { AdminPageComponent } from './pages/admin-page/admin-page.component';

export enum CONTENT_CORNER_ROUTES {
  ROOT = 'connect2-admin',
}

export const CONTENT_CORNER_ROUTING: Routes = [
  {
    path: '',
    component: AdminPageComponent,
  },
  {
    path: '**',
    redirectTo: `/${CONTENT_CORNER_ROUTES}`,
    pathMatch: 'full',
  },
];
