import { FieldsConfigService } from '@vdms-hq/config';
import { map } from 'rxjs/operators';
import { ResultDefinitionModel } from '@vdms-hq/shared';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataColumn, GridAdvancedAdditionalInfo, GridAdvancedMetadata } from '@vdms-hq/ui';
import { CollectionModelFlat } from '@vdms-hq/api-contract';

@Injectable({
  providedIn: 'root',
})
export class CollectionsFieldsConfigService extends FieldsConfigService {
  tableColumnsConfig$: Observable<{
    all: DataColumn[];
    enabled: string[];
  }> = this.tableConfigForScope$('other-collections').pipe(
    map((config) => ({
      all: this.convertToColumns(config.all),
      enabled: this.convertToIds(config.defaultList),
    })),
  );

  gridConfig$: Observable<{
    metadata: GridAdvancedMetadata<CollectionModelFlat>[];
    additional: GridAdvancedAdditionalInfo;
  }> = this.listConfigForScope$('other-collections').pipe(
    map((config) => {
      const additinalDef = config.all.find((def) => def.id === 'collectionName') as ResultDefinitionModel;

      return {
        metadata: this.convertToGridAdvancedArrayMetadata<CollectionModelFlat>(config.defaultList),
        additional: this.convertToGridAdvancedMetadata(additinalDef),
      };
    }),
  );
}
