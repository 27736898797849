<div
  class="advanced--chips__wrapper"
  [class.selected]="innerFormControl.value"
  [class.disabled]="innerFormControl.disabled || disabled"
  [matTooltip]="tooltip ?? ''"
  (click)="!innerFormControl.disabled && !disabled && chipClick.emit()"
>
  <div class="advanced--chips__item">
    <mat-icon class="advanced--chips__icon" *ngIf="icon">{{ icon }}</mat-icon>
    <span class="advanced--chips__item-text" *ngIf="label">
      {{ label | translate }}
    </span>
    <ng-container *ngIf="details">
      <ng-container *ngFor="let chip of details">
        <span class="advanced--chips__detail-item" (click)="$event.stopPropagation(); emitDetail()">
          {{ chip }}
        </span>
      </ng-container>
    </ng-container>
  </div>
</div>
