import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetadataListDatasource } from '../../logic/metadata-list.datasource';
import { UIEmptyResultsModule, UIInfinityLoaderModule, UILayoutModule, UILoaderModule } from '@vdms-hq/ui';
import { metadataListTableColumns, metadataListTableColumnsEnabled } from '../../logic/cfg/metadata-table-config';
import { MetadataListFiltersService } from '../../logic/metadata-list-filters.service';
import { DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { TimecodeTableComponent } from '../timecode-table/timecode-table.component';
import { MetadataListConfigParams, MetadataListConfigService } from '../../logic/metadata-list-config.service';

@Component({
  selector: 'vdms-hq-player-metadata-list',
  standalone: true,
  imports: [
    CommonModule,
    UIEmptyResultsModule,
    DynamicFiltersModule,
    TimecodeTableComponent,
    UILayoutModule,
    UIInfinityLoaderModule,
    UILoaderModule,
  ],
  templateUrl: './metadata-list.component.html',
  styleUrls: ['./metadata-list.component.scss'],
})
export class MetadataListComponent implements OnInit, OnDestroy {
  metadataListDatasource = inject(MetadataListDatasource);
  metadataListConfigService = inject(MetadataListConfigService);
  metadataListFiltersService = inject(MetadataListFiltersService);

  @Input() set config(params: MetadataListConfigParams) {
    this.metadataListConfigService.load(params);
  }

  @Input() withHeader = true;

  columns = metadataListTableColumns;
  enabled = metadataListTableColumnsEnabled;

  ngOnInit(): void {
    this.metadataListFiltersService.initialize();
  }

  ngOnDestroy() {
    this.metadataListConfigService.unload();
    this.metadataListFiltersService.reset();
  }
}
