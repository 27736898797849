<div class="multi-data-container">
  <vdms-hq-ui-data-presentation-header
    (action)="handleHeaderAction($event)"
    [actions]="this.currentOrder.headerActions"
    [breadcrumbs]="[
      { name: 'Home', path: '/' },
      { name: 'common.orders.title', path: '../' }
    ]"
    [title]="(currentOrder.data$ | async)?.title ? (currentOrder.data$ | async)?.title : ''"
    [counterIndicator]="dataSource.total$ | async"
    [listConfig]="(currentOrder.data$ | async)?.metadata ?? []"
  >
  </vdms-hq-ui-data-presentation-header>

  <ng-container *ngIf="canShowTable$ | async; else loading">
    <ng-container [ngTemplateOutlet]="warmingUp"></ng-container>

    <vdms-hq-asset-results-2
      *ngIf="actions$ | async as actions"
      [dataSource]="dataSource"
      [actions]="actions"
      [scopeName]="scopeName"
      [hiddenColumns]="hiddenColumns"
      (action)="handleAction($event)"
      [contextActions]="assetResultsMenuConfig2"
    >
      <vdms-hq-dynamic-form-group-renderer
        filters
        *ngIf="filterService.config as fieldsConfig"
        [form]="filterService.form"
        [fieldsConfig]="fieldsConfig"
        [isLoading]="dataSource.isLoading$ | async"
      ></vdms-hq-dynamic-form-group-renderer>
    </vdms-hq-asset-results-2>
  </ng-container>

  <ng-container *ngIf="footerActions$ | async; let footerActions">
    <vdms-hq-ui-actions-footer *ngIf="(dataSource.selection.entities$ | async)?.length && footerActions?.length">
      <div left>
        <vdms-hq-ui-button
          *ngFor="let footerAction of footerActions"
          (click)="handleHeaderAction({ key: footerAction.key })"
          [disabled]="footerAction?.disabled === true"
          [icon]="footerAction?.icon"
          [tooltip]="footerAction.label | translate"
          [color]="footerAction.color ?? 'primary'"
          >{{ footerAction.label | translate }}</vdms-hq-ui-button
        >
      </div>
    </vdms-hq-ui-actions-footer>
  </ng-container>

  <ng-template #loading>
    <vdms-hq-ui-loader></vdms-hq-ui-loader>
  </ng-template>
</div>

<ng-template #warmingUp>
  <div class="order-warming" *ngIf="estimatedRestoreAt">
    <vdms-hq-ui-empty-results
      type="compact"
      [path]="thermometerImageType$ | async"
      message="Assets are getting warmed up"
    >
      {{ 'ETA: ' }} {{ estimatedRestoreAt | countdown | async }}
    </vdms-hq-ui-empty-results>
  </div>
</ng-template>
