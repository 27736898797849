<h2>Demo settings:</h2>

<div class="grid grid-gap-x grid-gap-y grid-upto3">
  <vdms-hq-ui-form-input-text label="Hint" [(ngModel)]="hint"></vdms-hq-ui-form-input-text>

  <vdms-hq-ui-form-input-text label="Label" [(ngModel)]="label"></vdms-hq-ui-form-input-text>

  <div class="grid grid-gap-x grid-upto3">
    <vdms-hq-ui-form-input-checkbox label="Debug" [(ngModel)]="debug"></vdms-hq-ui-form-input-checkbox>

    <vdms-hq-ui-form-input-checkbox label="With footer" [(ngModel)]="withFooter"></vdms-hq-ui-form-input-checkbox>

    <vdms-hq-ui-form-input-checkbox label="Expand all" [(ngModel)]="expanded"></vdms-hq-ui-form-input-checkbox>
  </div>
</div>

<div *ngIf="debug">
  <h2>Form sate:</h2>

  <vdms-hq-ui-control-state [control]="formGroup"></vdms-hq-ui-control-state>
</div>

<h2>Field list:</h2>

<form [formGroup]="formGroup" class="grid grid-gap-y">
  <mat-accordion [multi]="true">
    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>Text short (input[text])</mat-panel-title>
      </mat-expansion-panel-header>

      <div class="grid grid-gap-x grid-upto3">
        <div class="grid grid-gap-x grid-upto3">
          <vdms-hq-ui-form-input-text
            formControlName="textCustomValue"
            label="Custom value"
          ></vdms-hq-ui-form-input-text>
          <vdms-hq-ui-button (click)="updateValue('text', getField('textCustomValue').value)"
            >Set value
          </vdms-hq-ui-button>
        </div>
      </div>
      <vdms-hq-ui-form-input-text
        [label]="label"
        [placeholder]="placeholder"
        formControlName="text"
        [withFooter]="withFooter"
        [customErrorMessages]="customErrorMessages"
        [hint]="hint"
      >
      </vdms-hq-ui-form-input-text>

      <p>With <code>'0000'</code> mask applied</p>
      <vdms-hq-ui-form-input-text
        [label]="label"
        [placeholder]="placeholder"
        formControlName="text"
        [withFooter]="withFooter"
        [customErrorMessages]="customErrorMessages"
        [hint]="hint"
        mask="0000"
        [showMaskTyped]="true"
      >
      </vdms-hq-ui-form-input-text>

      <vdms-hq-ui-control-state [control]="getField('text')" *ngIf="debug"></vdms-hq-ui-control-state>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>Autocomplete</mat-panel-title>
      </mat-expansion-panel-header>
      <vdms-hq-ui-form-input-text
        [label]="label"
        [placeholder]="placeholder"
        [hint]="hint"
        [withFooter]="withFooter"
        formControlName="autocomplete"
        [autocompleteList]="(filteredOptions$ | async) ?? []"
      >
      </vdms-hq-ui-form-input-text>

      <vdms-hq-ui-control-state [control]="getField('autocomplete')" *ngIf="debug"></vdms-hq-ui-control-state>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>Email (input[email])</mat-panel-title>
      </mat-expansion-panel-header>
      <vdms-hq-ui-form-input-email
        [label]="label"
        [placeholder]="placeholder"
        [hint]="hint"
        [withFooter]="withFooter"
        formControlName="email"
      >
      </vdms-hq-ui-form-input-email>

      <vdms-hq-ui-control-state [control]="getField('email')" *ngIf="debug"></vdms-hq-ui-control-state>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>Number (input[number])</mat-panel-title>
      </mat-expansion-panel-header>
      <vdms-hq-ui-form-input-number
        [label]="label"
        [placeholder]="placeholder"
        [hint]="hint"
        [withFooter]="withFooter"
        formControlName="number"
      ></vdms-hq-ui-form-input-number>

      <vdms-hq-ui-control-state [control]="getField('number')" *ngIf="debug"></vdms-hq-ui-control-state>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>Text long (textarea)</mat-panel-title>
      </mat-expansion-panel-header>
      <vdms-hq-ui-form-input-text-area
        [label]="label"
        [placeholder]="placeholder"
        [hint]="hint"
        [withFooter]="withFooter"
        formControlName="textarea"
      ></vdms-hq-ui-form-input-text-area>

      <vdms-hq-ui-control-state [control]="getField('textarea')" *ngIf="debug"></vdms-hq-ui-control-state>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>Selector</mat-panel-title>
      </mat-expansion-panel-header>
      <vdms-hq-ui-form-input-select
        [label]="label"
        [placeholder]="placeholder"
        [hint]="hint"
        formControlName="select"
        [multiple]="true"
        [enableSelectAll]="true"
        [enableDeselectAll]="true"
        [selectOptions]="selectOptions"
        [withFooter]="withFooter"
      >
      </vdms-hq-ui-form-input-select>

      <vdms-hq-ui-control-state [control]="getField('select')" *ngIf="debug"></vdms-hq-ui-control-state>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>Date</mat-panel-title>
      </mat-expansion-panel-header>

      <div class="grid grid-gap-x grid-upto3">
        <div class="grid grid-gap-x grid-upto3">
          <vdms-hq-ui-form-input-text
            formControlName="dateCustomValue"
            label="Custom value"
            mask="9999-99-99"
            [dropSpecialCharacters]="false"
          ></vdms-hq-ui-form-input-text>
          <vdms-hq-ui-button (click)="updateValue('date', getField('dateCustomValue').value)"
            >Set value
          </vdms-hq-ui-button>
        </div>
      </div>

      <vdms-hq-ui-form-input-date
        [label]="label"
        [placeholder]="placeholder"
        [hint]="hint"
        [withFooter]="withFooter"
        formControlName="date"
      ></vdms-hq-ui-form-input-date>

      <vdms-hq-ui-control-state [control]="getField('date')" *ngIf="debug"></vdms-hq-ui-control-state>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>Date with time</mat-panel-title>
      </mat-expansion-panel-header>

      <div class="grid grid-gap-x grid-upto3">
        <div class="grid grid-gap-x grid-upto3">
          <vdms-hq-ui-form-input-text
            formControlName="dateTimeCustomValue"
            label="Custom value"
            mask="9999-99-99SHh:m0:s0"
            [dropSpecialCharacters]="false"
          ></vdms-hq-ui-form-input-text>
          <vdms-hq-ui-button (click)="updateValue('dateTime', getField('dateTimeCustomValue').value)"
            >Set value
          </vdms-hq-ui-button>
        </div>
      </div>

      <vdms-hq-ui-form-input-date-time
        [label]="label"
        [placeholder]="placeholder"
        [hint]="hint"
        [withFooter]="withFooter"
        [timepicker]="true"
        formControlName="dateTime"
      ></vdms-hq-ui-form-input-date-time>

      <vdms-hq-ui-control-state [control]="getField('dateTime')" *ngIf="debug"></vdms-hq-ui-control-state>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>Checkbox</mat-panel-title>
      </mat-expansion-panel-header>
      <vdms-hq-ui-form-input-checkbox
        [label]="label"
        [placeholder]="placeholder"
        [hint]="hint"
        [withFooter]="withFooter"
        formControlName="checkbox"
      >
      </vdms-hq-ui-form-input-checkbox>

      <vdms-hq-ui-control-state [control]="getField('checkbox')" *ngIf="debug"></vdms-hq-ui-control-state>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>Checkbox list vertical</mat-panel-title>
      </mat-expansion-panel-header>
      <vdms-hq-ui-form-checkbox-list
        [label]="label"
        [placeholder]="placeholder"
        [hint]="hint"
        [withFooter]="withFooter"
        [available]="selectOptions"
        formControlName="checkboxList"
      ></vdms-hq-ui-form-checkbox-list>

      <vdms-hq-ui-control-state [control]="getField('checkboxList')" *ngIf="debug"></vdms-hq-ui-control-state>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>Checkbox list horizontal</mat-panel-title>
      </mat-expansion-panel-header>
      <vdms-hq-ui-form-checkbox-list
        [label]="label"
        [placeholder]="placeholder"
        [hint]="hint"
        [withFooter]="withFooter"
        [available]="selectOptions"
        orientation="horizontal"
        formControlName="checkboxListHorizontal"
      ></vdms-hq-ui-form-checkbox-list>

      <vdms-hq-ui-control-state [control]="getField('checkboxListHorizontal')" *ngIf="debug"></vdms-hq-ui-control-state>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>Simplified list</mat-panel-title>
      </mat-expansion-panel-header>
      <vdms-hq-ui-form-checkbox-list
        [label]="label"
        [placeholder]="placeholder"
        [hint]="hint"
        [withFooter]="withFooter"
        [available]="selectOptions"
        [checkboxVisible]="false"
        formControlName="simplifiedList"
      ></vdms-hq-ui-form-checkbox-list>

      <vdms-hq-ui-control-state [control]="getField('simplifiedList')" *ngIf="debug"></vdms-hq-ui-control-state>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>Simplified list horizontal</mat-panel-title>
      </mat-expansion-panel-header>
      <vdms-hq-ui-form-checkbox-list
        [label]="label"
        [placeholder]="placeholder"
        [hint]="hint"
        [withFooter]="withFooter"
        [available]="selectOptions"
        [checkboxVisible]="false"
        orientation="horizontal"
        formControlName="simplifiedListHorizontal"
      ></vdms-hq-ui-form-checkbox-list>

      <vdms-hq-ui-control-state
        [control]="getField('simplifiedListHorizontal')"
        *ngIf="debug"
      ></vdms-hq-ui-control-state>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>Radio vertical</mat-panel-title>
      </mat-expansion-panel-header>
      <vdms-hq-ui-form-radio-list
        [label]="label"
        [placeholder]="placeholder"
        [hint]="hint"
        [selectOptions]="selectOptions"
        [withFooter]="withFooter"
        formControlName="radio"
      >
      </vdms-hq-ui-form-radio-list>

      <vdms-hq-ui-control-state [control]="getField('radio')" *ngIf="debug"></vdms-hq-ui-control-state>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>Radio horizontal</mat-panel-title>
      </mat-expansion-panel-header>
      <vdms-hq-ui-form-radio-list
        [label]="label"
        [placeholder]="placeholder"
        [hint]="hint"
        [selectOptions]="selectOptions"
        [withFooter]="withFooter"
        orientation="horizontal"
        formControlName="radioHorizontal"
      >
      </vdms-hq-ui-form-radio-list>

      <vdms-hq-ui-control-state [control]="getField('radioHorizontal')" *ngIf="debug"></vdms-hq-ui-control-state>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>Selector with chips</mat-panel-title>
      </mat-expansion-panel-header>
      <vdms-hq-ui-form-input-chips
        [label]="label"
        [placeholder]="placeholder"
        [autocompleteOptions]="emailOptions"
        formControlName="selectorChips"
      ></vdms-hq-ui-form-input-chips>

      <vdms-hq-ui-control-state [control]="getField('selectorChips')" *ngIf="debug"></vdms-hq-ui-control-state>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>Chips</mat-panel-title>
      </mat-expansion-panel-header>
      <vdms-hq-ui-form-input-chips
        [label]="label"
        [placeholder]="placeholder"
        [hint]="hint"
        [withFooter]="withFooter"
        formControlName="chips"
      ></vdms-hq-ui-form-input-chips>

      <vdms-hq-ui-control-state [control]="getField('chips')" *ngIf="debug"></vdms-hq-ui-control-state>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>Slider</mat-panel-title>
      </mat-expansion-panel-header>

      <div class="grid grid-gap-x grid-upto3">
        <div class="grid grid-gap-x grid-upto3">
          <vdms-hq-ui-form-input-number
            formControlName="sliderCustomValue"
            label="Custom value"
          ></vdms-hq-ui-form-input-number>
          <vdms-hq-ui-button (click)="updateValue('slider', getField('sliderCustomValue').value)"
            >Set value
          </vdms-hq-ui-button>
        </div>
      </div>

      <vdms-hq-ui-form-slider
        [label]="label"
        [placeholder]="placeholder"
        [hint]="hint"
        [withFooter]="withFooter"
        formControlName="slider"
      >
      </vdms-hq-ui-form-slider>

      <vdms-hq-ui-control-state [control]="getField('slider')" *ngIf="debug"></vdms-hq-ui-control-state>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>Filtered List</mat-panel-title>
      </mat-expansion-panel-header>
      <vdms-hq-ui-form-filtered-list
        [sortingOptions]="filteringSortingOptions"
        [dataSource]="filteringListDataSource"
        [label]="label"
        [placeholder]="placeholder"
        [hint]="hint"
        [withFooter]="withFooter"
        formControlName="listValue"
      >
      </vdms-hq-ui-form-filtered-list>

      <vdms-hq-ui-control-state [control]="getField('listValue')" *ngIf="debug"></vdms-hq-ui-control-state>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>Sortable List</mat-panel-title>
      </mat-expansion-panel-header>
      <vdms-hq-ui-form-sortable-checkbox-list
        formControlName="listSortableCheckboxValue"
        [columns]="3"
        [available]="selectOptionsLong"
        [alwaysEnabled]="alwaysEnabled"
      >
      </vdms-hq-ui-form-sortable-checkbox-list>

      <vdms-hq-ui-control-state
        [control]="getField('listSortableCheckboxValue')"
        *ngIf="debug"
      ></vdms-hq-ui-control-state>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>Timecode</mat-panel-title>
      </mat-expansion-panel-header>

      <vdms-hq-form-input-timecode
        formControlName="timecode"
        [framerate]="framerate25fps"
        [label]="label"
        [placeholder]="placeholder"
        [hint]="hint"
        [withFooter]="withFooter"
      >
      </vdms-hq-form-input-timecode>
      <vdms-hq-ui-control-state [control]="getField('timecode')" *ngIf="debug"></vdms-hq-ui-control-state>

      <vdms-hq-form-input-timecode
        formControlName="timecode60fps"
        [framerate]="framerate60fps"
        label="Timecode 60fps"
        [placeholder]="placeholder"
        [hint]="hint"
        [withFooter]="withFooter"
      >
      </vdms-hq-form-input-timecode>
      <vdms-hq-ui-control-state [control]="getField('timecode60fps')" *ngIf="debug"></vdms-hq-ui-control-state>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>Timecode pair</mat-panel-title>
      </mat-expansion-panel-header>

      <vdms-hq-form-input-timecode-pair
        [framerate]="framerate30fps"
        formControlName="timecodePair"
        [label]="label"
        [placeholder]="placeholder"
        [max]="maxDurationForPair"
        [withFooter]="withFooter"
        [hint]="hint"
      >
      </vdms-hq-form-input-timecode-pair>
      <vdms-hq-ui-control-state [control]="getField('timecodePair')" *ngIf="debug"></vdms-hq-ui-control-state>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>Bitrate range</mat-panel-title>
      </mat-expansion-panel-header>

      <vdms-hq-form-input-bitrate-range
        formControlName="bitrateRange"
        [label]="label"
        [placeholder]="placeholder"
        [range]="{ max: 112340000 }"
        [withFooter]="withFooter"
        [hint]="hint"
      >
      </vdms-hq-form-input-bitrate-range>

      <vdms-hq-ui-control-state [control]="getField('bitrateRange')" *ngIf="debug"></vdms-hq-ui-control-state>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>Timecode range</mat-panel-title>
      </mat-expansion-panel-header>

      <vdms-hq-form-input-timecode-range
        formControlName="timecodeRange"
        [label]="label"
        [placeholder]="placeholder"
        [range]="{ min: minTimecode, max: maxTimecode }"
        [withFooter]="withFooter"
        [hint]="hint"
      >
      </vdms-hq-form-input-timecode-range>

      <vdms-hq-ui-control-state [control]="getField('timecodeRange')" *ngIf="debug"></vdms-hq-ui-control-state>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>Size range</mat-panel-title>
      </mat-expansion-panel-header>

      <vdms-hq-form-input-size-range
        formControlName="bytesRange"
        [label]="label"
        [placeholder]="placeholder"
        [range]="{ max: 41232122 }"
        [withFooter]="withFooter"
        [hint]="hint"
      >
      </vdms-hq-form-input-size-range>

      <vdms-hq-ui-control-state [control]="getField('bytesRange')" *ngIf="debug"></vdms-hq-ui-control-state>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>Toggle</mat-panel-title>
      </mat-expansion-panel-header>

      <vdms-hq-ui-form-input-toggle
        formControlName="toggle"
        [label]="label"
        [placeholder]="placeholder"
        [withFooter]="withFooter"
        [hint]="hint"
      >
      </vdms-hq-ui-form-input-toggle>

      <vdms-hq-ui-control-state [control]="getField('bytesRange')" *ngIf="debug"></vdms-hq-ui-control-state>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>Input Grid</mat-panel-title>
      </mat-expansion-panel-header>
      <vdms-hq-ui-form-input-grid [textLength]="6" [inputType]="'number'"> </vdms-hq-ui-form-input-grid>
    </mat-expansion-panel>
  </mat-accordion>
</form>
