import { Observable } from 'rxjs';

export interface LoadableDataSource {
  isLoading$: Observable<boolean>;
  emptyResults$?: Observable<boolean>;
  failedToLoad$?: Observable<boolean>;
  subNotEmptyResults$?: Observable<boolean>;
  startLoading?(): void;
  stopLoading?(): void;
}

export const isLoadableDataSource = (obj: any): false | LoadableDataSource => {
  return typeof obj === 'object' && 'isLoading$' in obj && (obj as LoadableDataSource);
};

export const isWithEmptyResults = (
  obj: any,
):
  | false
  | {
      emptyResults$: Observable<boolean>;
    } => {
  return (
    typeof obj === 'object' &&
    'emptyResults$' in obj &&
    (obj as {
      emptyResults$: Observable<boolean>;
    })
  );
};
