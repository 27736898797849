<vdms-hq-ui-filtered-results [sharedE2eId]="'asset-search-wrapper'" [hiddenSwitcher]="true">
  <vdms-hq-dynamic-filters
    *ngIf="config$ | async; let config"
    filters
    [filterConfig]="config"
    [facets]="(facets.facets$ | async) ?? undefined"
    [facetsEnabled]="true"
    [loading]="(dataSource.isLoading$ | async) ?? false"
    [withFooter]="false"
    [formControl]="form"
    [tourConfig]="{advancedSearch}"
    [scope]="scopeName"
    [sharedE2eId]="'asset-search-filters'"
  >
  </vdms-hq-dynamic-filters>
  <ng-container results>
    <vdms-hq-ui-data-presentation-header
      [actions]="headerActions$ | async"
      (action)="handleAction($event)"
      [title]="titleKey | translate"
      [counterIndicator]="(dataSource.total$ | async) ?? undefined"
    >
      <ng-container content [ngTemplateOutlet]="headerContentRef"></ng-container>
    </vdms-hq-ui-data-presentation-header>
    <vdms-hq-applied-dynamic-filters
      [filters]="appliedFilters$ | async"
      [configKey]="configKey$ | async"
      (changed)="onChangeAppliedDynamicFilters($event)"
    ></vdms-hq-applied-dynamic-filters>
    <vdms-hq-asset-results-2
      [dataSource]="dataSource"
      [actions]="(actions$ | async) ?? []"
      [contextActions]="assetResultsMenuConfig2"
      [scopeName]="scopeName"
      (action)="handleAction($event)"
      [sharedE2eId]="'asset-search-results'"
    ></vdms-hq-asset-results-2>
  </ng-container>
</vdms-hq-ui-filtered-results>

<vdms-hq-browse-results-actions
  *ngIf="(dataSource?.selection.total$ | async) > 0"
  [selectionManager]="dataSource.selection"
  (actions)="handleFooterActions($event)"
></vdms-hq-browse-results-actions>

<ng-template #headerContentRef>
  <div class="browse-header">
    <div class="browse-header__toggle" (click)="filteredResultsService.toggleFilters()">
      <mat-icon class="icon">tune</mat-icon>
      <span *ngIf="(filteredResultsService.sidebarOpened$ | async) === true">
        {{ 'common.ui.results.hide_advanced_filters' | translate }}
      </span>
      <span *ngIf="(filteredResultsService.sidebarOpened$ | async) === false">
        {{ 'common.ui.results.show_advanced_filters' | translate }}
      </span>
    </div>
  </div>
</ng-template>

<vdms-hq-tour-item #advancedSearch configName="advancedSearch"></vdms-hq-tour-item>
<vdms-hq-tour-item #searchListSettings configName="searchListSettings"></vdms-hq-tour-item>
<vdms-hq-tour-item #searchOpenAsset configName="searchOpenAsset"></vdms-hq-tour-item>
