import { FieldsConfigService } from '@vdms-hq/config';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataColumn } from '@vdms-hq/ui';

@Injectable({
  providedIn: 'root',
})
export class RightsPartnersFieldsConfigService extends FieldsConfigService {
  tableColumnsConfig$: Observable<{
    all: DataColumn[];
    enabled: string[];
  }> = this.tableConfigForScope$('other-rights-partners').pipe(
    map((config) => ({
      all: this.convertToColumns(config.all),
      enabled: this.convertToIds(config.defaultList),
    })),
  );
}
