import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CartStateService } from '@vdms-hq/cart-core';
import { E2eIdDirective, SharedModule } from '@vdms-hq/shared';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CartTotalDataSource } from '../../logic/data-sources/cart-total.data-source';

@Component({
  selector: 'vdms-hq-cart-button',
  templateUrl: './cart-button.component.html',
  styleUrls: ['./cart-button.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    MatButtonModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    CommonModule,
    TranslateModule,
    SharedModule,
    MatIconModule,
    RouterModule,
    E2eIdDirective,
  ],
})
export class CartButtonComponent {
  isBusy$: Observable<boolean> = combineLatest([this.cartTotalDataSource.totalIsLoading$]).pipe(
    map(([totalIsLoading]) => totalIsLoading),
  );
  total$ = this.cartTotalDataSource.total$;

  constructor(private cartTotalDataSource: CartTotalDataSource, private cartStateService: CartStateService) {}
}
