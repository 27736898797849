import { Pipe, PipeTransform } from '@angular/core';
import { TemplateBinding } from '@angular/compiler';

@Pipe({
  name: 'disabledMenuElement',
  pure: true,
  standalone: true,
})
export class DisabledPipe implements PipeTransform {
  transform<T = TemplateBinding>(value: ((item?: T | undefined) => boolean) | undefined, item: T): boolean {
    if (typeof value === 'function') {
      return value(item) ?? false;
    }

    return false;
  }
}
