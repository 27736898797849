import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UISidenavComponent } from './sidenav/sidenav.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { UISidenavMenuComponent } from './sidenav/menu/menu.component';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { SharedModule, E2eIdDirective } from '@vdms-hq/shared';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BdcWalkModule } from 'bdc-walkthrough';
import { SidenavService } from './logic/services/sidenav.service';
import { UIDialogModule } from '../ui-dialog';

@NgModule({
  declarations: [UISidenavComponent, UISidenavMenuComponent],
  imports: [
    CommonModule,
    MatSidenavModule,
    RouterModule,
    SharedModule,
    MatIconModule,
    MatRippleModule,
    MatListModule,
    MatTooltipModule,
    BdcWalkModule,
    UIDialogModule,
    E2eIdDirective,
  ],
  exports: [UISidenavComponent],
  providers: [SidenavService],
})
export class UISidenavModule {}
