import { BehaviorSubject } from 'rxjs';
import { PlayerAction, PlayerConfiguration, PlayerState, Subtitles } from './player.interface';
import { Framerate, Timecode } from '@vdms-hq/timecode';
import { take } from 'rxjs/operators';

export abstract class SharedPlayerService {
  currentVolumeLevel$ = new BehaviorSubject(1);
  currentPlaybackRate$ = new BehaviorSubject(1);
  #config?: PlayerConfiguration;
  get config() {
    return this.#config;
  }

  protected stateSubject$ = new BehaviorSubject<PlayerState>({
    state: 'not_initialized',
  });

  state$ = this.stateSubject$.asObservable();

  protected framerateSubject = new BehaviorSubject(Framerate.default());
  framerate$ = this.framerateSubject.asObservable();

  protected offsetSubject = new BehaviorSubject<Timecode | null>(null);
  offset$ = this.offsetSubject.asObservable();

  duration$ = new BehaviorSubject<Timecode | null>(null);
  currentTimecode$ = new BehaviorSubject<Timecode | null>(null);

  loading$ = new BehaviorSubject<boolean>(false);

  configure(config: PlayerConfiguration): void {
    this.#config = config;
    this.framerateSubject.next(config.framerate ?? Framerate.default());
    this.offsetSubject.next(config.offset);
  }

  handleAdvancedPlayerControlsAction(action: {
    key: PlayerAction;
    volume?: number | null;
    subtitles?: Subtitles;
    qualityLevel?: string | number;
    rate?: number;
    audioTrack?: number;
  }) {
    const { key, volume, subtitles, qualityLevel, rate, audioTrack } = action;

    if (key === 'updateVolume' && volume !== undefined && volume !== null) {
      this.updateVolume(volume);
      return;
    }

    if (key === 'changeSubtitles' && subtitles) {
      this.changeSubtitles(subtitles);
      return;
    }

    if (key === 'changeQualityLevel' && qualityLevel !== undefined) {
      this.changeQualityLevel(qualityLevel as number);
      return;
    }

    if (key === 'changePlaybackRate' && rate !== undefined) {
      this.setPlaybackRate(rate);
      return;
    }

    if (key === 'changeAudioTrack' && audioTrack !== undefined) {
      this.changeAudioTrack(audioTrack);
      return;
    }

    this.handleAction(key);
  }

  handleAction(key: PlayerAction, value?: string | number | Subtitles) {
    if (this.stateSubject$.value.state !== 'ready') {
      return;
    }

    switch (key) {
      case 'togglePlayPause':
        this.togglePlayPause();
        break;
      case 'secondForward':
        this.seekSeconds(value as number);
        break;
      case 'frameForward':
        this.seekFrames(value as number);
        break;
      case 'increasePlaybackRate':
        this.increasePlaybackRate();
        break;
      case 'decreasePlaybackRate':
        this.decreasePlaybackRate();
        break;
      case 'resetPlaybackRate':
        this.resetPlaybackRate();
        break;
      case 'toggleMute':
        this.toggleMute();
        break;
      case 'changeSubtitles':
        this.changeSubtitles(value as Subtitles);
        break;
      case 'toggleFullScreen':
        this.toggleFullScreen();
        break;
      case 'playToIn':
        this.playToIn();
        break;
      case 'playToOut':
        this.playToOut();
        break;
      case 'inToPlayhead':
        this.inToPlayhead();
        break;
      case 'outToPlayhead':
        this.outToPlayhead();
        break;
      case 'changeAudioTrack':
        this.changeAudioTrack(value as number);
        break;
      case 'updateVolumeUp':
        this.updateVolumeUp(value as number);
        break;
      case 'updateVolume':
        this.updateVolume(value as number);
        break;
      case 'pause':
        this.pause();
        break;
      case 'play':
        this.play();
        break;
      case 'changeQualityLevel':
        this.changeQualityLevel(value as number);
        break;
      case 'togglePictureInPicture':
        this.togglePictureInPicture();
        break;
      case 'toggleHelpDialog':
        this.toggleHelpDialog();
        break;
    }
  }

  protected abstract togglePlayPause(): void;

  protected abstract seekSeconds(value: number): void;

  protected abstract seekFrames(value: number): void;

  protected abstract updateVolumeUp(value: number): void;

  protected abstract updateVolume(value: number): void;

  protected abstract toggleMute(): void;

  protected abstract changeSubtitles(subtitle?: Subtitles): void;

  protected abstract increasePlaybackRate(): void;

  protected abstract decreasePlaybackRate(): void;

  protected abstract setPlaybackRate(rate: number): void;

  protected abstract resetPlaybackRate(): void;

  protected abstract pause(): void;

  protected abstract play(): void;

  protected abstract toggleFullScreen(): void;

  protected abstract changeAudioTrack(audioTrackId: number): void;

  protected abstract playToIn(): void;

  protected abstract playToOut(): void;

  protected abstract inToPlayhead(): void;

  protected abstract outToPlayhead(): void;

  protected abstract goToTime(seconds: number): void;

  protected abstract changeQualityLevel(levelIndex: number): void;

  protected abstract togglePictureInPicture(): void;

  protected abstract toggleHelpDialog(): void;

  protected setError(message: string) {
    this.stateSubject$.next({
      state: 'error',
      message,
    });
  }

  unloadShared() {
    this.stateSubject$.next({ state: 'not_initialized' });
    this.offsetSubject.next(null);
    this.currentTimecode$.next(null);
    this.duration$.next(null);
    this.currentPlaybackRate$.next(1);
    this.currentVolumeLevel$.next(1);
  }

  updateOffset(offset: Timecode): void {
    this.offsetSubject.next(offset);
  }

  goToTimecode(timecode: Timecode, withOffset = false) {
    if (withOffset) {
      this.offset$.pipe(take(1)).subscribe((offset) => {
        const tcMinusOffset = offset ? timecode.subtract(offset, false) : timecode;

        const seconds = tcMinusOffset?.countSeconds();
        if (seconds !== undefined) {
          this.goToTime(seconds);
        }
      });
    } else {
      const seconds = timecode?.countSeconds();
      if (seconds !== undefined) {
        this.goToTime(seconds);
      }
    }
  }
}
