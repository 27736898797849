import { inject, Injectable } from '@angular/core';
import { ActivatedClientService, Permission } from '@vdms-hq/activated-client';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, startWith, withLatestFrom } from 'rxjs/operators';
import { DestinationModel, DISCOUNT_STATUS } from '@vdms-hq/api-contract';
import { SelectOption } from '@vdms-hq/shared';

export enum CheckoutStep {
  CART = 0,
  DELIVERY_METHOD = 1,
  DELIVERY_DETAILS = 2,
  RECIPIENT_DETAILS = 3,
  DELIVERY_SUMMARY = 4,
}

export interface DiscountState {
  discountCode?: string | null;
  discountStatus: DISCOUNT_STATUS;
  discountName?: string;
}

export interface Department {
  uuid?: string;
  name?: string;
}

@Injectable({ providedIn: 'root' })
export class CartStateService {
  private readonly activatedClientService = inject(ActivatedClientService);

  readonly isClipOnlyAndPermitted$ = this.activatedClientService.vida$.pipe(
    withLatestFrom(this.activatedClientService.permissions$),
    map(([vida, permissions]) => !vida?.clipsOnly && permissions.includes(Permission.SHOPPING_CART)),
  );

  readonly isClipOnly$ = this.activatedClientService.vida$.pipe(map((vida) => !!vida?.clipsOnly));

  readonly isUpdating$ = new BehaviorSubject(false);
  readonly isSubmitting$ = new BehaviorSubject(false);

  readonly isBlocked$ = combineLatest([this.isUpdating$, this.isSubmitting$, this.isClipOnly$]).pipe(
    map(([isUpdating, isSubmitting, isClipOnly]) => isUpdating || isSubmitting || isClipOnly),
  );

  /** It checks is currently something is adding to cart */
  // todo seems like invalid
  readonly isEnabled$: Observable<boolean> = this.isUpdating$.asObservable().pipe(startWith(false));

  clientDiscountEnabled$ = this.activatedClientService.clientDiscountEnabled$;
  advancedCheckoutDepartmentRequired$ = this.activatedClientService.advancedCheckoutDepartmentRequired$;

  refresh$ = new BehaviorSubject<number>(Date.now());

  checkoutStep$ = new BehaviorSubject<number>(CheckoutStep.CART);
  isOrderApproved$ = new BehaviorSubject<boolean>(false);
  isValidated$ = new BehaviorSubject<boolean>(false);

  discount$ = new BehaviorSubject<DiscountState>({ discountCode: undefined, discountStatus: DISCOUNT_STATUS.NONE });
  department$ = new BehaviorSubject<Department>({ uuid: undefined, name: undefined });

  destinationsData$ = new BehaviorSubject<{ destinations: DestinationModel[]; options: SelectOption[] } | null>(null);

  get discountCode() {
    return this.discount$.value.discountCode;
  }

  get department() {
    return this.department$.value;
  }

  checkDiscount(reset: boolean = false, resetDepartment: boolean = false) {
    if (reset) {
      this.discount$.next({ discountCode: null, discountStatus: DISCOUNT_STATUS.NONE });
      if (resetDepartment) {
        this.department$.next({ uuid: undefined, name: undefined });
      }
    }
    this.refresh$.next(Date.now());
  }

  refreshCart = () => {
    this.isUpdating$.next(false);
    this.isSubmitting$.next(false);
    this.isValidated$.next(false);
    this.refresh$.next(Date.now());
  };

  resetAfterAssetsRemoved() {
    this.isUpdating$.next(false);
    this.isSubmitting$.next(false);
    this.isValidated$.next(true);
    this.checkoutStep$.next(CheckoutStep.CART);
    this.refresh$.next(Date.now());
  }

  nextStep() {
    const maxStep = Object.keys(CheckoutStep).length / 2 - 1;

    if (this.checkoutStep$.value < maxStep) {
      this.checkoutStep$.next(this.checkoutStep$.value + 1);
    }
  }
}
