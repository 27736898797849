import { inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  forkJoin,
  from,
  iif,
  Observable,
  of,
  shareReplay,
  startWith,
  take,
  tap,
  combineLatest,
  withLatestFrom,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { LocalDatabaseService } from './local-database-service';
import { LoggingService, JobsApiService } from '@vdms-hq/api-contract';
import { ToastService } from '@vdms-hq/toast';
import { MetadataListConfigService } from './metadata-list-config.service';
import { MetadataListFiltersService } from './metadata-list-filters.service';
import { PlayerMetadataListSource, UiTimecodesListDataSource, ViewPlayerMetadataItem } from './metadata-list.model';
import { LegacyStreamsSourceService } from './legacy/legacy-streams-source.service';
import { fromLocalCache } from './transformer/media-insights.transformer';
import { fromLogging, fromLoggingInfo } from './transformer/logging.transformer';
import { LoggingActionService } from '@vdms-hq/logging';
import { MetadataActionsService } from './metadata-actions.service';
import { MetadataRefreshService } from './metadata-refresh.service';

@Injectable({
  providedIn: 'root',
})
export class MetadataListDatasource implements UiTimecodesListDataSource {
  #legacyStreams = inject(LegacyStreamsSourceService);
  #localDatabase = inject(LocalDatabaseService);
  #jobsApiService = inject(JobsApiService);
  #toastService = inject(ToastService);
  #metadataConfigService = inject(MetadataListConfigService);
  #metadataListForm = inject(MetadataListFiltersService);
  #loggingService = inject(LoggingService);
  #loggingActionService = inject(LoggingActionService);
  #metadataActionsService = inject(MetadataActionsService);
  #metadataRefreshService = inject(MetadataRefreshService);

  #loading$ = new BehaviorSubject(false);
  isLoading$ = this.#loading$.asObservable();

  fetchedAt$ = new BehaviorSubject<Date | null>(null);

  assetUuid$ = this.#metadataConfigService.configDefinite$.pipe(map((config) => config.assetUuid));
  #framerate$ = this.#metadataConfigService.configDefinite$.pipe(map((config) => config.framerate));

  #loggingByAsset = this.assetUuid$.pipe(
    take(1),
    switchMap((assetUuid) => this.#loggingService.getEntireLog(assetUuid)),
  );

  #fetchData$: Observable<ViewPlayerMetadataItem[]> = combineLatest([
    this.#metadataConfigService.configDefinite$,
    this.#metadataRefreshService.refreshTable$,
  ]).pipe(
    tap(() => this.#loading$.next(true)),
    switchMap(([config]) =>
      from(this.#localDatabase.isLoaded(config.assetUuid)).pipe(
        map((isLoaded) => ({
          isLoaded,
          ...config,
        })),
      ),
    ),
    switchMap((data) => {
      if (data.isLoaded) {
        return of(data);
      }

      if (data.jobsJsonUrl) {
        return forkJoin([this.#jobsApiService.getFile(data.jobsJsonUrl), this.#loggingByAsset]).pipe(
          switchMap(([jobItems, logging]) => {
            const transformedLogging = logging.map((log, index) => fromLogging(log, jobItems.length + 1 + index));
            return this.#localDatabase.import([...jobItems, ...transformedLogging], data.assetUuid);
          }),
          catchError((err) => this.#handleError(err)),
          map(() => data),
        );
      }

      if (data.hasLegacyTranscription) {
        return this.#legacyStreams.getStreams().pipe(
          switchMap((items) => {
            return this.#localDatabase.import(items, data.assetUuid);
          }),
          catchError((err) => this.#handleError(err)),
          map(() => data),
        );
      }

      return this.#loggingByAsset.pipe(
        switchMap((logging) => {
          const transformedLogging = logging.map((log, index) => fromLogging(log, 1 + index));
          return this.#localDatabase.import(transformedLogging, data.assetUuid);
        }),
        catchError((err) => this.#handleError(err)),
        map(() => data),
      );
    }),
    switchMap((data) =>
      this.#metadataListForm.values$.pipe(
        tap(() => this.#loading$.next(true)),
        switchMap((filters) =>
          forkJoin([
            this.#localDatabase.findItems(data.assetUuid, filters),
            this.#localDatabase.getFetchedAt(data.assetUuid),
            of(filters),
          ]),
        ),
        tap(([, fetchedAt]) => this.fetchedAt$.next(fetchedAt)),
        catchError((err) => this.#handleError(err)),
        map(([items, fetchedAt, filters]) => items.map((item) => fromLocalCache(item, data.framerate, filters))),
      ),
    ),
    tap(() => this.#loading$.next(false)),
  );

  allData$ = this.#metadataConfigService.config$.pipe(
    switchMap((config) => iif(() => !!config, this.#fetchData$, of([]))),
    shareReplay(1),
  );

  connection$ = this.allData$;

  emptyResults$: Observable<boolean> = this.allData$.pipe(
    startWith([]),
    map((data) => data.length === 0),
  );

  total$ = this.allData$.pipe(map((list) => list.length));

  #handleError(err: any) {
    this.#loading$.next(false);
    this.#toastService.error({
      message: 'Failed to load metadata list',
      id: 'metadata-list',
    });

    console.error(err);

    return of([]);
  }

  reload() {
    this.#loading$.next(true);

    this.assetUuid$
      .pipe(
        take(1),
        switchMap((assetUuid) => this.#localDatabase.removeAsset(assetUuid)),
      )
      .subscribe(() => {
        this.#metadataConfigService.reload();
      });
  }

  removeItem(id: number, loggingUuid: string) {
    this.#loggingActionService
      .deleteNote(loggingUuid)
      .pipe(take(1), withLatestFrom(this.assetUuid$))
      .subscribe({
        next: ([, assetUuid]) => {
          this.#localDatabase.removeItem(id, assetUuid);
          this.#metadataRefreshService.refreshTable$.next(true);
          this.#toastService.success({
            id: 'log',
            message: 'pages.edl.deleted',
          });
        },
        error: () =>
          this.#toastService.error({
            id: 'log',
            message: 'common.global.fail',
          }),
      });
  }

  updateItem(item: ViewPlayerMetadataItem, type: PlayerMetadataListSource, loggingUuid?: string | undefined) {
    if (!item.id) {
      return;
    }

    switch (type) {
      case PlayerMetadataListSource.LOGGING:
        if (loggingUuid === undefined) {
          return;
        }

        this.#updateLoggingInfo(item.id, loggingUuid);
        break;
      case PlayerMetadataListSource.TRANSLATE_SUBTITLES:
      case PlayerMetadataListSource.TRANSCRIBE:
        this.#updateTranscribeInfo(item);
        break;
    }
  }

  #updateLoggingInfo(id: number, loggingUuid: string) {
    combineLatest([this.assetUuid$, this.#framerate$])
      .pipe(
        take(1),
        switchMap(([assetUuid, framerate]) =>
          forkJoin([this.#loggingActionService.editNote(loggingUuid, assetUuid, framerate), of(assetUuid)]),
        ),
        withLatestFrom(this.total$),
      )
      .subscribe({
        next: ([[[, loggingInfo], assetUuid], total]) => {
          this.#localDatabase.updateItem(id, assetUuid, undefined, fromLoggingInfo(loggingInfo, total + 1));
          this.#metadataRefreshService.refreshTable$.next(true);
          this.#toastService.success({
            id: 'log',
            message: 'pages.edl.edited',
          });
        },
        error: () =>
          this.#toastService.error({
            id: 'log',
            message: 'common.global.fail',
          }),
      });
  }

  #updateTranscribeInfo(item: ViewPlayerMetadataItem) {
    combineLatest([this.assetUuid$, this.#framerate$])
      .pipe(
        take(1),
        switchMap(([assetUuid, framerate]) =>
          this.#metadataActionsService.triggerEditItem(assetUuid, framerate, item.content, item),
        ),
      )
      .subscribe(() => {
        this.#metadataRefreshService.refreshTable$.next(true);
      });
  }
}
