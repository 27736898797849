import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormSectionComponent, UIButtonModule, UIDialogWrapperModule, UIFormModule, UILayoutModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import {
  bucketNameValidator,
  DestroyComponent,
  emailsArrayValidator,
  enumValuesToSelectOptions,
  prefixValidator,
} from '@vdms-hq/shared';
import { CredentialTypeEnum, DestinationAccessMethodEnum, DestinationStatus } from '@vdms-hq/api-contract';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DestinationFormService } from '../../logic/destination-form.service';
import { DeliveryDestinationCredentialsService } from '../../logic/delivery-destination-credentials.service';
import { AwsRegionsOptions } from '../../logic/delivery-destination-options';

@Component({
  selector: 'vdms-hq-edit-destination-dialog',
  templateUrl: './edit-destination-dialog.component.html',
  imports: [
    CommonModule,
    MatDialogModule,
    UIDialogWrapperModule,
    FormSectionComponent,
    UIFormModule,
    UIButtonModule,
    TranslateModule,
    UILayoutModule,
  ],
  standalone: true,
})
export class DestinationEditDialogComponent extends DestroyComponent implements OnInit {
  protected readonly DestinationAccessMethodEnum = DestinationAccessMethodEnum;
  protected readonly AwsRegionsOptions = AwsRegionsOptions;

  dialogRef: MatDialogRef<DestinationEditDialogComponent> = inject(MatDialogRef<DestinationEditDialogComponent>);
  formService: DestinationFormService = inject(DestinationFormService);
  destinationCredentialsService: DeliveryDestinationCredentialsService = inject(DeliveryDestinationCredentialsService);

  statusOptions = enumValuesToSelectOptions(DestinationStatus, 'common.delivery_destinations.status.', true);
  methodOptions = enumValuesToSelectOptions(DestinationAccessMethodEnum, 'common.delivery_destinations.method.', true);

  get method() {
    return this.form.controls.method.value;
  }

  form = new FormGroup({
    name: new FormControl<string>(this.formService.form.controls.name.value, {
      validators: [Validators.required],
    }),
    status: new FormControl<DestinationStatus>(this.formService.form.controls.status.value, {
      validators: [Validators.required],
    }),
    type: new FormControl<string | null>(this.formService.form.controls.type.value),
    method: new FormControl<string | null>({ disabled: false, value: this.formService.form.controls.method.value }),
    emailDelivery: new FormControl<string[]>(this.formService.form.controls.email.controls.delivery.value, {
      validators: [Validators.required, emailsArrayValidator(true)],
      nonNullable: true,
    }),
    deliveryEmails: new FormControl<string[]>(this.formService.form.controls.email.controls.notification.value, {
      validators: [emailsArrayValidator(true)],
      nonNullable: true,
    }),
    publish: new FormGroup({
      type: new FormControl<CredentialTypeEnum | null>(
        this.formService.form.controls.publish.controls?.type?.value ?? null,
      ),
      enabled: new FormControl<boolean>(this.formService.form.controls.publish.controls?.enabled?.value ?? true, {
        nonNullable: true,
      }),
      credentialUuid: new FormControl<string | null>(
        this.formService.form.controls.publish.controls?.credentialUuid?.value ?? null,
      ),
      configData: new FormGroup({
        region: new FormControl<string | null | undefined>(
          this.formService.form.controls.publish.controls?.configData?.controls?.region.value,
        ),
        bucketName: new FormControl<string | null | undefined>(
          this.formService.form.controls.publish.controls?.configData?.controls?.bucketName.value,
        ),
        prefix: new FormControl<string | undefined>(
          this.formService.form.controls.publish.controls?.configData?.controls?.prefix.value,
        ),
        earPassphrase: new FormControl<string | null | undefined>(
          this.formService.form.controls.publish.controls?.configData?.controls?.prefix.value,
        ),
        path: new FormControl<string | null | undefined>(
          this.formService.form.controls.publish.controls?.configData?.controls?.path.value,
        ),
      }),
    }),
  });

  ngOnInit() {
    const publishType = this.formService.form.controls.publish.controls?.type?.value;
    if (publishType) {
      this.destinationCredentialsService.getCredentialsByType(publishType);
    }

    this.form.controls.method.valueChanges.pipe(this.takeUntilDestroyed()).subscribe((method) => {
      switch (method) {
        case DestinationAccessMethodEnum.AWS:
          this.form.controls.publish.controls.credentialUuid.setValidators(Validators.required);
          this.form.controls.publish.controls.configData.controls.region.setValidators(Validators.required);
          this.form.controls.publish.controls.configData.controls.bucketName.setValidators([
            Validators.required,
            bucketNameValidator(),
          ]);
          this.form.controls.publish.controls.configData.controls.prefix.setValidators(prefixValidator());
          this.form.controls.publish.controls.type.setValue(CredentialTypeEnum.AWS);
          break;
        case DestinationAccessMethodEnum.ASPERA_HSTS:
          this.form.controls.publish.controls.credentialUuid.setValidators(Validators.required);
          this.form.controls.publish.controls.configData.controls.prefix.setValidators(prefixValidator());
          this.form.controls.publish.controls.type.setValue(CredentialTypeEnum.ASPERA_HSTS);
          break;
        case DestinationAccessMethodEnum.VIDA:
          this.form.controls.publish.controls.credentialUuid.removeValidators(Validators.required);
          this.form.controls.publish.controls.configData.controls.region.removeValidators(Validators.required);
          this.form.controls.publish.controls.configData.controls.bucketName.removeValidators(Validators.required);
          this.form.controls.publish.controls.configData.controls.prefix.removeValidators(prefixValidator());
          break;
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    if (this.form.valid) {
      const value = {
        name: this.form.controls.name.value,
        emailDelivery: this.form.controls.emailDelivery.value?.join(','),
        deliveryEmails: this.form.controls.deliveryEmails.value?.join(','),
        type: this.form.controls.type.value,
        method: this.form.controls.method.value,
        status: this.form.controls.status.value,
        publish: this.form.controls.publish.value,
      };
      console.log('value', value);
      this.dialogRef.close({ success: true, value });
    }
  }
}
