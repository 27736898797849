import { APP_INITIALIZER, Provider } from '@angular/core';
import { LibraryRouterService } from './services/library-router.service';

export const configureSearchLibrary = () => {
  const providers: Provider[] = [
    {
      provide: APP_INITIALIZER,
      useFactory: (libraryRouterService: LibraryRouterService) => () => libraryRouterService.registerListeners(),
      multi: true,
      deps: [LibraryRouterService],
    },
  ];

  return providers;
};
