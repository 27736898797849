import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ApiContractModule, DestinationApiService, DestinationModel } from '@vdms-hq/api-contract';
import { DeliveryDestinationView, DestinationsResultsDataSource } from '../../logic/destinations-results-data-source';
import {
  ActionContextLess,
  ActionIdentifier,
  DataPresentationHeaderComponent,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  UIConfirmationDialogService,
  UILoaderModule,
  UIResultsWrapperModule,
} from '@vdms-hq/ui';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { DestinationAddDialogComponent } from '../../components/destination-add-dialog/destination-add-dialog.component';
import { DELIVERY_DESTINATIONS_ROUTING } from '../../delivery-destinations-routing';
import { Router } from '@angular/router';
import { ToastService } from '@vdms-hq/toast';
import { Permission, PermissionService } from '@vdms-hq/activated-client';
import {
  catchError,
  combineLatest,
  debounceTime,
  filter,
  Observable,
  Subject,
  switchMap,
  take,
  takeUntil,
  throwError,
} from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { FilterType, ResourceModel, ValueFormat } from '@vdms-hq/shared';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'vdms-hq-destination-results',
  templateUrl: './destination-results.component.html',
  imports: [
    CommonModule,
    MatDialogModule,
    TranslateModule,
    ApiContractModule,
    UIResultsWrapperModule,
    UILoaderModule,
    MultipleDataPresentationComponent,
    DataPresentationHeaderComponent,
    DynamicFiltersModule,
  ],
  providers: [DestinationsResultsDataSource],
  standalone: true,
})
export class DestinationResultsComponent implements OnInit, OnDestroy {
  #destroy$ = new Subject<void>();

  #popToast = {
    DELETE_SUCCESS: () =>
      this.toastService.success({
        id: 'delete_field_success',
        message: 'common.delivery_destinations.delete.success',
      }),
    DELETE_FAILURE: (reason: string = 'Not specified') =>
      this.toastService.error({
        id: 'delete_field_failure',
        message: 'common.delivery_destinations.delete.failure',
        interpolatedParams: { reason },
      }),
  };
  enabled = ['uuid', 'name', 'status', 'email_delivery', 'delivery_emails', 'actions'];
  viewConfiguration: MultipleViewConfiguration<DestinationModel> = {
    tableAdvanced: {
      actions: [],
      columnsEnabled: this.enabled,
      columns: [
        {
          id: 'uuid',
          label: 'common.delivery_destinations.fields.uuid',
          valuePath: 'uuid',
        },
        {
          id: 'name',
          label: 'common.delivery_destinations.fields.name',
          valuePath: 'name',
        },
        {
          id: 'status',
          label: 'common.delivery_destinations.fields.status',
          valuePath: 'status',
          viewFormat: {
            pills: {
              active: 'done',
              draft: '',
            },
          },
        },
        {
          id: 'email_delivery',
          label: 'common.delivery_destinations.fields.email_delivery',
          valuePath: 'email.delivery',
          foldValues: true,
          viewFormat: {
            maxVisibleValues: 2,
          },
        },
        {
          id: 'delivery_emails',
          label: 'common.delivery_destinations.fields.delivery_emails',
          valuePath: 'email.notification',
          foldValues: true,
          viewFormat: {
            maxVisibleValues: 2,
          },
        },
        {
          id: 'actions',
          type: 'actions',
        },
      ],
    },
  };

  readonly filtersConfig: DynamicFilterInput[] = [
    {
      id: 'keyword',
      label: 'common.delivery_destinations.table.filter',
      resource: [ResourceModel.RATING],
      format: ValueFormat.AS_IS,
      filters: {
        objectPath: 'name',
        validFormat: 'keyword',
        type: FilterType.MASTER_TEXT,
      },
    },
  ];

  filters = new FormGroup({
    keyword: new FormControl<string>(''),
  });
  headerActions$: Observable<ActionContextLess[]> = this.permissionService
    .verifyWithOwnedPermissions$([Permission.CREATE_DELIVERY_DESTINATIONS])
    .pipe(
      map((hasPermission) =>
        hasPermission
          ? [
              {
                key: 'add',
                label: 'common.global.add',
                color: 'primary',
              },
            ]
          : [],
      ),
    );

  constructor(
    public dataSource: DestinationsResultsDataSource,
    private deliverService: DestinationApiService,
    private confirmationDialog: UIConfirmationDialogService,
    private translate: TranslateService,
    private toastService: ToastService,
    private dialog: MatDialog,
    private router: Router,
    private permissionService: PermissionService,
  ) {}

  ngOnInit() {
    this.dataSource.applyFilter('');
    this.#checkPermission();

    this.filters.valueChanges.pipe(takeUntil(this.#destroy$), debounceTime(250)).subscribe((value) => {
      this.dataSource.applyFilter(value.keyword ?? '');
    });
  }

  handleAction($event: { key: ActionIdentifier; item?: DeliveryDestinationView }) {
    switch ($event.key) {
      case 'add':
        this.dialog.open(DestinationAddDialogComponent);
        return;
    }

    if (!$event.item) {
      return;
    }
    switch ($event.key) {
      case 'edit':
        this.router.navigate([DELIVERY_DESTINATIONS_ROUTING.ROOT, $event.item.uuid]);
        break;
      case 'delete':
        this.deleteDestination($event.item.uuid, $event.item.name);
        break;
    }
  }

  deleteDestination(uuid: string, name: string) {
    this.confirmationDialog
      .openDelete({
        title: 'common.delivery_destinations.delete.delete_title',
        message: this.translate.instant('common.delivery_destinations.delete.delete_question') + name + ' ?',
      })
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() =>
          this.deliverService.delete(uuid).pipe(
            tap(() => {
              this.dataSource.applyFilter(this.dataSource.text$?.value ?? '');
              this.#popToast.DELETE_SUCCESS();
            }),
            catchError((err) => {
              this.#popToast.DELETE_FAILURE();
              return throwError(err);
            }),
          ),
        ),
      )
      .subscribe();
  }

  #checkPermission() {
    combineLatest([
      this.permissionService.verifyWithOwnedPermissions$([Permission.EDIT_DELIVERY_DESTINATIONS]),
      this.permissionService.verifyWithOwnedPermissions$([Permission.DELETE_DELIVERY_DESTINATIONS]),
    ])
      .pipe(take(1))
      .subscribe(([canEdit, canDelete]) => {
        canEdit &&
          this.viewConfiguration.tableAdvanced?.actions?.push({
            key: 'edit',
            icon: 'edit',
            onDoubleClick: true,
            label: 'common.global.edit',
          });
        canDelete &&
          this.viewConfiguration.tableAdvanced?.actions?.push({
            key: 'delete',
            icon: 'delete',
            onDoubleClick: false,
            label: 'common.global.delete',
          });
      });
  }

  ngOnDestroy() {
    this.#destroy$.next();
    this.#destroy$.complete();
  }
}
