import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Framerate, Timecode } from '@vdms-hq/timecode';
import { DialogResponse, SharedModule } from '@vdms-hq/shared';
import { UIButtonModule, UIDialogWrapperModule, UIFormModule } from '@vdms-hq/ui';

export interface EditTranscribeItemDialogInput {
  assetUuid: string;
  framerate: Framerate;
  timecodeIn: Timecode | null;
  timecodeOut: Timecode | null;
  content: string;
}

@Component({
  selector: 'vdms-hq-edit-item-dialog',
  templateUrl: './edit-item.component.html',
  styleUrls: ['./edit-item.component.scss'],
  standalone: true,
  imports: [UIFormModule, UIDialogWrapperModule, UIButtonModule, SharedModule],
})
export class EditItemComponent implements OnInit {
  form = new UntypedFormGroup({
    tcIn: new FormControl<Timecode | null>({ value: null, disabled: true }, []),
    tcOut: new FormControl<Timecode | null>({ value: null, disabled: true }, []),
    content: new FormControl<string | null>(null, Validators.required),
  });

  isLoading = false;
  framerate?: Framerate;

  constructor(
    public dialogRef: MatDialogRef<EditItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditTranscribeItemDialogInput,
  ) {}

  get tcIn() {
    return this.form.get('tcIn');
  }

  get tcOut() {
    return this.form.get('tcOut');
  }

  get content() {
    return this.form.get('content');
  }

  ngOnInit() {
    this.framerate = this.data.framerate;

    this.form.patchValue({
      tcIn: this.data.timecodeIn,
      tcOut: this.data?.timecodeOut,
      content: this.data?.content,
    });
  }

  onNoClick(): void {
    this.dialogRef.close({
      status: DialogResponse.ABORT,
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.dialogRef.close({
      status: DialogResponse.OK,
      content: this.content?.value,
    });
  }
}
