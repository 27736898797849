<nav class="side-menu" [ngClass]="state">
  <div [bdcWalkTriggerFor]="tourConfig.menuSidebar ? tourConfig.menuSidebar : defaultPopUp" class="menu-wrapper">
    <ng-container *ngFor="let section of config">
      <ng-container *ngIf="section.menuItems.length > 0 && section.label">
        <span
          [bdcWalkTriggerFor]="section.walkthroughTrigger ? tourConfig[section.walkthroughTrigger] : defaultPopUp"
          class="menu-label"
          *ngIf="showLabels; else separator"
          >{{ section.label | translate }}</span
        >
      </ng-container>
      <mat-nav-list class="menu">
        <ng-container *ngFor="let item of section.menuItems">
          <mat-list-item
            [bdcWalkTriggerFor]="item.walkthroughTrigger ? tourConfig[item.walkthroughTrigger] : defaultPopUp"
            *ngIf="
              (isExternal(item) && item.confirmation && isExternalSupport) ||
              (isExternal(item) && !item.confirmation) ||
              isInternal(item)
            "
          >
            <a
              [sharedE2eId]="'sidenav-' + item.id"
              *ngIf="isInternal(item)"
              [routerLink]="[item.route]"
              [queryParams]="item.queryParams"
              routerLinkActive="current"
              #menuLink
              class="menu-item"
              mat-ripple
            >
              <ng-container *ngTemplateOutlet="content"></ng-container>
            </a>
            <a
              *ngIf="isExternal(item) && !item.confirmation"
              [sharedE2eId]="'sidenav-' + item.id"
              [href]="item.href"
              class="menu-item"
              target="_blank"
              mat-ripple
            >
              <ng-container *ngTemplateOutlet="content"></ng-container>
            </a>
            <a
              *ngIf="isExternal(item) && item.confirmation && isExternalSupport"
              class="menu-item"
              mat-ripple
              (click)="showConfirmationDialog(item)"
            >
              <ng-container *ngTemplateOutlet="content_confirmation"></ng-container>
            </a>

            <ng-template #content>
              <ng-container *ngIf="showLabels">
                <mat-icon class="menu-item-icon" matListIcon>{{ item.icon }}</mat-icon>
              </ng-container>
              <mat-icon
                *ngIf="!showLabels"
                class="menu-item-icon"
                matListIcon
                [matTooltip]="item.label | translate"
                [matTooltipShowDelay]="800"
                [matTooltipPosition]="'right'"
                >{{ item.icon }}</mat-icon
              >
              <span class="menu-item-label">{{ item.label | translate }}</span>
            </ng-template>

            <ng-template #content_confirmation>
              <ng-container *ngIf="showLabels">
                <mat-icon class="menu-item-icon" matListIcon>{{ item.icon }}</mat-icon>
              </ng-container>
              <mat-icon
                *ngIf="!showLabels"
                class="menu-item-icon"
                matListIcon
                [matTooltip]="item.label | translate"
                [matTooltipShowDelay]="800"
                [matTooltipPosition]="'right'"
                >{{ item.icon }}</mat-icon
              >
              <span class="menu-item-label">{{ item.label | translate }}</span>
            </ng-template>
          </mat-list-item>
        </ng-container>
      </mat-nav-list>
    </ng-container>
  </div>

  <div class="version" *ngIf="branding">
    <span *ngIf="showLabels"> POWERED BY </span>
    <span class="logo" matTooltip="{{ 'POWERED BY VIDA ' + version }}" [matTooltipShowDelay]="800"></span>
    <span *ngIf="showLabels"> {{ version }} </span>
  </div>
</nav>
<div class="side-menu-filler"></div>

<ng-template #separator>
  <div class="menu-label__collapsed"></div>
</ng-template>

<bdc-walk-popup #defaultPopUp>default popup workaround</bdc-walk-popup>
