import { Component } from '@angular/core';
import { SearchBarVisibilityService } from '../../logic/search-bar-visibility.service';
import { debounceTime } from 'rxjs/operators';
import { SimpleQueryInputComponent } from '../simple-query-input/simple-query-input.component';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'vdms-hq-asset-search-results-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  imports: [SimpleQueryInputComponent, AsyncPipe, NgIf],
  standalone: true,
})
export class SearchBarComponent {
  isVisible$ = this.searchBarVisibility.isVisible$.pipe(debounceTime(0));

  constructor(private searchBarVisibility: SearchBarVisibilityService) {}
}
