import { inject, Injectable, OnDestroy } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import {
  CartApiService,
  DestinationApiService,
  DestinationConfigModel,
  DestinationModel,
  JobsApiService,
  JobType,
  ORDER_TYPE,
  JobTypePayloadInterface,
  JobTypeService,
  DISCOUNT_STATUS,
  UserApiService,
} from '@vdms-hq/api-contract';
import { AuthService } from '@vdms-hq/auth';
import {
  dashedToCapitalizedString,
  underscoreToString,
  DateValidator,
  emailsArrayValidator,
  SelectOption,
  SelectOptionKey,
} from '@vdms-hq/shared';
import moment from 'moment-timezone';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  merge,
  Observable,
  of,
  shareReplay,
  Subject,
  Subscription,
  takeUntil,
} from 'rxjs';
import { catchError, filter, map, startWith, take, tap, withLatestFrom } from 'rxjs/operators';
import { JobsSummary } from '../../pages/order-summary/order-summary.component';
import { CartStateService } from '@vdms-hq/cart-core';
import { DeliveryDestinationsValidators } from '@vdms-hq/delivery-destinations';
import { DepartmentService } from '@vdms-hq/discounts';

const burnInTextValidator = DeliveryDestinationsValidators.burnInTextValidator;

type DestinationsFormModel = {
  configUuid: FormControl<string | null>;
  uuid: FormControl<string | null>;
  hasBurnInText: FormControl<boolean>;
  burnInTextValue: FormControl<string | null>;
};
type DeliveryFormType = {
  destinations: FormArray<FormGroup<DestinationsFormModel>>;
  jobs: FormGroup;
  emails: FormControl<string[]>;
  expiryDate: FormControl<string>;
  deliveryMethod: FormControl<DeliveryMethods | null>;
  subject: FormControl<string>;
  purchaseOrder: FormControl<string>;
  comment: FormControl<string>;
  notificationEmails: FormControl<string[]>;
  expiry: FormControl<string>;
  deliveryDate: FormControl<string>;
  packageTitle: FormControl<string>;
  deliveryDelay: FormControl<string>;
  downloadLimit: FormControl<number>;
  unlimited: FormControl<boolean>;
  department_field_uuid: FormControl<string | null>;
};

export interface ValidationErrorList {
  type: 'error' | 'warning';
  subType?: 'missing-rates' | 'will-overwrite';
  message: string;
  assetUuid?: string;
  cartItemUuids?: string[];
  assetOriginalFilename?: string;
}

export enum DeliveryMethods {
  MANUAL = 'manual',
  PREDEFINED = 'predefined',
  WARM_UP = 'warm_up',
  WORKFLOW = 'workflow',
}

type DeliveryMethodsType = (typeof DeliveryMethods)[keyof typeof DeliveryMethods];

@Injectable({
  providedIn: 'root',
})
export class CartCheckoutFormService implements OnDestroy {
  private activatedClientService = inject(ActivatedClientService);
  private authService = inject(AuthService);
  private destinationsService = inject(DestinationApiService);
  private cartApiService = inject(CartApiService);
  private cartState = inject(CartStateService);
  private jobsApiService = inject(JobsApiService);
  private departmentService = inject(DepartmentService);
  private userService = inject(UserApiService);

  listenersSubscription!: Subscription;

  formInitialized = false;
  #destroy$ = new Subject<void>();
  #defaultExpiryDate = moment().add(7, 'd').toISOString();
  #form: FormGroup<DeliveryFormType> = new FormGroup({
    destinations: new FormArray([
      new FormGroup({
        uuid: new FormControl<string | null>(null, Validators.required),
        configUuid: new FormControl<string | null>(null, Validators.required),
        hasBurnInText: new FormControl<boolean>(false, { nonNullable: true }),
        burnInTextValue: new FormControl<string | null>(null, [burnInTextValidator()]),
      }),
    ]),
    jobs: new FormGroup({}),
    emails: new FormControl<string[]>([], [Validators.required, emailsArrayValidator(true)]),
    notificationEmails: new FormControl<string[]>([], [emailsArrayValidator(true)]),
    packageTitle: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
    purchaseOrder: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
    subject: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
    deliveryDelay: new FormControl<string>('immediate'),
    deliveryDate: new FormControl<string>(moment().add(15, 'm').toISOString()),
    deliveryMethod: new FormControl<DeliveryMethods | null>(null),
    expiry: new FormControl<string>('7'),
    expiryDate: new FormControl<string>(this.#defaultExpiryDate),
    comment: new FormControl<string>(''),
    downloadLimit: new FormControl<number>(-1),
    unlimited: new FormControl<boolean>(true),
    department_field_uuid: new FormControl<string | null>(null),
  });
  allDeliveriesConfig$ = new BehaviorSubject<DestinationConfigModel[]>([]);
  #allDeliveries$ = new BehaviorSubject<DestinationModel[]>([]);

  selectedDeliveryMethod$ = this.#form.controls.deliveryMethod.valueChanges;
  orderTitleValid$ = new BehaviorSubject<boolean>(false);
  purchaseOrderValid$ = new BehaviorSubject<boolean>(false);
  departmentValid$ = new BehaviorSubject<boolean>(false);
  deliveryDatesChecked$ = new BehaviorSubject<boolean>(false);
  expiryDatesChecked$ = new BehaviorSubject<boolean>(false);
  processSummaryAfterDownloadLimitChanged$ = new BehaviorSubject<number | null>(null);

  warmUpValidated$ = new BehaviorSubject<boolean>(false);
  emailDeliveryValidated$ = new BehaviorSubject<boolean>(false);

  jobTypes: JobType[] = [];
  jobsDetails: Record<string, string[]> = {};
  jobsFormValid$ = new BehaviorSubject<boolean>(false);
  jobsValidated$ = new BehaviorSubject<boolean>(false);

  overwriteApproved$ = new BehaviorSubject<boolean | null>(null);
  missingRates$ = new BehaviorSubject<boolean>(false);
  missingDefaultPolicy$ = new BehaviorSubject<boolean>(false);

  destinationsValidated$ = new BehaviorSubject(false);
  requiresValidation$ = combineLatest([this.destinationsValidated$, this.selectedDeliveryMethod$])
    .pipe(map(([isValidated, deliveryMethod]) => !isValidated && deliveryMethod === DeliveryMethods.PREDEFINED))
    .pipe(startWith(false));

  isFormValid$ = new BehaviorSubject(this.#form.valid);
  isLoading$ = new BehaviorSubject(false);
  generalExpiryTimeOptions: SelectOption[] = [
    {
      key: '7',
      label: 'pages.order.expiry_one_week',
    },
    {
      key: '14',
      label: 'pages.order.expiry_two_weeks',
    },
    {
      key: '30',
      label: 'pages.order.expiry_one_month',
    },
    {
      key: 'custom',
      label: 'pages.order.expiry_custom',
    },
  ];
  expiryTimeOptions$: BehaviorSubject<SelectOption[]> = new BehaviorSubject<SelectOption[]>([]);
  deliveryMinDate = new Date().toISOString().split('T')[0];

  deliveryTimeOptions$: BehaviorSubject<SelectOption[]> = new BehaviorSubject<SelectOption[]>([]);
  deliveryTimeSelectOptions$: Observable<SelectOption[]> = this.activatedClientService.integrations$.pipe(
    filter((value) => !value.salesforce?.enabled),
    map(() => [
      {
        key: 'immediate',
        label: 'pages.order.deliver_now',
      },
      {
        key: 'delayed',
        label: 'pages.order.select_date',
      },
    ]),
  );

  validationErrorList$ = new BehaviorSubject<ValidationErrorList[] | null>(null);
  validationErrorAssetUuids$ = new BehaviorSubject<string[]>([]);
  hasValidationErrors$ = this.validationErrorAssetUuids$.pipe(map((errors) => !!errors?.length && errors.length > 0));

  emailsPlaceholder = 'pages.order.email_placeholder';
  emailOptions: SelectOption[] = [];

  expiryMinDate = moment().add(1, 'd').toISOString().split('T')[0];
  expiryMaxDate = moment().add(90, 'd').toISOString().split('T')[0];
  submitted = false;

  clientDefinite$ = this.activatedClientService.clientDefinite$.pipe(takeUntil(this.#destroy$));
  downloadLimit$ = this.activatedClientService.downloadLimit$.pipe(takeUntil(this.#destroy$));
  isUsingConnect2OrDownloadPage$ = this.clientDefinite$.pipe(
    map((data) => Boolean(data?.contentCorner?.home) || Boolean(data?.vida?.sharedPacks)),
  );

  departmentOptions$ = new BehaviorSubject<SelectOption[]>([]);

  get orderType(): ORDER_TYPE {
    switch (this.#form?.controls.deliveryMethod.value) {
      case DeliveryMethods.MANUAL:
        return ORDER_TYPE.EMAIL_DELIVERY;
      case DeliveryMethods.PREDEFINED:
        return ORDER_TYPE.DELIVERY_DESTINATION;
      case DeliveryMethods.WARM_UP:
        return ORDER_TYPE.WARM_UP;
      case DeliveryMethods.WORKFLOW:
        return ORDER_TYPE.WORKFLOW;
      default:
        return ORDER_TYPE.EMAIL_DELIVERY;
    }
  }

  get deliveryMethod() {
    switch (this.#form?.controls.deliveryMethod.value) {
      case DeliveryMethods.MANUAL:
        return 'Email delivery';
      case DeliveryMethods.PREDEFINED:
        return 'Delivery destinations';
      case DeliveryMethods.WARM_UP:
        return 'Warm up';
      case DeliveryMethods.WORKFLOW:
        return 'Workflow';
      default:
        return null;
    }
  }

  get form() {
    return this.#form;
  }

  get orderTitle() {
    return this.#form?.controls.packageTitle.value;
  }

  get purchaseOrder() {
    return this.#form?.controls.purchaseOrder.value;
  }

  get deliveryTime() {
    switch (this.#form?.controls.deliveryDelay.value) {
      case 'immediate':
        return 'Right now';
      default:
        return new Date(this.#form?.controls.deliveryDate.value as string).toLocaleString(undefined, {
          timeStyle: 'medium',
          dateStyle: 'short',
        });
    }
  }

  get expiryTime() {
    switch (this.#form?.controls.expiry.value) {
      case '7':
        return '7 days';
      case '14':
        return '14 days';
      case '30':
        return 'One month';
      case 'custom':
        return new Date(this.#form?.controls.expiryDate.value as string).toLocaleString(undefined, {
          timeStyle: 'medium',
          dateStyle: 'short',
        });
      default:
        return null;
    }
  }

  get deliveryItems() {
    return this.#form?.controls.destinations.value.map((data) => {
      const config = this.allDeliveriesConfig$.value.find((config) => config.uuid === data.configUuid);
      const delivery = this.#allDeliveries$.value.find((delivery) => delivery.uuid === data.uuid);
      return {
        label: delivery?.name,
        config: config?.name,
      };
    });
  }

  get unavailableOptions(): string[] {
    return this.#form.controls.destinations.value.map((data) => (data?.configUuid ?? '') as string) || [];
  }

  get isFormValid() {
    return this.#form.valid;
  }

  get workflowJobs(): JobTypePayloadInterface[] {
    const workflowJobs: JobTypePayloadInterface[] = [];
    const jobs: [string, { [key: string]: { [key: string]: any } }][] = Object.entries(
      this.form.controls.jobs.getRawValue(),
    );
    jobs.forEach(([jobKey, jobValue]) => {
      const services = Object.entries(jobValue);
      services.forEach(([serviceKey, serviceValue]) => {
        if (Object.keys(serviceValue).length === 0) {
          const job = this.jobTypes.find((job) => job.context === jobKey);
          if (job) {
            workflowJobs.push({
              uuid: job.uuid,
              context: job.context,
              service: underscoreToString(serviceKey) as JobTypeService,
              payload: {},
            });
          }
        } else {
          const job = this.jobTypes.find((job) => job.context === jobKey);
          const payload = Object.entries(serviceValue)
            .filter(([_, value]) => !!value)
            .map(([key, value]) => ({
              [key]:
                typeof value === 'string' || (typeof value === 'number' && this.configTypeIsSelect(key, job))
                  ? [value]
                  : value,
            }))
            .reduce((acc, curr) => ({ ...acc, ...curr }), {});

          if (job) {
            workflowJobs.push({
              uuid: job.uuid,
              context: job.context,
              service: underscoreToString(serviceKey) as JobTypeService,
              payload,
            });
          }
        }
      });
    });

    return workflowJobs;
  }

  configTypeIsSelect(key: string, job: JobType | undefined) {
    //check if the config is a select
    return job?.config.find((config) => config.options?.find((option) => option.name === key)?.type === 'select');
  }

  get areDestinationsValid() {
    return this.#form.controls.destinations.length > 0 && this.#form.controls.destinations.valid;
  }

  get destinations() {
    return this.#form.controls.destinations;
  }

  get destinationsConfigs() {
    return this.destinations.value
      ?.map(({ configUuid, uuid }) => ({ config_uuid: configUuid, uuid }))
      ?.filter(({ uuid, config_uuid }) => uuid && config_uuid) as { config_uuid: string; uuid: string }[];
  }

  ngOnDestroy() {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  resetForm() {
    this.formInitialized = false;
    this.#form.controls.deliveryMethod.setValue(null);
    this.#form.controls.expiry.setValue('7');
    this.#form.controls.deliveryDelay.setValue('immediate');
    this.#form.controls.deliveryDate.setValue(moment().add(15, 'm').toISOString());
    this.#form.controls.expiryDate.setValue(this.#defaultExpiryDate);
    this.#form.controls.destinations.clear();
    this.#form.controls.destinations.push(
      new FormGroup({
        uuid: new FormControl<string | null>(null, Validators.required),
        configUuid: new FormControl<string | null>(null, Validators.required),
        hasBurnInText: new FormControl<boolean>(false, { nonNullable: true }),
        burnInTextValue: new FormControl<string | null>(null, [burnInTextValidator()]),
      }),
    );
    this.missingRates$.next(false);
    this.overwriteApproved$.next(null);
    this.jobsDetails = {};
    this.#form.controls.jobs = new FormGroup({});
    this.#form.controls.emails.setValue([]);
    this.#form.controls.notificationEmails.setValue([]);
    this.#form.controls.packageTitle.setValue('');
    this.#form.controls.purchaseOrder.setValue('');
    this.#form.controls.subject.setValue('');
    this.#form.controls.comment.setValue('');
    this.#form.controls.department_field_uuid.setValue(null);
    this.deliveryDatesChecked$.next(false);
    this.expiryDatesChecked$.next(false);
    this.cartState.isOrderApproved$.next(false);
    this.cartState.destinationsData$.next(null);
    this.cartState.discount$.next({ discountCode: null, discountStatus: DISCOUNT_STATUS.NONE });
    this.cartState.department$.next({ uuid: undefined, name: undefined });
    this.resetValidationErrors();
  }

  resetValidationErrors() {
    this.validationErrorList$.next([]);
    this.validationErrorAssetUuids$.next([]);
    this.destinationsValidated$.next(false);
    this.jobsValidated$.next(false);
    this.overwriteApproved$.next(null);
    this.missingRates$.next(false);
    this.missingDefaultPolicy$.next(false);
  }

  getForm() {
    return this.authService.auth$.pipe(
      tap(() => {
        !this.formInitialized && this.#initialize();
      }),
      take(1),
      map(() => this.#form as FormGroup<DeliveryFormType>),
    );
  }

  #initialize() {
    this.expiryTimeOptions$.next(this.#getGenericTimeOptions());
    this.#updateDeliveryTimeOptions();

    this.destinationsService
      .getAll()
      .pipe(
        take(1),
        tap((resp) => this.#allDeliveries$.next(resp.data)),
        takeUntil(this.#destroy$),
        map(({ data }) => data.map(({ configs }) => configs).reduce((prev, curr) => [...prev, ...curr], [])),
        tap((data) => this.allDeliveriesConfig$.next(data)),
      )
      .subscribe();

    this.cartApiService
      .getRecentEmails()
      .pipe(
        catchError(() => of([])),
        takeUntil(this.#destroy$),
        map((emails) => emails?.map((res: string) => ({ key: res, label: res }))),
        tap((resp) => (this.emailOptions = resp)),
      )
      .subscribe();

    this.listenersSubscription = this.#startListeners()
      .pipe(
        takeUntil(this.#destroy$),
        tap(() => {
          this.formInitialized = true;
        }),
      )
      .subscribe();
  }

  #startListeners() {
    const destinationsChanges$ = this.#form.controls.destinations.valueChanges.pipe(
      debounceTime(600),
      distinctUntilChanged(),
      tap(() => {
        this.destinationsValidated$.next(false);
      }),
    );

    const formChanges$ = this.#form.valueChanges.pipe(
      tap(() => {
        this.isFormValid$.next(this.#form.valid);
      }),
    );

    const orderTitleChanges$ = this.#form.controls.packageTitle.valueChanges.pipe(
      tap(() => {
        this.orderTitleValid$.next(this.#form.controls.packageTitle.valid);
      }),
    );

    const purchaseOrderChanges$ = this.#form.controls.purchaseOrder.valueChanges.pipe(
      tap(() => {
        this.purchaseOrderValid$.next(this.#form.controls.purchaseOrder.valid);
      }),
    );

    const departmentChanges$ = this.#form.controls.department_field_uuid.valueChanges.pipe(
      withLatestFrom(this.departmentService.departmentOptions$),
      tap(([uuid, options]) => {
        this.departmentValid$.next(this.#form.controls.department_field_uuid.valid);
        const department = options.find((option) => option.key === uuid);
        if (department && department.key !== '') {
          this.cartState.department$.next({ uuid: department.key as string, name: department.label as string });
          if (department.allowed) {
            this.cartState.discount$.next({ discountCode: undefined, discountStatus: DISCOUNT_STATUS.NONE });
          }
          this.cartState.checkDiscount();
        } else {
          this.cartState.checkDiscount(true, true);
        }
      }),
    );

    const departmentOptions$ = this.departmentService.departmentOptions$.pipe(
      withLatestFrom(this.cartState.advancedCheckoutDepartmentRequired$),
      tap(([options, required]) => {
        if (options.length > 1 && required) {
          this.#form.controls.department_field_uuid.setValidators(Validators.required);
        } else {
          this.#form.controls.department_field_uuid.removeValidators(Validators.required);
        }
        this.departmentOptions$.next(options);
        this.getUserDepartment();
      }),
    );

    const jobsChanges$ = this.#form.controls.jobs.valueChanges.pipe(
      tap(() => {
        this.jobsFormValid$.next(
          Object.values(this.#form.controls.jobs.value).some((value) => !!value) && this.#form.controls.jobs.valid,
        );
      }),
    );

    const deliveryMethodChanges$ = this.#form.controls.deliveryMethod.valueChanges.pipe(
      startWith('manual'),
      tap((method) => {
        this.#form.controls.emails.reset();
        this.#form.controls.notificationEmails.reset();
        this.#form.controls.destinations.clear();
        this.#form.controls.deliveryDelay.setValue('immediate');
        this.validationErrorList$.next(null);
        this.addDestination();

        if (method === 'predefined') {
          this.#form.controls.expiry.setValue('30');
          this.#form.controls.expiry.disable();
          this.#form.controls.destinations.enable();
        }

        if (method === 'manual') {
          this.#form.controls.expiry.setValue('7');
          this.#form.controls.expiry.enable();
          this.#form.controls.destinations.disable();
        }
      }),
    );

    const deliveryDelayChanges$ = this.#form.controls.deliveryDelay.valueChanges.pipe(
      tap((shipping) => {
        const validators = [Validators.required, DateValidator.dateNotPast()];

        if (shipping === 'delayed') {
          this.#form.controls.deliveryDate.addValidators(validators);
        } else if (shipping === 'immediate') {
          this.#form.controls.deliveryDate.removeValidators(validators);
          this.#form.controls.deliveryDate.setValue(moment().add(15, 'm').toISOString());
        }
        this.#form.updateValueAndValidity();
      }),
    );

    const expiryChanges$ = this.#form.controls.expiry.valueChanges.pipe(
      tap((expiry) => {
        switch (expiry) {
          case '14':
            this.#form.controls.expiryDate.setValue(moment().add(14, 'd').toISOString());
            break;
          case '30':
            this.#form.controls.expiryDate.setValue(moment().add(30, 'd').toISOString());
            break;
          case 'custom':
            this.#form.controls.expiryDate.setValue(moment().add(7, 'd').toISOString());
            break;
          default:
            this.#form.controls.expiryDate.setValue(moment().add(7, 'd').toISOString());
        }
        this.expiryDatesChecked$.next(true);
      }),
    );

    const deliveryDateChanges$ = this.#form.controls.deliveryDate.valueChanges.pipe(
      tap(() => {
        this.#form.controls.deliveryDelay.value === 'delayed' && this.#updateDeliveryTimeOptions();
      }),
    );

    const emailsChanges$ = this.#form.controls.emails.valueChanges.pipe(
      tap((value) => {
        value?.length ? (this.emailsPlaceholder = '') : (this.emailsPlaceholder = 'pages.order.email_placeholder');
      }),
    );

    const downloadLimitChanges$ = this.#form.controls.downloadLimit.valueChanges.pipe(
      tap((value) => {
        if (this.#form.controls.downloadLimit.dirty) {
          this.processSummaryAfterDownloadLimitChanged$.next(value);
        }
      }),
    );

    const unlimitedChanges$ = this.#form.controls.unlimited.valueChanges.pipe(
      withLatestFrom(this.downloadLimit$),
      tap(([value, clientLimit]) => {
        const unlimited = -1;
        if (this.#form.controls.unlimited.dirty && value) {
          this.form.controls.downloadLimit.setValue(unlimited);
          this.processSummaryAfterDownloadLimitChanged$.next(unlimited);
        } else {
          this.form.controls.downloadLimit.setValue(clientLimit ?? unlimited);
          this.processSummaryAfterDownloadLimitChanged$.next(clientLimit ?? unlimited);
        }
      }),
    );

    return merge(
      destinationsChanges$,
      formChanges$,
      orderTitleChanges$,
      purchaseOrderChanges$,
      deliveryMethodChanges$,
      deliveryDelayChanges$,
      expiryChanges$,
      deliveryDateChanges$,
      jobsChanges$,
      downloadLimitChanges$,
      emailsChanges$,
      unlimitedChanges$,
      departmentChanges$,
      departmentOptions$,
    ).pipe(takeUntil(this.#destroy$));
  }

  getUserDepartment() {
    this.userService
      .getUser()
      .pipe(
        shareReplay(1),
        tap((user) => {
          this.form.controls.department_field_uuid.patchValue(user.department_field?.uuid ?? null, { emitEvent: true });
        }),
      )
      .subscribe();
  }

  addDestination() {
    this.#form.controls.destinations?.push(
      new FormGroup<DestinationsFormModel>({
        uuid: new FormControl<string | null>(null, Validators.required),
        configUuid: new FormControl<string | null>(null, Validators.required),
        hasBurnInText: new FormControl<boolean>(false, { nonNullable: true }),
        burnInTextValue: new FormControl<string | null>(null, [burnInTextValidator()]),
      }),
    );
  }

  removeDestination(index: number) {
    this.#form.controls.destinations.removeAt(index);
    this.completeEmailControls();
  }

  completeEmailControls() {
    this.#form.controls.notificationEmails.setValue([...new Set(this.#getNotificationEmails())]);
    this.#form.controls.emails.setValue([...new Set(this.#getDeliverEmails())]);
  }

  completeDownloadLimitControl() {
    this.downloadLimit$.subscribe((limit) => {
      this.#form.controls.downloadLimit.setValue(limit ?? -1);
      this.#form.controls.unlimited.setValue(limit === -1);

      if (limit === -1) {
        this.#form.controls.unlimited.disable();
      } else {
        this.#form.controls.unlimited.enable();
      }
    });
  }

  #getSelectedDeliveries() {
    return this.#allDeliveries$.value
      .filter((delivery) =>
        this.#form.controls.destinations.value.some((destination) => destination.uuid === delivery.uuid),
      )
      .map((item) => item.email);
  }

  #getNotificationEmails() {
    return this.#getSelectedDeliveries().length
      ? this.#getSelectedDeliveries()
          .map((item) => item.notification)
          .reduce((p, c) => p.concat(c))
      : [];
  }

  #getDeliverEmails() {
    return this.#getSelectedDeliveries().length
      ? this.#getSelectedDeliveries()
          .map((item) => item.delivery)
          .reduce((p, c) => p.concat(c))
      : [];
  }

  #getGenericTimeOptions() {
    const timeOptions: SelectOption[] = [];
    const date = moment().utc();

    for (let h = 0; h < 24; ++h) {
      for (let m = 0; m < 60; m = m + 15) {
        date.hours(h);
        date.minutes(m);

        const key = date.format('HH:mm');

        timeOptions.push({
          key,
          label: key,
        });
      }
    }

    return timeOptions;
  }

  #updateDeliveryTimeOptions() {
    if (!this.#form.controls.deliveryDate?.value || this.#form.controls.deliveryDate?.invalid) {
      return;
    }

    const timeOptions: SelectOption[] = [];
    const selectedDate = moment(this.#form.controls.deliveryDate?.value);

    if (
      selectedDate.isSame(moment(), 'year') &&
      selectedDate.isSame(moment(), 'month') &&
      selectedDate.isSame(moment(), 'day')
    ) {
      const counter = moment(selectedDate);

      counter.minutes(Math.floor(counter.minutes() / 15) * 15);

      while (counter.day() == selectedDate.day()) {
        counter.add(15, 'm');

        const key = counter.format('HH:mm');

        timeOptions.push({
          key,
          label: key,
        });
      }
    } else if (selectedDate.isAfter(moment(), 'day')) {
      for (let h = 0; h < 24; ++h) {
        for (let m = 0; m < 60; m = m + 15) {
          selectedDate.hours(h);
          selectedDate.minutes(m);

          const key = selectedDate.format('HH:mm');

          timeOptions.push({
            key,
            label: key,
          });
        }
      }
    }

    this.deliveryTimeOptions$.next(timeOptions);
    this.deliveryDatesChecked$.next(true);
  }

  setDeliveryMethod(deliveryMethod: SelectOptionKey | string) {
    const method = deliveryMethod?.toString();
    const deliveryMethods: string[] = Object.values(DeliveryMethods);

    if (method && deliveryMethods.includes(method)) {
      this.#form.controls.deliveryMethod.setValue(method as DeliveryMethodsType);
      if (deliveryMethod === DeliveryMethods.WORKFLOW) {
        this.#getCartJobs();
      }
    } else {
      this.#form.controls.deliveryMethod.setValue(DeliveryMethods.MANUAL);
    }
  }

  #getCartJobs() {
    this.jobsApiService
      .getJobsTypes()
      .pipe(takeUntil(this.#destroy$), shareReplay(1))
      .subscribe((jobs) => {
        jobs.sort((a, b) => {
          if (a.dependsOn?.includes(b.context)) {
            return 1;
          }
          if (b.dependsOn?.includes(a.context)) {
            return -1;
          }
          return 0;
        });

        this.jobTypes = jobs;
      });
  }

  jobsSummary(): JobsSummary[] {
    const jobsDetails = this.jobsDetails;
    return Object.entries(jobsDetails).map(([key, value]) => {
      return <JobsSummary>{
        context: dashedToCapitalizedString(key),
        details: value,
      };
    });
  }

  removeOverwriteWarnings() {
    const errors = this.validationErrorList$.value;
    const filteredErrors = errors?.filter((error) => error.subType !== 'will-overwrite');
    this.validationErrorList$.next(filteredErrors ?? null);
  }
}
