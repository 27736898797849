import { PlayerMetadataItemLocalDB, PlayerMetadataListSource, ViewPlayerMetadataItem } from '../metadata-list.model';
import { Framerate, Timecode } from '@vdms-hq/timecode';
import { MetadataListFilters } from '../metadata-list-filters.service';

const emptyItem = 'N/A';

export const fromLocalCache = (
  item: PlayerMetadataItemLocalDB,
  framerate: Framerate,
  filters: MetadataListFilters,
): ViewPlayerMetadataItem => {
  const type = item.type;
  const timestamp = {
    timecodeIn: Timecode.fromSeconds(item.start / 1000, framerate, false),
    timecodeOut: item.end ? Timecode.fromSeconds(item.end / 1000, framerate, false) : null,
  };
  const isBoundingBoxAsterisk = item.instances.length > 0 ? ' *' : '';
  return {
    type,
    timestamp,
    language: item.language,
    loggingType: item?.loggingType,
    loggingUuid: item?.loggingUuid,
    isEdited: item?.isEdited,
    id: item.id,
    duration:
      timestamp.timecodeIn && timestamp.timecodeOut ? timestamp.timecodeOut.subtract(timestamp.timecodeIn) : null,
    content: item.content,
    contentHtml:
      highlightPhrase(item.content + isBoundingBoxAsterisk, filters?.phrase ?? []) || renderEmptyContent(item.type),
    confidence: item.confidence ?? 100,
    pill: pillColor(item.type),
    onTimeline: false,
    boundingBoxes: item.instances.filter((instance) => instance.start === item.start),
    canShowOnTimeline: [
      PlayerMetadataListSource.LABEL_DETECTION,
      PlayerMetadataListSource.FACE_DETECTION,
      PlayerMetadataListSource.TEXT_DETECTION,
      PlayerMetadataListSource.CELEBRITY_RECOGNITION,
      PlayerMetadataListSource.CONTENT_MODERATION,
      PlayerMetadataListSource.LOGGING,
      PlayerMetadataListSource.VIDEO_SEGMENT_DETECTION,
      PlayerMetadataListSource.TRANSLATE_SUBTITLES,
    ].includes(item.type),
    canDelete: [PlayerMetadataListSource.LOGGING].includes(item.type),
    canEdit: [
      PlayerMetadataListSource.LOGGING,
      PlayerMetadataListSource.TRANSLATE_SUBTITLES,
      PlayerMetadataListSource.TRANSCRIBE,
    ].includes(item.type),
    canAddToPlayer: false,
    vttPath: undefined,
    urls: item.urls,
    // [
    // PlayerMetadataListSource.TRANSCRIBE,
    // ].includes(item.type),
  };
};

const renderEmptyContent = (type: PlayerMetadataListSource) => {
  if (type === PlayerMetadataListSource.FACE_DETECTION) {
    return 'Unknown person';
  }

  return emptyItem;
};
const highlightPhrase = (content: string, phrases: string[]): string => {
  if (!phrases.length) {
    return content;
  }

  return content.replace(new RegExp(phrases.join('|'), 'ig'), (match: string) => '<em>' + match + '</em>');
};

const pillColor = (
  type: PlayerMetadataListSource,
): 'cold-blue' | 'orange' | 'white-dashed' | 'orange-light' | 'yellow' | 'done-light' | 'done' | 'failed' | '' => {
  switch (type) {
    case PlayerMetadataListSource.LABEL_DETECTION:
      return 'done-light';
    case PlayerMetadataListSource.CELEBRITY_RECOGNITION:
      return 'orange-light';
    case PlayerMetadataListSource.VIDEO_SEGMENT_DETECTION:
      return 'white-dashed';
    case PlayerMetadataListSource.TRANSCRIBE:
      return 'cold-blue';
    case PlayerMetadataListSource.CONTENT_MODERATION:
      return 'failed';
    case PlayerMetadataListSource.FACE_DETECTION:
      return 'orange';
    case PlayerMetadataListSource.TEXT_DETECTION:
      return 'yellow';
    case PlayerMetadataListSource.LOGGING:
      return 'done';
    default:
      return '';
  }
};
