import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  DataPresentationHeaderComponent,
  SidebarItem,
  SidebarNestedItem,
  UIFormModule,
  UILayoutModule,
} from '@vdms-hq/ui';
import { Subject, takeUntil } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { LibrarySchemaService } from '../../services/library-schema.service';

@Component({
  selector: 'vdms-hq-browse-library-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss'],
  standalone: true,
  imports: [CommonModule, UILayoutModule, UIFormModule, RouterModule, DataPresentationHeaderComponent, TranslateModule],
})
export class LibraryComponent implements OnInit, OnDestroy {
  private librarySchemeService = inject(LibrarySchemaService);
  private router = inject(Router);

  #destroyed$ = new Subject<void>();
  assetView = false;
  navItems: (SidebarItem | SidebarNestedItem)[] = [];
  activeSubheader$ = this.librarySchemeService.activeSchema$.pipe(map((active) => (active ? active.name : '')));

  ngOnDestroy(): void {
    this.#destroyed$.next();
    this.#destroyed$.complete();
  }

  ngOnInit(): void {
    this.librarySchemeService.allSchemas$
      .pipe(
        takeUntil(this.#destroyed$),
        tap(() => this.#handleEmptyRoute()),
      )
      .subscribe((schemas) => {
        this.navItems = [
          {
            name: 'Available schemas',
            routerLink: '/browse-library',
            children: [
              ...schemas
                .filter(({ type }) => type === 'client')
                .map((schema) => ({
                  name: schema.name,
                  routerLink: `/browse-library/${schema.id}`,
                  superscript: schema.isDefault ? '*' : null,
                })),
              'separator',
              ...schemas
                .filter(({ type }) => type === 'user')
                .map((schema) => ({
                  name: schema.name,
                  routerLink: `/browse-library/${schema.id}`,
                  superscript: schema.isDefault ? '*' : null,
                })),
              {
                name: 'Add new',
                routerLink: '/browse-library/add',
              },
            ],
          },
        ];
      });
  }

  #handleEmptyRoute(): void {
    this.librarySchemeService.activeSchema$
      .pipe(
        take(1),
        takeUntil(this.#destroyed$),
        tap(() => {
          const isUrlDifferentThanBrowsePage = this.router.url.split('/').filter(Boolean).length > 1;
          if (isUrlDifferentThanBrowsePage) {
            return;
          }
        }),
      )
      .subscribe();
  }
}
