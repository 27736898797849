<vdms-hq-ui-layout-page-wrapper
  [breadcrumbs]="[{ name: 'common.landingpad.heading' | translate }]"
  [loading]="isLoading"
>
  <ng-container actions>
    <vdms-hq-ui-button
      (click)="export()"
      [loading]="isLoading"
      [tooltip]="'common.landingpad.actions.export_csv_tooltip' | translate"
      color="primary"
    >
      {{ 'common.landingpad.actions.export_csv' | translate }}
    </vdms-hq-ui-button>
  </ng-container>

  <form [formGroup]="form" class="form">
    <vdms-hq-ui-form-input-text-area
      formControlName="ingest"
      [minRows]="10"
      [label]="'common.landingpad.fields.ingest_objects' | translate"
      [hint]="'common.landingpad.fields_hint.ingest_objects' | translate"
      *requiresPermissions="[Permission.INGEST_LANDINGPAD_ASSETS]"
    >
    </vdms-hq-ui-form-input-text-area>
    <vdms-hq-ui-form-input-text-area
      formControlName="remove"
      [minRows]="10"
      [label]="'common.landingpad.fields.remove_objects' | translate"
      [hint]="'common.landingpad.fields_hint.ingest_objects' | translate"
      *requiresPermissions="[Permission.DELETE_LANDINGPAD_ASSETS]"
    >
    </vdms-hq-ui-form-input-text-area>
  </form>
  <vdms-hq-ui-actions-footer>
    <vdms-hq-ui-button
      right
      (click)="save()"
      [loading]="isLoading"
      [disabled]="form.invalid || form.pristine"
      color="primary"
    >
      {{ 'common.global.save' | translate }}
    </vdms-hq-ui-button>
  </vdms-hq-ui-actions-footer>
</vdms-hq-ui-layout-page-wrapper>
