import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ActivatedClientService } from '@vdms-hq/activated-client';

@Injectable({ providedIn: 'root' })
export class ReturnButtonService {
  savedRouteBeforeLeave$ = new BehaviorSubject<string | null>(null);
  routeChangeListener$ = this.router.events.pipe(
    filter((e): e is NavigationEnd => e instanceof NavigationEnd),
    map(() => Boolean(!this.isBrowsePage)),
  );

  visible$ = combineLatest([this.routeChangeListener$, this.savedRouteBeforeLeave$]).pipe(
    map(([listener, savedRoute]) => Boolean(listener && savedRoute)),
  );
  constructor(private readonly router: Router, private readonly activatedClient: ActivatedClientService) {}

  get isBrowsePage(): boolean {
    return this.router.url?.split(/[/?]/)?.filter(Boolean)[0] === 'browse';
  }

  registerListeners(): void {
    this.activatedClient.clientDefiniteValueChanges$.subscribe(() => this.#resetLastSavedRoute());
  }

  #resetLastSavedRoute(): void {
    if (!this.savedRouteBeforeLeave$.value) {
      return;
    }
    this.savedRouteBeforeLeave$.next(null);
  }
}
