import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoggingInfo, LoggingInfoEntire, LoggingService } from '@vdms-hq/api-contract';
import { EdlDialogComponent, EdlDialogInput } from '../components/edl-dialog/edl-dialog.component';
import { Framerate } from '@vdms-hq/timecode';
import { BehaviorSubject, EMPTY, forkJoin, of, switchMap, take } from 'rxjs';
import { DialogResponse } from '@vdms-hq/shared';
import { ToastService } from '@vdms-hq/toast';
import { UIConfirmationDialogService } from '@vdms-hq/ui';
import { filter } from 'rxjs/operators';
import { excludeOffsetFromTimecodes } from './logging.helper';

export interface AddedNote {
  loggingInfo: LoggingInfoEntire;
  assetUuid: string;
}

@Injectable({
  providedIn: 'root',
})
export class LoggingActionService {
  private dialog: MatDialog = inject(MatDialog);
  private loggingApi: LoggingService = inject(LoggingService);
  private toast: ToastService = inject(ToastService);
  private confirmationDialog: UIConfirmationDialogService = inject(UIConfirmationDialogService);

  #addingNoteSubject$ = new BehaviorSubject<AddedNote | null>(null);
  addingNote$ = this.#addingNoteSubject$.asObservable();

  addNote(assetUuid: string, log: Partial<LoggingInfo>, framerate: Framerate) {
    this.#openDialog({
      log,
      assetUuid,
      framerate,
      isEdit: false,
    })
      .pipe(
        switchMap((dialogResponse) => {
          if (dialogResponse?.status !== DialogResponse.OK) {
            return EMPTY;
          }
          const payload = excludeOffsetFromTimecodes(dialogResponse.log);
          return this.loggingApi.create([payload]);
        }),
        take(1),
      )
      .subscribe({
        next: (logs) => {
          this.#addingNoteSubject$.next({ loggingInfo: logs[logs.length - 1], assetUuid });
          this.toast.success({
            id: 'log',
            message: 'pages.edl.created',
          });
        },
        error: () =>
          this.toast.error({
            id: 'log',
            message: 'common.global.fail',
          }),
      });
  }

  editNote(uuid: string, assetUuid: string, framerate: Framerate) {
    return this.#openDialog({
      uuid,
      assetUuid,
      framerate,
      isEdit: true,
    }).pipe(
      switchMap((dialogResponse) => {
        if (dialogResponse?.status !== DialogResponse.OK) {
          return EMPTY;
        }

        const payload = excludeOffsetFromTimecodes(dialogResponse.log);
        return forkJoin([this.loggingApi.update(uuid, payload), of(payload)]);
      }),
    );
  }

  deleteNote(uuid: string) {
    return this.confirmationDialog.openDelete().pipe(
      take(1),
      filter(Boolean),
      switchMap(() => this.loggingApi.delete(uuid)),
    );
  }

  #openDialog(edlInput: EdlDialogInput) {
    return this.dialog
      .open<EdlDialogComponent, EdlDialogInput>(EdlDialogComponent, {
        data: edlInput,
      })
      .afterClosed();
  }
}
