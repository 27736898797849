import { Routes } from '@angular/router';
import { LandingpadComponent } from './pages/landingpad/landingpad.component';

export enum LANDINGPAD_ROUTES {
  ROOT = 'landingpad',
}

export const LANDINGPAD_ROUTING: Routes = [
  {
    path: '',
    component: LandingpadComponent,
  },
];
