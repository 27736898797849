import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, shareReplay, switchMap } from 'rxjs';
import { SelectOption, Type } from '@vdms-hq/shared';
import { map, tap } from 'rxjs/operators';
import { SourceProvider } from '../source-provider';
import { FieldsOptionsService, AssetType } from '@vdms-hq/api-contract';
import { SelectorSourceType } from '../selector-source.type';
import { ActivatedClientService } from '@vdms-hq/activated-client';

@Injectable({
  providedIn: 'root',
})
export class ApiFieldsSourceService implements SourceProvider {
  #isLoadingSubject$ = new BehaviorSubject(true);
  isLoading$ = this.#isLoadingSubject$.asObservable();

  #all = combineLatest([this.activatedClientService.clientIdDefinite$]).pipe(
    switchMap(() => this.fieldsOptionsService.getTypes()),
    tap(() => {
      this.#isLoadingSubject$.next(false);
    }),
    shareReplay(1),
  );

  constructor(
    private fieldsOptionsService: FieldsOptionsService,
    private activatedClientService: ActivatedClientService,
  ) {}

  listByType(type: SelectorSourceType, assetType?: AssetType): Observable<SelectOption[]> {
    return this.#all.pipe(
      this.#restrictByAssetType(assetType),
      map((fields) => fields.find((field) => field.name === type && field.fields.length > 0)),
      map((type) => (type ? type.fields.map(({ key, label }) => ({ key, label })) : [])),
    );
  }

  listAllByType(type: SelectorSourceType, assetType?: AssetType) {
    return this.#all.pipe(
      this.#restrictByAssetType(assetType),
      map((fields) => fields.find((field) => field.name === type)),
      map((type) => (type ? type.fields : [])),
    );
  }

  #restrictByAssetType(assetType?: AssetType) {
    return map((fields) =>
      assetType
        ? (fields as Type[])
            .map((item) => ({
              ...item,
              fields: item.fields.filter(
                (field) =>
                  !field?.extra?.restricted ||
                  field.extra.restricted.length === 0 ||
                  field.extra.restricted.includes(assetType),
              ),
            }))
            .filter((item) => item.fields.length > 0)
        : (fields as Type[]),
    );
  }
}
