import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ConnectedPosition } from '@angular/cdk/overlay';

@Injectable({ providedIn: 'root' })
export class AdvancedSearchStateService {
  suffixConfig = {
    icon: 'settings_suggest',
    onFocus: false,
    type: 'secondary',
    onlyIcon: true,
  };
  overlayPositionConfig: ConnectedPosition[] = [
    {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
      panelClass: 'advanced--search__overlay',
    },
  ];
  state = new FormControl<boolean>(false);
}
