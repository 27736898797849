import { Injectable } from '@angular/core';
import { debounceTime, Observable, startWith, switchMap, take } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthService } from '@vdms-hq/auth';
import { map } from 'rxjs/operators';
import {
  FilterOrderStatus,
  OrderDeliveryStatus,
  OrderDownloadStatus,
  PaginationAPIProps as PaginationProps,
} from '@vdms-hq/api-contract';
import { SORT_BY } from './../../logic/orders-filters-provider';
import moment from 'moment-timezone';

type OrdersFiltersView = {
  ordersType?: string;
  downloadStatus?: OrderDownloadStatus;
  deliveryStatus?: OrderDeliveryStatus;
  status?: FilterOrderStatus;
  nameSubject?: string;
  sort: Pick<PaginationProps, 'orderBy' | 'orderDir'>;
  txDate: {
    from: moment.Moment;
    to: moment.Moment;
  };
};

@Injectable()
export class FiltersOrderForm {
  filters = new FormGroup({
    keyword: new FormControl<string>(''),
    sort: new FormControl<string>(SORT_BY[0].key),
    status: new FormControl<string>('all'),
    deliveryStatus: new FormControl<OrderDeliveryStatus | null>(null),
    downloadStatus: new FormControl<OrderDownloadStatus | null>(null),
    ordersType: new FormControl<string | null>(null),
    txDate: new FormControl<{
      from: moment.Moment | null;
      to: moment.Moment | null;
    }>({
      from: null,
      to: null,
    }),
  });

  values$: Observable<OrdersFiltersView> = this.filters.valueChanges.pipe(
    startWith(this.filters.value),
    debounceTime(400),
    switchMap(() => {
      const formValue = this.filters.value;
      return this.authService.authDefinite$.pipe(take(1)).pipe(
        map(() => {
          const selectedSort = SORT_BY.find((item) => item.key === formValue?.sort)?.props;

          const filters = <OrdersFiltersView>{};

          if (selectedSort) {
            filters.sort = selectedSort;
          }

          switch (formValue?.status) {
            case 'pending':
              filters.status = FilterOrderStatus.PENDING;
              break;
            case 'approved':
              filters.status = FilterOrderStatus.APPROVED;
              break;
          }

          if (formValue.ordersType) {
            filters.ordersType = formValue.ordersType;
          }

          if (formValue?.deliveryStatus) {
            filters.deliveryStatus = formValue.deliveryStatus;
          }

          if (formValue?.downloadStatus) {
            filters.downloadStatus = formValue.downloadStatus;
          }

          if (formValue?.keyword) {
            filters.nameSubject = formValue.keyword;
          }

          if (formValue.txDate?.from && formValue.txDate?.to) {
            filters.txDate = {
              from: formValue.txDate.from,
              to: formValue.txDate.to,
            };
          }

          return filters;
        }),
      );
    }),
  );

  constructor(private authService: AuthService) {}
}
