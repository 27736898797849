import { Injectable } from '@angular/core';
import { ConnectableDataSource, LoadableDataSource, RefreshService, SelectOption } from '@vdms-hq/shared';
import { BehaviorSubject, combineLatest, EMPTY, map, Observable, shareReplay, switchMap, tap } from 'rxjs';
import { ToastService } from '@vdms-hq/toast';
import { RightsContractsService, PaginationAPIModel as Pagination } from '@vdms-hq/api-contract';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RightsContractsAddDsService implements LoadableDataSource, ConnectableDataSource<SelectOption> {
  refresh$ = new BehaviorSubject<boolean>(true);

  isLoading$ = new BehaviorSubject<boolean>(false);
  total$ = new BehaviorSubject<number>(0);

  allData$: Observable<SelectOption[]> = combineLatest([this.refresh$, this.refreshService.refresh$]).pipe(
    tap(() => this.isLoading$.next(true)),
    switchMap(() => {
      const pagination = {
        page: 0,
        perPage: 1000,
      };

      return this.rightsContractsService
        .getMany(
          Pagination.create({
            ...pagination,
          }),
        )
        .pipe(
          catchError((err) => {
            this.#errorHandler();
            throw err;
          }),
          tap((response) => {
            this.total$.next(response.total);
            this.isLoading$.next(false);
          }),
          map((response) => response.data.map((item) => ({ key: item.uuid, label: item.name }))),
        );
    }),
    shareReplay(1),
  );

  connection$: Observable<SelectOption[]> = this.allData$;

  constructor(
    private toastService: ToastService,
    private rightsContractsService: RightsContractsService,
    private refreshService: RefreshService,
  ) {}

  emptyResults$ = combineLatest([this.allData$, this.isLoading$]).pipe(
    map(([data, loading]) => !loading && data.length === 0),
  );

  #errorHandler = () => {
    this.toastService.error({ id: 'rightsContracts', message: 'Error fetching rights contracts' });
    this.isLoading$.next(false);
    return EMPTY;
  };

  refresh() {
    this.refresh$.next(true);
  }
}
