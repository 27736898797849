import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, shareReplay, startWith, tap, withLatestFrom } from 'rxjs';
import { ToastService } from '@vdms-hq/toast';
import { FieldsConfigService } from '@vdms-hq/config';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
  LicensedPackagesNotificationTypes,
  NotificationsConfig,
  NotificationSettingsService,
  NotificationTypes,
  Policy,
  PolicyService,
} from '@vdms-hq/api-contract';
import { SelectOption } from '@vdms-hq/shared';
import { ActivatedClientService } from '@vdms-hq/activated-client';

type FilterGroup = FormGroup<{
  key: FormControl<string>;
  values: FormControl<string[]>;
}>;

type PolicyGroup = FormGroup<{
  policy_uuid: FormControl<string | null>;
  notification_event_type: FormControl<NotificationTypes | null>;
  filters: FormArray<FilterGroup>;
}>;

@Injectable({
  providedIn: 'root',
})
export class EmailSettingsFormService {
  private readonly emailFilterKeys: string[] = [
    'processingStatus',
    'uuid',
    'timecodeStart',
    'cleanAperture',
    'encodedPixels',
    'clientAssetId',
    'format',
    'resolution',
    'duration',
    'videoDuration',
    'containerDuration',
    'width',
    'height',
    'videoCodec',
    'videoCodecProfile',
    'videoBitrate',
    'bitrateMode',
    'videoBitDepth',
    'interlaceMode',
    'pixelAspectRatio',
    'framerate',
    'chromaFormat',
    'colorSpace',
    'colorMatrixCoefficients',
    'colorPrimaries',
    'colorTransferCharacteristics',
    'aspectRatio',
    'size',
    'episodeName',
    'episodeNumber',
    'seriesName',
    'seriesNumber',
    'clientRef',
    'programmeNumber',
    'facilityOfOrigin',
    'originalFilename',
    'imageAspect',
    'elements',
    'segment',
    'coldOnly',
    'assetMasterType',
    'assetType',
    'mediaInfoDisposition',
    'physicalVersionTitle',
    'physicalVersionDescription',
    'genre',
    'note',
    'tapeNo',
    'altNo',
    'productionCompany',
    'userPlayerOffset',
    'metadataSubType',
    'isPreviewAppEnabled',
    'contentClass',
    'contentType',
    'theme',
    'variation',
    'category',
    'version',
    'vidaNumber',
    'txDate',
    'librarySalesRights',
    'seasonTitle',
    'releaseYear',
    'inventoryType',
    'origin',
    'multiversion',
    'isQuarantined',
    'stage',
    'embargo',
    'legacyIngestDate',
    'interlacingType',
    'assetStatus',
    'coldStatus',
  ];

  private policy = inject(PolicyService);
  private activatedClientService = inject(ActivatedClientService);

  readonly FILTERS_AVAILABLE_EVENT_TYPES = LicensedPackagesNotificationTypes;
  events = Object.values(this.FILTERS_AVAILABLE_EVENT_TYPES);

  private toastService = inject(ToastService);
  private fieldsConfigService = inject(FieldsConfigService);
  private notificationSettingsService = inject(NotificationSettingsService);

  filtersDefinitionsForEmail$ = this.fieldsConfigService.filterDefinitionsForAssetFiltersAdmin$.pipe(
    map((definitions) => definitions.filter((definition) => this.emailFilterKeys.includes(String(definition.key)))),
  );
  #initializing$ = new BehaviorSubject<boolean>(false);
  initializing$ = this.#initializing$.asObservable();
  #saving$ = new BehaviorSubject<boolean>(false);
  saving$ = this.#saving$.asObservable();
  form = new FormGroup<Record<string, FormArray<PolicyGroup>>>({});

  #policies$ = this.policy.getPolicies().pipe(
    startWith([]),
    withLatestFrom(this.activatedClientService.clientIdDefinite$),
    map(([policies, clientId]) => policies.filter((policy) => policy.groups?.find((group) => group.uuid === clientId))),
    shareReplay(1),
  );

  policiesLoading$ = this.#policies$.pipe(
    map((policies) => policies.length === 0),
    shareReplay(1),
  );

  emptyPolicies$: Observable<SelectOption[]> = this.#policies$.pipe(
    map((policies) =>
      policies.filter((policy) => policy.permissions?.length === 0).map((policy) => this.#policyToSelectOption(policy)),
    ),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  #policyToSelectOption(policy: Policy) {
    return {
      key: policy.uuid,
      label: policy.name,
    };
  }

  init() {
    this.#initializing$.next(true);
    this.notificationSettingsService.getFilters().subscribe((existingFilters) => {
      existingFilters.forEach((filter) => {
        const eventType = filter.notification_event_type;
        const isSupported = this.events.includes(eventType);

        if (isSupported) {
          const filters: FilterGroup[] = filter?.filters?.map(({ key, values }) => {
            return new FormGroup({
              key: new FormControl(key ?? '', { nonNullable: true }),
              values: new FormControl(values ?? [], { nonNullable: true }),
            });
          });

          const control: PolicyGroup = new FormGroup({
            policy_uuid: new FormControl<string | null>(filter.policy_uuid),
            notification_event_type: new FormControl<NotificationTypes | null>(filter.notification_event_type),
            filters: new FormArray<FilterGroup>(filters),
          });

          if (this.form.controls[eventType] === undefined) {
            this.form.addControl(eventType, new FormArray([control]));
          } else {
            this.form.controls[eventType].push(control);
          }
        }
      });

      this.#initializing$.next(false);
    });
  }

  addNewPolicy(eventType: string) {
    const filterGroup: FilterGroup = new FormGroup({
      key: new FormControl('', { nonNullable: true }),
      values: new FormControl<string[]>([], { nonNullable: true }),
    });

    const policyGroup: PolicyGroup = new FormGroup({
      policy_uuid: new FormControl<string | null>(null),
      notification_event_type: new FormControl<NotificationTypes | null>(eventType as NotificationTypes),
      filters: new FormArray<FilterGroup>([filterGroup]),
    });

    if (this.form.controls[eventType] === undefined) {
      this.form.addControl(eventType, new FormArray([policyGroup]));
    } else {
      this.form.controls[eventType].push(policyGroup);
    }
  }

  addNewFilterInPolicy(control: PolicyGroup) {
    const filterGroup: FilterGroup = new FormGroup({
      key: new FormControl('', { nonNullable: true }),
      values: new FormControl<string[]>([], { nonNullable: true }),
    });

    control.controls.filters.push(filterGroup);
  }

  removeFilterInPolicy(control: PolicyGroup, index: number) {
    control.controls.filters.removeAt(index);
  }

  removePolicy(eventType: string, controlIndex: number) {
    this.form.controls[eventType].removeAt(controlIndex);
  }

  save(): Observable<boolean> {
    this.#saving$.next(true);
    this.toastService.processing({ id: 'filters_form', message: 'common.notifications.update.processing' });

    const policies = Object.values(this.form.value) as NotificationsConfig[][];

    const availableData = policies
      .reduce((prev, curr) => [...prev, ...curr], [])
      .filter(({ policy_uuid }) => !!policy_uuid)
      .map((config) => ({
        ...config,
        filters: config.filters.filter(({ key, values }) => !!key && values.length > 0),
      }));

    if (!availableData?.length) {
      this.toastService.warning({ id: 'filters_form', message: 'Filters form is invalid or not completed.' });
      return of(false);
    }
    return this.notificationSettingsService.saveFilters(availableData).pipe(
      map(() => true),
      catchError(() => of(false)),
      tap((response) => {
        if (response) {
          this.toastService.success({ id: 'filters_form', message: 'common.notifications.update.done' });
        } else {
          this.toastService.error({ id: 'filters_form', message: 'common.notifications.update.failed' });
        }
      }),
    );
  }
}
