import { Component, inject, OnInit } from '@angular/core';
import { CollectionsService } from '@vdms-hq/api-contract';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastService } from '@vdms-hq/toast';
import { TeamsAddData, TeamsAddDataSource } from '../../logic/client-teams-add-ds.service';
import { DynamicFilterInput } from '@vdms-hq/dynamic-filters';
import { DialogResponse, TileSelectableConfig, UIButtonModule, UIDialogWrapperModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { UiAddDialogSelectableWrapperComponent } from '@vdms-hq/selectable-tiles-wrapper';
import { CommonModule } from '@angular/common';
import { FilterType, ResourceModel, ValueFormat } from '@vdms-hq/shared';

export interface AddToTeamsResponse {
  status: DialogResponse;
}

export interface AddToTeamsInput {
  shared_to_teams?: string[];
  collectionUuid?: string;
  collectionName?: string;
}

@Component({
  selector: 'vdms-hq-teams-add-dialog',
  templateUrl: './teams-add-dialog.component.html',
  styles: [
    '.form-content { display: flex; flex-direction: column; gap: 16px; padding-right: 1rem;} mat-divider { margin-bottom: 8px;}',
  ],
  standalone: true,
  imports: [
    CommonModule,
    UIDialogWrapperModule,
    TranslateModule,
    UiAddDialogSelectableWrapperComponent,
    UIButtonModule,
  ],
})
export class TeamsAddDialogComponent implements OnInit {
  private dialogRef = inject(MatDialogRef<TeamsAddDialogComponent>);
  private collectionsService = inject(CollectionsService);
  public teamsAddDS = inject(TeamsAddDataSource);
  private toastService = inject(ToastService);

  public data: AddToTeamsInput = inject(MAT_DIALOG_DATA);

  isSaving = false;

  readonly filtersConfig: DynamicFilterInput[] = [
    {
      id: 'keyword',
      label: 'common.client-teams.table.filter',
      resource: [ResourceModel.CLIENT_TEAM],
      format: ValueFormat.AS_IS,
      filters: {
        objectPath: 'name',
        validFormat: 'keyword',
        type: FilterType.MASTER_TEXT,
      },
    },
  ];

  teamsTilesConfig: TileSelectableConfig<TeamsAddData> = {
    label: 'label',
    key: 'key',
    columns: '2-columns',
    metadata: [
      {
        label: 'common.client-teams.dialogs.number_of_users',
        valuePath: 'number_of_users',
        viewFormat: {
          modifiers: {
            asNumberWithZero: true,
          },
        },
        singleLine: true,
      },
      {
        label: 'common.client-teams.dialogs.number_of_collections',
        valuePath: 'number_of_collections',
        viewFormat: {
          modifiers: {
            asNumberWithZero: true,
          },
        },
        singleLine: true,
      },
      {
        label: 'common.client-teams.dialogs.created_by',
        valuePath: 'created_by',
        singleLine: true,
      },
    ],
  };

  popToast = {
    ADD_SUCCESS: () =>
      this.toastService.success({
        id: 'add_collection_to_teams',
        message: 'common.collections.notifications.add_to_teams.success',
      }),
    ADD_FAILURE: (reason: string = 'Not specified') =>
      this.toastService.error({
        id: 'add_collection_to_teams',
        message: 'common.collections.notifications.add_to_teams.failure',
        interpolatedParams: { reason },
      }),
  };

  #form = new FormGroup({
    selected: new FormControl<string[]>([], { nonNullable: true }),
  });
  selectedControl = this.#form.get('selected') as FormControl<string[]>;

  ngOnInit() {
    this.selectedControl.setValue(this.data.shared_to_teams ?? []);
  }

  selectTeam(item: TeamsAddData) {
    const selected = this.selectedControl.value;
    if (selected.includes(item.key)) {
      this.selectedControl.setValue(selected.filter((id) => id !== item.key));
    } else {
      this.selectedControl.setValue([...selected, item.key]);
    }
  }

  save() {
    if (this.data.collectionUuid) {
      this.isSaving = true;
      this.collectionsService
        .update(this.data.collectionUuid, { shared_to_team_uuids: this.selectedControl.value ?? [] })
        .subscribe({
          next: () => {
            this.popToast.ADD_SUCCESS();
            this.isSaving = false;
            this.close(true);
          },
          error: (err) => {
            this.isSaving = false;
            this.popToast.ADD_FAILURE(err);
          },
        });
    }
  }

  close(saved = false) {
    this.teamsAddDS.refresh(saved);
    this.dialogRef.close({
      status: saved ? DialogResponse.OK : DialogResponse.ABORT,
    });
  }
}
