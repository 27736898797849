import { inject, Injectable } from '@angular/core';
import { ApiService } from '../../rest/api.service';
import {
  LOGGING_ENDPOINTS,
  LoggingInfo,
  LoggingId,
  LoggingType,
  LoggingInfoEntire,
  LoggingInfoGetOne,
} from './logging.model';
import { ApiPaginatedResponse, ApiResponse } from '../api.model';
import { GetResponseData } from '../../operators/get-response-data.operator';

@Injectable({ providedIn: 'root' })
export class LoggingService {
  private apiService: ApiService = inject(ApiService);

  get(uuid: string) {
    return this.apiService
      .get<ApiResponse<LoggingInfoGetOne>>(`${LOGGING_ENDPOINTS.ROOT}/${uuid}`)
      .pipe(GetResponseData);
  }

  getEntireLog(assetUuid: string) {
    return this.apiService
      .get<ApiResponse<LoggingInfoEntire[]>>(`${LOGGING_ENDPOINTS.ROOT}/${assetUuid}/asset`)
      .pipe(GetResponseData);
  }

  getTypesList() {
    return this.apiService.get<ApiPaginatedResponse<LoggingType>>(`${LOGGING_ENDPOINTS.TYPES}`).pipe(GetResponseData);
  }

  create(payload: LoggingInfo[]) {
    return this.apiService
      .post<LoggingInfo[], ApiResponse<LoggingInfoEntire[]>>(`${LOGGING_ENDPOINTS.ROOT}`, payload)
      .pipe(GetResponseData);
  }

  update(uuid: string, payload: LoggingInfo) {
    return this.apiService
      .patch<LoggingInfo, ApiResponse<LoggingId>>(`${LOGGING_ENDPOINTS.ROOT}/${uuid}`, payload)
      .pipe(GetResponseData);
  }

  delete(uuid: string) {
    return this.apiService.delete(`${LOGGING_ENDPOINTS.ROOT}/${uuid}`);
  }
}
