import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationAPIModel, PaginationAPIProps } from '../pagination.model';
import { Observable } from 'rxjs';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { ApiEmptyResponse, ApiPaginatedResponse, ApiResponse } from '../api.model';
import { ApiService } from '../api.service';
import { AssetSearchFilters } from '../asset';
import { CART_ENDPOINTS } from './models/cart-endpoints.model';
import {
  AssetFlatCartItem,
  CartSummaryRequest,
  CartSummary,
  Counters,
  CartValidationError,
  OrderPdfResponse,
  RecentEmailsResponse,
  InvalidCartAsset,
  PostCartValidateType,
  AddToCartPayload,
} from './models/cart.model';
import { JobTypePayloadInterface, ORDER_TYPE } from '../order/order.model';

@Injectable({ providedIn: 'root' })
export class CartApiService {
  constructor(private readonly apiService: ApiService) {}

  getCartLibrary = (pagination?: PaginationAPIProps) => {
    const headers = PaginationAPIModel.create(pagination).applyToHeaders(new HttpHeaders());
    return this.apiService
      .get<ApiResponse<number>>(`${CART_ENDPOINTS.CART}/${CART_ENDPOINTS.CATALOG_ITEMS}`, { headers })
      .pipe(GetResponseData);
  };

  getCartValidation = (options?: { pagination?: PaginationAPIProps }): Observable<ApiResponse<InvalidCartAsset[]>> => {
    const headers = PaginationAPIModel.create(options?.pagination).applyToHeaders(new HttpHeaders());

    return this.apiService.get<ApiResponse<InvalidCartAsset[]>>(
      `${CART_ENDPOINTS.CART}/${CART_ENDPOINTS.ASSETS}/${CART_ENDPOINTS.INVALID}`,
      {
        headers,
      },
    );
  };

  getCartAssets = (options?: {
    pagination?: PaginationAPIProps;
    embargoed?: boolean;
    quarantined?: boolean;
  }): Observable<ApiPaginatedResponse<AssetFlatCartItem>> => {
    let headers = PaginationAPIModel.create(options?.pagination).applyToHeaders(
      options?.embargoed !== undefined
        ? new HttpHeaders().set('embargoed', options.embargoed ? 'true' : 'false')
        : new HttpHeaders(),
    );

    headers =
      options?.quarantined !== undefined ? headers.set('quarantined', options.quarantined ? 'true' : 'false') : headers;

    return this.apiService.get<ApiPaginatedResponse<AssetFlatCartItem>>(
      `${CART_ENDPOINTS.CART}/${CART_ENDPOINTS.ASSETS}`,
      { headers },
    );
  };

  getCartSummary = (
    order_type: ORDER_TYPE,
    destinations?: CartSummaryRequest['destinations'],
    discount_code?: string | null,
    workflow?: JobTypePayloadInterface[],
    download_limit?: number,
    department_field_uuid?: string,
  ) => {
    return this.apiService
      .post<CartSummaryRequest, ApiResponse<CartSummary>>(`${CART_ENDPOINTS.CART}/summary`, {
        order_type,
        discount_code: discount_code,
        destinations: destinations?.length ? destinations : undefined,
        jobs: workflow?.length ? workflow : undefined,
        download_limit,
        department_field_uuid,
      })
      .pipe(GetResponseData);
  };

  getOrderPdf = (orderUuid: string) => {
    return this.apiService
      .get<ApiResponse<OrderPdfResponse>>(`${CART_ENDPOINTS.ORDER}/${orderUuid}/pdf`)
      .pipe(GetResponseData);
  };

  getRecentEmails = () =>
    this.apiService
      .get<ApiResponse<RecentEmailsResponse>>(`${CART_ENDPOINTS.ORDER}/${CART_ENDPOINTS.EMAILS}`)
      .pipe(GetResponseData);

  getTotal = (): Observable<number> =>
    this.apiService.get<ApiResponse<number>>(`${CART_ENDPOINTS.CART}/${CART_ENDPOINTS.COUNT}`).pipe(GetResponseData);

  delete = (ids: string[]): Observable<Counters> =>
    this.apiService.delete<ApiResponse<Counters>, string[]>(`${CART_ENDPOINTS.CART}`, ids).pipe(GetResponseData);

  deleteAllItems = (): Observable<boolean> =>
    this.apiService.delete<ApiResponse<boolean>>(`${CART_ENDPOINTS.CART}`).pipe(GetResponseData);

  addFromSearchQuery = (searchFilters: AssetSearchFilters) =>
    this.apiService.put(`${CART_ENDPOINTS.CART_FOLDER}`, { text: '', filters: searchFilters });

  addToCart(payload: AddToCartPayload) {
    return this.apiService.put<AddToCartPayload, ApiEmptyResponse>(`${CART_ENDPOINTS.CART}`, payload);
  }

  validate = (payload: PostCartValidateType): Observable<CartValidationError<ORDER_TYPE>[]> => {
    return this.apiService
      .post<PostCartValidateType, ApiResponse<CartValidationError<ORDER_TYPE>[]>>(
        `${CART_ENDPOINTS.CART}/validate`,
        payload,
      )
      .pipe(GetResponseData);
  };
}
