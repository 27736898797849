import { ChangeDetectionStrategy, Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { SearchBarVisibilityService } from '../../logic/search-bar-visibility.service';
import { CommonModule } from '@angular/common';
import { SearchHeroInputComponent } from '../search-hero-input/search-hero-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { SimpleAiQueryInputComponent } from '../simple-ai-query-input/simple-ai-query-input.component';

@Component({
  selector: 'vdms-hq-browse-search-hero',
  templateUrl: './search-hero.component.html',
  styleUrls: ['./search-hero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, SearchHeroInputComponent, TranslateModule, SimpleAiQueryInputComponent],
  providers: [],
})
export class SearchHeroComponent implements OnInit, OnDestroy {
  @Input() header = 'pages.home.search.hero.header';
  @Input() subheader = 'pages.home.search.hero.subheader';
  public searchWithAI = false;

  constructor(private searchBarVisibility: SearchBarVisibilityService) {}

  ngOnDestroy(): void {
    this.searchBarVisibility.show();
  }

  ngOnInit(): void {
    this.searchBarVisibility.hide();
  }

  @HostListener('window:keydown.control.a', ['$event'])
  useAISearch(event: KeyboardEvent) {
    event.preventDefault();
    this.header = !this.searchWithAI ? 'pages.home.search.ai_hero.header' : 'pages.home.search.hero.header';
    this.subheader = !this.searchWithAI ? 'pages.home.search.ai_hero.subheader' : 'pages.home.search.hero.subheader';
    this.searchWithAI = !this.searchWithAI;
  }
}
