import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormGroup,
  NgControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { delay, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { defaultErrors } from '../../default-error-messages.model';

type ErrorKey = string;
type ErrorMessage = string;

type PossibleControls = UntypedFormControl | UntypedFormGroup | UntypedFormArray | FormGroup;

@Component({ template: '' })
export abstract class FormControlValueAccessorComponent<Outer = string | null | undefined, Inner = string | null>
  implements ControlValueAccessor, OnInit, AfterViewChecked, OnDestroy, AfterViewInit, Validator
{
  @Input() withFooter = true;
  @Input() withTouchedIndicator = false;
  @Input() hint?: string;
  @Input() label = '';
  @Input() additionalLabel = '';
  @Input() placeholder = '';
  @Input() e2ePrefixId = '';
  @Input() newField = false;

  @Input() set customErrorMessages(value: Record<ErrorKey, ErrorMessage>) {
    this.errorMessages = {
      ...defaultErrors,
      ...value,
    };
  }

  @Output() valueChange = new EventEmitter<Outer>();

  #unknownErrorMessage = 'common.validators.invalid';
  errorMessages: Record<ErrorKey, ErrorMessage> = defaultErrors;
  #outerNgControl?: NgControl | null;
  #outerAbstractControl?: AbstractControl | null;
  abstract innerFormControl: PossibleControls;

  protected destroy = new Subject<void>();
  protected outerValue?: Outer;
  isRequired = false;

  protected propagateToOuter = (nextValue: Outer) => {
    this.propagateChange(nextValue);
    this.valueChange.emit(nextValue);
  };

  propagateChange: (_: Outer) => void = () => undefined;
  propagateTouch: () => void = () => undefined;
  validatorChange: () => void = () => undefined;

  get errors(): {
    transLang: string;
    contextParams?: unknown;
  }[] {
    return Object.entries(this.innerFormControl.errors ?? {}).map(([key, contextParams]) => ({
      transLang: this.errorMessages[key] ?? this.#unknownErrorMessage,
      contextParams,
    }));
  }

  constructor(private injector: Injector, protected changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.#outerNgControl = this.injector.get(NgControl, null);

    this.#outerAbstractControl = this.#outerNgControl?.control;
    if (!this.#outerAbstractControl) {
      return;
    }

    const prevMarkAllAsTouched = this.#outerAbstractControl.markAllAsTouched;
    this.#outerAbstractControl.markAllAsTouched = () => {
      this.innerFormControl?.markAllAsTouched();
      prevMarkAllAsTouched.bind(this.#outerAbstractControl)();
      this.changeDetectorRef.markForCheck();
    };

    const prevMarkAsTouched = this.#outerAbstractControl.markAsTouched;
    this.#outerAbstractControl.markAsTouched = (args) => {
      this.innerFormControl?.markAsTouched(args);
      prevMarkAsTouched.bind(this.#outerAbstractControl)(args);
      this.changeDetectorRef.markForCheck();
    };

    const prevMarkAsUnTouched = this.#outerAbstractControl.markAsUntouched;
    this.#outerAbstractControl.markAsUntouched = (args) => {
      this.innerFormControl?.markAsUntouched(args);
      prevMarkAsUnTouched.bind(this.#outerAbstractControl)(args);
      this.changeDetectorRef.markForCheck();
    };

    const prevMarkAsPristine = this.#outerAbstractControl.markAsPristine;
    this.#outerAbstractControl.markAsPristine = (args) => {
      this.innerFormControl?.markAsPristine(args);
      prevMarkAsPristine.bind(this.#outerAbstractControl)(args);
      this.changeDetectorRef.markForCheck();
    };

    const prevMarkAsDirty = this.#outerAbstractControl.markAsDirty;
    this.#outerAbstractControl.markAsDirty = (args) => {
      this.innerFormControl?.markAsDirty(args);
      prevMarkAsDirty.bind(this.#outerAbstractControl)(args);
      this.changeDetectorRef.markForCheck();
    };

    const prevMarkAsPending = this.#outerAbstractControl.markAsPending;
    this.#outerAbstractControl.markAsPending = (args) => {
      this.innerFormControl?.markAsPending(args);
      prevMarkAsPending.bind(this.#outerAbstractControl)(args);
      this.changeDetectorRef.markForCheck();
    };

    const prevValidatorAdd = this.#outerAbstractControl.addValidators;
    this.#outerAbstractControl.addValidators = (args) => {
      this.innerFormControl?.addValidators(args);
      prevValidatorAdd.bind(this.#outerAbstractControl)(args);
      this.changeDetectorRef.markForCheck();
      this.#updateRequiredState();
    };

    const prevValidatorRemove = this.#outerAbstractControl.removeValidators;
    this.#outerAbstractControl.removeValidators = (args) => {
      this.innerFormControl?.removeValidators(args);
      prevValidatorRemove.bind(this.#outerAbstractControl)(args);
      this.changeDetectorRef.markForCheck();
      this.#updateRequiredState();
    };

    const prevUpdateValueAndValidity = this.#outerAbstractControl.updateValueAndValidity;
    this.#outerAbstractControl.updateValueAndValidity = (args) => {
      this.innerFormControl?.updateValueAndValidity(args);
      prevUpdateValueAndValidity.bind(this.#outerAbstractControl)(args);
      this.changeDetectorRef.markForCheck();
    };

    const prevSetValidators = this.#outerAbstractControl.setValidators;
    this.#outerAbstractControl.setValidators = (args) => {
      this.innerFormControl?.setValidators(args);
      prevSetValidators.bind(this.#outerAbstractControl)(args);
      this.changeDetectorRef.markForCheck();
      this.#updateRequiredState();
    };

    const prevClearValidators = this.#outerAbstractControl.clearValidators;
    this.#outerAbstractControl.clearValidators = () => {
      this.innerFormControl?.clearValidators();
      prevClearValidators.bind(this.#outerAbstractControl)();
      this.changeDetectorRef.markForCheck();
      this.#updateRequiredState();
    };

    const validators = this.#outerAbstractControl.validator ?? null;
    const asyncValidator = this.#outerAbstractControl.asyncValidator ?? null;
    this.innerFormControl.setValidators(validators);
    this.innerFormControl.setAsyncValidators(asyncValidator);

    this.#updateRequiredState();
  }

  #updateRequiredState() {
    const validators = this.innerFormControl.validator ?? null;

    if (validators) {
      const testValidator = validators(new UntypedFormControl()) ?? {};

      this.isRequired = 'required' in testValidator || 'requiredTrue' in testValidator;
    } else {
      this.isRequired = false;
    }
  }

  ngOnInit() {
    this.innerFormControl.valueChanges
      .pipe(
        takeUntil(this.destroy),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        filter((value) => this.outerValue === undefined || value !== this.outerValue),
      )
      .subscribe((value: Inner) => {
        this.outerValue = undefined;
        const outer = this.transformInnerToOuter(value);
        this.propagateToOuter(outer);
      });

    this.innerFormControl.statusChanges.pipe(takeUntil(this.destroy), delay(100)).subscribe(() => {
      this.changeDetectorRef.markForCheck();
    });
  }

  ngAfterViewChecked() {
    this.#updateRequiredState();
  }

  public registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.propagateTouch = fn;
  }

  public registerOnValidatorChange(fn: () => void): void {
    this.validatorChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.innerFormControl.disable() : this.innerFormControl.enable();
  }

  writeValue(value: Outer): void {
    this.outerValue = value;
    const inner = this.transformOuterToInner(value);
    this.innerFormControl.setValue(inner);
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.innerFormControl.errors;
  }

  protected transformInnerToOuter(value: Inner): Outer {
    return value as unknown as Outer;
  }

  protected transformOuterToInner(value: Outer): Inner {
    return (value ?? null) as unknown as Inner;
  }
}
