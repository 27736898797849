<vdms-hq-ui-dialog-wrapper [size]="'default'">
  <div title>
    <span class="title">{{ 'pages.lists.select_list' | translate }}</span>
  </div>
  <div content class="relative">
    <vdms-hq-ui-add-dialog-selectable-wrapper
      [dataSource]="dataSource"
      [filters]="dataSource.filters"
      [fieldsConfig]="filtersConfig"
      [config]="collectionsTilesConfig"
      [loadingText]="'common.client-teams.dialogs.fields.collections-loading' | translate"
      [selected]="selectedControl.value ?? []"
      [emptyMessage]="'Could not find any collections'"
      [hasEmptyActionButton]="true"
      [emptyActionButtonText]="'Create new collection'"
      (emptyActionButtonClick)="createNewCollection()"
      (selectedChangeEmit)="selectCollection($event)"
    >
    </vdms-hq-ui-add-dialog-selectable-wrapper>
  </div>

  <ng-container footer>
    <vdms-hq-ui-button (click)="close()" color="secondary">{{ 'common.global.cancel' | translate }}</vdms-hq-ui-button>
    <vdms-hq-ui-button
      (click)="onSubmit()"
      [disabled]="selectedControl.invalid || selectedControl.value.length === 0"
      color="primary"
      >{{ 'common.global.save' | translate }}</vdms-hq-ui-button
    >
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
