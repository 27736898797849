<div class="value-group">
  <ng-container *ngFor="let value of arrayValue | slice : 0 : sliceTo">
    <div
      *ngIf="value | valueModifier : format?.modifiers ?? {}; let modifiedValue; else: fallbackTmpl"
      class="value-outer"
      [ngClass]="value | applyPillsClasses : format"
      [matTooltip]="format?.modifiers?.truncateCharacters ? makeString(value) : ''"
      [matTooltipDisabled]="!format?.modifiers?.truncateCharacters || modifiedValue.showTooltip === false"
    >
      <ng-container
        *ngIf="
          modifiedValue.nextValue !== null && modifiedValue.nextValue !== '' && modifiedValue.nextValue !== 0;
          else fallbackTmpl
        "
      >
        <span [innerHTML]="modifiedValue.nextValue"></span>
      </ng-container>
    </div>
  </ng-container>

  <div
    class="more"
    matRipple
    *ngIf="arrayValue.length > (format?.maxVisibleValues ?? defaultVisibleValues)"
    (click)="unfolded = !unfolded"
  >
    {{ unfolded ? 'hide ' : '+' }}
    {{ arrayValue.length - (format?.maxVisibleValues ?? defaultVisibleValues) }}
  </div>
</div>

<ng-container *ngIf="arrayValue.length === 0" [ngTemplateOutlet]="fallbackTmpl"></ng-container>
<ng-template #fallbackTmpl>
  <span class="fallback">{{ format?.fallback ?? 'N/A' }}</span>
</ng-template>
