import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LICENSED_PACKAGE_VIEW_STATE, LicensedPackage, SORT_BY } from '@vdms-hq/api-contract';
import {
  ActionContextLess,
  DataAction as Action,
  DataPresentationHeaderComponent,
  DefaultMultiViewType,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  UIButtonModule,
  UILayoutModule,
} from '@vdms-hq/ui';
import { ColumnSettingsScope, ColumnsSettingsScopes, Permission } from '@vdms-hq/firebase-contract';
import { LicensePackagesActionsService } from '../../../logic/license-packages-actions';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { TranslateModule } from '@ngx-translate/core';
import { DestroyComponent, enumToSelectOptions, FilterType, ResourceModel, ValueFormat } from '@vdms-hq/shared';
import { LicensePackagesResultsMultiDs } from '../../../logic/license-packages-results-multi-ds.service';
import { Router } from '@angular/router';
import { LICENSE_PACKAGES_ROUTER_BASE } from '../../../license-packages-routing.module';
import { RightsContractsAddDsService } from '@vdms-hq/rights-contracts';
import { RightsPartnersAddDsService } from '@vdms-hq/rights-partners';
import { combineLatest, Observable } from 'rxjs';
import { ActivatedClientModule, PermissionService } from '@vdms-hq/activated-client';
import { map } from 'rxjs/operators';
import { TABLE_TYPE, ViewSettingsService } from '@vdms-hq/view-settings';
import { LicensePackageFieldsConfigService } from '../../../logic/license-package-fields-config.service';

@Component({
  selector: 'vdms-hq-license-packages-results',
  standalone: true,
  imports: [
    CommonModule,
    UIButtonModule,
    DynamicFiltersModule,
    TranslateModule,
    UILayoutModule,
    MultipleDataPresentationComponent,
    ActivatedClientModule,
    DataPresentationHeaderComponent,
  ],
  templateUrl: './license-packages-results.component.html',
  styles: [],
})
export class LicensePackagesResultsComponent extends DestroyComponent {
  public licensePackagesResultsMultiDs = inject(LicensePackagesResultsMultiDs);
  private licensePackagesActionsService = inject(LicensePackagesActionsService);
  private rightsContractsAddDsService = inject(RightsContractsAddDsService);
  private rightsPartnersAddDsService = inject(RightsPartnersAddDsService);
  private permissionService = inject(PermissionService);
  private viewSettingsService = inject(ViewSettingsService);
  private router = inject(Router);
  private fieldConfig = inject(LicensePackageFieldsConfigService);

  protected readonly Permission = Permission;

  scopeName: ColumnSettingsScope = ColumnsSettingsScopes.LICENSED_PACKAGES;

  filtersConfig$: Observable<DynamicFilterInput[]> = combineLatest([
    this.rightsContractsAddDsService.allData$,
    this.rightsPartnersAddDsService.allData$,
  ]).pipe(
    this.takeUntilDestroyed(),
    map(([contracts, partners]) => {
      return <DynamicFilterInput[]>[
        {
          id: 'keyword',
          label: 'common.license_package.table.filter_placeholder',
          resource: [ResourceModel.LICENSED_PACKAGE],
          format: ValueFormat.AS_IS,
          filters: {
            objectPath: 'name',
            validFormat: 'keyword',
            type: FilterType.MASTER_TEXT,
          },
        },
        {
          id: 'partners',
          label: 'common.license_package.table.partners_placeholder',
          resource: [ResourceModel.LICENSED_PACKAGE],
          format: ValueFormat.SELECTOR_MULTI,
          filters: {
            objectPath: 'partners_uuid',
            validFormat: 'keyword',
            type: FilterType.SELECTOR,
          },
          selectOptions: partners,
          isMultiple: true,
          enableDeselectAll: true,
          enableSelectAll: true,
        },
        {
          id: 'contract_in_out',
          label: 'common.license_package.table.contract_in_out_placeholder',
          resource: [ResourceModel.LICENSED_PACKAGE],
          format: ValueFormat.DATETIME,
          filters: {
            objectPath: 'contract_in_out',
            validFormat: 'keyword',
            type: FilterType.DATEPICKER_RANGE,
          },
        },

        {
          id: 'contracts',
          label: 'common.license_package.table.contracts_placeholder',
          resource: [ResourceModel.LICENSED_PACKAGE],
          format: ValueFormat.SELECTOR_MULTI,
          filters: {
            objectPath: 'contracts_uuid',
            validFormat: 'keyword',
            type: FilterType.SELECTOR,
          },
          selectOptions: contracts,
          isMultiple: true,
          enableDeselectAll: true,
          enableSelectAll: true,
        },
        {
          id: 'sort',
          label: 'common.orders.filters.sort',
          resource: [ResourceModel.LICENSED_PACKAGE],
          format: ValueFormat.AS_IS,
          filters: {
            validFormat: 'keyword',
            objectPath: 'sort',
            type: FilterType.SELECTOR,
          },
          selectOptions: SORT_BY,
        },
        {
          id: 'view_state',
          label: 'common.dialogs.license_package.update.fields.view_state',
          resource: [ResourceModel.LICENSED_PACKAGE],
          format: ValueFormat.AS_IS,
          filters: {
            validFormat: 'keyword',
            objectPath: 'view_state',
            type: FilterType.SELECTOR,
          },
          selectOptions: enumToSelectOptions(
            LICENSED_PACKAGE_VIEW_STATE,
            'common.dialogs.license_package.view_state.',
            true,
          ).filter((option) => option.key !== LICENSED_PACKAGE_VIEW_STATE.NONE),
          enableClear: true,
        },
      ];
    }),
  );

  #actions: Observable<Action<LicensedPackage>[]> = combineLatest([
    this.permissionService.verifyWithOwnedPermissions$([Permission.EDIT_LICENSED_PACKAGES]),
    this.permissionService.verifyWithOwnedPermissions$([Permission.DELETE_LICENSED_PACKAGES]),
  ]).pipe(
    this.takeUntilDestroyed(),
    map(([canEdit, canDelete]) => {
      const actions: Action<LicensedPackage>[] = [];
      if (canEdit) {
        actions.push({
          key: 'edit',
          label: 'common.license_package.table.actions.edit',
          icon: 'edit',
        });
      }
      if (canDelete) {
        actions.push({
          key: 'delete',
          label: 'common.license_package.table.actions.delete',
          icon: 'delete',
        });
      }
      actions.push({
        key: 'preview',
        label: 'common.license_package.table.actions.preview',
        icon: 'visibility',
        onDoubleClick: true,
        onDoubleClickTooltip: 'common.license_package.table.actions.preview_tooltip',
      });

      return actions;
    }),
  );

  licensePackageConfig$: Observable<MultipleViewConfiguration<LicensedPackage>> = combineLatest([
    this.#actions,
    this.viewSettingsService.defaultViewFor$(TABLE_TYPE.LICENSED_PACKAGE),
    this.fieldConfig.tableColumnsConfig$,
    this.fieldConfig.gridConfig$,
  ]).pipe(
    map(([actions, viewType, columnsConfig, gridMetadata]) => {
      return {
        multiView: {
          defaultView: viewType,
          emptyResults: {
            message: 'Could not find any Licensed Packages',
            icon: 'folder_special',
          },
        },
        tableAdvanced: {
          actions: actions,
          layout: {
            headers: true,
          },
          columns: columnsConfig.all,
          columnsEnabled: columnsConfig.enabled,
        },
        gridTiles: {
          actions: actions,
          content: {
            titlePath: 'name',
          },
          layout: {
            columns: 'up-to-6',
          },
          image: {
            bgPath: 'custom_cover_path',
            bgHoverPath: 'custom_cover_path',
          },
        },
        gridAdvanced: {
          actions: actions,
          layout: {
            columns: 'up-to-2',
          },
          content: {
            titlePath: 'name',
            additionalInfo: gridMetadata.additional,
            metadata: gridMetadata.metadata,
          },
          image: {
            bgPath: 'custom_cover_path',
            bgHoverPath: 'custom_cover_path',
          },
        },
      };
    }),
  );

  headerActions$: Observable<ActionContextLess[]> = this.permissionService
    .verifyWithOwnedPermissions$([Permission.CREATE_LICENSED_PACKAGES])
    .pipe(
      map((hasPermission) =>
        hasPermission
          ? [
              {
                key: 'create',
                label: 'common.license_package.table.actions.create',
              },
            ]
          : [],
      ),
    );

  setPreferredTableView($event: DefaultMultiViewType) {
    this.viewSettingsService.saveDefaultViewFor(TABLE_TYPE.LICENSED_PACKAGE, $event);
  }

  customActionHandler(event: { key: string; item?: LicensedPackage }): boolean {
    switch (event.key) {
      case 'preview':
        if (!event.item) {
          return false;
        }
        this.router.navigate([LICENSE_PACKAGES_ROUTER_BASE.ROOT, event.item.uuid]).then();
        return true;
      case 'create':
        this.licensePackagesActionsService.createDialog();
        return true;
      case 'edit':
        if (!event.item) {
          return false;
        }
        this.licensePackagesActionsService.editDialog(event.item.uuid);
        return true;
      case 'delete':
        if (!event.item) {
          return false;
        }
        this.licensePackagesActionsService.deleteDialog(event.item.uuid, event.item.name);
        return true;
    }
    return false;
  }
}
