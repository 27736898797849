import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormSectionComponent, UIButtonModule, UIDialogWrapperModule, UIFormModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { iif } from 'rxjs';
import { NotificationSettingsService, UpdateNotificationsConfigInterface } from '@vdms-hq/api-contract';
import { ToastService } from '@vdms-hq/toast';
import { DestroyComponent, SortDirection, SortDirectionEnum } from '@vdms-hq/shared';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'vdms-hq-notification-subscription-edit-settings-dialog',
  templateUrl: './notification-subscription-edit-settings-dialog.component.html',
  styleUrls: ['./notification-subscription-edit-settings-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    UIDialogWrapperModule,
    ReactiveFormsModule,
    FormSectionComponent,
    UIFormModule,
    UIButtonModule,
    TranslateModule,
  ],
  providers: [],
})
export class NotificationSubscriptionEditSettingsDialogComponent extends DestroyComponent implements OnInit {
  #notificationConfigUuid?: string;
  #dialogRef = inject(MatDialogRef<NotificationSubscriptionEditSettingsDialogComponent>);
  #toast = inject(ToastService);
  #notificationSettingsService = inject(NotificationSettingsService);

  notificationsSettingsForm = new FormGroup({
    material_availability_days_before_contract_start: new FormControl<number>(30),
    asset_added_licensed_package: new FormControl<boolean>(false),
    material_availability_start: new FormControl<boolean>(false),
    rights_contract_start: new FormControl<boolean>(false),
    rights_contract_end: new FormControl<boolean>(false),
    sort_assets_list_by_field: new FormControl<string>(''),
    sort_assets_list_order_dir: new FormControl<SortDirection>('asc'),
  });
  readonly sortDirOptions = [
    {
      key: SortDirectionEnum.ASC,
      label: SortDirectionEnum.ASC,
    },
    {
      key: SortDirectionEnum.DESC,
      label: SortDirectionEnum.DESC,
    },
  ];
  readonly sortFieldOptions = [
    {
      key: 'episodeNumber',
      label: 'Episode Number',
    },
    {
      key: 'seriesNumber',
      label: 'Series Number',
    },
    {
      key: 'originalFilename',
      label: 'Original Filename',
    },
    {
      key: 'createdAt',
      label: 'Created At',
    },
  ];
  loading = false;

  ngOnInit() {
    this.#initNotificationSettings();
  }

  saveNotificationSettings() {
    this.loading = true;
    this.#toast.processing({
      id: 'notification-settings',
      message: 'Notification settings saving',
    });
    const formValue = <Partial<UpdateNotificationsConfigInterface>>this.notificationsSettingsForm.value;
    iif(
      () => !!this.#notificationConfigUuid,
      this.#notificationSettingsService.updateNotificationSettings(this.#notificationConfigUuid as string, formValue),
      this.#notificationSettingsService.saveNotificationSettings(formValue),
    ).subscribe(() => {
      this.loading = false;

      this.#toast.success({
        id: 'notification-settings',
        message: 'Notification settings saved',
      });
      this.#dialogRef.close();
    });
  }

  #initNotificationSettings() {
    this.#notificationSettingsService.getNotificationSettings().subscribe((settings) => {
      const { uuid, ...data } = settings;
      this.#notificationConfigUuid = uuid;
      this.notificationsSettingsForm.patchValue(data);
    });
  }

  close() {
    this.#dialogRef.close(false);
  }
}
