import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { SelectOption } from '@vdms-hq/shared';
import { FormControlValueAccessorComponent } from '../../models/form/inputs/form-control-value-accessor.component';

@Component({
  selector: 'vdms-hq-ui-form-input-text',
  templateUrl: './form-input-text.component.html',
  styleUrls: ['./form-input-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('enterAnimation', [transition(':enter', [style({ opacity: 0 }), animate('200ms', style({ opacity: 1 }))])]),
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormInputTextComponent),
    },
  ],
})
export class FormInputTextComponent extends FormControlValueAccessorComponent {
  @ViewChild(MatAutocompleteTrigger) autoCompleteTrigger!: MatAutocompleteTrigger;
  @Input() type: 'text' | 'password' = 'text';
  @Input() mask = '';
  @Input() dropSpecialCharacters = true;
  @Input() showMaskTyped = false;
  @Input() alternativeAppearance = false;
  @Input() enableShowPasswordIcon = false;
  @Input() prefixIcon?: string;
  @Input() prefixText?: string;
  @Input() autocompleteList?: SelectOption[];
  @Input() autocompleteListRemoveItemButton = false;
  @Input() autocompleteIncludeNull = false;
  @Input() loading = false;
  @Input() suffixConfig?: SuffixConfig | null;
  @Input() fallbackSuffixIcon?: string;
  @Output() clickSuffix = new EventEmitter();
  @Output() clickAutocompleteItem = new EventEmitter();
  @Output() clickAutocompleteRemoveItem = new EventEmitter<SelectOption>();
  @Output() blurEvent = new EventEmitter();
  @Output() focusEvent = new EventEmitter();
  innerFormControl = new UntypedFormControl(null);
  focused = false;

  onFocus() {
    this.focused = true;
    this.focusEvent.emit('focused');
  }

  onEnter() {
    if (this.autocompleteList) {
      this.autoCompleteTrigger.closePanel();
    }
  }

  onBlur() {
    setTimeout(() => {
      this.focused = false;
      this.blurEvent.emit('blurred');
      this.changeDetectorRef.detectChanges();
    }, 100);
  }
}

export interface SuffixConfig {
  icon?: string;
  onlyIcon?: boolean;
  text?: string;
  type: 'primary' | 'secondary' | 'primary-transparent';
  onFocus: boolean;
}
