import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Timecode } from '../value-object/timecode';
import { Framerate } from '../value-object/framerate';

export abstract class TimecodeValidators {
  static isValid = (fps = Framerate.default(), withFrames: boolean): ValidatorFn => {
    return (control): ValidationErrors | null => {
      try {
        if (!control.value) {
          return null;
        }
        Timecode.fromTimecode(control.value, fps, true, withFrames);
        return null;
      } catch (e) {
        return { invalid: true };
      }
    };
  };

  static isRangeValid = (min: Timecode | null, max: Timecode | null): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!Array.isArray(control.value?.pair)) {
        return null;
      }
      const tcFirst = control.value?.pair[0] as Timecode | null;
      const tcSecond = control.value?.pair[1] as Timecode | null;

      if (tcFirst === null && tcSecond === null) {
        return null;
      }

      if (tcFirst === null || tcSecond == null) {
        return tcFirst === null ? { timecodeFirstIsMissing: true } : { timecodeSecondIsMissing: true };
      }

      if (tcFirst.greaterThan(tcSecond)) {
        return { timecodeFirstIsGreaterThanTcSecond: true };
      }

      if (tcFirst.equals(tcSecond)) {
        return { timecodesAreEqual: true };
      }

      const tcFirstMinValid = min && tcFirst.greaterThan(min, true);
      const tcFirstMaxValid = max && tcFirst.lessThan(max, true);

      if (!tcFirstMinValid || !tcFirstMaxValid) {
        return {
          timecodeIsOutOfBoundaries: {
            fieldName: 'TC IN',
            min: min?.toString() ?? 'N/A',
            max: max?.toString() ?? 'N/A',
          },
        };
      }
      const tcSecondMinValid = min && tcSecond.greaterThan(min, true);
      const tcSecondMaxValid = max && tcSecond.lessThan(max, true);

      if (!tcSecondMinValid || !tcSecondMaxValid) {
        return {
          timecodeIsOutOfBoundaries: {
            fieldName: 'TC IN',
            min: min?.toString() ?? 'N/A',
            max: max?.toString() ?? 'N/A',
          },
        };
      }
      return null;
    };
  };
}
