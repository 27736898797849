<form [formGroup]="settingsForm" class="form-container" (ngSubmit)="saveSettings()">
  <vdms-hq-ui-layout-page-wrapper [breadcrumbs]="[{ name: 'pages.clients.global_settings.title' | translate }]">
    <vdms-hq-ui-info-bar
      [type]="InfoBarType.INFO"
      [disableClose]="true"
      [clickAction]="InfoBarClickAction.DISABLE"
      [message]="'pages.clients.global_settings.warning' | translate"
    ></vdms-hq-ui-info-bar>
    <vdms-hq-ui-form-section
      [layout]="'grid-2-columns'"
      [title]="'pages.clients.global_settings.thresholds.title' | translate"
      [divider]="false"
    >
      <ng-container *ngFor="let control of controls" (mouseleave)="activeControl$.next(null)">
        <vdms-hq-ui-form-input-number
          #control
          [formControlName]="control"
          [label]="'pages.clients.global_settings.thresholds.' + control | translate"
          [min]="0"
          (mouseenter)="activeControl$.next(control)"
        ></vdms-hq-ui-form-input-number>
        <div
          class="threshold-set-container"
          [style.opacity]="activeControl$.value === control ? 1 : 0"
          *ngIf="hasEditPermission$ | async"
        >
          <ng-container *ngIf="!['threshold_high_fps', 'threshold_ultra_high_fps'].includes(control); else manual">
            <vdms-hq-ui-form-input-number
              #width
              [label]="'pages.clients.global_settings.thresholds.width' | translate"
              [min]="0"
              (valueChange)="setThreshold(control, width.innerFormControl.value, height.innerFormControl.value)"
            ></vdms-hq-ui-form-input-number>
            <vdms-hq-ui-form-input-number
              #height
              [label]="'pages.clients.global_settings.thresholds.height' | translate"
              [min]="0"
              (valueChange)="setThreshold(control, width.innerFormControl.value, height.innerFormControl.value)"
            ></vdms-hq-ui-form-input-number>
          </ng-container>
          <ng-template #manual>
            <div class="manual">{{ 'pages.clients.global_settings.thresholds.manual' | translate }}</div>
          </ng-template>
        </div>
      </ng-container>
    </vdms-hq-ui-form-section>
  </vdms-hq-ui-layout-page-wrapper>
  <vdms-hq-ui-loader [mode]="'over-parent'" [backdrop]="true" *ngIf="loading$ | async"></vdms-hq-ui-loader>
  <vdms-hq-ui-actions-footer>
    <vdms-hq-ui-button
      right
      [disabled]="settingsForm.pristine"
      color="primary"
      [isSubmit]="true"
      [loading]="loading$ | async"
    >
      {{ 'common.global.save' | translate }}
    </vdms-hq-ui-button>
  </vdms-hq-ui-actions-footer>
</form>
