import { Routes } from '@angular/router';
import { EmailTextTemplateCreatorComponent } from './pages/email-text-template-creator/email-text-template-creator.component';

export enum EMAIL_TEXT_ROUTES {
  ROOT = 'email-text',
}

export const EMAIL_TEXT_ROUTING: Routes = [
  {
    path: '',
    component: EmailTextTemplateCreatorComponent,
  },
  {
    path: '**',
    redirectTo: `/${EMAIL_TEXT_ROUTES}`,
    pathMatch: 'full',
  },
];
