<div class="checkout-summary">
  <h1>Cart Summary</h1>
  <div class="checkout-summary__totals">
    <div>
      <span>{{ dataSource.total$ | async }} {{ (dataSource.total$ | async) === 1 ? 'asset' : 'assets' }}</span>
    </div>
    <mat-divider></mat-divider>
    <ng-container *ngIf="!formService.deliveryMethod && (cartState.checkoutStep$ | async) !== 0">
      <vdms-hq-ui-info-bar
        [disableClose]="true"
        [type]="InfoBarType.INFO"
        [clickAction]="InfoBarClickAction.DISABLE"
        [message]="'pages.cart.checkout.select_order_type' | translate"
      ></vdms-hq-ui-info-bar>
    </ng-container>
    <ng-container *ngIf="showEstimation; else noEstimation">
      <vdms-hq-ui-loader
        [mode]="'over-parent'"
        [backdrop]="true"
        *ngIf="formService.deliveryMethod && (dataSource.isLoading$ | async)"
        [text]="'pages.cart.checkout.estimation_loading' | translate"
      ></vdms-hq-ui-loader>
      <ng-container *ngIf="dataSource.cartSummary$ | async; let summary">
        <ng-container *requiresPermissions="[Permissions.SHOPPING_CART_SHOW_PRICES]">
          <div *ngIf="summary.price.restore && summary.price.restore">
            <span>{{ 'pages.cart.checkout.restore' | translate }}</span>
            <vdms-hq-ui-price
              [price]="summary.price.restore"
              [currency]="summary.settings ? summary.settings.currency : GroupCurrencyISO.GBP"
              [discountPrice]="summary.discount_prices.restore"
              [discountTotal]="summary.discount_prices.total"
            ></vdms-hq-ui-price>
          </div>
          <div *ngIf="summary?.price?.processing">
            <span>{{ 'pages.cart.checkout.processing' | translate }}</span>
            <vdms-hq-ui-price
              [price]="summary.price.processing"
              [currency]="summary.settings ? summary.settings.currency : GroupCurrencyISO.GBP"
              [discountPrice]="summary.discount_prices.processing"
              [discountTotal]="summary.discount_prices.total"
            ></vdms-hq-ui-price>
          </div>
          <div *ngIf="summary?.price?.transcode">
            <span>{{ 'pages.cart.checkout.transcode' | translate }}</span>
            <vdms-hq-ui-price
              [price]="summary.price.transcode"
              [currency]="summary.settings ? summary.settings.currency : GroupCurrencyISO.GBP"
              [discountPrice]="summary.discount_prices.transcode"
              [discountTotal]="summary.discount_prices.total"
            ></vdms-hq-ui-price>
          </div>
          <div *ngIf="summary?.price?.egress && formService.deliveryMethod !== 'Workflow'">
            <span>{{ 'pages.cart.checkout.egress' | translate }}</span>
            <vdms-hq-ui-price
              [price]="summary.price.egress"
              [currency]="summary.settings ? summary.settings.currency : GroupCurrencyISO.GBP"
              [discountPrice]="summary.discount_prices.egress"
              [discountTotal]="summary.discount_prices.total"
            ></vdms-hq-ui-price>
          </div>
          <div *ngIf="summary?.price?.video_analysis && formService.deliveryMethod === 'Workflow'">
            <span>{{ 'pages.cart.checkout.video_analysis' | translate }}</span>
            <vdms-hq-ui-price
              [price]="summary.price.video_analysis"
              [currency]="summary.settings ? summary.settings.currency : GroupCurrencyISO.GBP"
              [discountPrice]="summary.discount_prices.video_analysis"
              [discountTotal]="summary.discount_prices.total"
            ></vdms-hq-ui-price>
          </div>
          <ng-container *ngIf="summary.discount_prices.total">
            <mat-divider></mat-divider>
            <div class="total-discount">
              <span>{{ 'pages.cart.checkout.total_discount' | translate }}</span>
              <span>{{
                totalDiscount | clientCurrency : (summary.settings ? summary.settings.currency : GroupCurrencyISO.GBP)
              }}</span>
            </div>
          </ng-container>
          <div *ngIf="summary.price.total">
            <span>{{ 'pages.cart.checkout.estimated' | translate }}</span>
            <span>{{
              summary.discount_prices.total ?? summary.price.total
                | clientCurrency : (summary.settings ? summary.settings.currency : GroupCurrencyISO.GBP)
            }}</span>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <div *ngIf="(clientDiscountEnabled$ | async) && formService.deliveryMethod" class="department-code">
      <ng-container *ngIf="cartState.discount$ | async as discountState">
        <vdms-hq-ui-form-input-text
          *ngIf="discountState.discountStatus === DISCOUNT_STATUS.NONE"
          [ngModel]="discountState.discountCode"
          (ngModelChange)="
            cartState.discount$.next({ discountCode: $event, discountStatus: discountState.discountStatus })
          "
          [suffixConfig]="discountInputConfig"
          [withFooter]="false"
          [label]="'pages.cart.checkout.discount_code' | translate"
          (clickSuffix)="actions.emit(CartActions.CHECKOUT_DISCOUNT_CHECK)"
        ></vdms-hq-ui-form-input-text>
        <ng-container
          *ngIf="discountState.discountStatus !== DISCOUNT_STATUS.NONE"
          [ngSwitch]="discountState.discountStatus"
        >
          <vdms-hq-ui-info-bar
            *ngSwitchCase="DISCOUNT_STATUS.ACTIVE"
            (action)="actions.emit(CartActions.CHECKOUT_DISCOUNT_RESET)"
            [closeWithEmitAction]="true"
            [clickAction]="InfoBarClickAction.CLOSE_EMIT"
            [type]="InfoBarType.SUCCESS"
            [emitMsgTooltip]="'pages.cart.checkout.discount_reset' | translate"
            [message]="'pages.cart.checkout.code_discount_applied' | translate : { code: discountState.discountCode }"
          ></vdms-hq-ui-info-bar>
          <vdms-hq-ui-info-bar
            *ngSwitchCase="DISCOUNT_STATUS.DEPARTMENT"
            (action)="actions.emit(CartActions.CHECKOUT_DISCOUNT_RESET)"
            [closeWithEmitAction]="true"
            [clickAction]="InfoBarClickAction.CLOSE_EMIT"
            [type]="InfoBarType.SUCCESS"
            [emitMsgTooltip]="'pages.cart.checkout.discount_reset' | translate"
            [message]="
              'pages.cart.checkout.department_discount_applied' | translate : { code: discountState.discountName }
            "
          ></vdms-hq-ui-info-bar>
          <vdms-hq-ui-info-bar
            *ngSwitchCase="DISCOUNT_STATUS.INACTIVE"
            (action)="actions.emit(CartActions.CHECKOUT_DISCOUNT_RESET)"
            [closeWithEmitAction]="true"
            [type]="InfoBarType.WARNING"
            [emitMsgTooltip]="'pages.cart.checkout.discount_reset' | translate"
            [message]="
              'pages.cart.checkout.discount_inactive' | translate : { code: discountState.discountCode ?? ' ' }
            "
          ></vdms-hq-ui-info-bar>
          <vdms-hq-ui-info-bar
            *ngSwitchCase="DISCOUNT_STATUS.WRONG"
            (action)="actions.emit(CartActions.CHECKOUT_DISCOUNT_RESET)"
            [closeWithEmitAction]="true"
            [type]="InfoBarType.ERROR"
            [emitMsgTooltip]="'pages.cart.checkout.discount_reset' | translate"
            [message]="'pages.cart.checkout.discount_wrong' | translate : { code: discountState.discountCode ?? ' ' }"
          ></vdms-hq-ui-info-bar>
        </ng-container>
      </ng-container>
    </div>
    <ng-template #noEstimation>
      <ng-container *requiresPermissions="[Permissions.SHOPPING_CART_SHOW_PRICES]">
        <vdms-hq-ui-info-bar
          *ngIf="formService.deliveryMethod === null"
          [disableClose]="true"
          [type]="InfoBarType.INFO"
          [clickAction]="InfoBarClickAction.DISABLE"
          [message]="'pages.cart.checkout.default_summary_msg' | translate"
        ></vdms-hq-ui-info-bar>
        <vdms-hq-ui-info-bar
          *ngIf="formService.deliveryMethod === 'Email delivery' || formService.deliveryMethod === 'Warm up'"
          [disableClose]="true"
          [type]="InfoBarType.INFO"
          [clickAction]="InfoBarClickAction.DISABLE"
          [message]="'pages.cart.checkout.checkout_summary_msg' | translate"
        ></vdms-hq-ui-info-bar>
        <vdms-hq-ui-info-bar
          *ngIf="formService.deliveryMethod === 'Delivery destinations'"
          [disableClose]="true"
          [type]="InfoBarType.INFO"
          [clickAction]="InfoBarClickAction.DISABLE"
          [message]="'pages.cart.checkout.delivery_summary_msg' | translate"
        ></vdms-hq-ui-info-bar>
        <vdms-hq-ui-info-bar
          *ngIf="formService.deliveryMethod === 'Workflow'"
          [disableClose]="true"
          [type]="InfoBarType.INFO"
          [clickAction]="InfoBarClickAction.DISABLE"
          [message]="'pages.cart.checkout.ai_summary_msg' | translate"
        ></vdms-hq-ui-info-bar>
      </ng-container>
    </ng-template>
  </div>
  <vdms-hq-cart-checkout-actions
    *ngIf="(dataSource?.total$ | async) > 0"
    [config]="config"
    (actions)="actions.emit($event)"
  ></vdms-hq-cart-checkout-actions>
  <ng-container *ngIf="(formService.hasValidationErrors$ | async) && (formService.missingRates$ | async) === false">
    <vdms-hq-ui-info-bar
      [type]="InfoBarType.ERROR"
      [message]="'pages.cart.checkout.validation_error' | translate"
      [disableClose]="true"
      [clickAction]="InfoBarClickAction.EMIT"
      (action)="actions.emit(CartActions.REMOVE_FAILED_VALIDATION)"
    ></vdms-hq-ui-info-bar>
  </ng-container>
  <ng-container *ngIf="(formService.overwriteApproved$ | async) === false">
    <vdms-hq-ui-info-bar
      [type]="InfoBarType.WARNING"
      [message]="'pages.cart.checkout.overwrite_warning' | translate"
      [disableClose]="true"
      [clickAction]="InfoBarClickAction.CLOSE_EMIT"
      (action)="actions.emit(CartActions.APPROVE_OVERWRITING)"
    ></vdms-hq-ui-info-bar>
  </ng-container>
  <ng-container *ngIf="formService.missingRates$ | async">
    <vdms-hq-ui-info-bar
      [type]="InfoBarType.ERROR"
      [message]="'pages.cart.checkout.rates_missing' | translate"
      [disableClose]="true"
      [clickAction]="InfoBarClickAction.DISABLE"
    ></vdms-hq-ui-info-bar>
  </ng-container>
  <ng-container *ngIf="formService.missingDefaultPolicy$ | async">
    <vdms-hq-ui-info-bar
      [type]="InfoBarType.ERROR"
      [message]="'pages.cart.checkout.policy_missing' | translate"
      [disableClose]="true"
      [clickAction]="InfoBarClickAction.DISABLE"
    ></vdms-hq-ui-info-bar>
  </ng-container>
  <ng-container *ngIf="dataSource.cartSummary$ | async">
    <ng-container *requiresPermissions="[Permissions.SHOPPING_CART_SHOW_PRICES]">
      <mat-divider></mat-divider>
      <span class="checkout-summary__disclaimer">
        <span [innerHTML]="'pages.cart.checkout.disclaimer' | translate"></span>
      </span>
    </ng-container>
  </ng-container>
  <vdms-hq-ui-button
    class="cancel"
    color="secondary"
    *ngIf="(cartState.checkoutStep$ | async) > 0"
    [size]="'full'"
    [disabled]="(dataSource.isLoading$ | async) || (formService.isLoading$ | async)"
    (click)="cancel()"
  >
    {{ 'pages.cart.checkout.cancel' | translate }}
  </vdms-hq-ui-button>
</div>
