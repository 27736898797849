import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ActionContextLess,
  DataAction as Action,
  DataPresentationHeaderComponent,
  DefaultMultiViewType,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  UIButtonModule,
  UIConfirmationDialogService,
  UILayoutModule,
} from '@vdms-hq/ui';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { TranslateModule } from '@ngx-translate/core';
import { DestroyComponent, FilterType, ResourceModel, ValueFormat } from '@vdms-hq/shared';
import { ContractListItem, PaginationAPIModel as Pagination, RightsContractsService } from '@vdms-hq/api-contract';
import { RightsContractsResultsMultiDs } from '../../logic/rights-contracts-results-multi-ds.service';
import { RightsContractsActionsService } from '../../logic/rights-contracts-actions.service';
import { RightsPartnersAddDsService } from '@vdms-hq/rights-partners';
import { combineLatest, Observable, switchMap, take, withLatestFrom } from 'rxjs';
import { Permission } from '@vdms-hq/firebase-contract';
import { ActivatedClientModule, ActivatedClientService, PermissionService } from '@vdms-hq/activated-client';
import { filter, map } from 'rxjs/operators';
import { TABLE_TYPE, ViewSettingsService } from '@vdms-hq/view-settings';
import { ToastService } from '@vdms-hq/toast';
import { RightContractsFieldsConfigService } from '../../logic/right-contracts-fields-config.service';

@Component({
  selector: 'vdms-hq-rights-contracts-results',
  standalone: true,
  imports: [
    CommonModule,
    UIButtonModule,
    DynamicFiltersModule,
    TranslateModule,
    UILayoutModule,
    MultipleDataPresentationComponent,
    ActivatedClientModule,
    DataPresentationHeaderComponent,
  ],
  templateUrl: './rights-contracts-results.component.html',
  styles: [],
})
export class RightsContractsResultsComponent extends DestroyComponent {
  private rightsContractsActionsService: RightsContractsActionsService = inject(RightsContractsActionsService);
  public rightsContractsResultsMultiDs: RightsContractsResultsMultiDs = inject(RightsContractsResultsMultiDs);
  private rightsPartnersAddDsService: RightsPartnersAddDsService = inject(RightsPartnersAddDsService);
  private viewSettingsService: ViewSettingsService = inject(ViewSettingsService);
  private permissionsService: PermissionService = inject(PermissionService);
  private activatedClientService: ActivatedClientService = inject(ActivatedClientService);
  private confirmationDialog: UIConfirmationDialogService = inject(UIConfirmationDialogService);
  private rightsContractsService: RightsContractsService = inject(RightsContractsService);
  private toastService: ToastService = inject(ToastService);
  private fieldsConfig = inject(RightContractsFieldsConfigService);

  protected readonly Permission = Permission;
  headerActions$: Observable<ActionContextLess[]> = this.activatedClientService.permissions$.pipe(
    this.takeUntilDestroyed(),
    map((permissions) => [
      {
        key: 'export',
        label: 'pages.assets_list.export',
        icon: 'forward_to_inbox',
      },
      {
        key: 'create',
        label: 'common.rights_contracts.table.actions.create',
        hiddenIf: () => !permissions.includes(Permission.CREATE_RIGHTS_CONTRACTS),
      },
    ]),
  );

  filtersConfig$: Observable<DynamicFilterInput[]> = combineLatest([
    this.rightsPartnersAddDsService.allData$,
    this.permissionsService.verifyWithOwnedPermissions$([Permission.EDIT_RIGHTS_CONTRACTS]),
    this.permissionsService.verifyWithOwnedPermissions$([Permission.DELETE_RIGHTS_CONTRACTS]),
  ]).pipe(
    this.takeUntilDestroyed(),
    map(([partners]) => {
      return <DynamicFilterInput[]>[
        {
          id: 'keyword',
          label: 'common.rights_contracts.table.filter_placeholder',
          resource: [ResourceModel.RIGHTS_CONTRACT],
          format: ValueFormat.AS_IS,
          filters: {
            objectPath: 'name',
            validFormat: 'keyword',
            type: FilterType.MASTER_TEXT,
          },
        },
        {
          id: 'partners',
          label: 'common.license_package.table.partners_placeholder',
          resource: [ResourceModel.ASSET_LICENSED_PACKAGE],
          format: ValueFormat.SELECTOR,
          filters: {
            objectPath: 'partners_uuid',
            validFormat: 'keyword',
            type: FilterType.SELECTOR,
          },
          selectOptions: partners,
          enableClear: true,
        },
      ];
    }),
  );

  #actions: Observable<Action<ContractListItem>[]> = combineLatest([
    this.permissionsService.verifyWithOwnedPermissions$([Permission.EDIT_RIGHTS_CONTRACTS]),
    this.permissionsService.verifyWithOwnedPermissions$([Permission.DELETE_RIGHTS_CONTRACTS]),
    this.permissionsService.verifyWithOwnedPermissions$([Permission.BROWSE_RIGHTS_CONTRACTS_LOGS]),
  ]).pipe(
    this.takeUntilDestroyed(),
    map(([canEdit, canDelete, canShowLogs]) => {
      const actions: Action<ContractListItem>[] = [];

      if (canEdit) {
        actions.push({
          key: 'edit',
          label: 'common.rights_contracts.table.actions.edit',
          icon: 'edit',
        });
        actions.push({
          key: 'licensed_packages',
          label: 'common.rights_contracts.table.actions.licensed_packages',
          icon: 'folder_special',
        });
      }
      if (canDelete) {
        actions.push({
          key: 'delete',
          label: 'common.rights_contracts.table.actions.delete',
          icon: 'delete',
        });
      }
      if (canShowLogs) {
        actions.push({
          key: 'logs',
          label: 'common.rights_contracts.table.actions.show_logs',
          icon: 'web_stories',
        });
      }

      return actions;
    }),
  );

  rightsContractsConfig$: Observable<MultipleViewConfiguration<ContractListItem>> = combineLatest([
    this.#actions,
    this.viewSettingsService.defaultViewFor$(TABLE_TYPE.CONTRACT),
    this.fieldsConfig.tableColumnsConfig$,
    this.fieldsConfig.gridConfig$,
  ]).pipe(
    map(([actions, defaultView, columnsConfig, gridMetadata]) => {
      return {
        multiView: {
          defaultView: defaultView,
          emptyResults: {
            message: 'Could not find any Rights Contracts',
            icon: 'copyright',
          },
        },
        tableAdvanced: {
          actions: actions,
          layout: {
            headers: true,
          },
          columns: columnsConfig.all,
          columnsEnabled: columnsConfig.enabled,
        },
        gridAdvanced: {
          actions: actions,
          content: {
            titlePath: 'name',
            additionalInfo: gridMetadata.additional,
            metadata: gridMetadata.metadata,
          },
        },
      };
    }),
  );

  setPreferredTableView($event: DefaultMultiViewType) {
    this.viewSettingsService.saveDefaultViewFor(TABLE_TYPE.CONTRACT, $event);
  }

  customActionHandler(event: { key: string; item?: ContractListItem }): boolean {
    switch (event.key) {
      case 'create':
        this.rightsContractsActionsService.createDialog();
        return true;
      case 'edit':
        if (!event.item) {
          return false;
        }
        this.rightsContractsActionsService.editDialog(event.item.uuid);
        return true;
      case 'licensed_packages':
        if (!event.item) {
          return false;
        }
        this.rightsContractsActionsService.editLicensedPackagesDialog(event.item.uuid);
        return true;
      case 'delete':
        if (!event.item) {
          return false;
        }
        this.rightsContractsActionsService.deleteDialog(event.item.uuid, event.item.name);
        return true;
      case 'logs':
        if (!event.item) {
          return false;
        }
        this.rightsContractsActionsService.logsDialog(event.item.uuid);
        return true;
      case 'export':
        this.confirmationDialog
          .open({
            title: 'Please confirm export action',
            message: 'If you confirm, we’ll send you a CSV with applied filters via email shortly.',
          })
          .pipe(
            take(1),
            filter((confirmed) => confirmed),
            withLatestFrom(
              this.rightsContractsResultsMultiDs.values$,
              this.rightsContractsResultsMultiDs.pageIndex$,
              this.rightsContractsResultsMultiDs.pageSize$,
              this.rightsContractsResultsMultiDs.sortBy$,
              this.rightsContractsResultsMultiDs.sortDirection$,
            ),
            switchMap(([, filters, pageIndex, pageSize, sortBy, sortDirection]) => {
              const paginationObject = Pagination.create({
                page: pageIndex,
                perPage: pageSize,
                orderBy: sortBy,
                orderDir: sortDirection,
              });

              return this.fieldsConfig.exportColumnsConfig$.pipe(
                switchMap((fields) => this.rightsContractsService.export(fields, paginationObject, filters)),
              );
            }),
          )
          .subscribe({
            next: () =>
              this.toastService.success({
                id: 'export',
                message: 'notifications.export.done',
              }),
            error: () => {
              this.toastService.error({
                id: 'export',
                message: 'notifications.export.failed',
              });
            },
          });
        return false;
    }
    return false;
  }
}
