import { inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  filter,
  Observable,
  of,
  shareReplay,
  Subject,
  switchMap,
  takeUntil,
  timer,
  withLatestFrom,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { ReindexAutomatizationService } from '@vdms-hq/api-contract';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { MultipleViewDataSource } from '@vdms-hq/ui';
import { SelectableDataSource, SelectionManager } from '@vdms-hq/shared';
import { ReindexAutomatizationViewModel } from './reindex.class';

@Injectable()
export class ReindexAutomatizationDatasource
  implements
    MultipleViewDataSource<ReindexAutomatizationViewModel>,
    SelectableDataSource<ReindexAutomatizationViewModel>
{
  #REFRESH_INTERVAL = 10_000;
  #reindexService = inject(ReindexAutomatizationService);
  #activatedClientService = inject(ActivatedClientService);

  #destroy$ = new Subject<void>();

  #refresh$ = new BehaviorSubject<string>('');
  #authorizedClients$ = this.#activatedClientService.authorizedClientsOptions$.pipe(
    map((selectOptions) => selectOptions.sort((a, b) => a.label.localeCompare(b.label))),
    shareReplay({
      refCount: true,
      bufferSize: 1,
    }),
  );

  pause$ = new BehaviorSubject<boolean>(false);
  loading$ = new BehaviorSubject<boolean>(false);
  reindexProgress$ = this.#reindexService.get();
  sortBy$ = new BehaviorSubject('ALL');
  sortDir$ = new BehaviorSubject('label:ASC');

  allData$: Observable<Array<ReindexAutomatizationViewModel>> = combineLatest([
    this.#refresh$,
    timer(0, this.#REFRESH_INTERVAL),
    this.pause$,
  ]).pipe(
    takeUntil(this.#destroy$),
    catchError((err) => of(err)),
    filter(([, , pause]) => !pause),
    switchMap(() =>
      combineLatest([this.#authorizedClients$, this.reindexProgress$]).pipe(
        withLatestFrom(this.sortBy$, this.sortDir$),
        map(([[items, processing], sortBy, sortDir]) => {
          return items
            .map((item) => {
              const processingItem = (processing ?? [])?.find((inProcess) => item.key === inProcess.group_uuid);
              return ReindexAutomatizationViewModel.fromReindexItem({ ...item, ...(processingItem ?? {}) });
            })
            .filter((item) => !!(item.props?.key && item.props?.label))
            .filter((item) => {
              if (sortBy === 'PROGRESS') {
                return item.status.includes('progress') ? -1 : 0;
              }
              if (sortBy === 'COMPLETED') {
                return item.status.includes('completed') ? -1 : 0;
              }

              return item;
            })
            .sort((a, b) => {
              const [key, sortDirection] = sortDir.split(':');
              switch (key) {
                case 'label':
                  sortDirection === 'DESC' && ([a.props.label, b.props.label] = [b.props.label, a.props.label]);
                  return a.props?.label?.toLowerCase()?.localeCompare(b.props?.label?.toLowerCase() ?? '') ?? 0;

                case 'total_assets':
                  sortDirection === 'DESC' &&
                    ([a.props.total_assets, b.props.total_assets] = [b.props.total_assets, a.props.total_assets]);
                  return (a.props?.total_assets ?? 0) > (b.props?.total_assets ?? 0) ? -1 : 0;

                case 'index_size':
                  if (sortDirection === 'DESC') {
                    return +(a.props?.index_size ?? 0) - +(b.props?.index_size ?? 0);
                  }

                  return +(a.props?.index_size ?? 0) > +(b.props?.index_size ?? 0) ? -1 : 0;
                case 'requested_at':
                  sortDirection === 'DESC' &&
                    ([a.props.requested_at, b.props.requested_at] = [b.props.requested_at, a.props.requested_at]);
                  return a.props?.requested_at?.localeCompare(b.props?.requested_at ?? '') ?? 0;

                default:
                  return 0;
              }
            });
        }),
      ),
    ),
    shareReplay(1),
  );
  connection$ = this.allData$;
  selection: SelectionManager<ReindexAutomatizationViewModel> = new SelectionManager<ReindexAutomatizationViewModel>(
    this,
    (item) => item.props?.key ?? '',
  );

  destroy() {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  refresh(value: string) {
    this.#refresh$.next(value);
  }
}
