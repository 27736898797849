import { ResultsActions } from '@vdms-hq/asset-results';
import { DataAction, DataColumn, GridAdvancedMetadata } from '@vdms-hq/ui';
import { FlatOrderViewModel } from '../../results/logic/order-results-ds';

export const sharedPacksViewConfiguration: {
  actions: DataAction<FlatOrderViewModel>[];
} = {
  actions: [
    {
      key: ResultsActions.DOWNLOAD_ALL_ASPERA,
      label: 'tooltip.download_all_with_aspera',
      icon: 'download',
      disabledIf: (order) =>
        order?.orderExpired ||
        !order?.isProcessing ||
        order?.hasEmbargoedAsset ||
        order?.orderScheduled ||
        !order?.couldBeDownloaded ||
        order?.isWarmingUp,
    },
    {
      key: ResultsActions.DOWNLOAD_ALL_ASPERA_FOLDERS,
      label: 'tooltip.download_all_with_aspera_in_folders',
      icon: 'cloud_download',
      disabledIf: (order) =>
        order?.orderExpired ||
        !order?.isProcessing ||
        order?.hasEmbargoedAsset ||
        order?.orderScheduled ||
        !order?.couldBeDownloaded ||
        order?.isWarmingUp,
    },
    {
      key: ResultsActions.PREVIEW,
      icon: 'visibility',
      onDoubleClick: true,
      label: 'common.global.preview',
    },
  ],
};

export const ordersViewConfiguration: {
  actions: DataAction<FlatOrderViewModel>[];
} = {
  actions: [
    {
      key: ResultsActions.PREVIEW,
      icon: 'visibility',
      onDoubleClick: true,
      label: 'common.global.preview',
      e2eId: 'order-preview-button',
    },
  ],
};

export const ordersMetadata: GridAdvancedMetadata<FlatOrderViewModel>[] = [
  {
    label: 'common.orders.package_title',
    valuePath: 'packageTitle',
  },
  {
    label: 'common.orders.sales_force_name',
    valuePath: 'salesForceName',
  },
  {
    label: 'common.orders.total_assets',
    valuePath: 'totalAssets',
  },
  {
    label: 'common.orders.purchase_order_no',
    valuePath: 'purchaseOrderNo',
  },
  {
    label: 'common.orders.order_sender',
    valuePath: 'orderSender',
  },
  {
    label: 'common.orders.shared_by',
    valuePath: 'sharedBy',
  },
  {
    label: 'common.orders.order_shared',
    valuePath: 'orderShared',
  },
  {
    label: 'common.orders.order_shared',
    valuePath: 'deliveryEmails',
  },
  {
    label: 'common.orders.delivery_date',
    valuePath: 'deliveryDate',
    viewFormat: {
      modifiers: {
        dateFormat: 'dd-MM-yyyy hh:mm',
      },
    },
  },
  {
    label: 'common.orders.expiry_date',
    valuePath: 'expiryDate',
    viewFormat: {
      modifiers: {
        dateFormat: 'dd-MM-yyyy hh:mm',
      },
    },
  },
  {
    label: 'common.orders.media_pulse_id',
    valuePath: 'mediaPulseId',
  },
  {
    label: 'common.orders.status',
    valuePath: 'status',
  },
  {
    label: 'common.orders.used_department',
    valuePath: 'usedDepartment',
  },
  {
    label: 'common.orders.transcode_progress',
    valuePath: 'transcode_progress',
    fullLine: true,
    viewFormat: {
      modifiers: {
        asPercentage: true,
      },
    },
  },
  {
    label: 'common.orders.comment',
    valuePath: 'comment',
    fullLine: true,
  },
];

export const ordersColumns: DataColumn[] = [
  {
    id: 'deliveryStatus',
    label: 'common.orders.delivery_status',
    valuePath: 'deliveryStatus',
    viewFormat: {
      pills: {
        'common.orders.delivery.getty_images_failed': 'failed',
        'common.orders.delivery.delivered': 'done',
        'common.orders.delivery.failed': 'failed',
      },
      modifiers: {
        translate: true,
      },
    },
  },
  {
    id: 'downloadStatus',
    label: 'common.orders.download_status',
    valuePath: 'downloadStatus',
    viewFormat: {
      pills: {
        'common.orders.download.downloaded': 'done',
      },
      modifiers: {
        translate: true,
      },
    },
  },
  {
    id: 'packageTitle',
    label: 'common.orders.package_title',
    valuePath: 'packageTitle',
  },
  {
    id: 'salesForceName',
    label: 'common.orders.sales_force_name',
    valuePath: 'salesForceName',
  },
  {
    id: 'deliveryEmails',
    label: 'common.orders.order_shared',
    valuePath: 'deliveryEmails',
    viewFormat: {
      maxVisibleValues: 3,
    },
  },
  {
    id: 'totalAssets',
    label: 'common.orders.total_assets',
    valuePath: 'totalAssets',
  },
  {
    id: 'usedDepartment',
    label: 'common.orders.used_department',
    valuePath: 'usedDepartment',
  },
  {
    id: 'purchaseOrderNo',
    label: 'common.orders.purchase_order_no',
    valuePath: 'purchaseOrderNo',
  },
  {
    label: 'common.orders.shared_by',
    valuePath: 'sharedBy',
    id: 'sharedBy',
  },
  {
    label: 'common.orders.order_shared',
    valuePath: 'orderShared',
    id: 'orderShared',
    viewFormat: {
      maxVisibleValues: 3,
    },
  },
  {
    label: 'common.orders.delivery_date',
    valuePath: 'deliveryDate',
    id: 'deliveryDate',
    viewFormat: {
      modifiers: {
        dateFormat: 'dd-MM-yyyy hh:mm',
      },
    },
  },
  {
    label: 'common.orders.expiry_date',
    valuePath: 'expiryDate',
    id: 'expiryDate',
    viewFormat: {
      modifiers: {
        dateFormat: 'dd-MM-yyyy hh:mm',
      },
    },
  },
  {
    label: 'common.orders.created_at',
    valuePath: 'createdAt',
    id: 'createdAt',
    viewFormat: {
      modifiers: {
        dateFormat: 'dd-MM-yyyy hh:mm',
      },
    },
  },
  {
    label: 'common.orders.media_pulse_id',
    valuePath: 'mediaPulseId',
    id: 'mediaPulseId',
  },
  {
    label: 'common.orders.status',
    valuePath: 'status',
    id: 'status',
    viewFormat: {
      pills: {
        downloaded: 'done',
        expired: 'orange',
        new: '',
        default: 'yellow',
      },
      modifiers: {
        translate: true,
      },
    },
  },
  {
    label: 'common.orders.comment',
    valuePath: 'comment',
    id: 'comment',
    viewFormat: {
      modifiers: {
        truncateCharacters: 200,
      },
    },
  },
  {
    label: 'common.orders.uuid',
    valuePath: 'uuid',
    id: 'uuid',
  },
  {
    label: 'common.orders.total_bytes',
    valuePath: 'totalBytes',
    id: 'totalBytes',
    viewFormat: {
      modifiers: {
        fileSizeFormat: true,
      },
    },
  },
  {
    label: 'common.orders.total_downloadable_assets',
    valuePath: 'totalDownloadableAssets',
    id: 'totalDownloadableAssets',
  },
  {
    label: 'common.orders.total_downloaded_assets',
    valuePath: 'totalDownloadedAssets',
    id: 'totalDownloadedAssets',
  },
  {
    label: 'common.orders.total_price',
    valuePath: 'totalPrice',
    id: 'totalPrice',
  },
  {
    label: 'orders.workflow.label',
    valuePath: 'workflow',
    id: 'workflow',
    foldValues: false,
  },
];

export const availableOrdersColumns = [
  'deliveryStatus',
  'downloadStatus',
  'packageTitle',
  'salesForceName',
  'deliveryEmails',
  'totalAssets',
  'purchaseOrderNo',
  'orderSender',
  'sharedBy',
  'expiresAt',
  'orderShared',
  'deliveryDate',
  'expiryDate',
  'createdAt',
  'totalSize',
  'mediaPulseId',
  'status',
  'comment',
  'usedDepartment',
  'uuid',
  'totalBytes',
  'totalDownloadableAssets',
  'totalDownloadedAssets',
  'totalPrice',
  'workflow',
];

export const defaultOrdersColumns = [
  'packageTitle',
  'purchaseOrderNo',
  'sharedBy',
  'sharedWith',
  'deliveryEmails',
  'deliveryDate',
  'totalAssets',
  'usedDepartment',
  'comment',
  'transcode_progress',
  'workflow',
];

export const defaultSalesForceOrdersColumns = [
  'deliveryStatus',
  'salesForceName',
  'packageTitle',
  'orderSender',
  'orderShared',
  'usedDepartment',
  'comment',
  'transcode_progress',
  'workflow',
];
