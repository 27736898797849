import { inject, Injectable } from '@angular/core';
import { CredentialsTypeOptions } from '../logic/delivery-destination-options';
import {
  CredentialInterface,
  CredentialsApiService,
  CredentialTypeEnum,
  DestinationAccessMethodEnum,
} from '@vdms-hq/api-contract';
import { SelectOption } from '@vdms-hq/shared';
import { shareReplay } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DeliveryDestinationCredentialsService {
  credentialTypeOptions = CredentialsTypeOptions.filter(
    ({ key }) => key === CredentialTypeEnum.AWS || key === CredentialTypeEnum.ASPERA_HSTS,
  );
  credentialsAWS: CredentialInterface<CredentialTypeEnum>[] = [];
  credentialsAsperaHSTS: CredentialInterface<CredentialTypeEnum>[] = [];
  credentialsSelectorSourceAWS: SelectOption[] = [];
  credentialsSelectorSourceAsperaHSTS: SelectOption[] = [];

  isLoading$ = new BehaviorSubject<boolean>(false);

  credentialsService = inject(CredentialsApiService);

  getCredentialsByType(type: DestinationAccessMethodEnum | CredentialTypeEnum) {
    if (type === DestinationAccessMethodEnum.VIDA) {
      return;
    }
    if (type === DestinationAccessMethodEnum.AWS && this.credentialsAWS.length > 0) {
      return;
    }
    if (type === DestinationAccessMethodEnum.ASPERA_HSTS && this.credentialsAsperaHSTS.length > 0) {
      return;
    }

    this.isLoading$.next(true);

    this.credentialsService
      .getByType(type.toUpperCase() as CredentialTypeEnum)
      .pipe(shareReplay(1))
      .subscribe((credentials) => {
        switch (type) {
          case CredentialTypeEnum.AWS:
          case DestinationAccessMethodEnum.AWS:
            this.credentialsAWS = credentials;
            this.credentialsSelectorSourceAWS = credentials.map((credential) => ({
              key: credential.uuid,
              label: credential.name,
            }));
            this.isLoading$.next(false);
            return;
          case CredentialTypeEnum.ASPERA_HSTS:
          case DestinationAccessMethodEnum.ASPERA_HSTS:
            this.credentialsAsperaHSTS = credentials;
            this.credentialsSelectorSourceAsperaHSTS = credentials.map((credential) => ({
              key: credential.uuid,
              label: credential.name,
            }));
            this.isLoading$.next(false);
            return;
        }
      });
  }
}
