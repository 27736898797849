import { AsperaFiles } from '../../upload-job/upload-job.model';
import { ApiResponse } from '../../api.model';

export enum ASSETS_ENDPOINTS {
  SEARCH = 'search',
  LIST = 'assets',
  ONE = 'asset',
  LOGS = 'storage-log',
  SET_AS_COLD = 'assets/set-as-cold-only',
  SET_AS_COLD_SEARCH = 'assets/set-as-cold-only/search',
  SET_AS_HOT_PRICE = 'assets/set-as-hot/price',
  SET_AS_HOT_SEARCH_PRICE = 'assets/set-as-hot/search/price',
  ASSET_LOCK = 'asset/lock',
}

export enum DOWNLOAD_TYPE {
  FILE = 'download',
  PROXY_FILE = 'download-proxy',
  SPEC_PDF_FILE = 'pdf',
}

export interface AssetAsperaPost {
  files: AsperaFiles[];
}

export type AssetAsperaPostResponse = ApiResponse<{
  token: string;
  cookie: string;
  destinationRoot: string;
  remoteHost?: string;
  remoteUser?: string;
  sshPort?: number;
}>;
