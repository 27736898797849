import { AssetFlatView2 } from '@vdms-hq/asset-results';
import { DataProviderService } from '@vdms-hq/selectors';
import { StorageUrlService } from '@vdms-hq/storage';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { AssetFlat } from '@vdms-hq/api-contract';

export class BrowseAssetViewModel extends AssetFlatView2 {
  static fromBrowseAsset(
    originalAsset: AssetFlat,
    deps: {
      dataProvider: DataProviderService;
      storageUrlService: StorageUrlService;
      activatedClientService: ActivatedClientService;
    },
  ) {
    return new BrowseAssetViewModel(originalAsset, deps);
  }
}
