<div *ngIf="file" class="file-selected" [class.no-bottom-margin]="noBottomMargin" [class.no-border]="noBorders">
  <span class="file-title">{{ file.name }}</span>
  <div *ngIf="uploadState">
    <span class="file-upload-percent"
      >{{ 'common.global.upload_status.' + uploadState.status | translate }} -
      {{ uploadState.file?.progress || 0 }}%</span
    >
    <mat-progress-bar
      *ngIf="isError"
      [mode]="(uploadState.file?.progress ?? 0) > 0 ? 'determinate' : 'indeterminate'"
      color="primary"
      value="{{ uploadState.file?.progress }}"
    >
    </mat-progress-bar>
  </div>
  <vdms-hq-ui-button
    type="transparent"
    icon="delete"
    [iconOnly]="true"
    [disabled]="!!uploadState || disabled"
    (click)="clear.emit()"
  >
  </vdms-hq-ui-button>
  <ng-content></ng-content>
</div>
