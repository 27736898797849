import { Injectable } from '@angular/core';
import { AuthStateService } from './auth-state.service';
import { filter, of, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs';
import { filterEmpty } from '@vdms-hq/shared';
import {
  ClientContractService,
  ClientModel,
  UserContractService,
  UserModel,
  UserUpdateAttributes,
} from '@vdms-hq/firebase-contract';
import { map, take } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UserAttributes } from '../models/authenticated-user.model';

@Injectable()
/**
 * @internal do not use outside auth.module.ts, instead use proxy class auth.service.ts
 */
export class AuthAttributesService {
  currentAuthAttributesState$ = this.authStateService.email$.pipe(
    switchMap((email) => {
      if (!email) {
        return of(null);
      }

      return this.userContractService
        .userData(email)
        .pipe(map((attributes) => this.#transformMetadata(attributes ?? {})));
    }),
    shareReplay(1),
  );

  displayName$ = this.auth.user.pipe(
    map((user) => user?.displayName ?? user?.email),
    filterEmpty(),
  );

  currentClient$ = this.authStateService.clientsInfo$;

  currentAuthAttributesStateDefinite$ = this.currentAuthAttributesState$.pipe(filterEmpty());
  #currentClientData!: ClientModel;

  constructor(
    private authStateService: AuthStateService,
    private userContractService: UserContractService,
    private clientService: ClientContractService,
    private auth: AngularFireAuth,
  ) {
    this.currentClient$
      .pipe(
        filter((current) => !!current.selectedClientId),
        take(1),
        switchMap((current) =>
          this.clientService
            .findClients$((client) => current.selectedClientId === client.uuid)
            .pipe(
              map((clients) => clients[0]),
              tap((client) => {
                this.#currentClientData = client;
              }),
            ),
        ),
      )
      .subscribe();
  }

  #transformMetadata = (userData: Partial<UserModel>): UserAttributes => ({
    selectedClientId: userData.selectedClientId,
    preferredTimezone: this.#setTimeZone(userData.preferredTimezone),
    acceptLicense: userData.eulaAcceptedDate ? new Date(userData.eulaAcceptedDate) : undefined,
    mfaReminderDate:
      userData.mfaReminderDate !== null
        ? userData.mfaReminderDate
          ? new Date(userData.mfaReminderDate)
          : undefined
        : null,
    vida: {
      ...userData.vida,
      preferredPageSize: userData.vida?.preferredPageSize ? Number(userData.vida?.preferredPageSize) : 24,
      preferredAssetView:
        userData.vida?.preferredAssetView ?? this.#currentClientData.vida?.assetDefaultView ?? 'table',
      preferredDefaultTableView: userData.vida?.preferredDefaultTableView ?? 'table',
      finishedTour: userData.vida?.finishedTour ?? userData.finishedTour ?? false,
      openAssetPreviewInPopup: userData.vida?.openAssetPreviewInPopup ?? false,
      preferredTableView: userData.vida?.preferredTableView ?? {
        assetView: 'table',
        collectionsView: 'advanced',
        ordersView: 'advanced',
        downloadsView: 'table',
        licensedPackagesView: 'advanced',
        licenseesPartnersView: 'table',
        contractsView: 'advanced',
      },
      preferredDialogDrawerView: userData.vida?.preferredDialogDrawerView ?? 'drawer',
    },
  });

  #setTimeZone = (userPreferredTimezone: string | undefined) => {
    if (!this.#currentClientData.vida?.dateDefaultTimeZone) return 'local';
    let preferredTimezone: string;
    switch (this.#currentClientData.vida?.dateDefaultTimeZone) {
      case 'local':
        preferredTimezone = 'local';
        break;
      default:
        preferredTimezone =
          !userPreferredTimezone || userPreferredTimezone === 'client'
            ? this.#currentClientData.vida?.dateDefaultTimeZone
            : 'local';
        break;
    }
    return preferredTimezone;
  };

  updateAttributes = (data: UserUpdateAttributes) => {
    return this.currentAuthAttributesStateDefinite$.pipe(
      take(1),
      withLatestFrom(this.authStateService.emailDefinite$),
      switchMap(([current, email]) =>
        this.userContractService.saveUserData(email, {
          ...data,
          vida: {
            ...current.vida,
            ...data.vida,
          },
        }),
      ),
    );
  };

  updateProfile = async (
    data: Partial<{
      displayName: string;
      email: string;
    }>,
  ) => {
    const currentUser = await this.auth.currentUser;
    if (!currentUser) {
      return false;
    }

    await currentUser.updateProfile({ displayName: data.displayName, photoURL: null });
    if (data.email) {
      await currentUser.updateEmail(data.email);
    }

    await currentUser.reload();
    return true;
  };
}
