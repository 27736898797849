import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

export enum InfoBarType {
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
  INFO = 'info',
}

export enum InfoBarClickAction {
  CLOSE_EMIT = 'close_emit',
  CLOSE = 'close',
  EMIT = 'emit',
  DISABLE = 'disable',
}

@Component({
  selector: 'vdms-hq-ui-info-bar[type][message]',
  templateUrl: './info-bar.component.html',
  styleUrls: ['./info-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoBarComponent {
  @Input() type!: InfoBarType;
  @Input() message!: string;
  @Input() disableClose = false;
  @Input() clickAction: InfoBarClickAction = InfoBarClickAction.CLOSE;
  @Input() closeWithEmitAction = false;
  @Input() emitMsgTooltip = '';
  @Output() action = new EventEmitter<void>();

  closed = false;

  protected readonly InfoBarType = InfoBarType;
  protected readonly InfoBarClickAction = InfoBarClickAction;

  clickBar() {
    switch (this.clickAction) {
      case InfoBarClickAction.CLOSE_EMIT:
        this.action.emit();
        this.closed = true;
        break;
      case InfoBarClickAction.EMIT:
        this.action.emit();
        break;
      case InfoBarClickAction.DISABLE:
        this.closed = false;
        break;
      case InfoBarClickAction.CLOSE:
        this.closed = true;
        break;
    }
  }

  close() {
    if (this.closeWithEmitAction) {
      this.action.emit();
    }
    this.closed = true;
  }
}
