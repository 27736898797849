import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculateFoldersContainerHeight',
  standalone: true,
})
export class CalculateFoldersContainerHeightPipe implements PipeTransform {
  transform(numberOfItems?: number, perRow = 3): string {
    const rowHeight = 88;

    const maxRows = 15;
    const forItems = Math.min(numberOfItems ?? 1, maxRows * perRow);

    const rows = Math.ceil(forItems / perRow);
    const padding = 40;

    return rows * rowHeight + padding + 'px';
  }
}
