import { Injectable, inject } from '@angular/core';
import { FieldsOptionsService } from '@vdms-hq/api-contract';
import { map } from 'rxjs/operators';
import { SelectOption, Type } from '@vdms-hq/shared';
import { BehaviorSubject, Observable, shareReplay, switchMap, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DepartmentService {
  private fieldOptionsService = inject(FieldsOptionsService);
  private refreshTrigger$ = new BehaviorSubject<void>(undefined);

  departments$: Observable<Type> = this.refreshTrigger$.pipe(
    switchMap(() => this.fieldOptionsService.getTypeByName('department')),
    shareReplay(1),
  );

  departmentOptions$ = this.departments$.pipe(
    map<Type, SelectOption[]>((type) =>
      type.fields.map((field) => ({ key: field.uuid, label: field.label, allowed: field.have_discount })),
    ),
    map((options) => [{ key: '', label: 'N/A' }, ...options]),
  );

  refresh() {
    this.refreshTrigger$.next();
  }
}
