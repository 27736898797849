import { Component, Input } from '@angular/core';

@Component({
  selector: 'vdms-hq-ui-form-section-title',
  templateUrl: './form-section-title.component.html',
  styleUrls: ['./form-section-title.component.scss'],
})
export class FormSectionTitleComponent {
  @Input() title = '';
  @Input() size: 'small' | 'medium' | 'large' = 'small';
  @Input() icon?: string;
}
