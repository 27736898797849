import { Inject, Injectable } from '@angular/core';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { AuthorizedClient, ClientKeyLabel, ClientModel, ClientRef } from '../models/client.model';
import { ActivatedClientForHostnameService } from './activated-client-for-hostname.service';
import { filter, map, shareReplay, startWith, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { AuthService } from '@vdms-hq/auth';
import { filterEmpty, RefreshService } from '@vdms-hq/shared';
import { ClientContentStructure, ClientContractService } from '@vdms-hq/firebase-contract';
import { ACTIVATED_CLIENT_CONFIG_TOKEN, ActivatedClientConfig } from '../models/activated-client-config.model';

@Injectable({
  providedIn: 'root',
})
export class ActivatedClientService {
  constructor(
    private authService: AuthService,
    private refreshService: RefreshService,
    private clientsContractService: ClientContractService,
    private hostnameClientService: ActivatedClientForHostnameService,
    @Inject(ACTIVATED_CLIENT_CONFIG_TOKEN) private activatedClientConfig: ActivatedClientConfig,
  ) {}

  #authorizedClients: Observable<AuthorizedClient[]> = this.authService.clientsInfo$.pipe(
    switchMap((auth) =>
      this.clientsContractService
        .findClients$((client) => auth.clients.findIndex((authClient) => authClient.uuid === client.uuid) !== -1)
        .pipe(
          map((clients) =>
            clients.map((client) => ({
              ...client,
              permissions: auth.clients.find((item) => item.uuid === client.uuid)?.permissions ?? [],
            })),
          ),
        ),
    ),
    shareReplay(1),
  );

  #clientIdSubject$ = new ReplaySubject<ClientRef | null>(1);
  #clientId$ = this.#clientIdSubject$.asObservable();
  #clientIdDefinite$ = this.#clientIdSubject$.pipe(filterEmpty(), shareReplay(1));

  #client$: Observable<AuthorizedClient | undefined> = this.#clientId$.pipe(
    switchMap((clientId) =>
      this.#authorizedClients.pipe(
        filter((client) => client.length > 0),
        take(1),
        map((clients) => clients.find((client) => client.uuid === clientId)),
      ),
    ),
    shareReplay(1),
  );

  #clientDefinite$: Observable<AuthorizedClient> = this.#client$.pipe(filterEmpty(), shareReplay(1));

  #clientDefiniteValueChanges$: Observable<AuthorizedClient> = combineLatest([
    this.#authorizedClients,
    this.#clientId$,
  ]).pipe(
    map(([clients, clientId]) => clients.find((client) => client.uuid === clientId)),
    filterEmpty(),
    shareReplay(1),
  );

  clientId$: Observable<ClientRef | null | undefined> = this.#clientId$;
  clientIdDefinite$ = this.#clientIdDefinite$;

  /** @description This value emit if client id change. Undefined is filtered out. */
  clientDefinite$ = this.#clientDefinite$;

  /** @description Bg Shaded client Logo */
  backgroundShadedImageUrl$ = this.clientDefinite$.pipe(
    map((client) => client.contentCorner?.appearance?.backgroundShadedImageUrl),
  );

  /** @description This value emit if document change or client id change. Undefined is filtered out. */
  clientDefiniteValueChanges$ = this.#clientDefiniteValueChanges$;

  authorizedClientsOptions$: Observable<ClientKeyLabel[]> = this.#authorizedClients.pipe(
    map((items) => items.map(({ uuid: key, name: label }) => ({ key, label }))),
  );

  metadataRecognition$ = this.clientDefinite$.pipe(map((client) => client.metadataRecognition));
  vida$ = this.clientDefinite$.pipe(map((client) => client.vida));
  permissions$ = this.clientDefinite$.pipe(map((client) => client.permissions));
  integrations$ = this.clientDefinite$.pipe(map((client) => client.integrations));
  fields$ = this.clientDefinite$.pipe(map((client) => client.fields));
  fieldsValueChanges$ = this.clientDefiniteValueChanges$.pipe(map((client) => client.fields));
  clientColumns$ = this.clientDefinite$.pipe(map((client) => client?.columns));
  clientColumnsValueChanges$ = this.clientDefiniteValueChanges$.pipe(map((client) => client?.columns));
  searchConfig$ = this.clientDefiniteValueChanges$.pipe(map((client) => client.search));
  clientFilters$ = this.clientDefiniteValueChanges$.pipe(map((client) => client.filters));
  libraryConfigs$ = this.clientDefiniteValueChanges$.pipe(map((client) => client.libraryConfigs));
  snapshots$ = this.clientIdDefinite$.pipe(
    switchMap((clientId) => this.clientsContractService.getSnapshotCollection(clientId)),
  );
  snapshotLatestVersion$ = this.#clientDefinite$.pipe(
    switchMap((clientId) => this.clientsContractService.getLatestSnapshotVersion(clientId.uuid)),
  );
  userListHidden$ = this.clientDefinite$.pipe(map((client) => client.vida?.hideUsersList ?? false));
  coldStorageEnabled$ = this.clientDefinite$.pipe(map((client) => client.vida?.coldStorageEnabled ?? false));
  aiProcessingEnabled$ = this.clientDefinite$.pipe(map((client) => client.vida?.aiProcessingCheckoutEnabled ?? false));
  assetDefaultView$ = this.clientDefinite$.pipe(map((client) => client.vida?.assetDefaultView ?? 'table'));
  tableDefaultView$ = this.clientDefinite$.pipe(map((client) => client.vida?.tableDefaultView ?? 'table'));
  dateDefaultView$ = this.clientDefinite$.pipe(map((client) => client.vida?.dateDefaultTimeZone ?? 'local'));
  clientDiscountEnabled$ = this.clientDefinite$.pipe(map((client) => client.vida?.clientDiscountEnabled ?? false));
  advancedCheckoutDepartmentRequired$ = this.clientDefinite$.pipe(
    map((client) => client.vida?.advancedCheckoutDepartmentRequired ?? false),
  );
  downloadLimit$ = this.clientDefinite$.pipe(map((client) => client.vida?.downloadLimit ?? -1));
  isSalesforceEnabled$ = this.clientDefinite$.pipe(map((client) => client.integrations?.salesforce?.enabled ?? false));

  // Content Corner - Connect2 Settings
  ccConfig$ = this.clientDefinite$.pipe(map((client) => client.contentCorner));
  ccRestrictedPolicies$ = this.ccConfig$.pipe(
    map((config) => {
      const restrictedPolicies = new Set<string>([]);
      const structure: ClientContentStructure[] | undefined = config?.details?.structure;
      if (structure && structure.length > 0) {
        structure.forEach((item) => {
          if (item.restrictedToPolicies && item.restrictedToPolicies.length > 0) {
            item.restrictedToPolicies.forEach((policy) => restrictedPolicies.add(policy));
          }
        });
      }
      return [...restrictedPolicies];
    }),
  );

  /** @deprecated use clientColumns$  instead*/
  columnsConfig$ = this.clientDefinite$.pipe(map((client) => client.columnsConfig));

  /** @deprecated use clientColumnsValueChanges$ instead*/
  columnsConfigValueChanges$ = this.clientDefiniteValueChanges$.pipe(map((client) => client.columnsConfig));

  /** @deprecated use clientIdDefinite$ instead*/
  selectedClientId$ = this.clientIdDefinite$;

  /** @deprecated use clientDefinite$ instead*/
  selectedUserClient$ = this.clientDefinite$;

  clientOrHostnameFallbackClient$: Observable<ClientModel | undefined> = combineLatest([
    this.clientDefinite$.pipe(startWith(null)),
    this.hostnameClientService.client$,
  ]).pipe(map(([client, forHostname]) => client ?? forHostname));

  setActivatedClientId(nextClientId: ClientRef) {
    this.clientId$
      .pipe(
        take(1),
        switchMap((prevClientId) => {
          if (prevClientId === nextClientId) {
            return of();
          }

          this.#clientIdSubject$.next(nextClientId);

          return this.authService.updateAttributes({
            selectedClientId: nextClientId,
          });
        }),
      )
      .subscribe(() => this.refreshService.refresh());
  }

  /**
   * Sets a client on every auth change covering cases of
   * * non-existent / non-available (for user) clientId
   * * not saved (firebase) clientId
   * * defined for domain
   * @private
   */
  initialize(clientForCurrentHostname?: ClientModel): void {
    if (clientForCurrentHostname && !this.activatedClientConfig.allowChangeClientForHostname) {
      this.#clientIdSubject$.next(clientForCurrentHostname.uuid);
      return;
    }

    this.authService.clientsInfo$.subscribe((auth) => {
      if (auth.clients.length === 0) {
        this.#clientIdSubject$.next(null);
        return;
      }

      const id = auth.selectedClientId ?? clientForCurrentHostname?.uuid ?? auth.clients[0]?.uuid;

      this.#clientIdSubject$.next(id);
    });
  }

  update(client: Partial<ClientModel>) {
    return combineLatest({
      clientId: this.clientIdDefinite$,
      email: this.authService.email$,
      id: this.authService.id$,
    }).pipe(
      take(1),
      switchMap((res) =>
        this.clientsContractService.update(res.clientId, { ...client, user: { email: res.email, id: res.id } }),
      ),
    );
  }

  removeUsingPath(path: string) {
    return this.clientIdDefinite$.pipe(
      take(1),
      switchMap((id) => this.clientsContractService.removeUsingPath(id, path)),
    );
  }

  removeSnapshot(version: number) {
    return this.#clientIdDefinite$.pipe(
      take(1),
      switchMap((id) => this.clientsContractService.removeSnapshotFromCollection(id, version)),
    );
  }

  getFilters$ = (scopeName: string) => this.clientFilters$.pipe(map((filters) => filters?.[scopeName]));

  getColumns$ = (scopeName: string) =>
    combineLatest([this.clientColumnsValueChanges$, this.columnsConfig$]).pipe(
      map(
        ([clientColumns, columnsLegacy]) => clientColumns?.[scopeName] ?? clientColumns?.['default'] ?? columnsLegacy,
      ),
    );

  forwardEmailAndClientId = <T>(stream: (email: string, clientId: string) => Observable<T> | Promise<T>) =>
    this.authService.authDefinite$.pipe(
      withLatestFrom(this.clientIdDefinite$),
      switchMap(([user, clientId]) => stream(user.email, clientId)),
    );

  refresh(uuid: string) {
    this.#clientIdSubject$.next(uuid);
  }
}
