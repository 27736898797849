import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  InfoBarClickAction,
  InfoBarType,
  UIButtonModule,
  UIDialogWrapperModule,
  UIFormModule,
  UILayoutModule,
  UILoaderModule,
} from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CredentialsDatasourceService } from '../../logic/credentials-datasource.service';
import {
  CredentialsApiService,
  CredentialTypeEnum,
  GetCredentialInterface,
  PatchCredentialInputInterface,
  PostCredentialInputInterface,
  SecuredDataType,
  VisibleDataType,
  VisibleDataTypes,
} from '@vdms-hq/api-contract';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CredentialsTypeOptions } from '@vdms-hq/delivery-destinations';
import { v4 as uuidv4 } from 'uuid';
import { ToastService } from '@vdms-hq/toast';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LabelPipe } from '../../logic/label.pipe';
import { DestroyComponent } from '@vdms-hq/shared';
import { WasabiRegionsOptions } from '../../logic/wasabi-regions-options';

@Component({
  selector: 'vdms-hq-credentials-create-edit-dialog',
  standalone: true,
  imports: [
    CommonModule,
    UIDialogWrapperModule,
    TranslateModule,
    UILayoutModule,
    ReactiveFormsModule,
    UIButtonModule,
    UILoaderModule,
    UIFormModule,
    MatTooltipModule,
    LabelPipe,
  ],
  templateUrl: './credentials-create-edit-dialog.component.html',
  styles: [],
})
export class CredentialsCreateEditDialogComponent extends DestroyComponent implements OnInit {
  loading$ = new BehaviorSubject(true);
  hasCredentialData = false;
  credentialInfoBarMsg = '';

  credentialForm = new FormGroup({
    uuid: new FormControl<string | null>(null),
    name: new FormControl<string>('', Validators.required),
    type: new FormControl<CredentialTypeEnum | null>(null, Validators.required),
    visible_data: new FormGroup({}),
    secured_data: new FormGroup({}),
  });

  // hide the FTP option
  credentialTypes = CredentialsTypeOptions.filter(({ key }) => key !== CredentialTypeEnum.FTP);

  protected readonly InfoBarType = InfoBarType;
  protected readonly InfoBarClickAction = InfoBarClickAction;

  protected readonly WasabiRegionsOptions = WasabiRegionsOptions;

  get visibleData() {
    const visible = this.credentialForm.get('visible_data')?.value;

    if (!visible) {
      return null;
    }
    return Object.keys(visible);
  }

  get securedData() {
    const secured = this.credentialForm.get('secured_data')?.value;

    if (!secured) {
      return null;
    }
    return Object.keys(secured);
  }

  get setTooltip() {
    switch (this.type) {
      case CredentialTypeEnum.AWS:
        return 'common.credentials.form.secured_data_tooltip_secret_key';
      case CredentialTypeEnum.FTP:
        return 'common.credentials.form.secured_data_tooltip_password';
      default:
        return '';
    }
  }

  get uuid() {
    return this.data?.uuid;
  }

  get type() {
    return this.credentialForm.controls.type.value;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { uuid: string | undefined },
    private ref: MatDialogRef<CredentialsCreateEditDialogComponent>,
    private dataSource: CredentialsDatasourceService,
    private toast: ToastService,
    private credentialsApiService: CredentialsApiService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.data && this.data.uuid) {
      this.credentialsApiService.getByUuid(this.data.uuid).subscribe((credential) => {
        if (credential) {
          this.hasCredentialData = true;
          this.credentialForm.controls.name.setValue(credential.name);
          this.credentialForm.controls.type.setValue(credential.type);
          this.credentialForm.controls.type.disable();

          this.#setEditedVisibleControls(credential);
          this.#setNewSecuredControls();
        }
        this.loading$.next(false);
      });
    } else {
      this.credentialForm.controls.type.valueChanges.pipe(this.takeUntilDestroyed()).subscribe(() => {
        this.#setNewVisibleControls();
        this.#setNewSecuredControls();
      });
      this.hasCredentialData = false;
      this.loading$.next(false);
    }
  }

  close(success?: boolean) {
    this.ref.close(success);
  }

  save() {
    if (this.credentialForm.invalid) {
      return;
    }

    const credentialBase = {
      name: this.credentialForm.controls.name.value as string,
      type: this.credentialForm.controls.type.value as CredentialTypeEnum,
    };

    const credential: PatchCredentialInputInterface<CredentialTypeEnum> = {
      ...credentialBase,
      visible_data: {
        ...this.credentialForm.controls.visible_data.value,
        usedEarPassphrase: !!this.credentialForm.controls.visible_data.get('usedEarPassphrase')?.value,
      } as VisibleDataType<CredentialTypeEnum>,
      secured_data: this.credentialForm.controls.secured_data.value as SecuredDataType<CredentialTypeEnum>,
    };

    this.loading$.next(true);

    if (this.uuid) {
      this.credentialsApiService.update(this.uuid, credential).subscribe({
        next: () => {
          this.loading$.next(false);
          this.dataSource.refresh();
          this.toast.success({
            id: 'updated_credential_success',
            message: 'common.credentials.crud.update.success',
          });
          this.close(true);
        },
        error: (err) => {
          this.loading$.next(false);
          this.toast.error({
            id: 'updated_credential_error',
            message: 'common.credentials.crud.update.error',
          });
          throw err;
        },
      });
    } else {
      const data = { ...credential, uuid: uuidv4() };
      this.credentialsApiService.create(data as PostCredentialInputInterface<CredentialTypeEnum>).subscribe({
        next: () => {
          this.loading$.next(false);
          this.dataSource.refresh();
          this.toast.success({
            id: 'created_credential_success',
            message: 'common.credentials.crud.create.success',
          });
          this.close(true);
        },
        error: (err) => {
          this.loading$.next(false);
          this.toast.error({ id: 'created_credential_error', message: 'common.credentials.crud.create.error' });
          throw err;
        },
      });
    }
  }

  #setNewVisibleControls() {
    this.credentialForm.controls.visible_data = new FormGroup({});
    this.credentialForm.controls.visible_data.valueChanges
      .pipe(debounceTime(400), this.takeUntilDestroyed())
      .subscribe(() => {
        this.credentialForm.updateValueAndValidity();
      });

    switch (this.type) {
      case CredentialTypeEnum.AWS:
        this.credentialForm.controls.visible_data.addControl(
          'accessKeyId',
          new FormControl<string>('', Validators.required),
        );
        break;
      case CredentialTypeEnum.FTP:
        this.credentialForm.controls.visible_data.addControl(
          'username',
          new FormControl<string>('', Validators.required),
        );
        break;
      case CredentialTypeEnum.WASABI:
        this.credentialForm.controls.visible_data.addControl('host', new FormControl<string>('', Validators.required));
        this.credentialForm.controls.visible_data.addControl('name', new FormControl<string>('', Validators.required));
        this.credentialForm.controls.visible_data.addControl(
          'region',
          new FormControl<string>('', Validators.required),
        );
        this.credentialForm.controls.visible_data.addControl(
          'accessKeyId',
          new FormControl<string>('', Validators.required),
        );
        break;
      case CredentialTypeEnum.ASPERA:
        this.credentialForm.controls.visible_data.addControl(
          'apiUrl',
          new FormControl<string>('', Validators.required),
        );
        this.credentialForm.controls.visible_data.addControl(
          'apiPort',
          new FormControl<string>('', Validators.required),
        );
        this.credentialForm.controls.visible_data.addControl(
          'destinationRoot',
          new FormControl<string>('', Validators.required),
        );
        this.credentialForm.controls.visible_data.addControl(
          'username',
          new FormControl<string>('', Validators.required),
        );
        break;
      case CredentialTypeEnum.ASPERA_HSTS:
        this.credentialForm.controls.visible_data.addControl(
          'server',
          new FormControl<string>('', Validators.required),
        );
        this.credentialForm.controls.visible_data.addControl(
          'username',
          new FormControl<string>('', Validators.required),
        );
        this.credentialForm.controls.visible_data.addControl(
          'sshPort',
          new FormControl<number | null>(33001, Validators.required),
        );
        this.credentialForm.controls.visible_data.addControl('udpPort', new FormControl<number | null>(33001));
        this.credentialForm.controls.visible_data.addControl(
          'multiSession',
          new FormControl<number | null>(1, Validators.required),
        );
        break;
    }
  }

  #setNewSecuredControls() {
    this.credentialForm.controls.secured_data = new FormGroup({});
    this.credentialForm.controls.secured_data.valueChanges
      .pipe(debounceTime(400), this.takeUntilDestroyed())
      .subscribe(() => {
        this.credentialForm.updateValueAndValidity();
      });

    switch (this.type) {
      case CredentialTypeEnum.AWS:
      case CredentialTypeEnum.WASABI:
        this.credentialForm.controls.secured_data.addControl(
          'secretAccessKey',
          new FormControl<string>('', Validators.required),
        );
        break;
      case CredentialTypeEnum.FTP:
      case CredentialTypeEnum.ASPERA:
        this.credentialForm.controls.secured_data.addControl(
          'password',
          new FormControl<string>('', Validators.required),
        );
        break;
      case CredentialTypeEnum.ASPERA_HSTS:
        this.credentialForm.controls.secured_data.addControl(
          'password',
          new FormControl<string>('', Validators.required),
        );
        this.credentialForm.controls.secured_data.addControl('sshPrivateKey', new FormControl<string>(''));
        this.credentialForm.controls.secured_data.addControl('earPassphrase', new FormControl<string>(''));
        break;
    }
  }

  #setEditedVisibleControls(credential: GetCredentialInterface<CredentialTypeEnum>) {
    let credentialVisible = null;

    switch (credential.type) {
      case CredentialTypeEnum.AWS:
        credentialVisible = credential.visible_data as VisibleDataTypes[CredentialTypeEnum.AWS];
        this.credentialInfoBarMsg = `Provided Access Key Id: ${credentialVisible.accessKeyId}`;

        this.credentialForm.controls.visible_data.addControl(
          'accessKeyId',
          new FormControl<string>(credentialVisible.accessKeyId, Validators.required),
        );
        break;
      case CredentialTypeEnum.FTP:
        credentialVisible = credential.visible_data as VisibleDataTypes[CredentialTypeEnum.FTP];
        this.credentialInfoBarMsg = `Provided Username: ${credentialVisible.username}`;

        this.credentialForm.controls.visible_data.addControl(
          'username',
          new FormControl<string>(credentialVisible.username, Validators.required),
        );
        break;
      case CredentialTypeEnum.ASPERA:
        credentialVisible = credential.visible_data as VisibleDataTypes[CredentialTypeEnum.ASPERA];
        this.credentialInfoBarMsg = `Provided Username: ${credentialVisible.username}`;

        this.credentialForm.controls.visible_data.addControl(
          'apiUrl',
          new FormControl<string>(credentialVisible.apiUrl, Validators.required),
        );
        this.credentialForm.controls.visible_data.addControl(
          'apiPort',
          new FormControl<string>(credentialVisible.apiPort, Validators.required),
        );
        this.credentialForm.controls.visible_data.addControl(
          'destinationRoot',
          new FormControl<string>(credentialVisible.destinationRoot, Validators.required),
        );
        this.credentialForm.controls.visible_data.addControl(
          'username',
          new FormControl<string>(credentialVisible.username, Validators.required),
        );
        break;
      case CredentialTypeEnum.ASPERA_HSTS:
        credentialVisible = credential.visible_data as VisibleDataTypes[CredentialTypeEnum.ASPERA_HSTS];
        this.credentialInfoBarMsg = `Provided Username: ${credentialVisible.username}`;

        this.credentialForm.controls.visible_data.addControl(
          'server',
          new FormControl<string>(credentialVisible.server, Validators.required),
        );
        this.credentialForm.controls.visible_data.addControl(
          'username',
          new FormControl<string>(credentialVisible.username, Validators.required),
        );
        this.credentialForm.controls.visible_data.addControl(
          'sshPort',
          new FormControl<number>(credentialVisible.sshPort, Validators.required),
        );
        this.credentialForm.controls.visible_data.addControl(
          'udpPort',
          new FormControl<number>(credentialVisible.udpPort, Validators.required),
        );
        this.credentialForm.controls.visible_data.addControl(
          'multiSession',
          new FormControl<number>(credentialVisible.multiSession, Validators.required),
        );
        break;
      case CredentialTypeEnum.WASABI:
        credentialVisible = credential.visible_data as VisibleDataTypes[CredentialTypeEnum.WASABI];
        this.credentialInfoBarMsg = `Provided Access Key Id: ${credentialVisible.accessKeyId}`;

        this.credentialForm.controls.visible_data.addControl(
          'host',
          new FormControl<string>(credentialVisible.host, Validators.required),
        );
        this.credentialForm.controls.visible_data.addControl(
          'name',
          new FormControl<string>(credentialVisible.name, Validators.required),
        );
        this.credentialForm.controls.visible_data.addControl(
          'region',
          new FormControl<string>(credentialVisible.region, Validators.required),
        );
        this.credentialForm.controls.visible_data.addControl(
          'accessKeyId',
          new FormControl<string>(credentialVisible.accessKeyId, Validators.required),
        );
        break;
    }
  }
}
