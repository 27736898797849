import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { AdvancedSearchInstructionComponent } from '../advanced-search-instruction/advanced-search-instruction.component';
import { AdvancedSearchStateService } from '../../logic/advanced-search-state.service';
import { UIFormModule } from '../../../ui-form';

@Component({
  selector: 'vdms-hq-advanced-search-overlay',
  standalone: true,
  imports: [CommonModule, UIFormModule],
  providers: [],
  styleUrls: ['advanced-search-overlay.component.scss'],
  templateUrl: './advanced-search-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvancedSearchOverlayComponent {
  state$ = inject(AdvancedSearchStateService);
  dialog = inject(MatDialog);

  detailedInstruction() {
    this.dialog.open(AdvancedSearchInstructionComponent);
  }
}
