import { SelectOption } from '@vdms-hq/shared';
import { CredentialTypeEnum } from '@vdms-hq/api-contract';

export const CredentialsTypeOptions: SelectOption[] = [
  {
    key: CredentialTypeEnum.AWS,
    label: 'AWS',
  },
  {
    key: CredentialTypeEnum.WASABI,
    label: 'WASABI',
  },
  {
    key: CredentialTypeEnum.ASPERA,
    label: 'ASPERA',
  },
  {
    key: CredentialTypeEnum.ASPERA_HSTS,
    label: 'ASPERA HSTS',
  },
  {
    key: CredentialTypeEnum.FTP,
    label: 'FTP',
  },
];

export const TranscodeDeliveryDestinationTypesOptions: SelectOption[] = [
  {
    key: 'youtube-vimeo',
    label: 'Youtube-Vimeo',
  },
  {
    key: 'itunes',
    label: 'iTunes',
  },
  {
    key: 'hls',
    label: 'HLS',
  },
];

export const ResolutionsOptions: SelectOption[] = [
  {
    key: 'DCI 8k',
    label: 'DCI 8k',
  },
  {
    key: 'DCI 4k',
    label: 'DCI 4k',
  },
  {
    key: 'DCI 2k',
    label: 'DCI 2k',
  },
  {
    key: 'UHD 8k',
    label: 'UHD 8k',
  },
  {
    key: 'UHD 5k',
    label: 'UHD 5k',
  },
  {
    key: 'UHD 4k',
    label: 'UHD 4k',
  },
  {
    key: 'HD 1080',
    label: 'HD 1080',
  },
  {
    key: 'HD 720',
    label: 'HD 720',
  },
  {
    key: 'SD PAL',
    label: 'SD PAL',
  },
  {
    key: 'SD NTSC',
    label: 'SD NTSC',
  },
  {
    key: 'Other',
    label: 'Other',
  },
];

export const AwsRegionsOptions: SelectOption[] = [
  { key: 'us-east-2', label: 'US East (us-east-2)' },
  { key: 'us-east-1', label: 'US East (us-east-1)' },
  { key: 'us-west-1', label: 'US West (us-west-1)' },
  { key: 'us-west-2', label: 'US West (us-west-2)' },
  { key: 'af-south-1', label: 'Africa (af-south-1)' },
  { key: 'ap-east-1', label: 'Asia Pacific (ap-east-1)' },
  { key: 'ap-south-2', label: 'Asia Pacific (ap-south-2)' },
  { key: 'ap-southeast-3', label: 'Asia Pacific (ap-southeast-3)' },
  { key: 'ap-southeast-4', label: 'Asia Pacific (ap-southeast-4)' },
  { key: 'ap-south-1', label: 'Asia Pacific (ap-south-1)' },
  { key: 'ap-northeast-3', label: 'Asia Pacific (ap-northeast-3)' },
  { key: 'ap-northeast-2', label: 'Asia Pacific (ap-northeast-2)' },
  { key: 'ap-southeast-1', label: 'Asia Pacific (ap-southeast-1)' },
  { key: 'ap-southeast-2', label: 'Asia Pacific (ap-southeast-2)' },
  { key: 'ap-northeast-1', label: 'Asia Pacific (ap-northeast-1)' },
  { key: 'ca-central-1', label: 'Canada (ca-central-1)' },
  { key: 'ca-west-1', label: 'Canada West (ca-west-1)' },
  { key: 'eu-central-1', label: 'Europe (eu-central-1)' },
  { key: 'eu-west-1', label: 'Europe (eu-west-1)' },
  { key: 'eu-west-2', label: 'Europe (eu-west-2)' },
  { key: 'eu-south-1', label: 'Europe (eu-south-1)' },
  { key: 'eu-west-3', label: 'Europe (eu-west-3)' },
  { key: 'eu-south-2', label: 'Europe (eu-south-2)' },
  { key: 'eu-north-1', label: 'Europe (eu-north-1)' },
  { key: 'eu-central-2', label: 'Europe (eu-central-2)' },
  { key: 'il-central-1', label: 'Israel (il-central-1)' },
  { key: 'me-south-1', label: 'Middle East (me-south-1)' },
  { key: 'me-central-1', label: 'Middle East (me-central-1)' },
  { key: 'sa-east-1', label: 'South America (sa-east-1)' },
  { key: 'us-gov-east-1', label: 'AWS GovCloud (us-gov-east-1)' },
  { key: 'us-gov-west-1', label: 'AWS GovCloud (us-gov-west-1)' },
];
