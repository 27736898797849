import { FieldsConfigService } from '@vdms-hq/config';
import { map } from 'rxjs/operators';
import { DataSelector, ResultDefinitionModel } from '@vdms-hq/shared';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataColumn, GridAdvancedAdditionalInfo, GridAdvancedMetadata } from '@vdms-hq/ui';
import { ContractListItem } from '@vdms-hq/api-contract';

@Injectable({
  providedIn: 'root',
})
export class RightContractsFieldsConfigService extends FieldsConfigService {
  tableColumnsConfig$: Observable<{
    all: DataColumn[];
    enabled: string[];
  }> = this.tableConfigForScope$('other-rights-contracts').pipe(
    map((config) => ({
      all: this.convertToColumns(config.all),
      enabled: this.convertToIds(config.defaultList),
    })),
  );

  exportColumnsConfig$: Observable<DataSelector[]> = this.tableConfigForScope$('other-rights-contracts').pipe(
    map((config) =>
      config.defaultList.map((field) => ({
        label: field.results2.objectPath,
        value: field.results2.exportObjectPath ?? field.results2.objectPath,
      })),
    ),
  );

  gridConfig$: Observable<{
    metadata: GridAdvancedMetadata<ContractListItem>[];
    additional: GridAdvancedAdditionalInfo;
  }> = this.listConfigForScope$('other-rights-contracts').pipe(
    map((config) => {
      const additinalDef = config.all.find((def) => def.id === 'contractPartnerName') as ResultDefinitionModel;

      return {
        metadata: this.convertToGridAdvancedArrayMetadata<ContractListItem>(config.defaultList),
        additional: this.convertToGridAdvancedMetadata(additinalDef),
      };
    }),
  );
}
