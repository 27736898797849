import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UIButtonModule, UIConfirmationDialogService, UIFormModule, UILayoutModule } from '@vdms-hq/ui';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastService } from '@vdms-hq/toast';
import { AssetApiService } from '@vdms-hq/api-contract';
import { filter, switchMap, take } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedClientModule, Permission } from '@vdms-hq/activated-client';

@Component({
  selector: 'vdms-hq-landingpad',
  standalone: true,
  imports: [CommonModule, TranslateModule, UILayoutModule, UIFormModule, UIButtonModule, ActivatedClientModule],
  templateUrl: './landingpad.component.html',
  styleUrls: ['./landingpad.component.scss'],
})
export class LandingpadComponent {
  protected readonly Permission = Permission;
  private toast = inject(ToastService);
  private assetApi = inject(AssetApiService);
  private confirmationDialog = inject(UIConfirmationDialogService);
  private translate = inject(TranslateService);

  isLoading = false;
  form = new FormGroup({
    ingest: new FormControl<string | null>(null),
    remove: new FormControl<string | null>(null),
  });

  export() {
    this.confirmationDialog
      .open({
        title: this.translate.instant('common.landingpad.actions.confirmation.title'),
        message: this.translate.instant('common.landingpad.actions.confirmation.message'),
      })
      .pipe(
        filter((confirmed) => confirmed),
        switchMap(() => this.assetApi.landingpadExport().pipe(take(1))),
      )
      .subscribe({
        next: () => {
          this.isLoading = false;
          this.toast.success({
            id: 'export_objects_success',
            message: 'notifications.export.done',
          });
        },
        error: (error: HttpErrorResponse) => {
          this.isLoading = false;
          console.error(error);

          this.toast.error({
            id: 'export_objects_error',
            message: 'common.landingpad.toast.remove_objects_error',
            interpolatedParams: { error: error.message },
          });
        },
      });
  }

  save() {
    this.isLoading = true;

    if (!this.form.valid) {
      return;
    }

    const ingestObjects = this.form.get('ingest')?.value;
    const removeObjects = this.form.get('remove')?.value;

    const ingestObjectsArray = ingestObjects?.split('\n').filter((item) => item.trim());
    const removeObjectsArray = removeObjects?.split('\n').filter((item) => item.trim());

    if (ingestObjectsArray?.length) {
      this.assetApi
        .landingpadIngest({
          filekeys: ingestObjectsArray,
        })
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.isLoading = false;
            this.toast.success({
              id: 'ingest_objects_success',
              message: 'common.landingpad.toast.ingest_objects_success',
            });
          },
          error: (error: HttpErrorResponse) => {
            this.isLoading = false;
            console.error(error);

            this.toast.error({
              id: 'ingest_objects_error',
              message: 'common.landingpad.toast.remove_objects_error',
              interpolatedParams: { error: error.message },
            });
          },
        });
    }

    if (removeObjectsArray?.length) {
      this.assetApi
        .landingpadDelete({
          filekeys: removeObjectsArray,
        })
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.isLoading = false;
            this.toast.success({
              id: 'remove_objects_success',
              message: 'common.landingpad.toast.remove_objects_success',
            });
          },
          error: (error: HttpErrorResponse) => {
            this.isLoading = false;
            console.error(error);

            this.toast.error({
              id: 'remove_objects_error',
              message: 'common.landingpad.toast.remove_objects_error',
              interpolatedParams: { error: error.message },
            });
          },
        });
    }
  }
}
