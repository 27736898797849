<div
  [class.with-touched-indicator]="withTouchedIndicator"
  [sharedE2eId]="'form-input-checkbox'"
  class="material-custom-field checkbox-outer"
>
  <mat-checkbox (blur)="propagateTouch()" [formControl]="innerFormControl" [required]="isRequired">
    {{ label }}
  </mat-checkbox>
  <div *ngIf="withFooter" class="footer">
    <mat-hint *ngIf="withFooter && hint">{{ hint }}</mat-hint>
    <mat-error *ngIf="withFooter && innerFormControl.touched">
      <span *ngFor="let error of errors">{{ error.transLang | translate : error.contextParams }}</span>
    </mat-error>
  </div>
</div>
