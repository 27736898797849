import { Component, Input, ChangeDetectionStrategy, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormSectionComponent, UIFormModule, UIButtonModule, ValueRendererComponent } from '@vdms-hq/ui';
import { ClientUserAdminDialogDataSource } from '../../logic/client-user-admin-dialog-datasource';
import { SharedModule, PillColorEnum } from '@vdms-hq/shared';
import { ObjectPipesModule } from 'ng-pipes';
import { ClientUserAdminDialogService } from '../../logic/client-user-admin-dialog.service';

@Component({
  standalone: true,
  selector: 'vdms-hq-manage-policies-list[dataSource]',
  templateUrl: './manage-policies-list.component.html',
  styleUrls: ['./manage-policies-list.component.scss'],
  imports: [
    CommonModule,
    FormSectionComponent,
    UIFormModule,
    SharedModule,
    UIButtonModule,
    ObjectPipesModule,
    ValueRendererComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManagePoliciesListComponent {
  private clientUserAdminDialogService = inject(ClientUserAdminDialogService);

  @Input() dataSource!: ClientUserAdminDialogDataSource;
  @Input() copyEnabled = true;

  formatConfig = {
    pills: {
      default: PillColorEnum.ColdBlue,
    },
  };

  copyPoliciesFrom(userUuid: string) {
    this.clientUserAdminDialogService.copyPoliciesFrom(userUuid);
  }
}
