import { Pipe, PipeTransform } from '@angular/core';
import { pairWithSelectOption, SelectOption } from '@vdms-hq/shared';
import { FieldsConfigService } from '@vdms-hq/config';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ClientContentStructure } from '@vdms-hq/firebase-contract';

@Pipe({
  name: 'enabledList',
  standalone: true,
})
export class EnabledListPipe implements PipeTransform {
  constructor(private activatedClientConfigService: FieldsConfigService) {}

  transform(structure: ClientContentStructure): Observable<SelectOption[]> {
    return this.activatedClientConfigService.filterAggregationsDefinitionsAsOptions$.pipe(
      take(1),
      map((all) => pairWithSelectOption(structure?.fields, all)),
    );
  }
}
