<div class="advanced--search">
  <h2 class="advanced--search__title">Advanced search</h2>
  <p class="advanced--search__content">
    Optimize your search with advanced query capabilities, allowing you to use logical operators like AND, OR, NOT, and
    more. <span (click)="detailedInstruction()">Click here</span> for detailed instructions on how to utilize these
    features effectively.
  </p>
  <div class="advanced--search__action">
    <vdms-hq-ui-form-input-toggle
      [formControl]="state$.state"
      label="Enable Advanced Search"
    ></vdms-hq-ui-form-input-toggle>
  </div>
</div>
