import { AsyncPipe, NgIf } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { TranslateModule } from '@ngx-translate/core';
import {
  AssetActionsService,
  AssetResults2Component,
  assetResultsMenuConfig2,
  ContextMenuActionsService,
  ResultsActions,
} from '@vdms-hq/asset-results';
import { CartAssetViewModel, CartStateService } from '@vdms-hq/cart-core';
import { CollectionsActionsService } from '@vdms-hq/collections';
import { ColumnSettingsScope } from '@vdms-hq/firebase-contract';
import { ActionContextLess, DataAction, DataPresentationHeaderComponent, UIButtonModule } from '@vdms-hq/ui';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CartCheckoutActionsComponent } from '../../components/cart-checkout-actions/cart-checkout-actions.component';
import { CartResultsActionsComponent } from '../../components/cart-results-actions/cart-results-actions.component';
import { CartSummaryComponent } from '../../components/cart-summary/cart-summary.component';
import { CartDataSource } from '../../logic/data-sources/cart-data-source';
import { CartActionsService } from '../../logic/services/cart-actions.service';
import { CartCheckoutService } from '../../logic/services/cart-checkout.service';
import { CartCheckoutFormService } from '../../logic/services/cart-checkout-form.service';

@Component({
  selector: 'vdms-hq-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  standalone: true,
  imports: [
    DataPresentationHeaderComponent,
    AsyncPipe,
    TranslateModule,
    AssetResults2Component,
    CartCheckoutActionsComponent,
    NgIf,
    CartResultsActionsComponent,
    MatDividerModule,
    UIButtonModule,
    CartSummaryComponent,
  ],
})
export class CartComponent implements OnInit, OnDestroy {
  private readonly collectionsActionsService = inject(CollectionsActionsService);
  private readonly cartActionsService = inject(CartActionsService);
  private readonly assetActionsService = inject(AssetActionsService);
  private readonly cartCheckoutService = inject(CartCheckoutService);
  private readonly cartFormService = inject(CartCheckoutFormService);
  private contextMenuActions = inject(ContextMenuActionsService);
  readonly dataSource = inject(CartDataSource);
  readonly cartState = inject(CartStateService);

  protected readonly assetResultsMenuConfig2 = assetResultsMenuConfig2;
  readonly scopeName: ColumnSettingsScope = 'cart';

  actions$: Observable<DataAction<CartAssetViewModel>[]> = combineLatest([
    this.collectionsActionsService.buildAddAssetToCollectionAction$<CartAssetViewModel>(),
    this.cartActionsService.buildRemoveAction$<CartAssetViewModel>(),
    this.assetActionsService.buildPreviewAction$<CartAssetViewModel>(),
  ]).pipe(
    map<any, DataAction<CartAssetViewModel>[]>((actions) =>
      actions.filter((action: DataAction<CartAssetViewModel> | null) => action !== null),
    ),
  );

  actionsConfig$ = this.cartCheckoutService.actionsConfig$;

  headerActions$: Observable<ActionContextLess[]> = combineLatest([
    this.collectionsActionsService.buildAddCartCollection$(),
  ]).pipe(
    map<any, ActionContextLess[]>((actions) => actions.filter((action: ActionContextLess | null) => action !== null)),
  );

  ngOnInit() {
    this.cartFormService.form.controls.deliveryMethod.setValue(null);
    this.cartState.checkoutStep$.next(0);
    this.dataSource.cartSummary$.next(null);
    this.dataSource.changePageIndex$.next(0);
  }

  ngOnDestroy() {
    this.dataSource.selection.clear();
  }

  handleAction($event: { id: string; item?: CartAssetViewModel }) {
    const mapToLegacyActionEvent = (event: { id: string; item?: CartAssetViewModel }) => ({
      key: event.id,
      item: event.item,
    });

    this.contextMenuActions.handleAction(mapToLegacyActionEvent($event));
    this.assetActionsService.handlePreviewAction<CartAssetViewModel>(mapToLegacyActionEvent($event));

    this.collectionsActionsService.handleAddAssetToCollectionAction<CartAssetViewModel>(mapToLegacyActionEvent($event));
    this.collectionsActionsService.handleAddCartCollection(mapToLegacyActionEvent($event));

    this.cartActionsService.handleRemoveAction<CartAssetViewModel>(mapToLegacyActionEvent($event));
    this.cartActionsService.handleCheckoutAction(mapToLegacyActionEvent($event));
    this.cartActionsService.applyDiscountCode(mapToLegacyActionEvent($event));
    this.cartActionsService.resetDiscountCode(mapToLegacyActionEvent($event));

    //todo get rid of this switch and use the above pattern
    switch ($event.id) {
      case ResultsActions.ADD_TO_COLLECTION:
        this.dataSource.selection.entities$
          .pipe(
            take(1),
            map((cartEntities) => cartEntities.map((cartEntity) => cartEntity.props.uuid)),
          )
          .subscribe((assetUuids) => {
            this.collectionsActionsService.addAssetsToCollection(assetUuids);
          });
        break;
      case ResultsActions.REMOVE_ALL:
        this.cartActionsService.removeAllItems();
        break;
      case ResultsActions.DELETE:
        this.cartActionsService.removeCartItems(this.dataSource.selection.identifiers$.value.map(String));
        break;
      case ResultsActions.DESELECT_ALL:
        this.dataSource.selection.clear();
        break;
    }
  }
}
