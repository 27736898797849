<div class="ui-sn__layout" [ngClass]="state$ | async">
  <div class="ui-sn__navbar" #navbar>
    <ng-content select="[navbar]"></ng-content>
  </div>
  <div class="ui-sn__main">
    <div class="ui-sn__sidebar" [style.top]="navbarHeight + 'px'">
      <ng-content select="[sidebar]"></ng-content>
    </div>
    <div
      class="ui-sn__root"
      [style.height]="calcNavbarHeight"
      [style.overflow-y]="disableVerticalScroll ? 'hidden' : 'auto'"
      [style.margin-top]="navbarHeight + 'px'"
    >
      <ng-content></ng-content>
    </div>
  </div>
</div>
