import { Injectable, inject, TrackByFunction } from '@angular/core';
import {
  ClientUserAdminService,
  VidaAppType,
  ClientUserAdminView,
  PaginationAPIModel as Pagination,
} from '@vdms-hq/api-contract';
import { RouterParamsPagination } from '@vdms-hq/view-settings';
import {
  Selection,
  RefreshService,
  SortEvent,
  SelectableDataSource,
  SelectionManager,
  LoadableDataSource,
} from '@vdms-hq/shared';
import {
  Observable,
  combineLatest,
  switchMap,
  catchError,
  tap,
  BehaviorSubject,
  map,
  takeUntil,
  Subject,
  shareReplay,
  startWith,
  EMPTY,
  debounceTime,
} from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastService } from '@vdms-hq/toast';
import { ActivatedClientService } from '@vdms-hq/activated-client';

@Injectable({ providedIn: 'root' })
export class ClientUserAdminDatasource
  extends RouterParamsPagination
  implements LoadableDataSource, SelectableDataSource<ClientUserAdminView>
{
  private clientUserAdminService = inject(ClientUserAdminService);
  private refreshService = inject(RefreshService);
  private toastService = inject(ToastService);
  private activatedClientService = inject(ActivatedClientService);

  readonly defaultPerPage = 192;
  readonly defaultPage = 0;

  #destroy$ = new Subject<void>();
  isLoading$ = new BehaviorSubject(true);

  refresh$ = this.refreshService.refresh$;

  filters = new FormGroup({
    app: new FormControl<VidaAppType | null>(null),
    text: new FormControl<string>('', { nonNullable: true }),
    policy: new FormControl<string>('', { nonNullable: true }),
  });

  sortBy$ = new BehaviorSubject<string>('name');
  sortDirection$ = new BehaviorSubject<'asc' | 'desc'>('asc');

  responseData$ = combineLatest([
    this.refresh$,
    this.pageIndex$,
    this.pageSize$,
    this.sortBy$,
    this.sortDirection$,
    this.filters.valueChanges.pipe(startWith(this.filters.value), debounceTime(300)),
    this.activatedClientService.clientIdDefinite$.pipe(tap(() => this.resetFilters())),
  ]).pipe(
    switchMap(([, page, perPage, orderBy, orderDir, filters, clientId]) => {
      this.isLoading$.next(true);
      const pagination = Pagination.create({ page, perPage, orderBy, orderDir });

      return this.clientUserAdminService.getAllUsersForGroup(pagination, filters);
    }),
    shareReplay(1),
  );
  emptyResults$ = this.responseData$.pipe(map((data) => data.total === 0));
  total$ = this.responseData$.pipe(map((data) => data.total));

  allData$: Observable<ClientUserAdminView[]> = this.responseData$.pipe(
    map(({ data }) => data.map((user) => ClientUserAdminView.fromClientUserType(user))),
    tap(() => this.isLoading$.next(false)),
    takeUntil(this.#destroy$),
    catchError((error) => {
      this.toastService.error({
        id: 'error',
        message: 'An error occurred while fetching data. Please try again.',
      });
      return EMPTY;
    }),
  );

  connection$ = this.allData$;

  trackBy?: TrackByFunction<any> | undefined;
  selection: Selection<ClientUserAdminView> = new SelectionManager<ClientUserAdminView>(this, (item) => item.uuid);

  constructor() {
    super();
  }

  disconnect() {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  sortChange($event: SortEvent) {
    this.sortBy$.next($event.active);
    this.sortDirection$.next($event.direction || 'asc');
  }

  refresh() {
    this.refreshService.refresh();
  }

  resetFilters() {
    this.filters.reset();
  }
}
