import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AssetFlatView2 } from './asset-flat-view-2.model';
import { AssetFlatView } from '../services/assets-updater.service';

export const StreamAssetsToViewModel = (
  allAssets: AssetFlatView2[],
): ((stream: Observable<Array<AssetFlatView>>) => Observable<AssetFlatView2[]>) => {
  return (stream: Observable<Array<AssetFlatView>>) =>
    stream.pipe(
      map((updatedAssets) => {
        const newValue = allAssets.map((asset) => {
          const newValueExist = updatedAssets.find((updatedAsset) => updatedAsset?.uuid === asset?.props?.uuid);
          if (newValueExist) {
            asset.updateProps(newValueExist);
          }
          return asset;
        });
        return newValue;
      }),
    );
};
