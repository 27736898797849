import { Injectable, inject } from '@angular/core';
import { ClientUserAdminDialogDataSource } from './client-user-admin-dialog-datasource';
import { take, map, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ClientUserAdminDialogService {
  private dataSource = inject(ClientUserAdminDialogDataSource);

  copyPoliciesFrom(userUuid: string) {
    this.dataSource.connection$
      .pipe(
        take(1),
        map(({ users, policies }) => {
          const user = users.find((user) => user.uuid === userUuid);
          const userPolicies = user?.policies;
          const userPoliciesUuids = userPolicies?.map((policy) => policy.uuid) || [];
          return policies.filter((policy) => {
            return userPoliciesUuids.includes(policy.uuid) && !policy.isSuperAdmin && !policy.isCrossGroup;
          });
        }),
        tap((policies) => {
          this.dataSource.emitSelectedPolicies(policies || []);
        }),
      )
      .subscribe();
  }
}
