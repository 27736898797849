import { Injectable } from '@angular/core';
import {
  GetEmailTemplateContentConfigInterface,
  PatchEmailTemplateContentConfigInterface,
} from '../notification-settings/notification-settings.model';
import { ApiService } from '../api.service';
import { ApiResponse } from '../api.model';
import { GetResponseData } from '../../operators/get-response-data.operator';

enum NOTIFICATION_SETTINGS_ENDPOINTS {
  EMAIL_TEMPLATES = 'email-template-content-config',
}

@Injectable({ providedIn: 'root' })
export class EmailTextApiService {
  constructor(private readonly apiService: ApiService) {}

  getEmailTemplates() {
    return this.apiService
      .get<ApiResponse<GetEmailTemplateContentConfigInterface[]>>(`${NOTIFICATION_SETTINGS_ENDPOINTS.EMAIL_TEMPLATES}`)
      .pipe(GetResponseData);
  }

  updateEmailTemplates(uuid: string, payload: PatchEmailTemplateContentConfigInterface) {
    return this.apiService.patch<PatchEmailTemplateContentConfigInterface, void>(
      `${NOTIFICATION_SETTINGS_ENDPOINTS.EMAIL_TEMPLATES}/${uuid}`,
      payload,
    );
  }
}
