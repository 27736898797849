import { Injectable } from '@angular/core';
import { MultipleViewDataSource } from '@vdms-hq/ui';
import { BehaviorSubject, combineLatest, debounceTime, EMPTY, Observable, switchMap } from 'rxjs';
import { ToastService } from '@vdms-hq/toast';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import {
  FlatOrder,
  OrderDeliveryStatus,
  OrderService,
  OrdersType,
  PaginationAPIModel as Pagination,
} from '@vdms-hq/api-contract';
import { FiltersOrderForm } from './filters-order-form';
import { StorageUrlService } from '@vdms-hq/storage';
import { DatePipe } from '@angular/common';
import moment from 'moment-timezone';
import { DELIVERY_STATUS } from '../../logic/orders-filters-provider';
import { RouterParamsPagination } from '@vdms-hq/view-settings';
import {
  ClientCurrencyPipe,
  GroupCurrencyISO,
  PageableDataSource,
  RenamePipe,
  SelectionManager,
} from '@vdms-hq/shared';

export class FlatOrderViewModel {
  get uuid() {
    return this.props.uuid;
  }

  constructor(
    private props: FlatOrder,
    private deps: {
      storageService: StorageUrlService;
      datePipe: DatePipe;
      renamePipe: RenamePipe;
    },
  ) {}

  static createFromFlatOrder(
    props: FlatOrder,
    deps: {
      storageService: StorageUrlService;
      datePipe: DatePipe;
      renamePipe: RenamePipe;
    },
  ): FlatOrderViewModel {
    return new FlatOrderViewModel(props, deps);
  }

  get background() {
    return this.deps.storageService.updateCdn(this.props.thumbnail) ?? '';
  }

  get title() {
    const titlePrefix = this.props.media_pulse_id ? this.props.media_pulse_id + ' - ' : '';
    const titleSuffix = this.props.package_title
      ? this.props.package_title
      : this.deps.datePipe.transform(this.props.created_at, 'dd-MM-yyyy');

    return `${titlePrefix}${titleSuffix}`;
  }

  get createdAt() {
    return this.props?.created_at ?? null;
  }

  get totalBytes() {
    return this.props?.total_bytes ?? null;
  }

  get totalDownloadedAssets() {
    return this.props?.total_downloaded_assets ?? null;
  }

  get totalDownloadableAssets() {
    return this.props?.total_downloadable_assets ?? null;
  }

  get totalPrice() {
    const totalPrice = this.props?.total_price;
    if (totalPrice !== 0 && !totalPrice) {
      return null;
    }

    return ClientCurrencyPipe.transform(totalPrice, this.props?.currency ?? GroupCurrencyISO.USD);
  }

  get packageTitle() {
    return this.props?.package_title ?? null;
  }

  get salesForceName() {
    return this.props?.sales_force_name ?? null;
  }

  get deliveryEmails() {
    return this.props?.delivery_emails ?? null;
  }

  get totalAssets() {
    return this.props?.total_assets ?? null;
  }

  get purchaseOrderNo() {
    return this.props?.purchase_order_no ?? null;
  }

  get sharedBy() {
    return this.props?.shared_by ?? null;
  }

  get orderSender() {
    return this.props?.shared_by ?? null;
  }

  get orderShared() {
    return this.props?.delivery_emails ? this.props.delivery_emails.split(', ') : null;
  }

  get deliveryDate() {
    return this.props?.delivery_date ?? null;
  }

  get mediaPulseId() {
    return this.props?.media_pulse_id ?? null;
  }

  get usedDepartment() {
    return this.props?.used_department ?? null;
  }

  get status() {
    return this.props?.status ?? null;
  }

  get expiryDate() {
    return this.props?.expires_at ?? null;
  }

  get totalSize() {
    return this.props?.total_bytes ? Number(this.props.total_bytes) : null;
  }

  get comment() {
    return this.props?.comment ?? null;
  }

  get orderExpired() {
    return moment(this.props.expires_at).isBefore(moment());
  }

  get orderScheduled() {
    return moment(this.props.delivery_date).isAfter(moment());
  }

  get hasEmbargoedAsset() {
    return this.props?.has_embargoed_asset ?? false;
  }

  get isProcessing() {
    return (this.props.completed_items ?? 0) >= this.props.total_assets;
  }

  get isDeliveryFailed() {
    return [OrderDeliveryStatus.FAILED, OrderDeliveryStatus.GETTYIMAGES_API_FAILED].includes(
      this.props.delivery_status,
    );
  }

  get isDelivered() {
    return [OrderDeliveryStatus.DELIVERED].includes(this.props.delivery_status);
  }

  get isWarmingUp() {
    return [OrderDeliveryStatus.RETRIEVING_FROM_COLD].includes(this.props.delivery_status);
  }

  get isDownloaded() {
    return this.props.total_downloadable_assets <= this.props.total_downloaded_assets;
  }

  get couldBeDownloaded() {
    return this.props.could_be_downloaded ?? false;
  }

  get deliveryStatus() {
    return (
      DELIVERY_STATUS.filter((status) => status.key === this.props.delivery_status)[0]?.label ??
      'common.orders.delivery.created'
    );
  }

  get downloadStatus() {
    if (new Date(this.props.expires_at ?? '') < new Date()) {
      return 'common.orders.download.expired';
    } else if (
      this.props.total_downloadable_assets &&
      this.props.total_downloadable_assets <= this.props.total_downloaded_assets
    ) {
      return 'common.orders.download.downloaded_all';
    } else if (this.props.total_downloaded_assets > 0) {
      return `Downloaded ${this.props.total_downloaded_assets}/${this.props.total_downloadable_assets}`;
    } else {
      return 'common.orders.download.new';
    }
  }

  get approvalStatus() {
    return this.props.approval_status ? 'common.orders.approval.approved' : 'common.orders.approval.pending';
  }

  get workflow() {
    if (!this.props?.type) {
      return;
    }
    return this.deps.renamePipe.transform(this.props?.type);
  }
}

@Injectable()
export class OrdersDataSource
  extends RouterParamsPagination
  implements MultipleViewDataSource<FlatOrderViewModel>, PageableDataSource
{
  selection: SelectionManager<FlatOrderViewModel>;

  filtersValues$ = this.filtersOrderResultsForm.values$;

  allData$ = combineLatest([this.filtersValues$, this.pageIndex$, this.pageSize$]).pipe(
    debounceTime(500),
    tap(() => this.isLoading$.next(true)),
    switchMap(([filters, pageIndex, pageSize]) => {
      return this.ordersApiService
        .getOrders(
          {
            'delivery-status': filters.deliveryStatus,
            'name-subject': filters.nameSubject,
            'download-status': filters.downloadStatus,
            'orders-type': filters.ordersType as OrdersType,
            'date-from': filters.txDate?.from.toISOString(),
            'date-to': filters.txDate?.to.toISOString(),
            status: filters.status,
          },
          Pagination.create({
            page: pageIndex,
            perPage: pageSize,
            ...filters.sort,
          }),
        )
        .pipe(
          catchError(() => {
            return this.#errorHandler();
          }),
          tap(({ total }) => this.total$.next(total)),
          map(({ data }) =>
            data.map((order) =>
              FlatOrderViewModel.createFromFlatOrder(order, {
                storageService: this.storageUrlService,
                datePipe: this.datePipe,
                renamePipe: this.renamePipe,
              }),
            ),
          ),
          tap(() => this.isLoading$.next(false)),
        );
    }),
    shareReplay(1),
  );

  isLoading$ = new BehaviorSubject<boolean>(true);

  connection$ = this.allData$;

  total$ = new BehaviorSubject<number>(0);

  emptyResults$ = combineLatest([this.allData$, this.isLoading$]).pipe(
    map(([data, loading]) => !loading && data.length === 0),
  );

  constructor(
    private ordersApiService: OrderService,
    private toastService: ToastService,
    private filtersOrderResultsForm: FiltersOrderForm,
    private storageUrlService: StorageUrlService,
    private datePipe: DatePipe,
    private renamePipe: RenamePipe,
  ) {
    super();
    this.selection = new SelectionManager(this, (item) => item.uuid);
  }
  #errorHandler(): Observable<never> {
    this.toastService.error({ id: 'sharedPackAssets', message: 'common.orders.errors.list' });
    this.isLoading$.next(false);
    return EMPTY;
  }
}
