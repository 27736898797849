import { LoggingInfo, LoggingInfoGetOne } from '@vdms-hq/api-contract';
import { Timecode } from '@vdms-hq/timecode';

export function excludeOffsetFromTimecodes(entity: LoggingInfo): LoggingInfo {
  if (!entity.offset || entity.offset === '00:00:00:00') {
    return entity;
  }

  const offsetTimecode = Timecode.fromTimecode(entity.offset);

  if (!offsetTimecode) {
    return entity;
  }

  const tcInTimecode = Timecode.fromTimecode(entity.timecode_in)?.subtract(offsetTimecode);
  const tcOutTimecode = Timecode.fromTimecode(entity.timecode_out)?.subtract(offsetTimecode);

  return {
    ...entity,
    timecode_in: tcInTimecode?.toString() ?? '00:00:00:00',
    timecode_out: tcOutTimecode?.toString() ?? '00:00:00:00',
  };
}

export function includeOffsetInTimecodes(entity: LoggingInfo): LoggingInfo {
  if (!entity.offset || entity.offset === '00:00:00:00') {
    return entity;
  }

  const offsetTimecode = Timecode.fromTimecode(entity.offset);

  if (!offsetTimecode) {
    return entity;
  }

  const tcInTimecode = Timecode.fromTimecode(entity.timecode_in)?.add(offsetTimecode);
  const tcOutTimecode = Timecode.fromTimecode(entity.timecode_out)?.add(offsetTimecode);

  return {
    ...entity,
    timecode_in: tcInTimecode?.toString() ?? '00:00:00:00',
    timecode_out: tcOutTimecode?.toString() ?? '00:00:00:00',
  };
}

export function transformToLoggingInfo(log: LoggingInfoGetOne): LoggingInfo {
  return {
    uuid: log.uuid,
    duration: log.duration,
    logging_type: log.loggingtype,
    note: log.note,
    timecode_in: log.timecodeIn,
    timecode_out: log.timecodeOut,
  };
}
