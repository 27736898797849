import { inject, Injectable } from '@angular/core';
import { FieldsOptionsService } from '@vdms-hq/api-contract';
import { fieldDefinitions } from '@vdms-hq/config';
import { map } from 'rxjs/operators';
import { SelectOption } from '@vdms-hq/shared';

@Injectable({ providedIn: 'root' })
export class ClientsFieldsOptionsService {
  private fieldsOptionsService = inject(FieldsOptionsService);

  proxyFilenameOptions$ = this.fieldsOptionsService.getAllFieldTypesSettings().pipe(
    map((fields: string[]) => this.#useAsFilenameSelectOptions(fields)),
    map((options) => [{ key: '', label: 'Default (Original File Name)' }, ...options]),
  );

  #useAsFilenameSelectOptions(fieldsPaths?: string[]): SelectOption[] {
    const selectOptions: SelectOption[] = fieldDefinitions.reduce((options, field) => {
      if (field.input?.objectPath && field.useAsFilename) {
        options.push({
          label: field.label,
          key: field.input.objectPath,
        });
      }
      return options;
    }, [] as SelectOption[]);

    if (!fieldsPaths || fieldsPaths.length === 0) {
      return selectOptions;
    }

    const uniqueFieldPaths = new Set(fieldsPaths);
    return selectOptions.filter((option) => uniqueFieldPaths.has(option.key?.toString() ?? ''));
  }
}
