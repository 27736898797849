import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AssetViewComponent, PlayerMetadataListSource } from '@vdms-hq/firebase-contract';
import { OffsetAddPipe, PlayerService } from '@vdms-hq/player';
import { castTo, ClipboardCopy, IsBetweenPipe, ParseUrl } from '@vdms-hq/shared';
import { Timecode, TimecodeModule } from '@vdms-hq/timecode';
import {
  DataAction,
  DataColumn,
  DataValueColumn,
  FloatingControlsV2Component,
  UIButtonModule,
  UIEmptyResultsModule,
  UIFormModule,
  UIModule,
} from '@vdms-hq/ui';
import {
  BehaviorSubject,
  EMPTY,
  combineLatest,
  from,
  Observable,
  Subject,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs';
import {
  PlayerMetadataItemLocalDB,
  UiTimecodesListDataSource,
  ViewPlayerMetadataItem,
} from '../../logic/metadata-list.model';
import { ConfidenceNotePipe } from '../../logic/pipes/confidence-note.pipe';
import { TranslateTypePipe } from '../../logic/pipes/translate-type.pipe';
import { LocalDatabaseService } from '../../logic/local-database-service';
import { MetadataListFiltersService } from '../../logic/metadata-list-filters.service';
import { ShowOnTimelineService } from '../../logic/show-on-timeline.service';
import { LoggingActionService } from '@vdms-hq/logging';
import { fromLogging } from '../../logic/transformer/logging.transformer';
import { MetadataActionsService } from '../../logic/metadata-actions.service';
import { MetadataRefreshService } from '../../logic/metadata-refresh.service';

type Frame = number;
type FirstIndex = number;

@Component({
  selector: 'vdms-hq-timecode-table',
  standalone: true,
  imports: [
    CommonModule,
    UIModule,
    UIFormModule,
    TimecodeModule,
    UIEmptyResultsModule,
    TranslateTypePipe,
    ConfidenceNotePipe,
    ScrollingModule,
    IsBetweenPipe,
    OffsetAddPipe,
    FloatingControlsV2Component,
    UIButtonModule,
    ParseUrl,
  ],
  templateUrl: './timecode-table.component.html',
  styleUrls: ['./timecode-table.component.scss'],
})
export class TimecodeTableComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  #latestListWidth = 440;

  #playerService = inject(PlayerService);
  #localDatabaseService = inject(LocalDatabaseService);
  #loggingActionService = inject(LoggingActionService);
  #metadataRefreshService = inject(MetadataRefreshService);
  metadataActionsService = inject(MetadataActionsService);
  filtersService = inject(MetadataListFiltersService);
  showOnTimelineService = inject(ShowOnTimelineService);
  clipboardCopy = inject(ClipboardCopy);

  private readonly ACTION_KEYS = {
    COPY: 'copy',
    EDIT: 'edit',
    DELETE: 'delete',
    TOGGLE_ON_TIMELINE: 'toggleOnTimeline',
    TOGGLE_ON_PLAYER: 'toggleOnPlayer',
  };

  @Input() enabledColumns: string[] = [];
  @Input() columns: DataColumn[] = [];
  @Input() dataSource!: UiTimecodesListDataSource;
  @Input() enabledComponents: AssetViewComponent[] = [];

  @ViewChild(CdkVirtualScrollViewport) viewPort!: CdkVirtualScrollViewport;

  $metadataItem = castTo<ViewPlayerMetadataItem>();
  $asDataValueColumn = castTo<DataValueColumn>();

  protected readonly EMPTY = EMPTY;
  #destroyed = new Subject<void>();

  modifiedItems$!: Observable<PlayerMetadataItemLocalDB[]>;

  viewportHeight$ = new BehaviorSubject<string>('440px');
  timecodesTableObserver?: ResizeObserver;
  enabledAndDefined: string[] = [];
  firstIndexesForFrame = new Map<Frame, FirstIndex>();
  isShowOnTimeline = true;
  currentFrame = 0;
  actions: DataAction<ViewPlayerMetadataItem>[] = [
    {
      key: this.ACTION_KEYS.COPY,
      icon: 'content_copy',
      label: 'Copy content',
    },
    {
      key: this.ACTION_KEYS.EDIT,
      icon: 'edit',
      label: 'Edit',
      hiddenIf: (item) => !item?.canEdit,
    },
    {
      key: this.ACTION_KEYS.DELETE,
      icon: 'delete',
      label: 'Delete',
      hiddenIf: (item) => !item?.canDelete,
    },
    {
      key: this.ACTION_KEYS.TOGGLE_ON_TIMELINE,
      icon: 'view_timeline',
      label: 'Toggle on timeline',
      hiddenIf: (item) => !item?.canShowOnTimeline,
    },
    {
      key: this.ACTION_KEYS.TOGGLE_ON_PLAYER,
      icon: 'movie',
      label: 'Toggle on player',
      hiddenIf: (item) => !item?.canAddToPlayer,
    },
  ];

  ngOnInit(): void {
    this.#prepareScrollBehavior();
    this.#playerService.currentTimecode$.pipe(takeUntil(this.#destroyed)).subscribe((timecode) => {
      timecode && this.scrollToTimeCode(timecode.countFrames());
    });

    this.isShowOnTimeline = [AssetViewComponent.ADVANCED_PLAYER, AssetViewComponent.TIMELINE].every((item) =>
      this.enabledComponents.includes(item),
    );

    from(this.#localDatabaseService.cleanOldAssets(5)).subscribe();
    this.modifiedItems$ = combineLatest([this.dataSource.assetUuid$, this.#metadataRefreshService.refreshTable$]).pipe(
      switchMap(([assetUuid]) => {
        if (!assetUuid) {
          return [];
        }

        return from(this.#localDatabaseService.getModifiedItems(assetUuid));
      }),
    );
    this.#loggingActionService.addingNote$
      .pipe(takeUntil(this.#destroyed), withLatestFrom(this.dataSource.total$))
      .subscribe(([data, total]) => {
        if (!data) {
          return;
        }
        this.#localDatabaseService.importItem(fromLogging(data?.loggingInfo, total + 1), data?.assetUuid);
        this.#metadataRefreshService.refreshTable$.next(true);
      });
  }

  ngOnDestroy(): void {
    this.#destroyed.next();
    this.#destroyed.complete();
    this.timecodesTableObserver?.disconnect();
  }

  ngAfterViewInit() {
    this.timecodesTableObserver = new ResizeObserver(([entry]) => {
      if (this.#latestListWidth === entry.contentRect.width) {
        return;
      }

      const playerElemment = document.getElementsByClassName('player-box').item(0);

      if (!playerElemment) {
        return;
      }

      const playerTop = (playerElemment as HTMLElement).offsetTop;
      const viewPortTop = this.viewPort.elementRef.nativeElement.offsetTop;

      const containerHeight = playerElemment.clientHeight - (playerTop - viewPortTop);

      this.viewportHeight$.next(containerHeight + 'px');
      this.viewPort && this.viewPort?.checkViewportSize();
    });
    this.timecodesTableObserver.observe(document.querySelector('.left') as Element);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['enabled'] || changes['columns']) {
      this.#updateEnabledColumns();
    }
  }

  onTimecodeClick(tc?: Timecode | null, boundingBoxes: ViewPlayerMetadataItem['boundingBoxes'] = []) {
    if (!tc) {
      return;
    }
    this.#playerService.goToTimecode(tc);

    this.#playerService.drawCanvas({
      timecode: tc,
      shapes: boundingBoxes,
    });
  }

  scrollToTimeCode(currentFrame: number) {
    this.currentFrame = currentFrame;
    const foundElementIndex = this.firstIndexesForFrame.get(currentFrame);

    if (!foundElementIndex) {
      return;
    }

    const isPossibleToScrollSmoothly = 25 > Math.abs(this.viewPort.getRenderedRange().start - foundElementIndex);

    this.viewPort.scrollToIndex(foundElementIndex, isPossibleToScrollSmoothly ? 'smooth' : 'auto');
  }

  #prepareScrollBehavior() {
    this.dataSource.allData$.pipe(takeUntil(this.#destroyed)).subscribe((data) => {
      this.firstIndexesForFrame.clear();
      data.forEach((row, index) => {
        const frame = row.timestamp.timecodeIn?.countFrames() ?? 0;

        const exist = this.firstIndexesForFrame.get(frame);
        if (!exist) {
          this.firstIndexesForFrame.set(frame, index);
        }
      });
    });
  }

  #updateEnabledColumns() {
    this.enabledAndDefined = this.enabledColumns.filter((enabledColumnKey) => {
      const foundDefinition = this.columns.find((definition) => definition.id === enabledColumnKey);

      if (!foundDefinition) {
        console.warn(`Missing column definition ${enabledColumnKey}, make sure its enabled in admin panel.`);
        return false;
      }

      return true;
    });
  }

  reloadData() {
    this.dataSource.reload();
  }

  toggleOnPlayer(row: ViewPlayerMetadataItem) {
    // todo
    console.log('toggle on player');
  }

  handleAction($event: { key: string; item?: ViewPlayerMetadataItem }) {
    if (!$event.item) {
      return;
    }

    switch ($event.key) {
      case this.ACTION_KEYS.COPY:
        this.clipboardCopy.copyToClipboard($event.item.content, 'content');
        break;
      case this.ACTION_KEYS.TOGGLE_ON_TIMELINE:
        this.showOnTimelineService.showOnTimeline($event.item);
        break;
      case this.ACTION_KEYS.TOGGLE_ON_PLAYER:
        this.showOnTimelineService.showOnTimeline($event.item);
        break;
      case this.ACTION_KEYS.EDIT:
        switch ($event.item.type) {
          case PlayerMetadataListSource.LOGGING:
            if (!$event.item.id || $event.item.loggingUuid === undefined) {
              return;
            }

            this.dataSource.updateItem($event.item, $event.item.type, $event.item.loggingUuid);
            break;
          case PlayerMetadataListSource.TRANSLATE_SUBTITLES:
          case PlayerMetadataListSource.TRANSCRIBE:
            if (!$event.item.id) {
              return;
            }

            this.dataSource.updateItem($event.item, $event.item.type);
            break;
        }
        break;
      case this.ACTION_KEYS.DELETE:
        if (!$event.item.id || $event.item.loggingUuid === undefined) {
          return;
        }
        this.dataSource.removeItem($event.item.id, $event.item.loggingUuid);
        break;
    }
  }
}
