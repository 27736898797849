import { DestinationRule, SimpleDestinationRule, VideoDestinationRule } from '@vdms-hq/api-contract';

export const transformVideoRuleToSendableObject = (rule: DestinationRule) => {
  if (isSimpleType(rule)) {
    return rule;
  }

  const overrideProfileAsObject =
    rule.transcode && rule.transcode.overrideProfile ? JSON.parse(rule.transcode.overrideProfile) : null;

  const audioTracks = rule?.transcode?.audioTracks?.map((audio) => {
    const audioTrack = { ...audio };
    !audioTrack?.languageUuid && delete audioTrack?.languageUuid;
    return audioTrack;
  });

  return {
    ...rule,
    transcode: rule.transcode
      ? {
          ...rule.transcode,
          overrideProfile: overrideProfileAsObject,
          audioTracks,
          enabled: rule.transcode && !!rule.transcode.type,
          burnInText: rule.transcode?.burnInText || false,
        }
      : undefined,
  };
};

export const transformVideoRuleToReadableObject = (rule: DestinationRule) => {
  if (isSimpleType(rule)) {
    return rule;
  }

  const overrideProfileAsString =
    rule.transcode && rule.transcode.overrideProfile
      ? typeof rule.transcode.overrideProfile === 'object'
        ? JSON.stringify(rule.transcode.overrideProfile, null, 2)
        : rule.transcode.overrideProfile
      : null;

  return {
    ...rule,
    transcode: rule.transcode
      ? {
          ...rule.transcode,
          overrideProfile: overrideProfileAsString,
          burnInText: rule.transcode?.burnInText || false,
          enabled: rule.transcode && !!rule.transcode.type,
        }
      : undefined,
    publish: { ...rule.publish, enabled: rule.publish && !!rule.publish.type },
  };
};

export const isVideoType = (rule: DestinationRule): rule is VideoDestinationRule => rule.assetType === 'video';
export const isSimpleType = (rule: DestinationRule): rule is SimpleDestinationRule => rule.assetType !== 'video';
