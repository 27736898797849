import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { UIDialogWrapperModule } from '../ui-dialog-wrapper';
import { UIButtonModule } from '../ui-button';
import { UIConfirmationDialogService } from './logic/services/confirmation-dialog.service';
import { E2eIdDirective, SanitizePipe } from '@vdms-hq/shared';

@NgModule({
  declarations: [ConfirmationDialogComponent],
  providers: [UIConfirmationDialogService],
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    MatButtonModule,
    UIDialogWrapperModule,
    UIButtonModule,
    SanitizePipe,
    E2eIdDirective,
  ],
  exports: [MatDialogModule, MatButtonModule],
})
export class UIDialogModule {}
