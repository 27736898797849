import { LoadableDataSource } from '../datasources/contracts/loadable.ds';
import { Observable, BehaviorSubject } from 'rxjs';
import { DefaultBase } from './default-base';

export function Loadable<T extends new (...args: any[]) => object>(Base: T = DefaultBase as T) {
  return class extends Base implements LoadableDataSource {
    #isLoading$ = new BehaviorSubject(false);
    isLoading$ = this.#isLoading$.asObservable();
    emptyResults$: Observable<boolean> = new BehaviorSubject(false);
    failedToLoad$: Observable<boolean> = new BehaviorSubject(false);
    subNotEmptyResults$: Observable<boolean> = new BehaviorSubject(false);

    constructor(...args: any[]) {
      super(...args);
      this.startLoading = this.startLoading.bind(this);
      this.stopLoading = this.stopLoading.bind(this);
    }

    startLoading(): void {
      this.#isLoading$.next(true);
    }

    stopLoading(): void {
      this.#isLoading$.next(false);
    }
  };
}
