import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material-experimental/mdc-slider';
import { MatMomentDateModule, MomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { E2eIdDirective, SharedModule } from '@vdms-hq/shared';
import { TimecodeModule } from '@vdms-hq/timecode';
import { NgPipesModule, ObjectPipesModule } from 'ng-pipes';
import { NgxMaskModule } from 'ngx-mask';
import { NgxSliderModule } from 'ngx-slider-v2';
import { UIButtonModule } from '../ui-button';
import { UIEmptyResultsModule } from '../ui-empty-results';
import { UIListModule } from '../ui-list';
import { UILoaderModule } from '../ui-loader';
import { UIPipesModule } from '../ui-pipes/ui-pipes.module';
import { FormCheckboxListComponent } from './components/form-checkbox-list/form-checkbox-list.component';
import { FormErrorsComponent } from './components/form-errors/form-errors.component';
import { FilteredListComponent } from './components/form-filtered-list/filtered-list.component';
import { FormInputAccordionComponent } from './components/form-input-accordion/form-input-accordion.component';
import { FormInputAccordionsComponent } from './components/form-input-accordion/form-input-accordions.component';
import { FormInputButtonsToggleComponent } from './components/form-input-buttons-toggle/form-input-buttons-toggle.component';
import { FormInputBitrateRangeComponent } from './components/form-input-bytes-range/form-input-bitrate-range/form-input-bitrate-range.component';
import { FormInputSizeRangeComponent } from './components/form-input-bytes-range/form-input-size-range/form-input-size-range.component';
import { FormInputCheckboxComponent } from './components/form-input-checkbox/form-input-checkbox.component';
import { FormInputChipsComponent } from './components/form-input-chips/form-input-chips.component';
import { FormInputDateRangeComponent } from './components/form-input-date-range/form-input-date-range.component';
import { FormInputDateTimeComponent } from './components/form-input-date-time/form-input-date-time.component';
import { FormInputDateComponent } from './components/form-input-date/form-input-date.component';
import { FormInputEmailComponent } from './components/form-input-email/form-input-email.component';
import { FormInputNumberComponent } from './components/form-input-number/form-input-number.component';
import { FormInputPhoneComponent } from './components/form-input-phone/form-input-phone.component';
import { FormInputSelectGroupComponent } from './components/form-input-select-group/form-input-select-group.component';
import { OnePerGroupPipe } from './components/form-input-select-group/one-per-group.pipe';
import { FormInputSelectComponent } from './components/form-input-select/form-input-select.component';
import { FormInputTextAreaComponent } from './components/form-input-text-area/form-input-text-area.component';
import { FormInputTextComponent } from './components/form-input-text/form-input-text.component';
import { FormInputTimecodePairComponent } from './components/form-input-timecode-pair/form-input-timecode-pair.component';
import { FormInputTimecodeRangeComponent } from './components/form-input-timecode-range/form-input-timecode-range.component';
import { FormInputTimecodeComponent } from './components/form-input-timecode/form-input-timecode.component';
import { FormInputToggleComponent } from './components/form-input-toggle/form-input-toggle.component';
import { FormRadioListComponent } from './components/form-radio-list/form-radio-list.component';
import { FormSectionTitleComponent } from './components/form-section-title/form-section-title.component';
import { FormSliderComponent } from './components/form-slider/form-slider.component';
import { FormSortableCheckboxListComponent } from './components/form-sortable-checkbox-list/form-sortable-checkbox-list.component';
import { FormTimecodeSliderComponent } from './components/form-timecode-slider/form-timecode-slider.component';
import { FormInputGridComponent } from './components/form-input-grid/form-input-grid.component';
import { FormInputDiscountMultiplierComponent } from './components/form-input-discount-multiplier/form-input-discount-multiplier.component';
import { MatMenuModule } from '@angular/material/menu';

const exportedComponents = [
  FormInputSelectComponent,
  FormInputNumberComponent,
  FormInputDiscountMultiplierComponent,
  FormInputTextComponent,
  FormInputEmailComponent,
  FormInputTextAreaComponent,
  FormInputChipsComponent,
  FormInputDateComponent,
  FormInputDateTimeComponent,

  FormRadioListComponent,
  FilteredListComponent,
  FormSliderComponent,
  FormCheckboxListComponent,
  FormSortableCheckboxListComponent,
  FormInputCheckboxComponent,
  FormInputTimecodeComponent,
  FormInputTimecodePairComponent,
  FormSliderComponent,
  FormInputToggleComponent,
  FormInputAccordionComponent,
  FormInputAccordionsComponent,
  FormInputBitrateRangeComponent,
  FormInputTimecodeRangeComponent,
  FormInputSizeRangeComponent,
  FormInputSelectGroupComponent,
  FormInputDateRangeComponent,
  OnePerGroupPipe,
  FormTimecodeSliderComponent,
  FormInputPhoneComponent,
  FormSectionTitleComponent,
  FormErrorsComponent,
  FormInputButtonsToggleComponent,
  FormInputGridComponent,
];

const exportedModules = [
  MatFormFieldModule,
  MatSelectModule,
  MatInputModule,
  FormsModule,
  ReactiveFormsModule,
  UILoaderModule,
  MatIconModule,
  MatChipsModule,
  MatRadioModule,
  MatAutocompleteModule,
  MatDatepickerModule,
  MomentDateModule,
  MatSliderModule,
];

@NgModule({
  declarations: [exportedComponents],
  imports: [
    CommonModule,
    exportedModules,
    UIPipesModule,
    TranslateModule,
    UIEmptyResultsModule,
    MatPaginatorModule,
    MatCheckboxModule,
    ObjectPipesModule,
    UIButtonModule,
    DragDropModule,
    UIListModule,
    NgxMaskModule,
    MatSliderModule,
    SharedModule,
    TimecodeModule,
    MatSlideToggleModule,
    MatTooltipModule,
    NgxSliderModule,
    ScrollingModule,
    MatDividerModule,
    MatInputModule,
    NgPipesModule,
    MatRippleModule,
    MatButtonModule,
    MatMomentDateModule,
    MatMenuModule,
    E2eIdDirective,
  ],
  exports: [exportedComponents, exportedModules],
})
export class UIFormModule {}
