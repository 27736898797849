import { inject, Injectable } from '@angular/core';
import { ToastService } from '@vdms-hq/toast';
import { MatDialog } from '@angular/material/dialog';
import { EditItemComponent } from '../components/edit-item/edit-item.component';
import { map, take } from 'rxjs/operators';
import { DialogResponse } from '@vdms-hq/shared';
import { Framerate } from '@vdms-hq/timecode';
import {
  AssetApiService,
  AssetLockPayload,
  JobItem,
  JobsApiService,
  modelContext,
  LockStreamService,
} from '@vdms-hq/api-contract';
import { HttpErrorResponse } from '@angular/common/http';
import {
  catchError,
  EMPTY,
  filter,
  from,
  Observable,
  switchMap,
  combineLatest,
  BehaviorSubject,
  tap,
  withLatestFrom,
} from 'rxjs';
import { PlayerMetadataItemLocalDB, ViewPlayerMetadataItem } from './metadata-list.model';
import { LocalDatabaseService } from './local-database-service';
import { MetadataListConfigService } from './metadata-list-config.service';
import { MetadataLockService } from './metadata-lock.service';
import moment from 'moment';
import { AuthService } from '@vdms-hq/auth';
import { MetadataRefreshService } from './metadata-refresh.service';

@Injectable({ providedIn: 'root' })
export class MetadataActionsService {
  #toast: ToastService = inject(ToastService);
  #dialog: MatDialog = inject(MatDialog);
  #assetApi: AssetApiService = inject(AssetApiService);
  #jobsApiService: JobsApiService = inject(JobsApiService);
  #localDatabase = inject(LocalDatabaseService);
  #metadataListConfig = inject(MetadataListConfigService);
  #metadataLockService = inject(MetadataLockService);
  #streamService = inject(LockStreamService);
  #auth = inject(AuthService);
  #metadataRefreshService = inject(MetadataRefreshService);

  readonly connect$ = this.#streamService.connection$;

  #jobsJsonUrl$ = this.#metadataListConfig.config$.pipe(map((config) => config?.jobsJsonUrl));
  #jobsItems$: Observable<JobItem[]> = this.#jobsJsonUrl$.pipe(
    filter(Boolean),
    switchMap((url) => this.#jobsApiService.getFile(url)),
  );
  #userUuid$ = this.#auth.id$;

  isLoading$ = new BehaviorSubject<boolean>(false);

  registerWebSocketListener() {
    return this.connect$.pipe(
      withLatestFrom(this.#userUuid$),
      tap(([msg, userUuid]) => {
        switch (msg.context) {
          case 'DELETE':
            if (msg.message.userUuid === userUuid) {
              this.#toast.success({
                id: 'save_modified_items_success',
                message: 'pages.metadata_list.save_modified_items.updated_for_owner',
              });
            } else {
              this.#toast.success({
                id: 'save_modified_items_success',
                message: 'pages.metadata_list.save_modified_items.updated_for_others',
              });
            }
            this.#metadataRefreshService.reloadTable$.next(true);
            this.#metadataListConfig.reload();
            break;
        }
      }),
    );
  }

  triggerEditItem(assetUuid: string, framerate: Framerate, content: string, item: ViewPlayerMetadataItem) {
    if (!this.#metadataLockService.isLockExpired()) {
      return this.editItem(assetUuid, framerate, content, item);
    }

    this.#toast.info({
      id: 'lock_asset_pending',
      message: 'pages.metadata_list.lock_asset.pending',
    });

    const payload: AssetLockPayload = { type: 'AI_JOB_EDIT', action: 'create' };
    return this.#assetApi.lockAsset(assetUuid, payload).pipe(
      take(1),
      switchMap((response) => {
        this.#metadataLockService.lockedAsset$.next(response);

        this.#toast.success({
          id: 'lock_asset_success',
          message: 'pages.metadata_list.lock_asset.success',
          interpolatedParams: { expires: moment(response.expires).format('DD-MM-yyyy HH:mm') },
        });

        return this.editItem(assetUuid, framerate, content, item);
      }),
      catchError((error: HttpErrorResponse) => {
        this.#toast.error({
          id: 'lock_asset_error',
          message: error.error.data,
        });
        return EMPTY;
      }),
    );
  }

  editItem(assetUuid: string, framerate: Framerate, content: string, item: ViewPlayerMetadataItem) {
    return this.#dialog
      .open(EditItemComponent, {
        data: {
          assetUuid,
          framerate,
          content,
          timecodeIn: item.timestamp.timecodeIn,
          timecodeOut: item.timestamp.timecodeOut,
        },
      })
      .afterClosed()
      .pipe(
        take(1),
        switchMap((dialogResponse) => {
          if (dialogResponse?.status !== DialogResponse.OK) {
            return EMPTY;
          }

          if (!item.id) {
            return EMPTY;
          }

          this.#toast.info({
            id: 'modified_item_success',
            message: 'pages.metadata_list.modified_item.success',
          });

          return this.#localDatabase.updateItem(item.id, assetUuid, content, {
            content: dialogResponse.content || 'N/A',
            isEdited: true,
          });
        }),
      );
  }

  saveModifiedJobItems(assetUuid: string) {
    this.isLoading$.next(true);
    combineLatest([from(this.#localDatabase.getModifiedItems(assetUuid)), this.#jobsItems$])
      .pipe(
        map(([modifiedItems, jobItems]) => {
          const modifiedItemsGroupedById = this.#groupObjectsById(modifiedItems);
          const jobsItemArray: JobItem[] = [];

          const oriJob = (id: number) => jobItems.find((item) => item.id === id);

          modifiedItemsGroupedById.forEach((group) => {
            const groupId = group[0].id;
            if (!groupId) {
              return;
            }

            const oriJobTranslations = oriJob(groupId)?.data.translations;
            if (oriJobTranslations === undefined) {
              return;
            }

            const baseTranslationsWithModified = [
              ...oriJobTranslations,
              ...group
                .filter((modifiedItem) => modifiedItem.language !== undefined)
                .map((item) => ({
                  language: item.language ?? '',
                  content: item.content,
                })),
            ];
            const translations = [
              ...new Map(baseTranslationsWithModified.map((item) => [item.language, item])).values(),
            ];

            const content =
              group.filter((modifiedItem) => modifiedItem.language === undefined)[0]?.content ??
              oriJob(groupId)?.data?.content;

            jobsItemArray.push({
              id: groupId,
              start: group[0]?.start,
              end: group[0]?.end,
              type: modelContext.TRANSCRIBE,
              confidence: group[0]?.confidence,
              data: {
                content,
                translations,
              },
            });
          });

          return jobsItemArray;
        }),
        switchMap((jobItems) => this.#jobsApiService.patchJobItems(assetUuid, jobItems)),
        take(1),
      )
      .subscribe({
        next: () => {
          this.isLoading$.next(false);
          this.#toast.success({
            id: 'save_modified_items_success',
            message: 'pages.metadata_list.save_modified_items.success',
          });

          from(this.#localDatabase.changeModifiedItemsAsNormal(assetUuid)).pipe(take(1)).subscribe();
          this.#metadataRefreshService.refreshTable$.next(true);
        },
        error: (err: HttpErrorResponse) => {
          this.isLoading$.next(false);
          this.#toast.error({
            id: 'save_modified_items_error',
            message: 'pages.metadata_list.save_modified_items.error',
          });
          console.error(err);
        },
      });
  }

  #groupObjectsById(objects: PlayerMetadataItemLocalDB[]): PlayerMetadataItemLocalDB[][] {
    return objects.reduce((acc, obj) => {
      const group = acc.find((group) => group[0]?.id === obj.id);
      if (group) {
        group.push(obj);
      } else {
        acc.push([obj]);
      }
      return acc;
    }, [] as PlayerMetadataItemLocalDB[][]);
  }
}
