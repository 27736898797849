import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AwsV4Signature } from '@vdms-hq/api-contract';
import { FileUploadProgressState } from '../model/upload-state';

@Injectable({ providedIn: 'root' })
export class S3SignatureUploadService {
  /**
   * Fast single part upload for small files - like collection backgrounds, etc.
   * @param file
   * @param signature
   */
  public uploadSmallFileWithSignature(file: File, signature: AwsV4Signature): Observable<FileUploadProgressState> {
    return new Observable((observer) => {
      const formData = new FormData();

      formData.append('acl', 'bucket-owner-full-control');
      formData.append('Content-Type', file.type);
      formData.append('X-Amz-Date', signature.x_amz_date);
      formData.append('X-Amz-Algorithm', signature.x_amz_algorithm);
      formData.append('X-Amz-Credential', signature.x_amz_credential);
      formData.append('X-Amz-Signature', signature.x_amz_signature);
      formData.append('Policy', signature.policy);
      formData.append('key', signature.key);
      formData.append('X-Amz-Security-Token', signature.x_amz_security_token);
      formData.append('file', file);

      const xhr = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 204) {
            observer.next({
              uploaded: true,
              progress: 100,
            });
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };

      xhr.upload.onprogress = (event) => {
        const progress = Math.round((event.loaded / event.total) * 100);

        observer.next({
          uploaded: false,
          progress,
        });
      };

      xhr.open('POST', signature.url, true);
      xhr.send(formData);
    });
  }
}
