<vdms-hq-ui-dialog-wrapper>
  <h1 title>{{ 'common.lists.create_new' | translate }}</h1>
  <div class="dialog-content" content>
    <mat-tab-group #tabGroup class="material-custom-tab">
      <mat-tab [label]="'pages.lists.create_new' | translate">
        <form [formGroup]="collectionForm">
          <div class="dialog-content fit-inputs metadata-wrapper" content>
            <vdms-hq-ui-form-input-text
              [label]="'common.lists.list_name' | translate"
              [placeholder]="'common.lists.list_name' | translate"
              formControlName="name"
              [sharedE2eId]="'new-collection-form-name'"
            >
            </vdms-hq-ui-form-input-text>

            <vdms-hq-ui-form-input-text-area
              [label]="'common.lists.list_description' | translate"
              formControlName="description"
            >
            </vdms-hq-ui-form-input-text-area>
          </div>
        </form>
      </mat-tab>

      <mat-tab
        *requiresPermissions="[permission.IMPORT_ASSETS_CSV_COLLECTION]"
        [label]="'common.lists.import_from_file' | translate"
      >
        <a class="download" href="/assets/common/files/sample-csv.csv" download>
          <vdms-hq-ui-info-bar
            [type]="InfoBarType.INFO"
            [message]="'common.lists.download_sample_csv' | translate"
          ></vdms-hq-ui-info-bar>
        </a>

        <vdms-hq-native-upload-component
          [mimeTypes]="types"
          [loading]="loading"
          hint="Drag and Drop CSV file here"
        ></vdms-hq-native-upload-component>
      </mat-tab>
    </mat-tab-group>
  </div>
  <ng-container footer>
    <div class="dialog-footer" *ngIf="tabGroup.selectedIndex === 0">
      <div class="footer buttons-group">
        <vdms-hq-ui-button (click)="closeDialog()" color="secondary">{{
          'common.global.cancel' | translate
        }}</vdms-hq-ui-button>
        <vdms-hq-ui-button
          [sharedE2eId]="'new-collection-form-save'"
          [disabled]="collectionForm.invalid"
          color="primary"
          (click)="onSubmit()"
          >{{ 'common.global.create' | translate }}</vdms-hq-ui-button
        >
      </div>
    </div>

    <div class="dialog-footer" *ngIf="tabGroup.selectedIndex === 1">
      <vdms-hq-ui-button color="secondary" (click)="closeDialog()">{{
        'common.global.cancel' | translate
      }}</vdms-hq-ui-button>
      <vdms-hq-ui-button color="primary" (click)="uploadFile()" [disabled]="(hasSelected$ | async) === false">{{
        'common.import_export.import_button' | translate
      }}</vdms-hq-ui-button>
    </div>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
