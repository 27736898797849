import {
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnInit,
  forwardRef,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { SelectOption, SelectOptionKey, pairWithSelectOption } from '@vdms-hq/shared';
import { CdkDragDrop, DragDropModule, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { tap } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { UIListModule } from '../../ui-list';
import { FormControlValueAccessorComponent, UIFormModule } from '../../ui-form';
import { UIButtonModule } from '../../ui-button';

type DragDrop = {
  defaultList: string[];
  availableList: string[];
  outsideList: string[];
};

@Component({
  selector: 'vdms-hq-ui-config-drag-drop',
  templateUrl: './config-drag-drop.component.html',
  styleUrls: ['./config-drag-drop.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ConfigDragDropComponent),
    },
  ],
  standalone: true,
  imports: [UIListModule, UIFormModule, UIButtonModule, DragDropModule, TranslateModule, CommonModule],
})
export class ConfigDragDropComponent extends FormControlValueAccessorComponent<DragDrop> implements OnInit, OnChanges {
  innerFormControl = new FormControl<{
    defaultList: string[];
    availableList: string[];
    outsideList: string[];
  }>({
    defaultList: [],
    availableList: [],
    outsideList: [],
  });
  cdkLeftList: SelectOption[] = [];
  cdkRightList: SelectOption[] = [];
  @Input() all: SelectOption[] = [];
  selectedOptions: SelectOptionKey[] = [];
  @Input() allowDeleting = true;
  @Input() viewName = '';

  constructor(injector: Injector, changeDetectorRef: ChangeDetectorRef) {
    super(injector, changeDetectorRef);
  }

  get outsideList() {
    return pairWithSelectOption(this.innerFormControl.value?.outsideList ?? [], this.all);
  }

  override writeValue(value: DragDrop): void {
    super.writeValue(value);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.innerFormControl.valueChanges
      .pipe(
        tap(() => {
          this.#render();
        }),
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.all) {
      this.#render();
    }
  }

  #render() {
    const value = this.innerFormControl.value;
    this.cdkLeftList = pairWithSelectOption([...(value?.defaultList ?? [])], this.all);
    this.cdkRightList = pairWithSelectOption([...(value?.availableList ?? [])], this.all);
  }

  drop(event: CdkDragDrop<SelectOption[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    this.innerFormControl.setValue({
      availableList: this.cdkRightList.map((v) => v.key) as string[],
      defaultList: this.cdkLeftList.map((v) => v.key) as string[],
      outsideList: this.innerFormControl.value?.outsideList ?? [],
    });
  }

  deleteItem(listName: 'available' | 'default', key: SelectOptionKey) {
    if (!key) return;
    const found = this.all.find((item) => item.key === key);
    let index;
    if (listName == 'available') {
      index = (this.cdkRightList ?? []).findIndex((item) => item.key === key);
      if (index !== -1 && found) {
        const modifiedList = [...(this.innerFormControl.value?.availableList ?? [])];
        modifiedList.splice(index, 1);
        this.innerFormControl.setValue({
          availableList: modifiedList,
          defaultList: this.innerFormControl.value?.defaultList ?? [],
          outsideList: [...(this.innerFormControl.value?.outsideList ?? []), found.key as string],
        });
      }
    } else if (listName == 'default') {
      index = (this.cdkLeftList ?? []).findIndex((item) => item.key === key);
      if (index !== -1 && found) {
        const modifiedList = [...(this.innerFormControl.value?.defaultList ?? [])];
        modifiedList.splice(index, 1);
        this.innerFormControl.setValue({
          availableList: this.cdkRightList.map((v) => v.key) as string[],
          defaultList: modifiedList,
          outsideList: [...(this.innerFormControl.value?.outsideList ?? []), found.key as string],
        });
      }
    }
  }

  addSelected() {
    const found = this.all
      .filter((item) => this.selectedOptions.indexOf(item.key) !== -1)
      .map((i) => i.key) as string[];

    if (found) {
      this.innerFormControl.setValue({
        defaultList: this.innerFormControl.value?.defaultList ?? [],
        availableList: [...(this.innerFormControl.value?.availableList ?? []), ...found],
        outsideList: this.outsideList
          .filter((item) => found.findIndex((keysToRemove) => keysToRemove === item.key) === -1)
          .map((v) => v.key) as string[],
      });
      this.selectedOptions = [];
    }
  }
}
