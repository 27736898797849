import { Routes } from '@angular/router';
import { Permission, RequiresPermissionsGuard } from '@vdms-hq/activated-client';
import { ClientUserAdminPageComponent } from './pages/client-user-admin-page/client-user-admin-page.component';

export const CLIENT_USER_ROUTING: Routes = [
  {
    path: '',
    component: ClientUserAdminPageComponent,
    canActivate: [RequiresPermissionsGuard],
    data: { permissions: [Permission.USER_ADMIN_BROWSE] },
  },
];
