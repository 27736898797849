import { ChangeDetectionStrategy, Component, forwardRef, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormSectionComponent, PaginatorComponent, UIEmptyResultsModule, UIFormModule } from '@vdms-hq/ui';
import { SelectionIdentifier } from '@vdms-hq/shared';
import { UsersShareDataSource } from '../../logic/users-share.ds';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'vdms-hq-user-selector',
  templateUrl: './user-selector.component.html',
  styleUrls: ['./user-selector.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    UIFormModule,
    PaginatorComponent,
    TranslateModule,
    FormSectionComponent,
    UIEmptyResultsModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => UserSelectorComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSelectorComponent implements ControlValueAccessor, OnInit {
  dataSource = inject(UsersShareDataSource);
  selectedUser: SelectionIdentifier | null = null;
  @Input() label = '';

  writeValue(obj: SelectionIdentifier | null): void {
    this.selectedUser = obj;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = (value: SelectionIdentifier) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  selectUser($event: SelectionIdentifier) {
    this.selectedUser = $event;
    this.onChange($event);
  }

  searchPhrase($event: any) {
    this.dataSource.applyFilter($event);
  }
  ngOnInit(): void {
    this.dataSource.applyFilter('');
  }
}
