import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedClientModule, WithPermissions } from '@vdms-hq/activated-client';
import { UIButtonModule, UILayoutModule } from '@vdms-hq/ui';
import { CartActionsType } from '../../logic/models/cart-actions.model';
import { CartCheckoutActions } from '../../logic/services/cart-checkout.service';
import { E2eIdDirective } from '@vdms-hq/shared';

@Component({
  selector: 'vdms-hq-cart-checkout-actions',
  templateUrl: './cart-checkout-actions.component.html',
  styleUrls: ['./cart-checkout-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    UILayoutModule,
    UIButtonModule,
    AsyncPipe,
    TranslateModule,
    NgIf,
    ActivatedClientModule,
    NgForOf,
    E2eIdDirective,
  ],
})
export class CartCheckoutActionsComponent<T> extends WithPermissions() {
  @Input() config: CartCheckoutActions = [];
  @Output() actions: EventEmitter<CartActionsType> = new EventEmitter();

  constructor() {
    super();
  }

  isString = (value: any): value is string => typeof value === 'string';
}
