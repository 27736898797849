export enum LOGGING_ENDPOINTS {
  ROOT = 'logging-info',
  TYPES = 'logging-types',
}

export interface LoggingInfo {
  uuid: string;
  duration?: string;
  logging_type: LoggingType;
  note: string;
  timecode_in: string;
  timecode_out: string;
  offset?: string;
}

export interface LoggingInfoEntire {
  uuid: string;
  start: number;
  end: number;
  data: {
    content: string;
    note?: string;
  };
}

export interface LoggingInfoGetOne {
  createdAt: string;
  deletedAt: string;
  duration: string;
  id: number;
  loggingtype: LoggingType;
  note: string;
  timecodeIn: string;
  timecodeOut: string;
  updatedAt: string;
  uuid: string;
}

export interface LoggingId {
  id: number;
}

export interface LoggingType {
  id: string;
  uuid: string;
  name: string;
}
