export enum websocketContext {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  PROGRESS = 'PROGRESS',
  INFO = 'INFO',
  ERROR = 'ERROR',
}

export type WebsocketContext = 'CREATE' | 'UPDATE' | 'DELETE' | 'PROGRESS' | 'INFO' | 'ERROR';

export interface WebsocketNotificationInterface<T> {
  uuid: string;
  groupUuid: string; // only backend relevant
  stream: string; // only backend relevant
  context: WebsocketContext;
  data?: T & {
    action: WebsocketNotificationActionEnum;
    message: string;
  };
}

export interface WebsocketLockInterface<T> {
  context: WebsocketContext;
  groupUuid: string;
  stream: string;
  message: T;
}

export interface WebsocketLockInterfaceMessage {
  lockUuid: string;
  userUuid: string;
}

export type WebsocketAnyNotificationMessage = { [value in string]?: number };

export interface WebsocketProgressInterface {
  total: number;
  processed: number;
  not_processed: number;
}

export enum WebsocketNotificationActionEnum {
  COLLECTION_ADD = 'collection_add',
  COLLECTION_REMOVE = 'collection_remove',
  CART_ADD = 'cart_add',
  ASSET_BATCH_UPDATE = 'asset_batch_update',
  RETRY_DD_JOBS = 'retry_dd_jobs',
  LICENSED_PACKAGE_REMOVE = 'LICENSED_PACKAGE_REMOVE',
  SUBSCRIPTION_CREATE = 'NOTIFICATION_SUBSCRIPTION_BATCH_CREATE',
  SUBSCRIPTION_DELETE = 'NOTIFICATION_SUBSCRIPTION_BATCH_DELETE',
  SUBSCRIPTION_UPDATE = 'NOTIFICATION_SUBSCRIPTION_BATCH_UPDATE',
  MOVE_TO_HOT = 'move_to_hot',
  MOVE_TO_COLD = 'move_to_cold',
}

export type WebsocketEntitiesUpdateInterface<T> = WebsocketEntitiesUpdate | T;

export type WebsocketEntitiesUpdate = {
  entityType: 'Asset';
  uuids: string[];
};

export interface GetAssetsDetailsMessagePayload {
  uuids: string[];
  groupUuid: string;
}

export interface WsRequestMessage {
  action: 'request';
  method: 'getAssetsDetails';
  data: GetAssetsDetailsMessagePayload;
}
