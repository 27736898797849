import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ConfirmationAction,
  ConfirmationInput,
  DEFAULT_ABORT_CONFIRMATION_ACTION,
  DEFAULT_CONFIRMATION_DIALOG,
  DEFAULT_OK_CONFIRMATION_ACTION,
} from './confirmation-dialog.interface';
import { DialogResponse as ConfirmationDialogResponse } from '../index';
import { E2eIdDirective, SANITIZE_TYPE } from '@vdms-hq/shared';

@Component({
  selector: 'vdms-hq-ui-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  readonly default = DEFAULT_CONFIRMATION_DIALOG;
  readonly defaultOk: ConfirmationAction = DEFAULT_OK_CONFIRMATION_ACTION;
  readonly defaultAbort: ConfirmationAction = DEFAULT_ABORT_CONFIRMATION_ACTION;
  readonly SANITIZE_TYPE = SANITIZE_TYPE;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationInput,
  ) {}

  abort() {
    this.dialogRef.close({ status: ConfirmationDialogResponse.ABORT });
  }

  ok() {
    this.dialogRef.close({ status: ConfirmationDialogResponse.OK });
  }
}
