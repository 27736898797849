<form [formGroup]="userForm" (ngSubmit)="onSubmit()">
  <vdms-hq-ui-dialog-wrapper footerAlign="flex-end">
    <ng-container title>
      <h1 *ngIf="!data.edit">{{ 'pages.users.create_new_user' | translate }}</h1>

      <h1 *ngIf="data.edit">
        <span *ngIf="data.users.length === 1 && data.users[0]?.name as name">{{ name }} - </span
        ><span>{{ 'client-user-admin.set.title' | translate }}</span
        ><span *ngIf="data.users.length > 1"> for {{ data.users.length }} users</span>
      </h1>
    </ng-container>
    <ng-container content>
      <div *ngIf="data.users.length > 1">
        <p class="subheader">
          <em>{{ 'client-user-admin.subheader.cross_client_info' | translate }}</em>
        </p>
      </div>
      <mat-tab-group class="material-custom-tab" *ngIf="!data.edit; else managePolicies">
        <mat-tab [label]="'pages.client_users_admin.personal_details' | translate">
          <ng-container *ngTemplateOutlet="personalDetails"></ng-container>
        </mat-tab>
        <mat-tab [label]="'pages.client_users_admin.policy_settings' | translate">
          <ng-container *ngTemplateOutlet="managePolicies"></ng-container>
        </mat-tab>
      </mat-tab-group>
    </ng-container>

    <ng-container footer>
      <vdms-hq-ui-button (click)="abort()" color="secondary">{{
        'common.global.cancel' | translate
      }}</vdms-hq-ui-button>
      <vdms-hq-ui-button
        [isSubmit]="true"
        [loading]="!!(isUpdating$ | async)"
        color="primary"
        [disabled]="!data.edit && userForm.invalid"
        >{{ 'common.global.save' | translate }}</vdms-hq-ui-button
      >
    </ng-container>
  </vdms-hq-ui-dialog-wrapper>
</form>
<ng-template #personalDetails>
  <div class="tab-content">
    <vdms-hq-ui-form-section [divider]="false" [dividerMarginBlock]="true" layout="single">
      <vdms-hq-ui-form-input-email
        [formControl]="userForm.controls.email"
        [placeholder]="'pages.settings.email_placeholder' | translate"
        [label]="'pages.settings.email' | translate"
      >
      </vdms-hq-ui-form-input-email>

      <vdms-hq-ui-form-input-text
        [formControl]="userForm.controls.name"
        [placeholder]="'pages.settings.name_placeholder' | translate"
        [label]="'pages.settings.name' | translate"
      >
        <vdms-hq-ui-form-errors [control]="userForm.controls.name" errors></vdms-hq-ui-form-errors>
      </vdms-hq-ui-form-input-text>
    </vdms-hq-ui-form-section>

    <vdms-hq-ui-form-section layout="grid-2-columns" [formGroup]="userPhone">
      <vdms-hq-ui-form-input-select
        formControlName="type"
        [label]="'pages.settings.phone_type' | translate"
        [selectOptions]="phoneTypes"
      ></vdms-hq-ui-form-input-select>

      <vdms-hq-ui-form-input-phone
        [numberLabel]="numberLabel"
        [numberPlaceholder]="numberPlaceholder"
        formControlName="number"
        class="w-100 d-block"
      ></vdms-hq-ui-form-input-phone>
    </vdms-hq-ui-form-section>

    <vdms-hq-ui-form-section
      [title]="'Company Address'"
      [titleSize]="'medium'"
      [divider]="false"
      layout="single"
      [formGroup]="companyAddress"
    >
      <vdms-hq-ui-form-input-text
        formControlName="address1"
        [label]="'pages.settings.address_line' | translate : { lineNumber: 1 }"
      ></vdms-hq-ui-form-input-text>
      <vdms-hq-ui-form-input-text
        formControlName="address2"
        [label]="'pages.settings.address_line' | translate : { lineNumber: 2 }"
      ></vdms-hq-ui-form-input-text>
      <vdms-hq-ui-form-section [columnSizes]="['1fr', '2fr']">
        <vdms-hq-ui-form-input-text
          formControlName="postalCode"
          [label]="'pages.settings.postal_code' | translate"
          [mask]="'0*'"
        ></vdms-hq-ui-form-input-text>
        <vdms-hq-ui-form-input-select
          *ngIf="countries$ | async as countries"
          formControlName="country"
          [label]="'pages.settings.country' | translate"
          [selectOptions]="countries"
        ></vdms-hq-ui-form-input-select>
      </vdms-hq-ui-form-section>
    </vdms-hq-ui-form-section>

    <vdms-hq-ui-form-section [divider]="false" layout="grid-2-columns" [formGroup]="userForm">
      <vdms-hq-ui-form-input-select
        formControlName="timezone"
        [label]="'pages.settings.timezone' | translate"
        [selectOptions]="timezones"
      >
        <vdms-hq-ui-form-errors [control]="userForm.controls.timezone" errors></vdms-hq-ui-form-errors>
      </vdms-hq-ui-form-input-select>
      <vdms-hq-ui-form-input-select
        formControlName="date_format"
        [label]="'pages.settings.date_format' | translate"
        [selectOptions]="dateFormats"
      >
        <vdms-hq-ui-form-errors [control]="userForm.controls.date_format" errors></vdms-hq-ui-form-errors>
      </vdms-hq-ui-form-input-select>
      <vdms-hq-ui-form-input-select
        formControlName="time_format"
        [label]="'pages.settings.time_format' | translate"
        [selectOptions]="timeFormats"
      >
        <vdms-hq-ui-form-errors [control]="userForm.controls.time_format" errors></vdms-hq-ui-form-errors>
      </vdms-hq-ui-form-input-select>

      <vdms-hq-ui-form-input-select
        formControlName="supplier"
        [label]="'pages.settings.supplier' | translate"
        [selectOptions]="(suppliersSelectOptions$ | async) ?? []"
      >
      </vdms-hq-ui-form-input-select>
      <vdms-hq-ui-form-errors [control]="userForm.controls.supplier" errors></vdms-hq-ui-form-errors>
    </vdms-hq-ui-form-section>
  </div>
</ng-template>

<ng-template #managePolicies>
  <div class="tab-content">
    <vdms-hq-manage-policies-list [dataSource]="dataSource" [copyEnabled]="!data.edit"> </vdms-hq-manage-policies-list>
  </div>
</ng-template>
