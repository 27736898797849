import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PolicySelectOption } from '@vdms-hq/api-contract';
import { SelectOption, SharedModule } from '@vdms-hq/shared';
import { FormSectionComponent, UIButtonModule, UIFormModule, UILayoutModule, InfoBarType } from '@vdms-hq/ui';
import { Observable, map, of, BehaviorSubject } from 'rxjs';
import { MatTooltipModule } from '@angular/material/tooltip';

type InfoBarMessage = { id: string; type: InfoBarType; body: string };

@Component({
  selector: 'vdms-hq-client-form',
  standalone: true,
  imports: [
    CommonModule,
    FormSectionComponent,
    UIFormModule,
    TranslateModule,
    UIButtonModule,
    SharedModule,
    UILayoutModule,
    MatTooltipModule,
  ],
  templateUrl: '/client-form.component.html',
})
export class ClientFormComponent implements OnInit {
  @Input() set clientForm(form: FormGroup) {
    this.#clientForm$.next(form);
    if (!this.clientForm.get('default_policies')) {
      return;
    }
    const defaultPoliciesGroup = this.clientForm.get('default_policies') as FormGroup;
    this.defaultPoliciesControlsKeys$.next(Object.keys(defaultPoliciesGroup.controls));
  }
  get clientForm() {
    return this.#clientForm$.value || new FormGroup({});
  }
  @Input() policiesOptions!: PolicySelectOption[];
  @Input() asperaCredentialsOptions!: SelectOption[];
  @Input() externalBucketCredentialsOptions!: SelectOption[];
  @Input() sqsUrlColumns: 'grid-2-columns' | 'grid-3-columns' = 'grid-3-columns';
  @Input() sqsPriorityColumns: 'grid-3-columns' | 'grid-5-columns' = 'grid-5-columns';
  @Input() proxyFilenameOptions: SelectOption[] = [];
  @Output() emitSyncQueue: EventEmitter<void> = new EventEmitter<void>();

  protected readonly InfoBarType = InfoBarType;

  #clientForm$ = new BehaviorSubject<FormGroup>(new FormGroup({}));
  clientForm$ = this.#clientForm$.asObservable();

  defaultPoliciesControlsKeys$ = new BehaviorSubject([] as string[]);

  defaultPoliciesControlGroup?: FormGroup;

  messages$: Observable<InfoBarMessage[]> = of([]);

  ngOnInit() {
    this.defaultPoliciesControlGroup = this.clientForm.get('default_policies')
      ? (this.clientForm.get('default_policies') as FormGroup)
      : new FormGroup({});

    this.messages$ = this.defaultPoliciesControlGroup.valueChanges.pipe(
      map((values) => {
        const messages: { id: string; type: InfoBarType; body: string }[] = [];
        const policyUuids = Object.values(values);

        if (policyUuids.length === 0) {
          messages.concat();
          return messages;
        }

        this.policiesOptions.map((policy) => {
          if (policyUuids.includes(policy.uuid) && policy.isCrossGroup) {
            messages.push({
              id: 'defaultPolicies',
              type: InfoBarType.WARNING,
              body: `Default policy "${policy.name}" is cross-client`,
            });
          }

          if (policyUuids.includes(policy.uuid) && policy.isSuperAdmin) {
            messages.push({
              id: 'defaultPolicies',
              type: InfoBarType.ERROR,
              body: `Default policy "${policy.name}" is Super Admin`,
            });
          }
        });
        return messages;
      }),
    );
  }

  get downloadLimitValue() {
    return this.clientForm.get('download_limit')?.value;
  }

  syncQueue() {
    this.emitSyncQueue.emit();
  }
}
