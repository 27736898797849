import { inject, Injectable, OnDestroy } from '@angular/core';
import { filter, Subject, switchMap, take, takeUntil } from 'rxjs';
import { TeamsService } from '@vdms-hq/api-contract';
import { ToastService } from '@vdms-hq/toast';
import { UIConfirmationDialogService } from '@vdms-hq/ui';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { catchError, tap } from 'rxjs/operators';
import { ClientTeamsListDataSource } from './client-teams-list-ds.service';
import { CreateEditClientTeamDialogComponent } from '../components/create-edit-team-dialog/create-edit-client-team-dialog.component';
import { Destroyable } from '@vdms-hq/shared';

@Injectable({ providedIn: 'root' })
export class ClientTeamsActionsService extends Destroyable() implements OnDestroy {
  private teamsService = inject(TeamsService);
  private teamsDS = inject(ClientTeamsListDataSource);
  private toastService = inject(ToastService);
  private confirmationDialog = inject(UIConfirmationDialogService);
  private matDialog = inject(MatDialog);
  private translate = inject(TranslateService);

  popToast = {
    CREATE_SUCCESS: () =>
      this.toastService.success({
        id: 'create-client-team',
        message: 'common.client-teams.notifications.create.success',
      }),
    CREATE_FAILURE: () =>
      this.toastService.error({
        id: 'create-client-team',
        message: 'common.client-teams.notifications.create.failure',
      }),
    UPDATE_SUCCESS: () =>
      this.toastService.success({
        id: 'update-client-team',
        message: 'common.client-teams.notifications.update.success',
      }),
    UPDATE_FAILURE: (msg?: string) =>
      this.toastService.error({
        id: 'update-client-team',
        message: msg ?? 'common.client-teams.notifications.update.failure',
      }),
    DELETE_SUCCESS: () =>
      this.toastService.success({
        id: 'delete-client-team',
        message: 'common.client-teams.notifications.delete.success',
      }),
    DELETE_FAILURE: () =>
      this.toastService.error({
        id: 'delete-client-team',
        message: 'common.client-teams.notifications.delete.failure',
      }),
    LOAD_FAILURE: () =>
      this.toastService.error({
        id: 'load-client-team',
        message: 'common.client-teams.notifications.load.failure',
      }),
  };

  ngOnDestroy() {
    this.destroy();
  }

  createEditTeamAction(teamUuid?: string, preview?: boolean) {
    this.matDialog.open(CreateEditClientTeamDialogComponent, { data: { teamUuid: teamUuid ?? undefined, preview } });
  }

  deleteTeamAction(teamUuid: string, teamName: string) {
    this.confirmationDialog
      .openDelete({
        title: 'common.client-teams.dialogs.delete.title',
        message: this.translate.instant('common.client-teams.dialogs.delete.message', { teamName }),
      })
      .pipe(
        takeUntil(this.isDestroyed$),
        take(1),
        filter(Boolean),
        tap(() => this.teamsDS.startLoading()),
        switchMap(() =>
          this.teamsService.deleteTeam(teamUuid).pipe(
            tap(() => {
              this.popToast.DELETE_SUCCESS();
              this.teamsDS.refresh$.next(new Date());
            }),
            catchError((error) => {
              this.popToast.DELETE_FAILURE();
              throw error;
            }),
          ),
        ),
      )
      .subscribe();
  }
}
