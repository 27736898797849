<vdms-hq-ui-dialog-wrapper footerAlign="space-between" size="wide">
  <h1 title>{{ 'Values tree (poc)' | translate }}</h1>

  <div class="fit-inputs dialog-content" content>
    <span>Structure of {{ data.option.label }} ({{ data.option.key }}:{{ data.option.uuid }})</span>
    <br />
    <mat-divider></mat-divider>
    <pre>{{ fields$ | async | json }}</pre>
  </div>

  <ng-container footer>
    <button mat-raised-button (click)="close()">{{ 'common.global.close' | translate }}</button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
