import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
import { FormSectionComponent, UIButtonModule, UIFormModule, UILayoutModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { RouterOutlet } from '@angular/router';
import { BehaviorSubject, take, tap, catchError, EMPTY } from 'rxjs';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { ToastService } from '@vdms-hq/toast';
import { EgressStatsAdminService } from '@vdms-hq/egress-stats';

@Component({
  selector: 'vdms-hq-reports-settings',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UILayoutModule,
    TranslateModule,
    UIButtonModule,
    UIFormModule,
    FormSectionComponent,
    RouterOutlet,
  ],
  templateUrl: './reports-settings.component.html',
  styleUrls: ['./reports-settings.component.scss'],
})
export class ReportsSettingsComponent implements OnInit {
  activatedClient = inject(ActivatedClientService);
  toastService = inject(ToastService);
  egressStatsSettingsService = inject(EgressStatsAdminService);

  egressDashboardSettings = new FormGroup({
    enableDailyCost: new FormControl<boolean>(false, { nonNullable: true }),
  });

  isLoading$ = new BehaviorSubject<boolean>(false);

  ngOnInit() {
    this.egressStatsSettingsService
      .getStatsSettings()
      .pipe(take(1))
      .subscribe((settings) => {
        this.egressDashboardSettings.patchValue(settings ?? {});
      });
  }

  save() {
    const settings = { statsConfig: this.egressDashboardSettings.value };
    this.egressStatsSettingsService
      .updateStatsSettings(settings)
      .pipe(
        take(1),
        tap(() => {
          this.toastService.success({
            id: 'egressStats',
            message: 'egress_stats.notifications.saved',
          });
          this.isLoading$.next(false);
        }),
        catchError(() => {
          this.toastService.error({
            id: 'egressStats',
            message: 'egress_stats.notifications.save_failed',
          });
          this.isLoading$.next(false);
          return EMPTY;
        }),
      )
      .subscribe();
  }
}
