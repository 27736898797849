import { Observable } from 'rxjs';
import { TrackByFunction } from '@angular/core';

export interface ConnectableDataSource<T> {
  /**
   * @deprecated use connection instead
   */
  allData$: Observable<T[]>;
  connection$: Observable<T[]>;
  trackBy?: TrackByFunction<T>;
  disconnect?: () => void;
}

export class BaseConnectableDataSource<T> implements ConnectableDataSource<T> {
  allData$: Observable<T[]> = new Observable();
  connection$: Observable<T[]> = new Observable();
  trackBy?: TrackByFunction<T>;
  disconnect?: () => void;
}
