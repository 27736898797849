import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormSectionComponent, UIButtonModule, UIDialogWrapperModule, UIFormModule, UILayoutModule } from '@vdms-hq/ui';
import { ReactiveFormsModule } from '@angular/forms';
import { FormService } from '../../logic/form.service';
import { MatTabsModule } from '@angular/material/tabs';
import { SharedModule } from '@vdms-hq/shared';
import { EmailSettingsFormService } from '../../logic/email-settings-form.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { SelectorsModule } from '@vdms-hq/selectors';

@Component({
  selector: 'vdms-hq-connect2-email-settings',
  standalone: true,
  imports: [
    CommonModule,
    UILayoutModule,
    UIButtonModule,
    UIDialogWrapperModule,
    FormSectionComponent,
    MatTabsModule,
    ReactiveFormsModule,
    UIFormModule,
    SharedModule,
    MatExpansionModule,
    SelectorsModule,
  ],
  providers: [FormService],
  templateUrl: './email-settings.component.html',
  styleUrls: ['./email-settings.component.scss'],
})
export class EmailSettingsComponent implements OnInit {
  private dialogRef = inject(MatDialogRef<EmailSettingsComponent>);
  formService = inject(EmailSettingsFormService);
  selectedTab = 0;

  ngOnInit(): void {
    this.formService.init();
  }

  save() {
    this.formService.save().subscribe(() => {
      // this.dialogRef.close();
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}
