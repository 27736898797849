<div
  style="visibility: hidden; position: fixed"
  class="context-menu"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="contextMenu"
></div>
<mat-menu #contextMenu="matMenu" xPosition="after">
  <ng-template matMenuContent>
    <button
      *ngFor="let action of actions"
      mat-menu-item
      class="menu-item"
      [disabled]="action.disabledIf | disabledMenuElement : item"
      (click)="emitAction($event, action)"
    >
      {{ action.label | nameTransformer : item | translate }}
    </button>
  </ng-template>
  <vdms-hq-ui-loader [center]="true" [backdrop]="true" *ngIf="!actions.length"></vdms-hq-ui-loader>
</mat-menu>
