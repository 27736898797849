<mat-label class="material-custom-field-label" *ngIf="label">
  {{ label }}
</mat-label>
<div class="input-grid" [formGroup]="innerFormGroup">
  <input
    *ngFor="let input of controlsArrayLength; let i = index"
    maxlength="1"
    [maxLength]="1"
    [formControlName]="i"
    [id]="'input' + i"
    [type]="inputType"
    [class.valid]="inputValid(i)"
    (keyup)="onKeyUp(i, $event)"
  />
</div>
