<vdms-hq-ui-layout-section
  [margin]="false"
  [breadcrumbs]="withHeader ? [{ name: 'common.admin.components.player_metadata_list' }] : undefined"
>
  <ng-container *ngIf="metadataListConfigService.config$ | async; let config; else: configuration">
    <vdms-hq-timecode-table
      [dataSource]="metadataListDatasource"
      [columns]="columns"
      [enabledColumns]="enabled"
      [enabledComponents]="config.enabledComponents"
    ></vdms-hq-timecode-table>
  </ng-container>
</vdms-hq-ui-layout-section>

<ng-template #configuration>
  <vdms-hq-ui-loader></vdms-hq-ui-loader>
</ng-template>
