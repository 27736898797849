<div class="field-wrapper">
  <span class="field-label">{{ label }}</span>
  <div class="user-selector-container fit-inputs">
    <h3 *ngIf="isShareSelector && userEmail === formGroup.controls['innerInputFormControl'].value">
      Can't share with yourself
    </h3>
    <form [formGroup]="formGroup" class="search-action">
      <vdms-hq-ui-form-input-text
        formControlName="innerInputFormControl"
        (keyup)="!searchDebounceEnabled && applyFilter($any($event.target).value)"
        [placeholder]="'pages.users.table.filter_placeholder' | translate"
        [sharedE2eId]="'data-selector-input'"
      >
      </vdms-hq-ui-form-input-text>
      <ng-container *ngIf="dataSource.connection$ | async; let options">
        <div
          class="create-btn"
          *ngIf="options.length === 0 && userEmail !== formGroup.controls['innerInputFormControl'].value"
        >
          <ng-content select="[addNew]"></ng-content>
        </div>
      </ng-container>
    </form>
    <vdms-hq-ui-loader
      *ngIf="dataSource.isLoading$ | async"
      [mode]="'over-parent'"
      [backdrop]="true"
    ></vdms-hq-ui-loader>

    <div class="selection" *ngIf="dataSource.connection$ | async; let options">
      <vdms-hq-ui-form-checkbox-list
        [available]="options"
        [columns]="3"
        [ngModel]="dataSource.selection.identifiers$ | async"
        (ngModelChange)="dataSource.selection.updateSelected($event)"
        [checkboxVisible]="!previewOnly"
        [disabled]="previewOnly"
        orientation="vertical"
      >
      </vdms-hq-ui-form-checkbox-list>
    </div>

    <div class="footer">
      <div class="select-all-container buttons-group" *ngIf="showSelectAll">
        <span>{{ 'common.global.select' | translate }}</span>
        <span (click)="onSelectAll()" class="select-link select-txt">{{ 'common.global.all' | translate }}</span
        >|
        <span (click)="onSelectVisible()" class="select-link select-txt">{{ 'common.global.visible' | translate }}</span
        >|
        <span (click)="onSelectNone()" class="select-link select-txt">{{ 'common.global.none' | translate }}</span>
      </div>
      <vdms-hq-ui-paginator [hidePageSize]="hidePageSize" [dataSource]="dataSource"></vdms-hq-ui-paginator>
    </div>
  </div>
  <ng-content select="[errors]"></ng-content>
</div>
