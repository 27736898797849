<div class="multi-data-container">
  <vdms-hq-ui-data-presentation-header
    [title]="'pages.home.menu.client_users'"
    [actions]="headerActions$ | async"
    (action)="handleAction($event)"
  >
  </vdms-hq-ui-data-presentation-header>
  <ng-container *ngIf="filtersConfig$ | async as filtersConfig; else loading">
    <ng-container *ngIf="viewConfiguration$ | async as config">
      <vdms-hq-ui-multiple-data-presentation
        [dataSource]="dataSource"
        [configuration]="config"
        (action)="handleAction($event)"
      >
        <vdms-hq-dynamic-form-group-renderer
          filters
          [form]="dataSource.filters"
          [fieldsConfig]="filtersConfig"
        ></vdms-hq-dynamic-form-group-renderer>
      </vdms-hq-ui-multiple-data-presentation>
      <ng-container *requiresPermissions="[Permission.USER_ADMIN_EDIT_POLICIES]">
        <vdms-hq-client-user-admin-action-bar
          *ngIf="(dataSource.selection.total$ | async) > 0"
          [dataSource]="dataSource"
          (action)="handleAction($event)"
        ></vdms-hq-client-user-admin-action-bar>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #loading>
  <vdms-hq-ui-loader [backdrop]="false"></vdms-hq-ui-loader>
</ng-template>
