export enum OptionType {
  AUDIO_LAYOUT = 'audio_tracks_layout',
  AUDIO_CLASS = 'audio_tracks_class',
  LAYOUT = 'layout',
  RELATIONSHIP = 'relationship',
  DYNAMIC_RANGE = 'dynamic_range',
  INVENTORY_TYPE = 'inventory_type',
  LOUDNESS_STANDARD = 'loudness_standard',
  AUDIO_DESCRIPTION = 'audio_description',
  ASPECT_RATIO = 'aspect_ratio',
  ASSET_TYPE = 'asset_type',
  RETENTION = 'retention',
  ELEMENTS = 'elements',
  FRAMING = 'framing',
  SUB_TYPE = 'sub_type',
  IMAGE_ASPECT = 'image_aspect',
  FACILITY_OF_ORIGIN = 'facility_of_origin',
  HYBRIK_TRACK_CLASS = 'hybrik_track_class',
  CONTENT_CLASS = 'content_class',
  CONTENT_TYPE = 'content_type',
  MEDIA_INSIGHTS_SOURCE_LANGUAGE = 'media_insights_source_language',
  MEDIA_INSIGHTS_TARGET_LANGUAGE = 'media_insights_target_language',
  IS_DIRTY = 'is_dirty',
  ORPHAN = 'orphan',
  IS_PURGED = 'is_purged',
  IS_DELETED = 'is_deleted',
  STAGE = 'stage',
  PREVIEW_APP_ENABLED = 'preview_app_enabled',
  SERIES_NAME = 'series_name',
  THEME = 'theme',
  EPISODE_NAME = 'episode_name',
  CLIENT_REF = 'client_ref',
  CATEGORY = 'category',
  FILENAME = 'filename',
  VARIATION = 'variation',
  SEASON_TITLE = 'season_title',
  QC_STATUS = 'qc_status',
  DISPOSITION = 'disposition',
  SEAMLESS_SEGMENTED = 'seamless_segmented',
  CHANNEL = 'channel',
  GENRE = 'genre',
  REJECT_REASON = 'reject_reason',
  EMBARGO = 'embargo',
  UUID = 'uuid',
  VIDEO_CODECS = 'video_codec',
  RESOLUTIONS = 'resolution',
  PRODUCTION_COMPANY = 'production_company',
  ASSET_STATUS = 'asset_status',
  PROCESSING_STATUS = 'processing_status',
  RELEASE_YEAR = 'release_year',
}

export interface EnabledOption {
  in_search: boolean;
  in_forms: boolean;
}

export interface RelationalOption extends Option {
  parent_field_type_name?: string;
  child_fields?: Option[];
  parent_field?: Option;
}

export interface Option {
  enabled: EnabledOption;
  uuid: string;
  type: OptionType;
  key: string;
  label: string;
  vida_id?: number;
  type_name: string;
  have_discount: boolean;
  extra: Extra | null;
}

export interface Extra {
  siblings: string;
  restricted: string[];
  has_discount?: boolean;
}

export type OptionReduced = Option & { reduced: string };

export interface PostOption {
  type: OptionType;
  key: string;
  label: string;
  enabled: EnabledOption;
}

export interface PatchOption {
  enabled: EnabledOption;
  label: string;
}

export interface Type {
  name: string;
  fields: RelationalOption[];
  parent_field_type_name?: string;
}

export interface NewType {
  name: string;
  parent_field_type_name: string;
}
