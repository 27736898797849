<form [formGroup]="ratesForm" class="form-container" (ngSubmit)="save()">
  <vdms-hq-ui-layout-page-wrapper [breadcrumbs]="[{ name: 'pages.clients.rate_card.title' | translate }]">
    <vdms-hq-ui-form-section [layout]="'grid-3-columns'" [divider]="false">
      <vdms-hq-ui-form-input-select
        formControlName="currency"
        [label]="'pages.clients.rate_card.currency' | translate"
        [selectOptions]="currencyOptions"
      ></vdms-hq-ui-form-input-select>
      <vdms-hq-ui-form-input-number
        formControlName="billing_day_of_the_month"
        [label]="'pages.clients.rate_card.billing_day_of_the_month' | translate"
        [min]="1"
        [max]="31"
      ></vdms-hq-ui-form-input-number>
    </vdms-hq-ui-form-section>
    <vdms-hq-ui-form-section [layout]="'grid-3-columns'" [formGroupName]="'monthly'">
      <vdms-hq-ui-form-input-number
        [formControlName]="'number_of_vida_users'"
        [label]="'pages.clients.rate_card.number_of_vida_users' | translate"
        [step]="'1'"
        [min]="-1"
        title="{{ 'pages.clients.rate_card.number_of_vida_users' | translate }}"
      ></vdms-hq-ui-form-input-number>
      <vdms-hq-ui-form-input-number
        [formControlName]="'number_of_sharing_users'"
        [label]="'pages.clients.rate_card.number_of_sharing_users' | translate"
        [step]="'1'"
        [min]="-1"
        title="{{ 'pages.clients.rate_card.number_of_sharing_users' | translate }}"
      ></vdms-hq-ui-form-input-number>
      <vdms-hq-ui-form-input-number
        [formControlName]="'included_number_of_assets'"
        [label]="'pages.clients.rate_card.included_number_of_assets' | translate"
        [step]="'1'"
        [min]="0"
        title="{{ 'pages.clients.rate_card.included_number_of_assets' | translate }}"
      ></vdms-hq-ui-form-input-number>
    </vdms-hq-ui-form-section>
    <vdms-hq-ui-form-section
      [formGroupName]="'monthly'"
      [layout]="'grid-2-columns'"
      [title]="'pages.clients.rate_card.monthly' | translate"
      [titleSize]="'medium'"
    >
      <vdms-hq-ui-form-input-number
        *ngFor="let control of monthly"
        [formControlName]="control"
        [label]="'pages.clients.rate_card.' + control | translate"
        [step]="'0.01'"
        [min]="0"
        [decimal]="2"
        title="{{ 'pages.clients.rate_card.' + control | translate }}"
      ></vdms-hq-ui-form-input-number>
    </vdms-hq-ui-form-section>
    <vdms-hq-ui-form-section
      [formGroupName]="'transactional'"
      [layout]="'grid-2-columns'"
      [title]="'pages.clients.rate_card.transactional' | translate"
      [titleSize]="'medium'"
      [divider]="false"
    >
      <vdms-hq-ui-form-input-number
        *ngFor="let control of transactional"
        [formControlName]="control"
        [label]="'pages.clients.rate_card.' + control | translate"
        [step]="'0.01'"
        [min]="0"
        [decimal]="2"
        title="{{ 'pages.clients.rate_card.' + control | translate }}"
      ></vdms-hq-ui-form-input-number>
    </vdms-hq-ui-form-section>
    <vdms-hq-ui-form-section [formGroupName]="'transactional'" [layout]="'grid-3-columns'" [divider]="false">
      <vdms-hq-ui-form-section
        [formGroupName]="'transcode'"
        [layout]="'single'"
        [title]="'pages.clients.rate_card.transcode' | translate"
        [divider]="false"
      >
        <vdms-hq-ui-form-input-number
          *ngFor="let control of transcode"
          [formControlName]="control"
          [label]="'pages.clients.discounts.dialogs.fields.' + control | translate"
          [step]="'0.01'"
          [min]="0"
          [decimal]="2"
          title="{{ 'pages.clients.discounts.dialogs.fields.' + control | translate }}"
        ></vdms-hq-ui-form-input-number>
      </vdms-hq-ui-form-section>
      <vdms-hq-ui-form-section
        [formGroupName]="'proxy'"
        [layout]="'single'"
        [title]="'pages.clients.rate_card.proxy_transcode' | translate"
        [divider]="false"
      >
        <vdms-hq-ui-form-input-number
          *ngFor="let control of proxy"
          [formControlName]="control"
          [label]="'pages.clients.discounts.dialogs.fields.' + control | translate"
          [step]="'0.01'"
          [min]="0"
          [decimal]="2"
          title="{{ 'pages.clients.discounts.dialogs.fields.' + control | translate }}"
        ></vdms-hq-ui-form-input-number>
      </vdms-hq-ui-form-section>
      <vdms-hq-ui-form-section
        [formGroupName]="'ingest'"
        [layout]="'single'"
        [title]="'pages.clients.rate_card.ingest.title' | translate"
        [divider]="false"
      >
        <vdms-hq-ui-form-input-number
          *ngFor="let control of ingest"
          [formControlName]="control"
          [label]="'pages.clients.discounts.dialogs.fields.' + control | translate"
          [step]="'0.01'"
          [min]="0"
          [decimal]="2"
          title="{{ 'pages.clients.discounts.dialogs.fields.' + control | translate }}"
        ></vdms-hq-ui-form-input-number>
      </vdms-hq-ui-form-section>
      <vdms-hq-ui-form-section
        [formGroupName]="'spec_correct'"
        [layout]="'single'"
        [title]="'pages.clients.rate_card.spec.title' | translate"
        [divider]="false"
      >
        <vdms-hq-ui-form-input-number
          *ngFor="let control of spec_correct"
          [formControlName]="control"
          [label]="'pages.clients.discounts.dialogs.fields.' + control | translate"
          [step]="'0.01'"
          [min]="0"
          [decimal]="2"
          title="{{ 'pages.clients.discounts.dialogs.fields.' + control | translate }}"
        ></vdms-hq-ui-form-input-number>
      </vdms-hq-ui-form-section>
      <vdms-hq-ui-form-section
        [formGroupName]="'hybrik'"
        [layout]="'single'"
        [title]="'pages.clients.rate_card.hybrik.title' | translate"
        [divider]="false"
      >
        <vdms-hq-ui-form-input-number
          *ngFor="let control of hybrik"
          [formControlName]="control"
          [label]="'pages.clients.rate_card.hybrik.' + control | translate"
          [step]="'0.01'"
          [min]="0"
          [decimal]="2"
          title="{{ 'pages.clients.rate_card.hybrik.' + control | translate }}"
        ></vdms-hq-ui-form-input-number>
      </vdms-hq-ui-form-section>
      <vdms-hq-ui-form-section
        [formGroupName]="'aws'"
        [layout]="'single'"
        [title]="'pages.clients.rate_card.aws.title' | translate"
        [divider]="false"
      >
        <vdms-hq-ui-form-input-number
          *ngFor="let control of aws"
          [formControlName]="control"
          [label]="'pages.clients.rate_card.aws.' + control | translate"
          [step]="'0.01'"
          [min]="0"
          [decimal]="2"
          title="{{ 'pages.clients.rate_card.aws.' + control | translate }}"
        ></vdms-hq-ui-form-input-number>
      </vdms-hq-ui-form-section>
      <vdms-hq-ui-form-section
        [formGroupName]="'openai'"
        [layout]="'single'"
        [title]="'pages.clients.rate_card.openai.title' | translate"
        [divider]="false"
      >
        <vdms-hq-ui-form-input-number
          *ngFor="let control of openai"
          [formControlName]="control"
          [label]="'pages.clients.rate_card.openai.' + control | translate"
          [step]="'0.01'"
          [min]="0"
          [decimal]="2"
          title="{{ 'pages.clients.rate_card.openai.' + control | translate }}"
        ></vdms-hq-ui-form-input-number>
      </vdms-hq-ui-form-section>
    </vdms-hq-ui-form-section>
  </vdms-hq-ui-layout-page-wrapper>
  <vdms-hq-ui-loader [mode]="'over-parent'" [backdrop]="true" *ngIf="loading$ | async"></vdms-hq-ui-loader>
  <vdms-hq-ui-actions-footer>
    <vdms-hq-ui-button
      right
      [disabled]="ratesForm.pristine"
      color="primary"
      [isSubmit]="true"
      [loading]="loading$ | async"
    >
      {{ 'common.global.save' | translate }}
    </vdms-hq-ui-button>
  </vdms-hq-ui-actions-footer>
</form>
