import { inject, Injectable } from '@angular/core';
import { RightsContractsService } from '@vdms-hq/api-contract';
import { startWith, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, of, Subject, withLatestFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LogsFetcher {
  private rightsContractsService: RightsContractsService = inject(RightsContractsService);

  refresh$ = new Subject<void>();
  loading$ = new BehaviorSubject(true);
  contractUuid$ = new BehaviorSubject<string | null>(null);
  emptyResults$ = new BehaviorSubject(false);

  logs$ = this.refresh$.pipe(
    startWith(null),
    tap(() => this.loading$.next(true)),
    withLatestFrom(this.contractUuid$),
    switchMap(([, uuid]) => {
      if (!uuid) {
        this.emptyResults$.next(true);
        return of([]);
      }

      return this.rightsContractsService.getLogs(uuid);
    }),
    tap((response) => {
      this.emptyResults$.next(response.length === 0);
    }),
    tap(() => this.loading$.next(false)),
  );
}
