<div class="checkbox-outer" [class.with-touched-indicator]="withTouchedIndicator">
  <vdms-hq-ui-loader [size]="20" *ngIf="loading" mode="over-parent"></vdms-hq-ui-loader>

  <mat-label class="material-custom-field-label" *ngIf="label">
    {{ label | translate }}
    <span class="required-indicator" *ngIf="isRequired">*</span>
  </mat-label>
  <vdms-hq-ui-empty-results *ngIf="!loading && total === 0" type="compact" [icon]="null"> </vdms-hq-ui-empty-results>
  <div class="lists columns-{{ columns }}">
    <div
      class="column"
      *ngFor="let list of listsArray; let listIndex = index"
      [class.horizontal]="orientation === 'horizontal'"
    >
      <div *ngFor="let item of lists[listIndex]">
        <label
          [class.checkbox-hidden]="!checkboxVisible"
          [class.highlight-on-checked]="highlightOnChecked"
          [class.empty]="!item.key"
          class="label"
        >
          <mat-checkbox
            [checked]="(innerFormControl.value ?? []).includes(item.key)"
            [required]="isRequired"
            class="material-custom-checkbox"
            (change)="toggleOption(item)"
            [disabled]="disabled"
            [sharedE2eId]="'form-checkbox'"
          >
            <span [innerHTML]="item?.label"> </span>
          </mat-checkbox>
          <span class="suffix" *ngIf="item.suffix">{{ item.suffix }}</span>
        </label>
      </div>
    </div>
  </div>
  <div class="mt-medium" *ngIf="withFooter">
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
    <mat-error *ngIf="innerFormControl.touched">
      <span *ngFor="let error of errors">{{ error.transLang | translate : error.contextParams }}</span>
    </mat-error>
  </div>
</div>
