import { ChangeDetectorRef, Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControlValueAccessorComponent, SuffixConfig, AdvancedSearchStateService } from '@vdms-hq/ui';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import {
  castTo,
  FieldConfigId,
  FilterDefinitionModel,
  FilterType,
  SelectOption,
  SelectOptionGroup,
} from '@vdms-hq/shared';
import { FieldsFacets } from '../../models/facets';
import { FilterValue } from '@vdms-hq/api-contract';
import { SelectorSourceType } from '@vdms-hq/selectors';
import { Timecode } from '@vdms-hq/timecode';
import { BdcWalkPopupComponent } from 'bdc-walkthrough';
import { ColumnSettingsScope } from '@vdms-hq/firebase-contract';
import { FiltersConfigService } from '../../filters-config.service';

type ParamsBag = { [key: FieldConfigId]: FilterValue };

export interface TourConfig {
  [key: string]: BdcWalkPopupComponent;
}

export type DynamicFilterInput = FilterDefinitionModel &
  Partial<
    { selectOptions: SelectOption[] } & {
      isMultiple: boolean;
      enableSelectAll: boolean;
      enableDeselectAll?: boolean;
      enableClear?: boolean;
    }
  >;

@Component({
  selector: 'vdms-hq-dynamic-filters[filterConfig]',
  templateUrl: './dynamic-filters.component.html',
  styleUrls: ['./dynamic-filters.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DynamicFiltersComponent),
    },
  ],
})
export class DynamicFiltersComponent extends FormControlValueAccessorComponent<ParamsBag, ParamsBag> implements OnInit {
  #filterConfig!: DynamicFilterInput[];

  get filterConfig() {
    return this.#filterConfig;
  }

  @Input() set filterConfig(config: DynamicFilterInput[]) {
    if (!config) {
      return;
    }
    this.#filterConfig = config;
    this.#prepareForm();
  }
  @Input() facetsEnabled = false;
  @Input() facets?: FieldsFacets;
  @Input() loading = false;
  @Input() tourConfig: TourConfig = {};
  @Input() scope: ColumnSettingsScope = 'browse';
  form: FormGroup = new FormGroup({});
  innerFormControl = new FormControl();
  readonly FilterType = FilterType;
  advancedSearchOpen = false;

  $suffixConfig = castTo<SuffixConfig>();
  $formControl = castTo<FormControl>();
  $selectOptionGroup = castTo<SelectOptionGroup[]>();
  $selectorSourceType = castTo<SelectorSourceType>();
  $facets = castTo<{ min?: Timecode | null | undefined; max?: Timecode | null | undefined }>();
  $rangeBoundaries = castTo<
    Partial<{
      min: number;
      max: number;
    }>
  >();

  constructor(
    injector: Injector,
    cdr: ChangeDetectorRef,
    public filtersConfigService: FiltersConfigService,
    public advancedSearchState: AdvancedSearchStateService,
  ) {
    super(injector, cdr);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  #prepareForm(): void {
    if (!this.#filterConfig) {
      return;
    }

    this.#filterConfig.forEach((config: FilterDefinitionModel) => {
      !Object.keys(this.form.controls).includes(config.id) && this.form.setControl(config.id, new FormControl());
    });

    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        debounceTime(400),
      )
      .subscribe(() => {
        const nextValue: ParamsBag = {};
        this.#filterConfig.forEach((config: FilterDefinitionModel) => {
          const control = this.form.get(config.id);

          if (!control || control.pristine) {
            return;
          }

          const emptyArray = Array.isArray(control.value) && control.value.length === 0;
          const emptyRange =
            !Array.isArray(control.value) &&
            typeof control.value === 'object' &&
            !control.value?.from &&
            !control.value?.to;

          if (!control?.value || emptyArray || emptyRange) {
            return;
          }

          nextValue[config.id] = control.value;
        });

        this.innerFormControl.setValue(nextValue);
      });
  }

  protected transformOuterToInner(value: ParamsBag): ParamsBag {
    this.#filterConfig.forEach((config: FilterDefinitionModel) => {
      const providedValue = value?.[config.id] ?? undefined;

      const control = this.form.get(config.id);
      if (!control) {
        return;
      }

      const currenValueIsEmpty = !control.value;
      const nextValueIsEmpty = !providedValue;
      if (currenValueIsEmpty && nextValueIsEmpty) {
        return;
      }

      if (providedValue) {
        control.markAsDirty();
      }
      control.setValue(providedValue);
    });

    return value;
  }
}
