import { DataColumn } from '@vdms-hq/ui';

export const metadataListTableColumnsEnabled = ['type', 'content', 'timestamp', 'duration', 'confidence'];
export const metadataListTableColumns: DataColumn[] = [
  {
    id: 'type',
    label: 'Type',
    valuePath: 'type',
    sortable: false,
  },
  {
    id: 'content',
    label: 'Content',
    valuePath: 'content',
    sortable: false,
  },
  {
    id: 'timestamp',
    label: 'Timestamp',
    valuePath: 'timestamp',
    sortable: false,
  },
  {
    id: 'duration',
    label: 'Duration',
    valuePath: 'duration',
    sortable: false,
  },
  {
    id: 'confidence',
    label: 'Confidence',
    valuePath: 'confidence',
    sortable: false,
  },
];
