import { inject, Injectable } from '@angular/core';
import { filterEmpty, RefreshService } from '@vdms-hq/shared';
import { ToastService } from '@vdms-hq/toast';
import { BehaviorSubject, shareReplay } from 'rxjs';
import { map } from 'rxjs/operators';
import { ASPERA_CONFIG, AsperaConfig, ViewConfig } from '../config-token';
import { TransferableBatch } from '../model/transferable-file.interface';
import { AsperaDragEvent, AsperaFile, AsperaSelectEvent } from './model/aspera-select-event.model';
import { TransfersEvent, TransferSpec } from './model/aspera-transfer-event.model';

import { AsperaTransferBatch, transformAsperaStatus } from './value-object/aspera-batch';
import { AsperaTransferFile } from './value-object/aspera-batch-file';
import { AssetPlaceholderUpload } from '../model/context.interface';
import { AsperaNotifier } from '../../../aspera-notifier.service';
import { statusEventUploadListener } from './model/status-handler';

declare const AW4: any;

export type BATCH_ID = string;

/** @internal */
@Injectable()
export class AsperaUploadService {
  private config = inject<AsperaConfig>(ASPERA_CONFIG);
  private toast = inject(ToastService);
  private asperaNotifier = inject(AsperaNotifier);
  private refreshService = inject(RefreshService);

  readonly BATCH_LIMIT = 100;
  dragOver$ = new BehaviorSubject<boolean>(false);
  connected$ = new BehaviorSubject<boolean>(false);
  private transfersSubject$ = new BehaviorSubject<AsperaTransferBatch[]>([]);
  transfers$ = this.transfersSubject$.asObservable();
  allFiles$ = this.transfers$.pipe(
    map((batches) => {
      const files: Record<string, AsperaTransferFile> = {};

      batches.forEach((batches) => {
        batches.files.forEach((file) => {
          files[file.id] = file;
        });
      });

      return files;
    }),
  );
  private transfers: Record<BATCH_ID, AsperaTransferBatch> = {};
  private selectedSubject$ = new BehaviorSubject<AsperaTransferBatch[]>([]);
  selected$ = this.selectedSubject$.asObservable().pipe(shareReplay(1));
  selectedNotTriggered$ = this.selected$.pipe(
    filterEmpty(),
    map((selected) => selected.filter((batch) => !this.isUploading(batch))),
  );
  private selected: Record<BATCH_ID, AsperaTransferBatch> = {};

  private asperaWeb: any;

  private connectSettings?: { allow_dialogs: string };
  private options!: ViewConfig;

  destroy() {
    this.asperaWeb.removeEventListener();
    delete this.asperaWeb;
    this.transfers = {};
    this.transfersSubject$.next([]);
    this.selectedSubject$.next([]);
    this.connected$.next(false);
  }

  reset() {
    this.transfers = {};
    this.transfersSubject$.next([]);
    this.selectedSubject$.next([]);
    this.connected$.next(false);
  }

  initialize = (additionalConfig: ViewConfig, assetPlaceholder: AssetPlaceholderUpload | false | undefined) => {
    if (this.asperaWeb) {
      return;
    }

    this.options = additionalConfig;
    this.asperaWeb = new AW4.Connect({
      dragDropEnabled: true,
      sdkLocation: this.config.sdkLocation,
      minVersion: '3.6.0',
    });

    const draggablePlaceholderCss = additionalConfig?.draggablePlaceholderCss;
    if (draggablePlaceholderCss) {
      this.asperaWeb.setDragDropTargets(
        additionalConfig?.draggablePlaceholderCss,
        {
          allowPropagation: false,
          dragEnter: true,
          dragOver: true,
          dragLeave: true,
          drop: true,
        },
        (event: AsperaDragEvent) => {
          switch (event.event.type) {
            case 'drop':
              if (event.event.type === 'drop') {
                const directory = event.files.dataTransfer.files.find((file) => file.type.includes('directory'));
                const folder = event.files.dataTransfer.files.find((file) => file.type.includes('folder'));

                if (folder || directory) {
                  this.dragOver$.next(false);
                  this.toast.info({
                    id: 'aspera_upload',
                    message: 'common.aspera.folder_not_supported',
                  });
                  return;
                }

                let ids: string[] = [];

                if (assetPlaceholder && assetPlaceholder.assetPlaceholderUuid) {
                  ids = [assetPlaceholder.assetPlaceholderUuid];
                }
                //for each 100 files, create a new batch
                this.createLimitedBatch(event.files.dataTransfer.files, ids);
              }
              this.dragOver$.next(false);
              break;

            case 'dragenter':
            case 'dragover':
              this.dragOver$.next(true);
              break;
            case 'dragleave':
              this.dragOver$.next(false);
              break;
          }
        },
      );
    }

    const asperaInstaller = new AW4.ConnectInstaller({
      sdkLocation: this.config.sdkLocation,
    });

    this.connectSettings = {
      allow_dialogs: 'no',
    };

    this.asperaWeb.addEventListener(
      AW4.Connect.EVENT.STATUS,
      statusEventUploadListener(this.asperaNotifier, asperaInstaller),
    );
    this.asperaWeb.initSession();

    this.asperaWeb.addEventListener('transfer', (event: 'transfer', obj: TransfersEvent) => {
      obj.transfers.forEach((eventTransfer) => {
        const transfer = this.transfers[eventTransfer.transfer_spec.batch_id];

        if (transfer && eventTransfer.status === 'removed') {
          this.removeFromTransfers(transfer);
        } else if (transfer) {
          const progress = Math.ceil(eventTransfer.percentage * 100);
          const status = transformAsperaStatus(eventTransfer.status);
          transfer.update({
            status,
            progress,
          });

          if (this.connected$.value && status === 'done' && additionalConfig.clearSelectedOnDone) {
            this.removeFromSelected(transfer);
            this.toast.success({ id: 'aspera_upload', message: 'common.aspera.upload_done' });
            this.refreshService.refresh();
          }
        } else {
          const batch = AsperaTransferBatch.fromAsperaTransferEvent(eventTransfer);
          this.addToTransfers(batch);
        }
      });

      if (!this.connected$.value) {
        this.connected$.next(true);
      }
    });
  };

  select = (ids: string[] = []): void => {
    this.asperaWeb.showSelectFileDialog(
      {
        success: (event: AsperaSelectEvent) => {
          const files = event.dataTransfer.files;
          if (files.length === 0) {
            return;
          }
          this.createLimitedBatch(files, ids);
        },
      },
      {
        allowMultipleSelection: this.options.allowMultipleSelection,
      },
    );
  };

  createLimitedBatch = (files: AsperaFile[], ids: string[] = [], limit = this.BATCH_LIMIT) => {
    const hundredMb = 100 * 1024 * 1024;
    const filesMoreThan100Mb = files.filter((file) => file.size > hundredMb);
    const filesLessThan100Mb = files.filter((file) => file.size <= hundredMb);

    this.splitBigFilesAsSeparateBatches(filesMoreThan100Mb, ids);
    this.splitFilesByLimit(filesLessThan100Mb, limit, ids);
  };

  splitBigFilesAsSeparateBatches(files: AsperaFile[], ids: string[] = []) {
    files.forEach((file) => {
      const batch = AsperaTransferBatch.fromAsperaFiles([file], ids);
      this.addToSelected(batch);
    });
  }

  splitFilesByLimit(files: AsperaFile[], limit: number, ids: string[] = []) {
    for (let i = 0; i < files.length; i += limit) {
      const batch = AsperaTransferBatch.fromAsperaFiles(files.slice(i, i + limit), ids);
      this.addToSelected(batch);
    }
  }

  startUpload(
    transferConfig: {
      token: string;
      cookie: string;
      destinationRoot: string;
      sshPort?: number;
      remoteHost?: string;
      remoteUser?: string;
    },
    transferBatch: AsperaTransferBatch,
  ): void {
    if (this.isUploading(transferBatch)) {
      return;
    }

    this.addToTransfers(transferBatch);

    const spec: TransferSpec = {
      authentication: 'token',
      remote_host: transferConfig.remoteHost ?? this.config.remoteHost,
      remote_user: transferConfig.remoteUser ?? this.config.remoteUser,
      destination_root: transferConfig.destinationRoot,
      ssh_port: transferConfig.sshPort ?? 33001,
      direction: 'send',
      target_rate_kbps: 500000,
      resume: 'sparse_checksum',
      batch_id: transferBatch.batchId,
      token: transferConfig.token,
      cookie: transferConfig.cookie,
      files_metadata: transferBatch.files.map((file) => file.toArray()),
      paths: transferBatch.files.map((file) => ({
        source: file.sourcePath,
        destination: file.destinationFilename,
        id: file.id,
        filename: file.filename,
      })),
    };

    if (this.config.debug) {
      // eslint-disable-next-line no-restricted-syntax
      console.debug('DEBUG: Aspera upload triggered', spec);
    }

    try {
      this.asperaWeb.startTransfer(spec, this.connectSettings, {
        success(cb: any) {
          cb?.transfer_specs?.[0]?.uuid && transferBatch.setTransferUuid(cb.transfer_specs[0].uuid ?? '');
        },
        error() {
          transferBatch.update({
            status: 'failed',
            progress: 0,
          });
        },
      });
    } catch (e) {
      console.error('Error during transfer:', e);
    }
  }

  private addToTransfers(batch: AsperaTransferBatch) {
    this.transfers[batch.batchId] = batch;
    this.transfersSubject$.next(Object.values(this.transfers));
  }

  private removeFromTransfers(batch: AsperaTransferBatch) {
    if (this.transfers[batch.batchId]) {
      delete this.transfers[batch.batchId];
      this.transfersSubject$.next(Object.values(this.transfers));
    }
  }

  private addToSelected(batch: AsperaTransferBatch) {
    const numberOfFilesInBatch = batch.files.length;
    const userSelectedTooManyFiles = numberOfFilesInBatch > 1 && !this.options.allowMultipleSelection;
    if (userSelectedTooManyFiles) {
      return;
    }

    this.selected[batch.batchId] = batch;
    this.selectedSubject$.next(Object.values(this.selected));
  }

  public removeFromSelected(batch: TransferableBatch) {
    if (this.selected[batch.batchId]) {
      delete this.selected[batch.batchId];
      this.selectedSubject$.next(Object.values(this.selected));
    }
  }

  public async deleteFromTransfer(batch: TransferableBatch) {
    if (!this.transfers[batch.batchId]?.transferUuid) {
      return;
    }
    await this.asperaWeb.removeTransfer(this.transfers[batch.batchId]?.transferUuid);
    this.removeFromSelected(batch);
  }

  private isUploading(batch: TransferableBatch) {
    return Boolean(this.transfers[batch.batchId]);
  }
}
