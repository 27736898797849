<vdms-hq-ui-dialog-wrapper [size]="'default'">
  <div title>
    <span class="title">{{
      'common.client-teams.dialogs.add_collection_dialog_title' | translate : { collectionName: data.collectionName }
    }}</span>
  </div>
  <div content class="relative">
    <vdms-hq-ui-add-dialog-selectable-wrapper
      [dataSource]="teamsAddDS"
      [filters]="teamsAddDS.filters"
      [fieldsConfig]="filtersConfig"
      [config]="teamsTilesConfig"
      [loadingText]="'common.client-teams.dialogs.teams_loading' | translate"
      [selected]="selectedControl.value ?? []"
      [emptyMessage]="'common.client-teams.dialogs.teams_empty' | translate"
      (selectedChangeEmit)="selectTeam($event)"
    >
    </vdms-hq-ui-add-dialog-selectable-wrapper>
  </div>
  <ng-container footer>
    <vdms-hq-ui-button (click)="close()" [disabled]="isSaving" color="secondary">{{
      'common.global.cancel' | translate
    }}</vdms-hq-ui-button>
    <vdms-hq-ui-button
      (click)="save()"
      [loading]="isSaving"
      [disabled]="selectedControl.invalid || selectedControl.value.length === 0"
      color="primary"
      >{{ 'common.global.save' | translate }}</vdms-hq-ui-button
    >
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
