import { Component, inject, OnInit } from '@angular/core';
import {
  Destroyable,
  E2eIdDirective,
  FilterType,
  Loadable,
  PillColorEnum,
  ResourceModel,
  ValueFormat,
} from '@vdms-hq/shared';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TeamsService, VidaAppTypes } from '@vdms-hq/api-contract';
import { ClientTeamDialogData } from '../../logic/client-teams.model';
import { ClientTeamsListDataSource } from '../../logic/client-teams-list-ds.service';
import { ClientTeamsActionsService } from '../../logic/client-teams-actions.service';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BehaviorSubject, take } from 'rxjs';
import {
  FormSectionComponent,
  TileSelectableConfig,
  UIButtonModule,
  UIDialogWrapperModule,
  UIFormModule,
} from '@vdms-hq/ui';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { CollectionsAddDataSource, defaultFilters, STATUS_OPTIONS, CollectionsAddData } from '@vdms-hq/collections';
import { DynamicFilterInput } from '@vdms-hq/dynamic-filters';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { Permission } from '@vdms-hq/firebase-contract';
import { ClientUserAdminAddData, ClientUserAdminAddDataSource, VidaAppTypesPills } from '@vdms-hq/client-user-admin';
import { UiAddDialogSelectableWrapperComponent } from '@vdms-hq/selectable-tiles-wrapper';
import { takeUntil } from 'rxjs/operators';
import { TeamsAddDataSource } from '@vdms-hq/teams-shared';

@Component({
  selector: 'vdms-hq-create-edit-client-team-dialog',
  standalone: true,
  imports: [
    UIDialogWrapperModule,
    TranslateModule,
    AsyncPipe,
    UIButtonModule,
    FormSectionComponent,
    ReactiveFormsModule,
    UIFormModule,
    CommonModule,
    UiAddDialogSelectableWrapperComponent,
    E2eIdDirective,
  ],
  templateUrl: './create-edit-client-team-dialog.component.html',
})
export class CreateEditClientTeamDialogComponent extends Loadable(Destroyable()) implements OnInit {
  private activatedClientService = inject(ActivatedClientService);
  private teamsService = inject(TeamsService);
  private teamsActionsService = inject(ClientTeamsActionsService);
  private teamsDS = inject(ClientTeamsListDataSource);
  private addTeamsDS = inject(TeamsAddDataSource);
  private dialogRef = inject(MatDialogRef<CreateEditClientTeamDialogComponent>);
  private translate = inject(TranslateService);
  public data = inject<ClientTeamDialogData>(MAT_DIALOG_DATA);
  public collectionsDataSource = inject(CollectionsAddDataSource);
  public clientUsersDataSource = inject(ClientUserAdminAddDataSource);

  clientUsersFiltersConfig: DynamicFilterInput[] = [
    {
      id: 'text',
      label: 'Name or email',
      resource: [ResourceModel.CLIENT_USER],
      format: ValueFormat.AS_IS,
      filters: {
        objectPath: 'text',
        validFormat: 'keyword',
        type: FilterType.MASTER_TEXT,
      },
    },
    {
      id: 'app',
      label: 'Application Access',
      resource: [ResourceModel.CLIENT_USER],
      format: ValueFormat.AS_IS,
      filters: {
        objectPath: 'app',
        validFormat: 'keyword',
        type: FilterType.SELECTOR,
      },
      selectOptions: [
        { key: '', label: 'All' },
        { key: 'vida', label: 'Vida' },
        { key: 'launchpad', label: 'Launchpad' },
        { key: 'storefront', label: 'Storefront' },
        { key: 'connect2', label: 'Connect2' },
      ],
    },
  ];
  clientUsersTilesConfig: TileSelectableConfig<ClientUserAdminAddData> = {
    label: 'label',
    key: 'key',
    columns: '2-columns',
    metadata: [
      {
        label: 'Email',
        valuePath: 'email',
        singleLine: true,
      },
      {
        label: 'App Accesses',
        valuePath: 'appAccesses',
        singleLine: true,
        viewFormat: {
          pills: VidaAppTypes.reduce((pills, app) => {
            pills[app] = PillColorEnum.ColdBlue;
            return pills;
          }, {} as VidaAppTypesPills),
        },
      },
    ],
  };

  collectionsFiltersConfig: DynamicFilterInput[] = [];
  collectionsTilesConfig: TileSelectableConfig<CollectionsAddData> = {
    label: 'label',
    key: 'key',
    disabledIf: {
      condition: (item: CollectionsAddData) => item?.access_type === 'dashboard',
      label: 'common.collection_not_available',
    },
    columns: '2-columns',
    metadata: [
      {
        label: 'Owner',
        valuePath: 'owner',
        viewFormat: {
          fallback: 'Unknown',
        },
        singleLine: true,
        hiddenIf: (item) => item.type === 'owned',
      },
      {
        label: 'Number of assets',
        valuePath: 'number_of_assets',
        singleLine: true,
        viewFormat: {
          modifiers: {
            asNumberWithZero: true,
          },
        },
      },
      {
        label: 'Description',
        valuePath: 'description',
        singleLine: true,
        viewFormat: {
          modifiers: {
            truncateCharacters: 140,
          },
        },
      },
    ],
  };

  readonly permission = Permission;

  form = new FormGroup({
    name: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    shared_collection_uuids: new FormControl<string[] | undefined>(undefined),
    user_uuids: new FormControl<string[] | undefined>(undefined),
  });

  initialized$ = new BehaviorSubject(false);
  isEdit$ = new BehaviorSubject(!!this.data.teamUuid);
  viewState$ = new BehaviorSubject<'users' | 'collections'>('users');

  title = 'common.client-teams.dialogs.title';
  previewUsers: string[] = [];
  previewCollections: string[] = [];

  collectionsBtn = 'Collections';
  usersBtn = 'Users';

  ngOnInit() {
    if (this.data.teamUuid) {
      this.startLoading();
      this.teamsService
        .getTeam(this.data.teamUuid)
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe({
          next: (team) => {
            this.form.patchValue({
              name: team.name,
              shared_collection_uuids: team.shared_collections.map((collection) => collection.uuid),
              user_uuids: team.users.map((user) => user.uuid),
            });
            if (this.data.preview) {
              this.previewUsers = team.users.map((user) => user.name);
              this.previewCollections = team.shared_collections.map((collection) => collection.name);
            }
            this.initialize();
            this.stopLoading();
          },
          error: () => {
            this.teamsActionsService.popToast.LOAD_FAILURE();
            this.stopLoading();
            this.closeDialog();
          },
        });
    } else {
      this.initialize();
    }
  }

  initialize() {
    if (this.data.preview) {
      this.title = this.translate.instant('common.client-teams.dialogs.preview.title', {
        teamName: this.form.controls.name.value,
      });
      return;
    }
    if (this.data.teamUuid) {
      this.title = 'common.client-teams.dialogs.edit.title';
      this.collectionsBtn = 'common.client-teams.dialogs.view_edit.collections';
      this.usersBtn = 'common.client-teams.dialogs.view_edit.users';
    } else {
      this.title = 'common.client-teams.dialogs.create.title';
      this.collectionsBtn = 'common.client-teams.dialogs.view_create.collections';
      this.usersBtn = 'common.client-teams.dialogs.view_create.users';
    }
    this.activatedClientService.permissions$.pipe(takeUntil(this.isDestroyed$)).subscribe((permissions) => {
      this.collectionsDataSource.filters.controls['keyword'].reset();
      this.collectionsFiltersConfig = defaultFilters;
      if (!permissions.includes(this.permission.BROWSE_SHARED_COLLECTIONS)) {
        this.collectionsFiltersConfig = this.collectionsFiltersConfig.map((f) => {
          if (f.id == 'collectionType') {
            return {
              ...f,
              selectOptions: STATUS_OPTIONS.filter((opt) => {
                return !['all', 'shared'].includes(opt.key);
              }),
            };
          }
          return f;
        });
      }
      this.initialized$.next(true);
    });
  }

  toggleViewState(viewState: 'users' | 'collections') {
    this.viewState$.next(viewState === 'users' ? 'collections' : 'users');
  }

  onSave() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.startLoading();
    const payload = {
      name: this.form.value.name ?? 'N/A',
      shared_collection_uuids: this.form.value.shared_collection_uuids ?? undefined,
      user_uuids: this.form.value.user_uuids ?? undefined,
    };
    if (this.isEdit$.value && this.data.teamUuid) {
      this.teamsService
        .updateTeam(this.data.teamUuid, payload)
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe({
          next: () => {
            this.teamsActionsService.popToast.UPDATE_SUCCESS();
            this.successActions();
          },
          error: () => {
            this.teamsActionsService.popToast.UPDATE_FAILURE();
            this.stopLoading();
          },
        });
    } else {
      this.teamsService
        .createTeam(payload)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.teamsActionsService.popToast.CREATE_SUCCESS();
            this.successActions();
          },
          error: () => {
            this.teamsActionsService.popToast.CREATE_FAILURE();
            this.stopLoading();
          },
        });
    }
  }

  selectCollection(item: CollectionsAddData) {
    const selected = this.form.controls.shared_collection_uuids.value;
    if (selected?.includes(item.key)) {
      this.form.controls.shared_collection_uuids.setValue(selected.filter((id) => id !== item.key));
    } else {
      this.form.controls.shared_collection_uuids.setValue([...(selected ?? []), item.key]);
    }
  }

  selectUsers(user: ClientUserAdminAddData) {
    const selected = this.form.controls.user_uuids.value;
    if (selected?.includes(user.key)) {
      this.form.controls.user_uuids.setValue(selected.filter((id) => id !== user.key));
    } else {
      this.form.controls.user_uuids.setValue([...(selected ?? []), user.key]);
    }
  }

  successActions() {
    this.teamsDS.refresh$.next(new Date());
    this.addTeamsDS.refresh$.next(new Date());
    this.stopLoading();
    this.closeDialog();
  }

  closeDialog(): void {
    this.clientUsersDataSource.refresh();
    this.collectionsDataSource.refresh();
    this.dialogRef.close();
  }
}
