import { CommonModule, DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import {
  AssetActionsService,
  AssetFlatView2Model,
  AssetResults2Component,
  AssetResultsModule,
  ResultsActions,
  assetResultsMenuConfig2,
} from '@vdms-hq/asset-results';
import { DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { ColumnSettingsScope } from '@vdms-hq/firebase-contract';
import { CountdownPipe, filterEmpty, FormatBytesPipe, RenamePipe } from '@vdms-hq/shared';
import { StorageAssetsModule } from '@vdms-hq/storage';
import { ToastModule, ToastService } from '@vdms-hq/toast';
import {
  ActionContextLess,
  DataAction,
  DataPresentationHeaderComponent,
  MultipleDataPresentationComponent,
  PaginatorComponent,
  UIEmptyResultsModule,
  UILayoutModule,
  UILoaderModule,
  UIResultsWrapperModule,
  UISimpleDetailsListModule,
} from '@vdms-hq/ui';
import moment from 'moment';
import { BehaviorSubject, combineLatest, EMPTY, Observable, Subject, switchMap, take, takeUntil } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { OrderMetadataTransformerService } from '../../../logic/order-metadata-transformer';
import { SharedPackAssetsFooterComponent } from '../../components/shared-pack-assets-footer/shared-pack-assets-footer.component';
import { TranscodeDetailsDialogComponent } from '../../components/transcode-details-dialog/transcode-details-dialog.component';
import { CurrentOrderAssetsDataSource } from '../../logic/current-order-assets.ds';
import { CurrentOrderService } from '../../logic/current-order.service';
import { OrderActionsProperties, OrderAssetViewModel2, OrderViewModel } from '../../logic/models';
import { OrderActionsService } from '../../logic/order-actions.service';
import { OrderAssetsFilterService } from '../../logic/order-assets-filters.service';
import { sharedPacksMetadata } from '../../../logic/config';
import { ContextMenuActionsService } from '@vdms-hq/asset-results';
import { LayoutService, Theme } from '@vdms-hq/theming';

@Component({
  selector: 'vdms-hq-shared-packs-details',
  templateUrl: './shared-packs-details.component.html',
  imports: [
    CommonModule,
    DynamicFiltersModule,
    MatIconModule,
    PaginatorComponent,
    TranslateModule,
    UILoaderModule,
    UILayoutModule,
    ToastModule,
    UIResultsWrapperModule,
    UISimpleDetailsListModule,
    AssetResultsModule,
    SharedPackAssetsFooterComponent,
    ReactiveFormsModule,
    StorageAssetsModule,
    MultipleDataPresentationComponent,
    AssetResults2Component,
    DataPresentationHeaderComponent,
    CountdownPipe,
    UIEmptyResultsModule,
  ],
  providers: [
    CurrentOrderService,
    CurrentOrderAssetsDataSource,
    OrderAssetsFilterService,
    OrderMetadataTransformerService,
    DatePipe,
    FormatBytesPipe,
    OrderActionsService,
    RenamePipe,
  ],
  standalone: true,
})
export class SharedPacksDetailsComponent implements OnInit, OnDestroy {
  scopeName: ColumnSettingsScope = 'shared-packs';
  thermometerImageType$ = this.layoutService
    .currentTheme()
    .pipe(
      map((theme) =>
        theme === Theme.dark
          ? '/assets/common/icons/order_warmup_dark.svg'
          : '/assets/common/icons/order_warmup_light.svg',
      ),
    );
  headerActions$: Observable<ActionContextLess[]> = combineLatest([
    this.orderActionsService.buildRetryAllDeliveryDestinationsAction$(),
    this.orderActionsService.buildDownloadAllAsperaAction$(),
    this.orderActionsService.buildDownloadAllAsperaAction$(true),
  ]).pipe(map((actions) => actions.filter(Boolean) as ActionContextLess[]));
  actions$: Observable<DataAction<OrderAssetViewModel2>[]> = combineLatest([
    this.orderActionsService.buildDownloadAsperaAction$<OrderAssetViewModel2>(),
    this.orderActionsService.buildRetryDeliveryDestinationsAction$<OrderAssetViewModel2>(),
    this.orderActionsService.buildTranscodeStatusAction$<OrderAssetViewModel2>(),
    this.assetActionsService.buildPreviewAction$<AssetFlatView2Model>(),
  ]).pipe(map((actions) => actions.filter(Boolean) as DataAction<OrderAssetViewModel2>[]));
  currentOrder$ = this.currentOrder.data$.pipe(shareReplay(1));
  isSharedPackExpired$ = this.currentOrder$.pipe(
    map((order) => (order.expires_at ? moment(order.expires_at).isBefore(moment()) : false)),
  );
  isDownloadLimitExpired$ = this.currentOrder$.pipe(map((order) => !order.could_be_downloaded ?? true));
  downloadLimitExpiredAssetsExist$ = this.dataSource.selection.entities$.pipe(
    map((selectedAssets) => {
      return selectedAssets.some((item) => item.remainingDownloads === 0);
    }),
  );
  isExpiredSubtitle$ = this.isSharedPackExpired$.pipe(
    map((expired) => (expired ? ' (Expired - download disabled)' : '')),
  );
  embargoedAssetsSelected$ = new BehaviorSubject(false);
  refresh$ = this.currentOrder.refresh$;
  currentOrderId: string | null = null;
  selectionDisabled = false;
  orderExpired = false;
  #currentOrder: OrderViewModel | null = null;
  #destroy$ = new Subject<void>();
  estimatedRestoreAt?: Date | string | null;
  canShowWarmUpCouter$ = this.currentOrder.data$.pipe(map(({ estimated_restore_at }) => estimated_restore_at));
  coldAssetsExist$ = this.dataSource.selection.entities$.pipe(
    map((selectedAssets) => {
      return selectedAssets.some((item) => item.props.storage === 'cold' || !item.downloadUuid);
    }),
  );

  readonly assetResultsMenuConfig2 = assetResultsMenuConfig2;

  constructor(
    public currentOrder: CurrentOrderService,
    public dataSource: CurrentOrderAssetsDataSource,
    public filterService: OrderAssetsFilterService,
    private orderActionsService: OrderActionsService,
    private dialog: MatDialog,
    private assetActionsService: AssetActionsService,
    private toast: ToastService,
    private contextMenuActions: ContextMenuActionsService,
    private layoutService: LayoutService,
  ) {
    this.dataSource.sortBy$.next('orderItem.createdAt');
    this.currentOrder.id$.pipe(takeUntil(this.#destroy$)).subscribe((id) => (this.currentOrderId = id));
  }

  get orderHasManyDeliveryDestinationConfigs() {
    if (this.#currentOrder?.delivery_destinations?.length && 'delivery_destinations' in this.#currentOrder) {
      return (
        this.#currentOrder.delivery_destinations.length ||
        this.#currentOrder.delivery_destinations?.[0]?.configs?.length
      );
    } else {
      return false;
    }
  }

  ngOnInit() {
    this.filterService.listenUntil(this.#destroy$);
    this.filterService.initConfig();
    this.currentOrder.configure(sharedPacksMetadata);
    this.cacheOrderDetails();
    this.#listenEntitiesChanges();
    this.#listenToRefresh();
  }

  ngOnDestroy() {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  cacheOrderDetails(): void {
    this.currentOrder.data$.pipe(take(1), takeUntil(this.#destroy$), filterEmpty()).subscribe((order) => {
      this.#currentOrder = order;
      this.orderExpired = order.expires_at ? moment(order.expires_at).isAfter(moment()) : false;
    });
  }

  #isDownloadReady(order: OrderViewModel): boolean {
    return (order?.completed_items ?? 0) >= order?.total_assets;
  }

  handleHeaderAction($event: { key: OrderActionsProperties }) {
    this.orderActionsService.handleDownloadAllAsperaAction($event);
    this.orderActionsService.handleRetryDeliveryDestinationsAction($event);
  }

  handleAction($event: { key: string; item: OrderAssetViewModel2 }) {
    this.assetActionsService.handlePreviewAction($event);
    this.orderActionsService.handleRetryDeliveryDestinationsAction($event);
    this.orderActionsService.handleDownloadAsperaAction($event);
    this.orderActionsService.handleTranscodeStatusAction($event);
    this.contextMenuActions.handleAction($event);
  }

  handleBatchAction($event: { action: ResultsActions; item?: OrderAssetViewModel2[] }) {
    const { item, action } = $event;

    if (!action || !item) {
      return;
    }

    switch (action) {
      case ResultsActions.DOWNLOAD_SELECTED_ASPERA_FOLDERS:
        this.#downloadAssets(item, true).pipe(takeUntil(this.#destroy$), take(1)).subscribe();
        return true;

      default:
        this.#downloadAssets(item, false).pipe(takeUntil(this.#destroy$), take(1)).subscribe();
        return true;
    }
  }

  #downloadAssets(items: OrderAssetViewModel2[], keepFoldersStructure: boolean): Observable<unknown> {
    if (items.every((item) => item.isAssetDownloadReady && item.downloadUuid !== null)) {
      return this.currentOrder.id$.pipe(
        switchMap((id) => {
          this.orderActionsService.download(id, items, keepFoldersStructure);
          return EMPTY;
        }),
      );
    }
    if (this.orderHasManyDeliveryDestinationConfigs) {
      this.dialog.open(TranscodeDetailsDialogComponent, {
        data: {
          orderActive: !this.orderExpired,
          items,
          orderId: this.currentOrderId,
          delivery_destinations: items[0].deliveryDestinations,
          definition: this.dataSource.fieldsDefinitions.find((field) => field.sourceListKey === 'transcode_status')
            ?.results2,
        },
      });
      return EMPTY;
    }
    return this.currentOrder.id$.pipe(
      switchMap((id) => {
        this.orderActionsService.download(id, items, keepFoldersStructure);
        return EMPTY;
      }),
    );
  }

  #listenToRefresh() {
    this.refresh$.pipe(takeUntil(this.#destroy$)).subscribe(() => {
      this.dataSource.refresh$.next(!this.dataSource.refresh$.value);
    });
  }

  #listenEntitiesChanges() {
    this.dataSource.selection?.entities$.pipe(takeUntil(this.#destroy$)).subscribe((entities) => {
      this.selectionDisabled =
        !!(
          !entities.length ||
          (this.#currentOrder?.expires_at && moment(this.#currentOrder?.expires_at).isBefore(moment()))
        ) || !this.#isDownloadReady(this.#currentOrder as OrderViewModel);
      this.embargoedAssetsSelected$.next(entities.some((entity) => entity.isEmbargoActive));
    });
  }
}
