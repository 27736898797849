import { ActivatedClientService } from '@vdms-hq/activated-client';
import { FieldsConfigService } from '@vdms-hq/config';
import { UIConfirmationDialogService } from '@vdms-hq/ui';
import { UserContractService } from '@vdms-hq/firebase-contract';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EMPTY, of, switchMap, withLatestFrom } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { inject, Injectable } from '@angular/core';
import { FieldConfigId } from '@vdms-hq/shared';
import * as uuid from 'uuid';

type FormModel = {
  name: string;
  id: string | null;
  dragDrop: { availableList: string[]; defaultList: FieldConfigId[] };
};

@Injectable({
  providedIn: 'root',
})
export class UsersLibrarySchemaFormService {
  private activatedClientService = inject(ActivatedClientService);
  private fieldsConfigService = inject(FieldsConfigService);
  private confirmationDialog = inject(UIConfirmationDialogService);
  private userContractService = inject(UserContractService);
  private router = inject(Router);
  #lastValue?: FormModel;

  form = new FormGroup({
    id: new FormControl<string | null>(null),
    name: new FormControl<string>('', [Validators.required]),
    dragDrop: new FormControl<{
      defaultList: string[];
      availableList: string[];
    }>({
      defaultList: [],
      availableList: [],
    }),
  });

  get isEdit() {
    return this.form.controls.id !== null;
  }

  get currentConfigName() {
    return this.form.controls.name.value;
  }

  get currentConfigId() {
    return this.form.controls.id.value;
  }

  allAvailableSchema$ = this.fieldsConfigService.filterAggregationsDefinitionsAsOptions$;

  allUsersSchemaConfigs$ = this.activatedClientService
    .forwardEmailAndClientId(this.userContractService.userLibraryConfig)
    .pipe(map((userConfigs) => userConfigs.configs));

  resetForm() {
    if (!this.#lastValue) {
      return;
    }
    this.form.reset(this.#lastValue);
  }

  remove() {
    this.confirmationDialog
      .openDelete()
      .pipe(
        switchMap((confirmed) => {
          if (confirmed && this.currentConfigId) {
            return this.#removeUserSchema(this.currentConfigId);
          }
          return of(EMPTY);
        }),
        tap(() => this.router.navigate(['/browse-library'])),
      )
      .subscribe();
  }

  save() {
    if (this.form.invalid) {
      return;
    }
    this.#updateUserSchema(
      {
        name: this.form.value.name ?? '',
        structure: this.form.value.dragDrop?.defaultList ?? [],
      },
      this.currentConfigId,
    )
      .pipe(tap((id) => this.router.navigate(['/browse-library', id])))
      .subscribe();
  }

  resolve(route: ActivatedRouteSnapshot) {
    const hasScheme = route.paramMap.has('schema');

    return this.allUsersSchemaConfigs$.pipe(
      take(1),
      withLatestFrom(this.allAvailableSchema$),
      map(([all, availableSchema]) => {
        const id = route.paramMap.get('schema');
        const found = all?.find((item) => item.id === id) ?? all[0];

        if (hasScheme && found) {
          this.#setValue({
            id: found.id,
            name: found.name,
            dragDrop: {
              defaultList: found.structure,
              availableList: availableSchema
                .filter((item) => !found.structure.includes(String(item.key)))
                .map((s) => String(s.key)),
            },
          });
        } else {
          this.#setValue({
            id: null,
            name: '',
            dragDrop: {
              defaultList: [],
              availableList: availableSchema.map((s) => String(s.key)),
            },
          });
        }
      }),
    );
  }

  #setValue(param: FormModel) {
    this.form.setValue(param);
    this.#lastValue = param;
  }

  #updateUserSchema(param: { name: string; structure: string[] }, schemaId: string | null) {
    const id = schemaId ?? uuid.v4();

    const existingUserSchemas$ = this.allUsersSchemaConfigs$.pipe(
      take(1),
      map((schemas) => {
        if (schemaId) {
          return schemas.filter((s) => s.id != schemaId);
        }
        return schemas;
      }),
    );

    return existingUserSchemas$.pipe(
      map((userSchemas) => {
        userSchemas.push({
          id: id,
          structure: param.structure,
          name: param.name,
        });

        return userSchemas;
      }),
      switchMap((userSchemas) => {
        return this.activatedClientService.forwardEmailAndClientId(
          this.userContractService.updateClientProxy({
            vidaLibrary: {
              configs: userSchemas,
            },
          }),
        );
      }),
      map(() => id),
    );
  }

  #removeUserSchema(schemaId: string) {
    return this.allUsersSchemaConfigs$.pipe(
      take(1),
      switchMap((schemas) => {
        const next = schemas.filter((schema) => schemaId !== schema.id);

        return this.activatedClientService.forwardEmailAndClientId(
          this.userContractService.updateClientProxy({
            vidaLibrary: {
              configs: next,
            },
          }),
        );
      }),
    );
  }
}
