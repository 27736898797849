import { GetMinifiedUserDetailsType } from '../asset';

export enum ClientTeamsEndpoints {
  ROOT = 'teams',
  SINGLE = 'team',
}

export interface GetTeamInterface {
  uuid: string;
  name: string;
  users: GetMinifiedUserDetailsType[];
  shared_collections: {
    name: string;
    uuid: string;
  }[];
  created_by: GetMinifiedUserDetailsType;
}

export type PostTeamInterface = {
  name: string;
  user_uuids?: string[];
};

export type PatchTeamInterface = {
  name?: string;
  user_uuids?: string[];
  shared_collection_uuids?: string[];
};
