import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedClientService, Permission } from '@vdms-hq/activated-client';
import {
  AsperaFiles,
  AssetApiService,
  AssetAsperaPost,
  DeliveryUploadJob,
  DeliveryUploadJobApiService,
} from '@vdms-hq/api-contract';
import {
  Observable,
  Subject,
  switchMap,
  withLatestFrom,
  EMPTY,
  from,
  concatMap,
  BehaviorSubject,
  delay,
  of,
} from 'rxjs';
import { filter, map, take, takeUntil, tap, catchError } from 'rxjs/operators';
import { EmbargoSelectorDialogComponent } from '../../view/embargo-selector-dialog/embargo-selector-dialog.component';
import { AsperaTransferBatch } from '../aspera/value-object/aspera-batch';
import { ViewConfig } from '../config-token';
import {
  DeliveryPackUpload,
  isAssetPlaceholder,
  isCatalogPlaceholder,
  isDeliveryJobReUpload,
  isDeliveryPack,
  UploadContext,
} from '../model/context.interface';
import { TransferableBatch, TransferableFile } from '../model/transferable-file.interface';
import { AsperaUploadService } from './../aspera/aspera-upload.service';
import { LastUploadedJobsService } from './last-uploaded-jobs.service';
import { RefreshService } from '@vdms-hq/shared';

@Injectable({ providedIn: 'root' })
export class AssetUploadService {
  private refreshService = inject(RefreshService);
  private aspera = inject(AsperaUploadService);
  private assetApiService = inject(AssetApiService);
  private deliveryUploadJobApiService = inject(DeliveryUploadJobApiService);
  private dialog = inject(MatDialog);
  private activatedClientService = inject(ActivatedClientService);
  private lastUploadedJobsService = inject(LastUploadedJobsService);

  error$ = new BehaviorSubject(false);
  selected$: Observable<TransferableBatch[]> = this.aspera.selected$;

  allTransferableFiles$: Observable<Record<string, TransferableFile>> = this.aspera.allFiles$;
  isInitialized$ = this.aspera.connected$;
  dragOver$ = this.aspera.dragOver$;
  destroyedView$ = new Subject<void>();
  uploadJobs$ = new Subject<DeliveryUploadJob[]>();

  removeFromSelected(item: TransferableBatch) {
    this.aspera.removeFromSelected(item);
  }

  initializeView(config: ViewConfig, context?: UploadContext, callback?: (batches: AsperaTransferBatch[]) => void) {
    const assetPlaceholder = context && isAssetPlaceholder(context);
    this.aspera.initialize(config, assetPlaceholder);

    if (config.autoUpload) {
      this.aspera.selectedNotTriggered$.pipe(takeUntil(this.destroyedView$)).subscribe((batches) => {
        callback && callback(batches);
        this.uploadMany(batches, context);
      });
    }
  }

  async deleteFromTransfer(item: TransferableBatch) {
    await this.aspera.deleteFromTransfer(item);
  }

  selectFiles = (uploadContext?: UploadContext) => {
    const assetPlaceholder = uploadContext && isAssetPlaceholder(uploadContext);

    if (assetPlaceholder && assetPlaceholder.assetPlaceholderUuid) {
      this.aspera.select([assetPlaceholder.assetPlaceholderUuid]);
      return;
    }

    this.aspera.select();
  };

  uploadMany = (batches: AsperaTransferBatch[], context?: UploadContext) => {
    return from(batches)
      .pipe(
        concatMap((batch) =>
          of(batch).pipe(
            delay(5000),
            switchMap((batch) => this.uploadBatch(batch, context)),
          ),
        ),
      )
      .subscribe();
  };

  destroy() {
    this.destroyedView$.next();
    this.aspera?.destroy();
  }

  private uploadBatch(batch: AsperaTransferBatch, context?: UploadContext) {
    const deliveryPack = context && isDeliveryPack(context);
    const deliveryJobReUpload = context && isDeliveryJobReUpload(context);
    const catalogPlaceholder = context && isCatalogPlaceholder(context);
    const assetPlaceholder = context && isAssetPlaceholder(context);

    let metadata = {};

    if (deliveryPack) {
      metadata = {
        series_title: (context as DeliveryPackUpload).metadata?.core?.series_name,
        theme: (context as DeliveryPackUpload).metadata?.additional?.theme,
        contentClass: (context as DeliveryPackUpload).metadata?.additional?.content_class,
      };
    }

    const files = batch.files.map((file) => ({
      uuid: file.id,
      originalFilename: file.filename,
      internalFilename: file.destinationFilename,
      batchId: batch.batchId,
      sourcePath: file.sourcePath,
      ...metadata,
    }));

    if (deliveryPack) {
      return this.deliveryUploadJobApiService
        .createAsperaBatchUpload({
          deliveryPackUuid: deliveryPack.deliveryPackUuid,
          files,
        })
        .pipe(
          withLatestFrom(
            this.activatedClientService.metadataRecognition$.pipe(
              map((data) => !!(data?.filenameConventions?.length ?? 0)),
            ),
          ),
          tap(([response]) => {
            this.aspera.startUpload(response, batch);
            this.uploadJobs$.next(response.uploadJobs);
            this.lastUploadedJobsService.setJobs(response.uploadJobs);
          }),
          catchError((error) => {
            this.aspera.reset();
            this.error$.next(true);
            this.refreshService.refresh();
            return EMPTY;
          }),
        );
    }

    if (deliveryJobReUpload) {
      return this.deliveryUploadJobApiService
        .createAsperaReUpload(deliveryJobReUpload.deliveryJobUuid, {
          deliveryPackUuid: deliveryJobReUpload.deliveryPackUuid,
          file: files[0],
        })
        .pipe(
          tap((response) => {
            this.aspera.startUpload(response, batch);
            this.uploadJobs$.next(response.uploadJobs);
          }),
        );
    }

    const payload: AssetAsperaPost = {
      files: batch.files.map((file) => ({
        uuid: file.id,
        catalog_item: (catalogPlaceholder && catalogPlaceholder.catalogItem) || undefined,
        originalFilename: file.filename,
        internalFilename: file.destinationFilename,
        batchId: batch.batchId,
        sourcePath: file.sourcePath,
      })),
    };

    return this.activatedClientService.clientDefinite$.pipe(
      take(1),
      map((client) => [client?.vida?.embargo, client?.permissions] as [boolean, Permission[]]),
      switchMap(([embargo, permissions]) => {
        if (embargo && (permissions || []).includes(Permission.EDIT_EMBARGO_DATES)) {
          return this.#embargoDialog(payload).pipe(
            filter(Boolean),
            switchMap((payload) =>
              this.assetApiService.createAsperaAssets({ files: payload as unknown as AsperaFiles[] }).pipe(
                tap((response) => {
                  this.aspera.startUpload(response, batch);
                }),
              ),
            ),
          );
        }

        if (assetPlaceholder) {
          const flatPayload = payload.files?.[0];
          return this.assetApiService.placeholderAssetUpload(assetPlaceholder.assetPlaceholderUuid, flatPayload).pipe(
            tap((response) => {
              this.aspera.startUpload(response, batch);
            }),
          );
        }

        return this.assetApiService.createAsperaAssets(payload).pipe(
          tap((response) => {
            this.aspera.startUpload(response, batch);
          }),
        );
      }),
    );
  }

  #embargoDialog(files: AssetAsperaPost): Observable<MatDialogRef<EmbargoSelectorDialogComponent>> {
    const dialog = this.dialog.open(EmbargoSelectorDialogComponent, {
      data: {
        files,
      },
    });
    return dialog.afterClosed();
  }
}
