<ng-template vdmsHqPortal attachTo="#library-header-right">
  <div class="actions">
    <vdms-hq-ui-form-input-toggle
      label="Asset view"
      [(ngModel)]="assetView"
      *ngIf="(segmentService.isLastSegment$ | async) === false"
      [withFooter]="false"
    ></vdms-hq-ui-form-input-toggle>

    <ng-container *ngIf="schemaService.activeSchema$ | async; let activeSchema">
      <vdms-hq-ui-button
        *ngIf="activeSchema.type === 'user'"
        [iconOnly]="true"
        icon="edit"
        [tooltip]="'common.global.edit' | translate"
        [routerLink]="['/browse-library', activeSchema.id, 'edit']"
      ></vdms-hq-ui-button>
    </ng-container>
  </div>
</ng-template>

<vdms-hq-ui-layout-page-wrapper
  [breadcrumbs]="[breadcrumb]"
  *ngIf="segmentService.lastBreadCrumb$ | async; let breadcrumb"
>
  <vdms-hq-ui-layout-breadcrumbs
    [breadcrumbs]="(segmentService.breadcrumbs$ | async) ?? []"
    (selectedBreadCrumb)="segmentService.selectBreadCrumb($event.index)"
    size="small"
    before-navbar
  ></vdms-hq-ui-layout-breadcrumbs>

  <div
    class="actions"
    actions
    *ngIf="(segmentService.isLastSegment$ | async) === false && !assetView"
    [formGroup]="filtersForm"
  >
    <vdms-hq-ui-form-input-text
      formControlName="text"
      [placeholder]="'tooltip.search' | translate"
      [label]="'tooltip.search' | translate"
      [fallbackSuffixIcon]="'search'"
    >
    </vdms-hq-ui-form-input-text>
    <vdms-hq-ui-form-input-select
      formControlName="sortDirection"
      [selectOptions]="filteringSortingOptions"
      [label]="'tooltip.sort_by' | translate"
    ></vdms-hq-ui-form-input-select>
  </div>
  <div *ngIf="(isLoading$ | async) === false; else loader">
    <vdms-hq-asset-results-2
      *ngIf="assetView || (segmentService.isLastSegment$ | async); else folders"
      [dataSource]="dataSource"
      [scopeName]="scopeName"
      [actions]="(actions$ | async) ?? []"
      [tableHeaderActions]="(tableHeaderActions$ | async) ?? []"
      (action)="handleBatchAction($event)"
    >
    </vdms-hq-asset-results-2>
  </div>
  <div class="debug-path" footer>
    <span>Schema: </span>
    <div class="path">
      <span *ngFor="let item of schemaService.activeSchemaFields$ | async">{{ item.label }}</span>
    </div>
  </div>
</vdms-hq-ui-layout-page-wrapper>

<vdms-hq-asset-results-actions
  *ngIf="dataSource.selection?.selected$ | async"
  [selectionManager]="dataSource.selection"
  [cartButtonVisibility]="(clipsOnlyEnabled$ | async) === false"
  (actions)="handleBatchAction({ key: $event })"
>
</vdms-hq-asset-results-actions>

<ng-template #folders>
  <vdms-hq-ui-folder
    [folders]="(segmentService.currentSegmentValuesAsFolders$ | async) ?? []"
    (clickSegment)="onSegmentChange($event.title)"
    [loading]="(segmentService.loading$ | async) ?? false"
    [actions]="(foldersActionsConfig$ | async) ?? []"
    (action)="handleFolderAction($event)"
  >
  </vdms-hq-ui-folder>
</ng-template>

<ng-template #loader>
  <vdms-hq-ui-loader></vdms-hq-ui-loader>
</ng-template>
