<ng-container *ngIf="dataSource && configuration; else missingRequiredData">
  <div class="grid-tiles" [class.grid-tiles--up-to-6]="configuration.layout?.columns === 'up-to-6'">
    <div
      class="grid-tiles__item"
      [class.selected-highlight]="isSelectable(dataSource) && (dataSource.selection.isSelected$(item) | async)"
      (dblclick)="handleDoubleClick($event, item)"
      [matTooltip]="configuration.actions | isRowTooltip | translate"
      [matTooltipShowDelay]="500"
      (click)="isSelectable(dataSource) && !isRowDisabled(item) && selectItem(item)"
      (contextmenu)="showContextMenu($event, item)"
      [class.disabled-row]="(configuration.rows | disableIfRow : item).disabled"
      *ngFor="let item of dataSource.connection$ | async; trackBy: dataSource.trackBy ?? defaultTrackBy"
    >
      <div class="grid-tiles__content">
        <div class="grid-tiles__content__image">
          <vdms-hq-ui-tile-image
            [src]="$castToString(item | valueByPath : configuration.image?.bgPath | async)"
            [hoverSrc]="$castToString(item | valueByPath : configuration.image?.bgHoverPath | async)"
          >
          </vdms-hq-ui-tile-image>
        </div>
        <div class="grid-tiles__title">
          <ng-container *ngIf="configuration.content?.iconPath; let iconPath">
            <mat-icon *ngIf="item | valueByPath : iconPath | async as icon" inline="true">{{ icon }}</mat-icon>
          </ng-container>
          <span
            [sharedE2eId]="'grid-tiles-title'"
            title
            [matTooltip]="'' + (item | valueByPath : configuration.content?.titlePath | async)"
            >{{ item | valueByPath : configuration.content?.titlePath | async }}</span
          >
        </div>
      </div>
      <vdms-hq-ui-floating-controls-v2
        *ngIf="configuration.actions && configuration.actions.length > 0"
        class="grid-tiles__controls"
        [actions]="configuration.actions"
        (action)="action.emit($event)"
        [item]="item"
        [alternativePillConfig]="configuration.rows"
      >
      </vdms-hq-ui-floating-controls-v2>
    </div>
  </div>
</ng-container>

<vdms-hq-ui-context-menu
  [actions]="configuration?.contextMenu?.actions ?? []"
  (action)="action.emit($event)"
></vdms-hq-ui-context-menu>

<ng-template #missingRequiredData>
  <vdms-hq-ui-runtime-error></vdms-hq-ui-runtime-error>
</ng-template>
