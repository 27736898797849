import { inject, Injectable } from '@angular/core';
import { Permission, PermissionService } from '@vdms-hq/activated-client';
import { map, take } from 'rxjs/operators';
import { DataAction, Folder } from '@vdms-hq/ui';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FolderActionsService {
  private permissionService = inject(PermissionService);

  buildAddToCollectionAction$(): Observable<DataAction<Folder>> {
    return this.permissionService.verifyWithOwnedPermissions$([Permission.EDIT_COLLECTIONS]).pipe(
      take(1),
      map((hasAccess) =>
        hasAccess
          ? {
              key: 'collection',
              icon: 'playlist_add',
              label: (item: Folder) => {
                return this.setName(
                  item,
                  'common.global.add_to_collection',
                  'tooltip.add_to_collection_folder_action_limit',
                );
              },
              disabledIf: (item: Folder) => this.isDisabled(item),
              defaultOnTileClick: false,
            }
          : null,
      ),
    );
  }

  buildShareNewCollectionAction$(): Observable<DataAction<Folder>> {
    return this.permissionService
      .verifyWithOwnedPermissions$([Permission.EDIT_COLLECTIONS, Permission.CREATE_COLLECTIONS])
      .pipe(
        take(1),
        map((hasAccess) =>
          hasAccess
            ? {
                key: 'share_new_collection',
                icon: 'share',
                label: (item: Folder) => {
                  return this.setName(
                    item,
                    'common.global.share_to_with_new_collection',
                    'tooltip.share_folder_action_limit',
                  );
                },
                disabledIf: (item: Folder) => this.isDisabled(item),
                defaultOnTileClick: false,
              }
            : null,
        ),
      );
  }

  buildAddToCartAction$(): Observable<DataAction<Folder>> {
    return this.permissionService.verifyWithOwnedPermissions$([Permission.SHOPPING_CART_V2]).pipe(
      take(1),
      map((hasAccess) =>
        hasAccess
          ? {
              key: 'cart',
              icon: 'shopping_cart',
              label: (item: Folder) => {
                return this.setName(item, 'common.global.add_to_cart', 'tooltip.add_to_cart_folder_action_limit');
              },
              disabledIf: (item: Folder) => this.isDisabled(item),
              defaultOnTileClick: false,
            }
          : null,
      ),
    );
  }

  isDisabled(item: Folder) {
    return item.subtitle && Number(item.subtitle.split(' ')[0]) > 500;
  }

  setName(item: Folder, allowedString: string, notAllowedString: string) {
    return this.isDisabled(item) ? notAllowedString : allowedString;
  }
}
