<vdms-hq-ui-layout-page-wrapper
  [breadcrumbs]="[
    {
      name: usersLibrarySchemaFormService.isEdit ? 'Edit ' + usersLibrarySchemaFormService.currentConfigName : 'Add new'
    }
  ]"
>
  <ng-container *ngIf="usersLibrarySchemaFormService.allAvailableSchema$ | async; let all">
    <vdms-hq-ui-library-schema-form [all]="all" [schemaForm]="usersLibrarySchemaFormService.form">
    </vdms-hq-ui-library-schema-form>
  </ng-container>
  <vdms-hq-ui-actions-footer>
    <vdms-hq-ui-button
      left
      color="warn"
      (click)="remove()"
      *ngIf="usersLibrarySchemaFormService.currentConfigId !== null"
    >
      {{ 'common.global.delete' | translate }}
    </vdms-hq-ui-button>
    <vdms-hq-ui-button
      left
      color="secondary"
      (click)="reset()"
      [disabled]="usersLibrarySchemaFormService.form.pristine"
    >
      {{ 'common.global.clear' | translate }}
    </vdms-hq-ui-button>
    <vdms-hq-ui-button right [disabled]="usersLibrarySchemaFormService.form.pristine" color="primary" (click)="save()">
      {{ 'common.global.save' | translate }}
    </vdms-hq-ui-button>
  </vdms-hq-ui-actions-footer>
</vdms-hq-ui-layout-page-wrapper>
