import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { DialogResponse, SelectOption } from '@vdms-hq/shared';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ResultsScopeKey } from '@vdms-hq/firebase-contract';
import { ResultsFieldsConfigService } from '../../logic/services/results-fields-config.service';

@Component({
  selector: 'vdms-hq-columns-config-dialog',
  templateUrl: './columns-config-dialog.component.html',
  styleUrls: ['./columns-config-dialog.component.scss'],
})
export class ColumnsConfigDialogComponent implements OnInit, OnDestroy {
  private resultsFieldsConfigService = inject(ResultsFieldsConfigService);
  private columnsConfigDialogRef = inject(MatDialogRef<ColumnsConfigDialogComponent>);
  public data = inject<{ scopeName: ResultsScopeKey; hiddenColumns: string[] }>(MAT_DIALOG_DATA);

  formGroup = new UntypedFormGroup({
    listSortableCheckboxValue: new UntypedFormControl([], Validators.required),
  });
  currentSettings: SelectOption[] = [];
  availableList: SelectOption[] = [];
  defaultList: SelectOption[] = [];
  isUserConfig = false;
  alwaysEnabledList = [];
  #destroyed = new Subject<void>();

  get listSortableCheckboxValues(): AbstractControl | null {
    return this.formGroup.get('listSortableCheckboxValue');
  }

  ngOnInit() {
    this.#initialize();
  }

  ngOnDestroy(): void {
    this.#destroyed.next();
    this.#destroyed.complete();
  }

  #initialize = () => {
    this.resultsFieldsConfigService
      .fieldsManagement$(this.data.scopeName, this.data.hiddenColumns)
      .pipe(take(1))
      .subscribe((config) => {
        this.currentSettings = config.currentSettings;
        this.defaultList = config.clientDefaultList;
        this.availableList = config.clientAvailableList;
        this.isUserConfig = config.isUserConfig;

        this.listSortableCheckboxValues?.patchValue(this.currentSettings.map((item) => item.key));
      });
  };

  cancel(): void {
    this.columnsConfigDialogRef.close({ status: DialogResponse.ABORT });
  }

  onSubmit() {
    this.columnsConfigDialogRef.close({
      status: DialogResponse.OK,
      columns: this.listSortableCheckboxValues?.value,
    });
  }

  selectAll() {
    this.listSortableCheckboxValues?.patchValue(this.availableList.map((item) => item.key));
  }

  deselectAll() {
    this.listSortableCheckboxValues?.patchValue([]);
  }

  revertDefaults() {
    this.listSortableCheckboxValues?.patchValue(this.defaultList.map((item) => item.key));
  }
}
