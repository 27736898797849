import { Injectable } from '@angular/core';
import { SelectOption } from '@vdms-hq/shared';
import { TableAdvancedDataSource } from '@vdms-hq/ui';
import {
  BehaviorSubject,
  debounceTime,
  combineLatest,
  shareReplay,
  tap,
  map,
  switchMap,
  Observable,
  EMPTY,
  take,
  startWith,
  of,
} from 'rxjs';
import {
  LicensedPackageFiltersView,
  LICENSED_PACKAGE_STATUS,
  LicensePackagesService,
  PaginationAPIModel as Pagination,
} from '@vdms-hq/api-contract';
import { catchError } from 'rxjs/operators';
import { ToastService } from '@vdms-hq/toast';
import { FormControl, FormGroup } from '@angular/forms';
import { RouterParamsPagination } from '@vdms-hq/view-settings';

export interface LicensePackagesAddData {
  key: string;
  label: string;
  number_of_assets: number;
  status: LICENSED_PACKAGE_STATUS;
}

@Injectable({ providedIn: 'root' })
export class LicensePackagesAddDsService
  extends RouterParamsPagination
  implements TableAdvancedDataSource<SelectOption>
{
  refresh$ = new BehaviorSubject<boolean>(true);

  isLoading$ = new BehaviorSubject<boolean>(true);
  total$ = new BehaviorSubject<number>(0);
  emptyResults$ = new BehaviorSubject(false);

  filters = new FormGroup({
    keyword: new FormControl<string>(''),
    status: new FormControl<string | null>(null),
    sort: new FormControl<string | null>('licensedPackage.createdAt_desc'),
    partners: new FormControl<string[] | null>(null),
    contracts: new FormControl<string[] | null>(null),
  });

  values$: Observable<LicensedPackageFiltersView> = this.filters.valueChanges.pipe(
    startWith(this.filters.value),
    debounceTime(400),
    switchMap(() => {
      const formValue = this.filters.value;
      const filters = <LicensedPackageFiltersView>{};

      if (formValue?.keyword) {
        filters.keyword = formValue.keyword;
      }

      if (formValue?.status) {
        filters.status = formValue.status;
      }

      if (formValue?.sort) {
        filters.sort = formValue.sort.split('_')[0];
        filters.direction = formValue.sort.split('_')[1];
      }

      if (formValue?.partners) {
        filters.partners = formValue.partners;
      }

      if (formValue?.contracts) {
        filters.contracts = formValue.contracts;
      }

      this.changePageIndex$.next(0);
      return of(filters);
    }),
  );

  allData$: Observable<LicensePackagesAddData[]> = combineLatest([
    this.values$,
    this.pageIndex$,
    this.pageSize$,
    this.refresh$,
  ]).pipe(
    tap(() => this.isLoading$.next(true)),
    switchMap(([filters, index, size]) => {
      const headers = Pagination.create({
        page: index,
        perPage: size,
        orderBy: filters.sort,
        orderDir: filters.direction,
      });

      return this.licensePackagesService.getMany(headers, filters).pipe(
        take(1),
        catchError((err) => {
          this.#errorHandler();
          throw err;
        }),
        tap((response) => {
          this.total$.next(response.total);
          this.emptyResults$.next(response.total === 0);
        }),
        map((response) =>
          response.data.map((item) => ({
            key: item.uuid,
            label: item.name,
            status: item.status,
            number_of_assets: item.number_of_assets,
          })),
        ),
      );
    }),
    tap(() => this.isLoading$.next(false)),
    shareReplay(1),
  );

  connection$ = this.allData$;

  constructor(private toastService: ToastService, private licensePackagesService: LicensePackagesService) {
    super();
  }

  refresh(): void {
    if (this.filters.dirty || this.changePageIndex$.value !== 0) {
      this.filters.reset();
      this.filters.controls['sort'].setValue('licensedPackage.createdAt_desc');
    }
  }

  #errorHandler() {
    this.toastService.error({ id: 'titlePackages', message: 'Error fetching title packages' });
    this.isLoading$.next(false);
    return EMPTY;
  }
}
