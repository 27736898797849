import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChildren,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AssetViewConfig, isFormField } from '@vdms-hq/firebase-contract';
import { Framerate } from '@vdms-hq/timecode';
import { UIEmptyResultsModule } from '@vdms-hq/ui';
import { CustomElementDirective } from '../../directives/custom-element.directive';
import { FormBuilderModule } from '../../form-builder.module';
import { ContentSettings } from '../../logic/content.model';
import { FieldRelationsService } from '../../logic/field-relations.service';
import { FormBuilderService } from '../../logic/form-builder.service';
import { CustomElementPipe } from '../../pipes/custom-element.pipe';
import { AssetType } from '@vdms-hq/api-contract';

@Component({
  selector: 'vdms-hq-elements-renderer',
  templateUrl: './elements-renderer.component.html',
  styleUrls: ['./elements-renderer.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormBuilderModule,
    UIEmptyResultsModule,
    CustomElementDirective,
    CustomElementPipe,
  ],
})
export class ElementsRendererComponent implements OnInit, OnDestroy {
  @Input() elements: AssetViewConfig['elements'] = [];
  @Input() columns = 1;
  isFormField = isFormField;
  @Input() framerate?: Framerate;
  @Input() withFooters = true;
  @Input() withStateToggle = false;
  @Input() assetType?: AssetType;
  @Input() content?: Partial<ContentSettings>;

  @ContentChildren(CustomElementDirective) customElements!: QueryList<CustomElementDirective>;

  @ViewChild('controlsPlaceholder', { read: ViewContainerRef })
  controlsPlaceholder!: ViewContainerRef;

  formGroup = this.formBuilder.formGroup;

  get isLocked() {
    return this.formBuilder.locked;
  }

  constructor(private formBuilder: FormBuilderService, public fieldRelationsService: FieldRelationsService) {}

  ngOnInit(): void {
    this.fieldRelationsService.listenToFieldRelations();
  }

  ngOnDestroy(): void {
    this.fieldRelationsService.onDestroy();
  }
}
