import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Policy, PolicyService, UserApiService, UserModelFlat } from '@vdms-hq/api-contract';
import { DestroyComponent, DialogResponse, SelectOption, SharedModule } from '@vdms-hq/shared';
import { CommonModule } from '@angular/common';
import { FormSectionComponent, UIButtonModule, UIDialogWrapperModule, UIFormModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';

export interface PolicyListDialogInput {
  user: UserModelFlat;
  policies?: Policy[];
}

@Component({
  selector: 'vdms-hq-policy-list-dialog',
  templateUrl: './policy-list-dialog.component.html',
  standalone: true,
  imports: [
    CommonModule,
    UIFormModule,
    UIDialogWrapperModule,
    SharedModule,
    UIButtonModule,
    FormSectionComponent,
    TranslateModule,
    UIFormModule,
    UIButtonModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolicyListDialogComponent extends DestroyComponent implements OnInit {
  loading$ = new BehaviorSubject(true);
  allPolicies: string[] = [];
  policies$: Observable<SelectOption[]> = this.policyService.getPolicies().pipe(
    map((policies) => policies.map((policy) => ({ key: policy.uuid, label: policy.name }))),
    tap((policies) => (this.allPolicies = policies.map((policy) => policy.key))),
    tap(() => this.loading$.next(false)),
  );
  selectedPolicies = new FormControl<string[]>([]);
  constructor(
    public dialogRef: MatDialogRef<PolicyListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PolicyListDialogInput,
    private userApiService: UserApiService,
    private policyService: PolicyService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.userApiService
      .getUser(this.data.user.uuid)
      .pipe(this.takeUntilDestroyed())
      .subscribe((user) => {
        this.selectedPolicies.setValue(user?.policies ? [...user.policies].map(({ uuid }) => uuid) : []);
      });
  }

  onSubmit(): void {
    this.dialogRef.close({
      status: DialogResponse.OK,
      user: {
        uuid: this.data.user?.uuid,
        policy_uuids: this.selectedPolicies.value,
      },
    });
  }

  toggleAll(toggle: boolean): void {
    this.selectedPolicies.setValue(toggle ? this.allPolicies : []);
  }
}
