export enum CartActions {
  REMOVE_ALL = 'remove_all',
  CHECKOUT = 'cart.checkout',
  REMOVE_FAILED_VALIDATION = 'checkout.remove_failed_validation',
  APPROVE_OVERWRITING = 'checkout.approve_overwriting',
  CHECKOUT_PREVIOUS_STEP = 'checkout.previous_step',
  CHECKOUT_NEXT_STEP = 'checkout.next_step',
  CHECKOUT_MANUAL = 'checkout.manual',
  CHECKOUT_PREDEFINED = 'checkout.predefined',
  CHECKOUT_WARM_UP = 'checkout.warm_up',
  CHECKOUT_WORKFLOW = 'checkout.workflow',
  CHECKOUT_DISCOUNT_CHECK = 'checkout.discount_check',
  CHECKOUT_DISCOUNT_RESET = 'checkout.discount_reset',
}

export type CartActionsType = (typeof CartActions)[keyof typeof CartActions];

export const AssetProcessingIcons: Record<string, string> = {
  'transcribe-video': 'movie',
  'translate-subtitles': 'closed_caption',
  'video-summary': 'library_books',
  'language-detection': 'translate',
  'video-segment-detection': 'segment',
  'content-moderation': 'picture_in_picture',
  'text-detection': 'edit_note',
  'celebrity-recognition': 'stars',
  'face-detection': 'group',
  'label-detection': 'beenhere',
  'video-black-detection': 'tv_gen',
  'video-statistics': 'signal_cellular_alt',
  'video-borders-detection-full': 'border_all',
  'video-borders-detection-quick': 'border_style',
  'audio-statistics': 'volume_down_alt',
  'video-interlacing-analyse-full': 'analytics',
  'video-interlacing-analyse-quick': 'analytics',
  'regenerate-proxy': 'cached',
};
