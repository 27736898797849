import { GroupDefaultPolicyType } from '@vdms-hq/api-contract';
import { SHARING_COLLECTION_POLICY_TYPE } from '@vdms-hq/api-contract';

export const PolicyTypeByView = (viewUrl: string): GroupDefaultPolicyType | null => {
  switch (true) {
    case viewUrl.includes('collections'):
      return SHARING_COLLECTION_POLICY_TYPE;
    default:
      return null;
  }
};
