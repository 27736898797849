import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { PersistenceSearchParams } from '@vdms-hq/api-contract';
import {
  AssetActionsService,
  AssetResults2Component,
  AssetResultsModule,
  AssetsColdErrorDialogComponent,
  ColumnsFetcherService,
  errorColdInput,
  errorColdOutput,
  errorData,
  ResultsActions,
} from '@vdms-hq/asset-results';
import { AuthService } from '@vdms-hq/auth';
import { AddToCartActionsService } from '@vdms-hq/cart-core';
import { CollectionsActionsService } from '@vdms-hq/collections';
import { FieldsConfigService } from '@vdms-hq/config';
import { ColumnSettingsScope } from '@vdms-hq/firebase-contract';
import { LicensePackagesActionsService } from '@vdms-hq/license-packages';
import { DestroyComponent, RefreshService, SortDirection } from '@vdms-hq/shared';
import { ToastService } from '@vdms-hq/toast';
import {
  ActionContextLess,
  ActionIdentifier,
  DataAction,
  Folder,
  SelectOption,
  UIButtonModule,
  UIConfirmationDialogService,
  UIFolderComponent,
  UIFormModule,
  UILayoutModule,
  UIPortalModule,
  UIResultsWrapperModule,
} from '@vdms-hq/ui';
import { combineLatest, EMPTY, Observable, Subject, switchMap } from 'rxjs';
import { debounceTime, filter, map, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { LibrarySchemaService } from '../../services/library-schema.service';
import { LibrarySegmentsService } from '../../services/library-segments.service';
import { FolderActionsService } from '../../services/folder-actions.service';
import { MatDialog } from '@angular/material/dialog';
import { LibraryAssetSearchService } from '../../services/library-asset-search-service';
import { BrowseAssetViewModel } from '@vdms-hq/asset-search';
import { LibraryResultsDsService } from '../../services/library-results-ds.service';

@Component({
  selector: 'vdms-hq-browse-library-results',
  templateUrl: './library-results.component.html',
  styleUrls: ['./library-results.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    UILayoutModule,
    UIResultsWrapperModule,
    AssetResultsModule,
    MatIconModule,
    UIFormModule,
    UIPortalModule,
    UIButtonModule,
    TranslateModule,
    FormsModule,
    RouterModule,
    UIFolderComponent,
    AssetResults2Component,
  ],
})
export class LibraryResultsComponent extends DestroyComponent implements OnInit, OnDestroy {
  private readonly fieldsConfigService = inject(FieldsConfigService);
  private readonly collectionsActionsService = inject(CollectionsActionsService);
  private dialog = inject(MatDialog);
  private columnsFetcherService = inject(ColumnsFetcherService);
  private toastService = inject(ToastService);
  private confirmationDialog = inject(UIConfirmationDialogService);
  private addToCartActionsService = inject(AddToCartActionsService);
  private activatedClientService = inject(ActivatedClientService);
  private authService = inject(AuthService);
  private assetActionsService = inject(AssetActionsService);
  private licensedPackagesActions = inject(LicensePackagesActionsService);
  private assetActionService = inject(AssetActionsService);
  private folderActionsService = inject(FolderActionsService);
  private refreshService = inject(RefreshService);
  public dataSource = inject(LibraryResultsDsService);
  public segmentService = inject(LibrarySegmentsService);
  public assetSearchService = inject(LibraryAssetSearchService);
  public schemaService = inject(LibrarySchemaService);

  destroy$ = new Subject<void>();
  scopeName: ColumnSettingsScope = 'browse-library';
  assetView = false;
  filtersForm = new FormGroup({
    text: new FormControl<string | null>(null),
    sortDirection: new FormControl<SortDirection>('asc'),
  });
  appliedParams$: Observable<PersistenceSearchParams> = this.assetSearchService.currentParams$;
  actions$: Observable<DataAction<BrowseAssetViewModel>[]> = combineLatest([
    this.collectionsActionsService.buildAddAssetToCollectionAction$<BrowseAssetViewModel>(),
    this.licensedPackagesActions.buildAddAssetAction$<BrowseAssetViewModel>(),
    this.addToCartActionsService.buildAddAssetAction$<BrowseAssetViewModel>(),
    this.assetActionService.buildDeleteAction$(),
    this.assetActionService.buildPreviewAction$<BrowseAssetViewModel>(),
    this.assetActionService.buildPlaceholderAction$<BrowseAssetViewModel>(),
    this.assetActionService.buildQAResultsAction(),
  ]).pipe(map((actions) => actions.filter((action) => action !== null)));
  clipsOnlyEnabled$ = this.activatedClientService.vida$.pipe(map((vida) => !!vida?.clipsOnly));
  tableHeaderActions$: Observable<ActionContextLess[]> = this.dataSource.total$?.pipe(
    takeUntil(this.destroy$),
    map((total) => [
      {
        key: ResultsActions.EXPORT,
        label: 'pages.assets_list.export',
        icon: 'download',
      },
      {
        key: ResultsActions.ADD_TO_LICENSED_PACKAGE,
        label:
          total <= 500 ? 'tooltip.send_filter_to_licensed_package' : 'tooltip.send_filter_to_licensed_package_limited',
        disabled: total === 0 || total > 500,
        icon: 'folder_special',
      },
    ]),
  );
  filteringSortingOptions: SelectOption[] = [
    {
      key: 'asc',
      label: 'Alphabetical order (asc)',
    },
    {
      key: 'desc',
      label: 'Alphabetical order (desc)',
    },
    {
      key: 'asc_results',
      label: 'Ascending order by results',
    },
    {
      key: 'desc_results',
      label: 'Descending order by results',
    },
  ];

  foldersActionsConfig$: Observable<DataAction<Folder>[]> = combineLatest([
    this.folderActionsService.buildAddToCollectionAction$(),
    this.folderActionsService.buildShareNewCollectionAction$(),
    this.folderActionsService.buildAddToCartAction$(),
  ]).pipe(map((actions) => actions.filter((action) => action !== null)));

  isLoading$ = combineLatest([this.dataSource.isLoading$, this.tableHeaderActions$]).pipe(
    map(([isLoading, actions]) => isLoading || !actions.length),
  );

  get textControl(): AbstractControl {
    return this.filtersForm.get('text');
  }

  ngOnInit() {
    this.#listenForFormChanges();

    this.appliedParams$.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.assetSearchService.applyParams(params);
    });
    this.segmentService.sortByText(null);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.assetSearchService.clearNativeParams();
  }

  onSegmentChange(title): void {
    this.textControl.setValue('');
    this.segmentService.updateActiveSegmentValue(title);
  }

  handleBatchAction(e: { key: string; item?: any }) {
    this.collectionsActionsService.handleAddAssetToCollectionAction<BrowseAssetViewModel>(e);
    this.addToCartActionsService.handleAddAssetAction<BrowseAssetViewModel>(e);
    this.assetActionService.handlePreviewAction<BrowseAssetViewModel>(e);
    this.assetActionService.handleDeleteAction<BrowseAssetViewModel>(e);
    this.assetActionService.handlePlaceholderAction<BrowseAssetViewModel>(e);
    this.assetActionService.handleQAResultsAction<BrowseAssetViewModel>(e);

    let assetIds: string[] = [];
    const selection = this.dataSource.selection;

    if (e.item) {
      assetIds.push(e.item.props.uuid);
    } else {
      assetIds = selection.identifiersSnapshot as string[];
    }

    switch (e.key) {
      case ResultsActions.DESELECT_ALL:
      case ResultsActions.CLEAR_SELECTION:
        selection.clear();
        break;
      case ResultsActions.BATCH_UPDATE:
        this.assetActionsService.popBatchUpdateDialog(assetIds);
        break;
      case ResultsActions.ADD_TO_COLLECTION:
        this.collectionsActionsService.addAssetsToCollection(assetIds);
        this.#clearSelectedAssets();
        break;
      case ResultsActions.ADD_TO_CART:
        this.addToCartActionsService.addAssets(assetIds.map((assetId) => ({ assetId })));
        this.#clearSelectedAssets();
        break;
      case ResultsActions.ADD_TO_LICENSED_PACKAGE:
        this.licensedPackagesActions.addAssetsToPackage(assetIds);
        this.#clearSelectedAssets();
        break;
      case ResultsActions.DELETE:
        this.assetActionService.removeSelectedAssets$(assetIds).subscribe();
        break;
      case ResultsActions.SET_AS_COLD:
        this.assetActionService
          .setAsCold(assetIds)
          .pipe(tap(() => this.#clearSelectedAssets()))
          .subscribe({
            error: (error: errorData) => {
              const errorColdMsg = 'Cannot set assets as coldOnly as long as they are in active order.';
              if (error.status === 403 && error.error.error === errorColdMsg) {
                if (error.error.data.allowed.length === 0) {
                  this.toastService.error({
                    id: 'cold_error',
                    message: 'pages.lists.cold.error_msg_no_allowed',
                  });
                  return;
                }
                this.toastService.warning({
                  id: 'cold_warning',
                  message: errorColdMsg,
                });
                const coldDialogError = this.dialog.open<
                  AssetsColdErrorDialogComponent,
                  errorColdInput,
                  errorColdOutput
                >(AssetsColdErrorDialogComponent, {
                  data: {
                    allowedAssets: error.error.data.allowed,
                    forbiddenAssets: error.error.data.forbidden,
                  },
                });
                coldDialogError.afterClosed().pipe(
                  take(1),
                  switchMap((resp) => {
                    if (resp.response) {
                      return this.assetActionService.setAsCold(error.error.data.allowed);
                    }
                    return EMPTY;
                  }),
                  tap(() => this.#clearSelectedAssets()),
                );
              }
            },
          });
        break;
      case ResultsActions.EXPORT: {
        this.confirmationDialog
          .open({
            title: 'Please confirm export action',
            message: 'If you confirm, we’ll send you a CSV with applied filters via email shortly.',
          })
          .pipe(
            filter((confirmed) => confirmed),
            withLatestFrom(
              this.activatedClientService.getColumns$(this.scopeName),
              this.columnsFetcherService.userConfig$(this.scopeName),
              this.fieldsConfigService.resultsDefinitions$,
            ),
            map(([, clientConfig, userConfig, allColumns]) => {
              const enabled = userConfig.length > 0 ? userConfig : clientConfig.default;
              return enabled.map((id) => allColumns.find((def) => def.id === id)).filter((def) => !!def);
            }),
            switchMap((fields) =>
              this.assetSearchService.export(
                fields,
                this.dataSource.sortBy$.value,
                this.dataSource.sortDirection$.value,
              ),
            ),
            tap(() =>
              this.toastService.success({
                id: 'export',
                message: 'notifications.export.done',
              }),
            ),
          )
          .subscribe({
            error: () => {
              this.toastService.error({
                id: 'export',
                message: 'notifications.export.failed',
              });
            },
          });
        break;
      }
      default: {
        console.warn('Unhandled action', e);
      }
    }
  }

  #listenForFormChanges(): void {
    this.filtersForm.valueChanges
      .pipe(
        debounceTime(200),
        tap(({ text, sortDirection }) => {
          this.segmentService.sortByText(text);
          this.segmentService.sort(sortDirection as SortDirection);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  #clearSelectedAssets(): void {
    if (!this.dataSource.selection.identifiers$?.value?.length) {
      return;
    }
    this.refreshService.refresh();
    this.dataSource.selection.clear();
  }
  handleFolderAction($event: { key: ActionIdentifier; item: Folder }) {
    this.segmentService
      .returnNewSegmentValue($event.item.title)
      .pipe(withLatestFrom(this.segmentService.breadcrumbs$, this.authService.auth$), this.takeUntilDestroyed())
      .subscribe(([next, breadcrumbs, user]) => {
        const searchParams = this.assetSearchService.persistenceQueryParamsToApiParams(next);
        switch ($event.key) {
          case 'collection':
            this.collectionsActionsService.addFolderAssetsToCollection(searchParams.filters);
            break;
          case 'share_new_collection':
            this.collectionsActionsService.createCollectionFromFolder(
              searchParams.filters,
              breadcrumbs,
              user.email,
              Number($event.item.subtitle.split(' ')[0]),
            );
            break;
          case 'cart':
            this.addToCartActionsService.addFromSearchFilters(searchParams.filters);
            break;
          default:
            console.warn('Unhandled action', $event);
        }
      });
  }
}
