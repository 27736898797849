import { Injectable, inject } from '@angular/core';
import { take, tap } from 'rxjs';
import { UserAdminDialogDataSource } from './user-admin-dialog-datasource';
import { UserApiService } from '@vdms-hq/api-contract';

@Injectable({ providedIn: 'root' })
export class UserAdminDialogService {
  private dataSource = inject(UserAdminDialogDataSource);
  private userAPIService = inject(UserApiService);

  copyPoliciesFrom(userUuid: string) {
    this.dataSource.startLoading();
    this.userAPIService
      .getUser(userUuid)
      .pipe(
        take(1),
        tap((user) => {
          if (!user) {
            throw new Error('User not found');
          }
          this.dataSource.emitSelectedPolicies(user.policies || []);
        }),
        tap(() => this.dataSource.stopLoading()),
      )
      .subscribe();
  }
}
