<vdms-hq-ui-dialog-wrapper>
  <h1 class="mb-0" title>Validation errors</h1>

  <div content class="dialog-content">
    <h2>The assets listed below did not pass validation:</h2>
    <ng-container *ngIf="!groupedErrors; else groupedErrorsView">
      <div class="error-group" *ngFor="let group of errorsGroup | keyvalue">
        <h3>{{ group.key }}</h3>
        <vdms-hq-ui-info-bar
          *ngFor="let item of group.value"
          [type]="item.type === 'error' ? InfoBarType.ERROR : InfoBarType.WARNING"
          [message]="item.message"
          [disableClose]="true"
          [clickAction]="InfoBarClickAction.DISABLE"
        ></vdms-hq-ui-info-bar>
      </div>
    </ng-container>
    <ng-template #groupedErrorsView>
      <div class="error-group">
        <p *ngFor="let item of data.errors">{{ item.assetOriginalFilename }} ({{ item.assetUuid }})</p>
        <vdms-hq-ui-info-bar
          [type]="InfoBarType.ERROR"
          [message]="data.errors[0].message"
          [disableClose]="true"
          [clickAction]="InfoBarClickAction.DISABLE"
        ></vdms-hq-ui-info-bar>
      </div>
    </ng-template>
    <h2 class="mt-1">Do you want to remove assets with <i>ERRORS</i> from shopping cart?</h2>
  </div>

  <div footer class="footer buttons-group">
    <vdms-hq-ui-button (click)="cancel()" color="secondary">{{
      'common.global.dialog.cancel' | translate
    }}</vdms-hq-ui-button>
    <vdms-hq-ui-button (click)="confirm()" color="primary" [isSubmit]="true">{{
      'common.global.dialog.remove' | translate
    }}</vdms-hq-ui-button>
  </div>
</vdms-hq-ui-dialog-wrapper>
