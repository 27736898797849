import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { SidenavService } from '../../ui-sidenav';
import { map } from 'rxjs/operators';

@Component({
  selector: 'vdms-hq-ui-site-navigation',
  templateUrl: './site-navigation.component.html',
  styleUrls: ['./site-navigation.component.scss'],
})
export class SiteNavigationComponent implements AfterViewInit {
  @ViewChild('navbar') sidenavRef!: ElementRef;
  @Input() disableVerticalScroll = false;
  state$ = this.sidenavService.sidenavStatus$.pipe(map((state) => (this.sidenavService.sidenavExist ? state : '')));

  readonly INITIAL_NAVBAR_HEIGHT = 64;
  constructor(private sidenavService: SidenavService, private cdr: ChangeDetectorRef) {}

  navbarHeight = this.INITIAL_NAVBAR_HEIGHT;

  get calcNavbarHeight() {
    return `calc(100% - ${this.navbarHeight ?? this.INITIAL_NAVBAR_HEIGHT}px)`;
  }

  ngAfterViewInit() {
    this.navbarHeight = this.sidenavRef.nativeElement.clientHeight;
    this.cdr.detectChanges();
  }
}
