import { Directive, ElementRef, inject, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[sharedE2eId]',
  standalone: true,
})
export class E2eIdDirective implements OnInit {
  @Input('sharedE2eId') e2eId?: string;

  private el = inject(ElementRef<HTMLElement>);

  ngOnInit(): void {
    if (!this.e2eId) {
      return;
    }
    this.el.nativeElement.setAttribute('data-e2e-id', this.e2eId);
  }
}
