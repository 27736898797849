<div class="filters">
  <ng-content select="[filters]"></ng-content>
</div>

<vdms-hq-ui-multiple-data-presentation
  *ngIf="configuration$ | async; let configuration"
  [dataSource]="dataSource"
  [configuration]="configuration"
  [drawerVisible]="drawerVisible"
  (action)="action.emit($event); handleSettingsAction($event)"
  (changeDefaultView)="setPreferredTableView($event)"
>
  <ng-container drawer-header>
    <ng-content select="[drawer-header]"></ng-content>
  </ng-container>
  <ng-container drawer-content>
    <ng-content select="[drawer-content]"></ng-content>
  </ng-container>
  <ng-container drawer-footer>
    <ng-content select="[drawer-footer]"></ng-content>
  </ng-container>
</vdms-hq-ui-multiple-data-presentation>
