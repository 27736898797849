import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Action } from '../../logic/common-config';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NameTransformerPipe } from '../../../ui-floating-controls';
import { UILoaderModule } from '../../../ui-loader';
import { DisabledPipe } from '../../../ui-floating-controls/component/disabled.pipe';

@Component({
  selector: 'vdms-hq-ui-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
  standalone: true,
  imports: [
    MatMenuModule,
    NgForOf,
    TranslateModule,
    NameTransformerPipe,
    NgIf,
    AsyncPipe,
    UILoaderModule,
    DisabledPipe,
  ],
})
export class ContextMenuComponent<T> {
  item!: T;
  @Input() actions: Action<T>[] = [];
  @Output() action = new EventEmitter<{ key: string; item?: T }>();
  @ViewChild(MatMenuTrigger, { static: true }) contextMenu?: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };

  emitAction($event: Event, action: Action<T>) {
    if (!this.item) {
      return;
    }
    if (action?.disabledIf && action.disabledIf(this.item)) {
      return;
    }
    $event.stopPropagation();
    $event.preventDefault();
    this.action.emit({ key: action.key, item: this.item });

    this.contextMenu?.closeMenu();
  }

  open(event: MouseEvent, item: T) {
    if (!this.contextMenu) {
      return;
    }

    this.item = item;
    this.contextMenuPosition.x = `${event.x}px`;
    this.contextMenuPosition.y = `${event.y}px`;

    this.contextMenu.menu?.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  openButtonMenu(event: PointerEvent, item: T) {
    if (!this.contextMenu) {
      return;
    }

    this.actions = [];

    this.item = item;
    this.contextMenuPosition.x = `${event.x}px`;
    this.contextMenuPosition.y = `${event.y}px`;

    this.contextMenu.menu?.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }
}
