import { AfterViewInit, Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import {
  AssetSearchService,
  Filters,
  FilterValue,
  PersistenceSearchParams,
  PersistenceSearchQuery,
} from '@vdms-hq/api-contract';
import {
  AssetActionsService,
  AssetFlatView2,
  AssetResults2Component,
  assetResultsMenuConfig2,
  AssetsColdErrorDialogComponent,
  ColumnsFetcherService,
  ContextMenuActionsService,
  errorColdInput,
  errorColdOutput,
  errorData,
  ResultsActions,
} from '@vdms-hq/asset-results';
import { AddToCartActionsService } from '@vdms-hq/cart-core';
import { CollectionsActionsService } from '@vdms-hq/collections';
import { FieldsConfigService } from '@vdms-hq/config';
import { ColumnSettingsScope, ColumnsSettingsScopes, Permission } from '@vdms-hq/firebase-contract';
import { LicensePackagesActionsService } from '@vdms-hq/license-packages';
import { E2eIdDirective, FilterDefinitionModel, RefreshService, ResultDefinitionModel } from '@vdms-hq/shared';
import { ToastService } from '@vdms-hq/toast';
import { TourItemComponent } from '@vdms-hq/tour-guide';
import {
  ActionContextLess,
  DataAction,
  DataPresentationHeaderComponent,
  FilteredResultsService,
  UIConfirmationDialogService,
  UILayoutModule,
  UIModule,
} from '@vdms-hq/ui';
import { combineLatest, EMPTY, Observable, Subject, switchMap } from 'rxjs';
import { filter, map, startWith, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { SearchResultsDataSource } from '../../logic/data-sources/search-results-ds';
import { FacetsService } from '../../logic/services/facets.service';
import { ReturnButtonService } from '../../logic/services/return-button.service';
import { DynamicFiltersModule, FiltersFetcherService } from '@vdms-hq/dynamic-filters';
import { CommonModule } from '@angular/common';
import { BrowseResultsActionsComponent } from '../../components/browse-results-actions/browse-results-actions.component';
import { BrowseAssetViewModel } from '@vdms-hq/asset-search';

@Component({
  selector: 'vdms-hq-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
  imports: [
    DynamicFiltersModule,
    CommonModule,
    UILayoutModule,
    ReactiveFormsModule,
    DataPresentationHeaderComponent,
    TranslateModule,
    AssetResults2Component,
    BrowseResultsActionsComponent,
    TourItemComponent,
    UIModule,
    E2eIdDirective,
  ],
  standalone: true,
})
export class ResultsComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly fieldsConfigService = inject(FieldsConfigService);
  private columnsFetcherService = inject(ColumnsFetcherService);
  private licensePackagesActionsService = inject(LicensePackagesActionsService);
  private toastService = inject(ToastService);
  private confirmationDialog = inject(UIConfirmationDialogService);
  private returnButtonService = inject(ReturnButtonService);
  private addToCartActionsService = inject(AddToCartActionsService);
  private activatedClientService = inject(ActivatedClientService);
  private assetActionService = inject(AssetActionsService);
  private refreshService = inject(RefreshService);
  private dialog = inject(MatDialog);
  private activatedRoute = inject(ActivatedRoute);
  private translate = inject(TranslateService);
  private contextMenuActions = inject(ContextMenuActionsService);
  public filteredResultsService = inject(FilteredResultsService);
  public collectionsActions = inject(CollectionsActionsService);
  public assetSearchService = inject(AssetSearchService);
  public dataSource = inject(SearchResultsDataSource);
  public facets = inject(FacetsService);
  public filtersFetcher = inject(FiltersFetcherService);

  protected readonly assetResultsMenuConfig2 = assetResultsMenuConfig2;

  #destroy = new Subject<void>();
  #clearAndRefresh = tap(() => {
    this.#clearSelectedAssets();
    this.refreshService.refresh();
  });
  scopeName: ColumnSettingsScope = 'browse';
  config$: Observable<FilterDefinitionModel[]> = EMPTY;
  appliedParams$: Observable<PersistenceSearchParams> = this.assetSearchService.currentParams$;
  appliedFilters$: Observable<Filters> = this.appliedParams$.pipe(map(({ query }) => query.filters));
  form = new FormControl();
  actions$: Observable<DataAction<BrowseAssetViewModel>[]> = combineLatest([
    this.collectionsActions.buildAddAssetToCollectionAction$<BrowseAssetViewModel>(),
    this.addToCartActionsService.buildAddAssetAction$<BrowseAssetViewModel>(),
    this.assetActionService.buildDeleteAction$(),
    this.assetActionService.buildPreviewAction$<BrowseAssetViewModel>(),
    this.assetActionService.buildPlaceholderAction$<BrowseAssetViewModel>(),
    this.assetActionService.buildImposeQuarantineAction$<BrowseAssetViewModel>(),
    this.assetActionService.buildLiftQuarantineAction$<BrowseAssetViewModel>(),
    this.assetActionService.buildQAResultsAction(),
  ]).pipe(map((actions) => actions.filter((action) => action !== null)));
  headerActions$: Observable<ActionContextLess[]> = this.dataSource.total$?.pipe(
    withLatestFrom(this.activatedClientService.permissions$, this.activatedClientService.coldStorageEnabled$),
    takeUntil(this.#destroy),
    map(([total, permissions, coldStorageEnabled]) => [
      {
        key: ResultsActions.EXPORT,
        label: 'pages.assets_list.export',
        icon: 'forward_to_inbox',
      },
      {
        key: ResultsActions.SEND_FILTERS_TO_LICENSED_PACKAGE,
        label:
          total <= 500 ? 'tooltip.send_filter_to_licensed_package' : 'tooltip.send_filter_to_licensed_package_limited',
        disabled: total === 0 || total > 500,
        icon: 'folder_special',
      },
      {
        key: ResultsActions.SEND_FILTERS_TO_COLD,
        label: total <= 500 ? 'tooltip.set_filtered_as_cold' : 'tooltip.set_filtered_as_cold_limited',
        disabled: total === 0 || total > 500,
        hiddenIf: () => !coldStorageEnabled || !permissions.includes(Permission.SET_ASSETS_AS_COLD_ONLY),
        icon: 'ac_unit',
      },
    ]),
  );

  configKey$ = this.activatedRoute.params.pipe(map(({ configKey }) => configKey ?? 'browse'));

  setFiltersConfigOnParamsChange$ = this.activatedRoute.params.pipe(
    map(({ configKey }) => configKey ?? 'browse'),
    tap(this.#setScope.bind(this)),
    tap(this.#setEnabledFilters.bind(this)),
  );

  setFormValuesOnAppliedParamsChange$ = combineLatest([this.appliedParams$, this.refreshService.refresh$]).pipe(
    tap(([params]) => {
      this.form.setValue(params.query.allKeyValues, { emitEvent: false });
    }),
  );

  setParamsOnFormChange$ = combineLatest([
    this.form.valueChanges.pipe(startWith(null)),
    this.refreshService.refresh$,
  ]).pipe(
    withLatestFrom(this.appliedParams$, this.fieldsConfigService.filterDefinitionsForAssetFilters$),
    map(([[nextValues, refreshValue], prevParams, filterDefinitions]) => {
      const next = prevParams.clone();

      const filters: Filters = {};

      if (nextValues) {
        Object.entries(nextValues).forEach(([key, value]) => {
          const filterDef = filterDefinitions.find((def) => def.id === key);
          if (filterDef) {
            filters[key] = { filterDef, value: value as FilterValue };
          }
        });

        next.query = PersistenceSearchQuery.fromFilters(nextValues['text'], filters, prevParams.query.hiddenFilters);
      }
      next.pageOptions.page = 0;

      return { next, refreshValue };
    }),
    tap((res) => {
      this.#clearSelectedAssets();
      this.assetSearchService.applyParams(res.next);
      if (!res.next.query?.text && !Object.keys(res.next.query?.keyValues)?.length) {
        return;
      }
      this.returnButtonService.savedRouteBeforeLeave$.next(window.location.href.split('/').splice(-1)[0]);
    }),
  );

  @ViewChild('searchListSettings') searchListSettings!: TourItemComponent;
  @ViewChild('searchOpenAsset') searchOpenAsset!: TourItemComponent;
  titleKey = 'common.ui.results.search_results';

  ngOnInit() {
    this.setFiltersConfigOnParamsChange$.pipe(takeUntil(this.#destroy)).subscribe();
  }

  ngAfterViewInit() {
    this.setFormValuesOnAppliedParamsChange$.pipe(takeUntil(this.#destroy)).subscribe();
    this.setParamsOnFormChange$.pipe(takeUntil(this.#destroy)).subscribe();
  }

  ngOnDestroy(): void {
    this.#destroy.next();
    this.#destroy.complete();
    this.assetSearchService.clearNativeParams();
  }

  onChangeAppliedDynamicFilters(filters: Filters): void {
    this.appliedParams$.pipe(take(1)).subscribe((prevParams) => {
      const next = prevParams.clone();
      next.query = PersistenceSearchQuery.fromFilters(this.form.value['text'], filters);
      next.pageOptions.page = 0;

      this.#resetBackToResultsButton(next);
      this.assetSearchService.applyParams(next);
    });
  }

  handleAction($event: { key: string; item?: AssetFlatView2 }) {
    this.assetActionService.handleQAResultsAction<AssetFlatView2>($event);
    this.assetActionService.handleImposeQuarantineAction<AssetFlatView2>($event);
    this.assetActionService.handleLiftQuarantineAction<AssetFlatView2>($event);
    this.assetActionService.handlePreviewAction<AssetFlatView2>(
      $event,
      this.appliedParams$.pipe(map((params) => params.query.allKeyValues)),
    );
    this.assetActionService.handlePlaceholderAction<AssetFlatView2>($event);
    this.collectionsActions.handleAddAssetToCollectionAction<AssetFlatView2>($event);
    this.assetActionService.handleDeleteAction<AssetFlatView2>($event);
    this.addToCartActionsService.handleAddAssetAction<AssetFlatView2>($event);
    this.contextMenuActions.handleAction($event, this.appliedParams$.pipe(map((params) => params.query.allKeyValues)));
    this.handleBatchAction({ action: $event.key as ResultsActions, item: $event.item });
  }

  handleBatchAction(e: { action: ResultsActions; item?: AssetFlatView2 }) {
    let assetIds: string[] = [];
    const selection = this.dataSource.selection;

    if (e.item) {
      assetIds.push(e.item.props.uuid);
    } else {
      assetIds = selection.identifiersSnapshot as string[];
    }

    switch (e.action as string) {
      case ResultsActions.DESELECT_ALL:
      case ResultsActions.CLEAR_SELECTION:
        selection.clear();
        break;
      case ResultsActions.BATCH_UPDATE:
        this.assetActionService.popBatchUpdateDialog(assetIds);
        break;
      case ResultsActions.ADD_TO_COLLECTION:
        this.collectionsActions
          .addAssetsToCollection$(assetIds, e.item ? e.item.props.collections : undefined, undefined, true)
          .pipe(takeUntil(this.#destroy))
          .subscribe(() => {
            this.#clearSelectedAssets();
          });
        break;
      case ResultsActions.ADD_TO_LICENSED_PACKAGE:
        this.licensePackagesActionsService.addAssetsToPackage(assetIds);
        this.#clearSelectedAssets();
        break;
      case ResultsActions.SEND_FILTERS_TO_LICENSED_PACKAGE:
        this.assetSearchService.currentParams$
          .pipe(
            take(1),
            tap((persistenceParams: PersistenceSearchParams) => {
              this.licensePackagesActionsService.addFiltersToPackage(
                PersistenceSearchParams.toApiParams(persistenceParams),
              );
            }),
          )
          .subscribe();
        this.#clearSelectedAssets();
        break;
      case ResultsActions.SEND_FILTERS_TO_COLD:
        this.assetSearchService.currentParams$
          .pipe(
            take(1),
            switchMap((persistenceParams: PersistenceSearchParams) => {
              return this.assetActionService.setFiltersAsCold(PersistenceSearchParams.toApiParams(persistenceParams));
            }),
          )
          .subscribe({
            next: () => {
              this.#clearSelectedAssets();
              this.refreshService.refresh();
            },
          });
        this.#clearSelectedAssets();
        break;
      case ResultsActions.SET_AS_COLD:
        this.assetActionService
          .setAsCold(assetIds)
          .pipe(this.#clearAndRefresh)
          .subscribe({
            error: (error: errorData) => {
              const errorColdMsg = this.translate.instant('pages.lists.cold.error_msg');
              if (error.status === 403 && error.error.error === errorColdMsg) {
                if (error.error.data.allowed.length === 0) {
                  this.toastService.error({
                    id: 'cold_error',
                    message: this.translate.instant('pages.lists.cold.error_msg_no_allowed'),
                  });
                  return;
                }
                this.toastService.warning({
                  id: 'cold_warning',
                  message: errorColdMsg,
                });
                const coldDialogError = this.dialog.open<
                  AssetsColdErrorDialogComponent,
                  errorColdInput,
                  errorColdOutput
                >(AssetsColdErrorDialogComponent, {
                  data: {
                    allowedAssets: error.error.data.allowed,
                    forbiddenAssets: error.error.data.forbidden,
                  },
                });
                coldDialogError
                  .afterClosed()
                  .pipe(
                    take(1),
                    switchMap((resp) => {
                      if (resp?.response) {
                        return this.assetActionService.setAsCold(error.error.data.allowed);
                      }
                      return EMPTY;
                    }),
                    this.#clearAndRefresh,
                  )
                  .subscribe();
              }
            },
          });
        break;
      case ResultsActions.ADD_TO_CART:
        this.addToCartActionsService.addAssets(assetIds.map((assetId) => ({ assetId })));
        this.#clearSelectedAssets();
        break;
      case ResultsActions.DELETE:
        this.assetActionService.removeSelectedAssets$(assetIds).subscribe({
          next: () => this.assetActionService.popToast.DELETE_ASSETS_SUCCESS(),
          error: () => this.assetActionService.popToast.DELETE_ASSETS_FAILED(),
        });
        break;

      case ResultsActions.EXPORT: {
        this.confirmationDialog
          .open({
            title: 'Please confirm export action',
            message: 'If you confirm, we’ll send you a CSV with applied filters via email shortly.',
          })
          .pipe(
            take(1),
            filter((confirmed) => confirmed),
            withLatestFrom(
              this.activatedClientService.getColumns$(this.scopeName),
              this.columnsFetcherService.userConfig$(this.scopeName),
              this.fieldsConfigService.resultsDefinitions$,
            ),
            map(([, clientConfig, userConfig, allColumns]) => {
              const enabled = userConfig.length > 0 ? userConfig : clientConfig.default;
              return enabled.map((id) => allColumns.find((def) => def.id === id)).filter((def) => !!def);
            }),
            switchMap((fields) =>
              this.assetSearchService.export(
                fields as ResultDefinitionModel[],
                this.dataSource.sortBy$.value,
                this.dataSource.sortDirection$.value,
              ),
            ),
            tap(() =>
              this.toastService.success({
                id: 'export',
                message: 'notifications.export.done',
              }),
            ),
          )
          .subscribe({
            error: () => {
              this.toastService.error({
                id: 'export',
                message: 'notifications.export.failed',
              });
            },
          });
        break;
      }
      case ResultsActions.BATCH_LIFT_QUARANTINE: {
        this.confirmationDialog
          .open({
            title: 'Lift quarantine',
            message: 'Are you sure you want to lift quarantine for selected assets?',
          })
          .pipe(
            take(1),
            filter((confirmed) => confirmed),
            switchMap(() => this.assetActionService.liftQuarantine(assetIds)),
          )
          .subscribe();
        break;
      }
      case ResultsActions.BATCH_IMPOSE_QUARANTINE: {
        this.confirmationDialog
          .open({
            title: 'Impose quarantine',
            message: 'Are you sure you want to impose quarantine on selected assets?',
          })
          .pipe(
            take(1),
            filter((confirmed) => confirmed),
            switchMap(() => this.assetActionService.imposeQuarantine(assetIds)),
            this.#clearAndRefresh,
          )
          .subscribe();

        break;
      }
      default: {
        console.warn('Unhandled action', e);
      }
    }
  }

  handleFooterActions($event: ResultsActions) {
    if (!$event) {
      return;
    }

    this.handleAction({ key: $event });
  }

  #clearSelectedAssets(): void {
    if (!this.dataSource.selection.identifiers$?.value?.length) {
      return;
    }
    this.dataSource.selection.clear();
  }

  #resetBackToResultsButton(next: PersistenceSearchParams): void {
    if (Object.keys(next.query.keyValues)?.length) {
      return;
    }
    this.returnButtonService.savedRouteBeforeLeave$.next(null);
  }

  #setScope(configName: string): void {
    switch (configName) {
      case 'deleted':
        this.scopeName = ColumnsSettingsScopes.PURGED_ASSETS;
        this.titleKey = 'common.ui.results.search_results_deleted';
        break;
      case 'quarantined':
        this.scopeName = ColumnsSettingsScopes.QUARANTINED_ASSETS;
        this.titleKey = 'common.ui.results.search_results_quarantined';
        break;
      default:
        this.scopeName = configName as ColumnSettingsScope;
        break;
    }
    this.dataSource.scope = this.scopeName;
  }

  #setEnabledFilters(configName: string): void {
    this.config$ = this.filtersFetcher
      .userConfig$(configName)
      .pipe(
        switchMap((userFiltersConfig) =>
          this.fieldsConfigService.enabledFiltersInSearch$(configName, userFiltersConfig),
        ),
      );
  }
}
