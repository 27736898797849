import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import {
  TileSelectableConfig,
  UiAddDialogSelectableTilesComponent,
  UIButtonModule,
  UIEmptyResultsModule,
  UILoaderModule,
} from '@vdms-hq/ui';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { ConnectableDataSource, LoadableDataSource, PageableDataSource } from '@vdms-hq/shared';
import { MatDividerModule } from '@angular/material/divider';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TranslateModule } from '@ngx-translate/core';

export type SelectableTilesDataSource<T, R> = ConnectableDataSource<T> &
  LoadableDataSource &
  PageableDataSource & {
    responseData$: Observable<{
      data: any[];
      total: number;
    }>;
    filters: R;
  };

@Component({
  selector: 'vdms-hq-ui-add-dialog-selectable-wrapper',
  templateUrl: './ui-add-dialog-selectable-wrapper.component.html',
  standalone: true,
  imports: [
    DynamicFiltersModule,
    MatDividerModule,
    AsyncPipe,
    UiAddDialogSelectableTilesComponent,
    NgIf,
    UIEmptyResultsModule,
    MatPaginatorModule,
    UILoaderModule,
    TranslateModule,
    UIButtonModule,
  ],
})
export class UiAddDialogSelectableWrapperComponent<T, R> {
  @Input() dataSource!: SelectableTilesDataSource<T, R>;
  @Input() config!: TileSelectableConfig<T>;
  @Input() selected: string[] = [];
  @Input() loadingText = '';
  @Input() hasLoader = true;
  @Input() fullWidth = true;
  @Input() fieldsConfig: DynamicFilterInput[] = [];
  @Input() emptyMessage = 'No items found';
  @Input() hasEmptyActionButton = false;
  @Input() emptyActionButtonText = '';
  @Output() emptyActionButtonClick = new EventEmitter<void>();
  @Output() selectedChangeEmit = new EventEmitter<T>();
  @Input() set filters(value: R) {
    this.#filters = value;
  }

  get filters() {
    if (!this.#filters) {
      throw new Error('Filters not set');
    }
    return this.#filters;
  }

  #filters?: R;

  selectedChange($event: T) {
    this.selectedChangeEmit.emit($event);
  }

  emptyActionButtonClicked() {
    this.emptyActionButtonClick.emit();
  }
}
