import { Timecode } from '@vdms-hq/timecode';
import { FieldConfigId, FilterDefinitionModel } from '@vdms-hq/shared';
import { Moment } from 'moment/moment';

export interface FilterRange {
  from?: number;
  to?: number;
}

export interface FilterDateRange {
  from?: Moment;
  to?: Moment;
}

export interface FilterTimecodeRange {
  from?: Timecode;
  to?: Timecode;
}

type Ranges = FilterRange | FilterDateRange | FilterTimecodeRange;
type Primitive = boolean | string | number;

export type FilterValue = Ranges | Primitive | Array<Primitive>;

type DefinedFilter = { filterDef: FilterDefinitionModel; value: FilterValue };

export const hasRangeValues = (obj: any) => {
  return obj !== null && typeof obj === 'object' && ('from' in obj || 'to' in obj) && (obj as Ranges);
};

export type Filters = Record<FieldConfigId, DefinedFilter>;
