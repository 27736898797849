<div class="empty-results" [class.compact]="type === 'compact'">
  <mat-icon *ngIf="icon && !path" class="icon">{{ icon }}</mat-icon>
  <img *ngIf="path" [ngSrc]="path" width="50" height="50" class="icon" />
  <span
    *ngIf="message"
    class="label"
    [sharedE2eId]="'empty-results-message'"
    [innerHTML]="message | translate"
    [ngClass]="{ 'label--as-cart': navigateLink && btnText }"
    [sharedE2eId]="'empty-results-message'"
  ></span>
  <vdms-hq-ui-button *ngIf="navigateLink && btnText && showButton" [routerLink]="navigateLink" color="primary">
    {{ btnText }}
  </vdms-hq-ui-button>
  <div class="content-container">
    <ng-content></ng-content>
  </div>
</div>
