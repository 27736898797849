import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { Framerate, Timecode } from '@vdms-hq/timecode';
import { map, shareReplay, tap } from 'rxjs/operators';
import { DialogResponse, SelectOption, SharedModule } from '@vdms-hq/shared';
import { UIButtonModule, UIDialogWrapperModule, UIFormModule } from '@vdms-hq/ui';
import { LoggingInfo, LoggingService } from '@vdms-hq/api-contract';
import { Observable, take } from 'rxjs';
import { includeOffsetInTimecodes, transformToLoggingInfo } from '../../logic/logging.helper';

export interface EdlDialogInput {
  isEdit: boolean;
  assetUuid: string;
  framerate: Framerate;
  log?: Partial<LoggingInfo>;
  uuid?: string;
}

@Component({
  selector: 'vdms-hq--edl-dialog',
  templateUrl: './edl-dialog.component.html',
  styleUrls: ['./edl-dialog.component.scss'],
  standalone: true,
  imports: [UIFormModule, UIDialogWrapperModule, UIButtonModule, SharedModule],
})
export class EdlDialogComponent implements OnInit {
  form = new UntypedFormGroup({
    type: new FormControl<string | null>(null, Validators.required),
    tcIn: new FormControl<Timecode | null>(null, Validators.required),
    tcOut: new FormControl<Timecode | null>(null),
    note: new FormControl<string | null>(null),
  });

  isLoading = true;
  framerate?: Framerate;

  types$: Observable<SelectOption<string>[]> = this.loggingApi.getTypesList().pipe(
    take(1),
    map((types) => types.map((type) => ({ key: type.uuid, label: type.name }))),
    tap(() => (this.isLoading = false)),
    shareReplay(1),
  );

  constructor(
    public dialogRef: MatDialogRef<EdlDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EdlDialogInput,
    private loggingApi: LoggingService,
  ) {}

  get type() {
    return this.form.get('type');
  }

  get tcIn() {
    return this.form.get('tcIn');
  }

  get tcOut() {
    return this.form.get('tcOut');
  }

  get note() {
    return this.form.get('note');
  }

  ngOnInit() {
    this.framerate = this.data.framerate;

    if (this.data.isEdit && this.data.uuid) {
      this.loggingApi
        .get(this.data.uuid)
        .pipe(take(1))
        .subscribe((log) => {
          const updatingLog = includeOffsetInTimecodes(transformToLoggingInfo(log));
          this.form.patchValue({
            tcIn: updatingLog?.timecode_in,
            tcOut: updatingLog?.timecode_out,
            note: updatingLog?.note,
            type: updatingLog?.logging_type.uuid,
          });
        });
    }

    if (this.data.log) {
      const { log } = this.data;
      this.form.patchValue({
        tcIn: log?.timecode_in,
        tcOut: log?.timecode_out,
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    let log: LoggingInfo = {
      uuid: this.data?.uuid ?? uuidv4(),
      offset: this.data.log?.offset ?? '00:00:00:00',
      logging_type: this.type?.value,
      timecode_in: this.tcIn?.value.toString(),
      timecode_out: this.tcOut?.value.toString(),
      note: this.note?.value,
    };

    if (!this.data.isEdit) {
      log = {
        ...log,
        asset: this.data.assetUuid,
      } as LoggingInfo;
    }

    this.dialogRef.close({
      status: DialogResponse.OK,
      log,
    });
  }
}
