<button
  mat-button
  type="button"
  class="cart-button"
  [matTooltip]="'tooltip.shopping_cart' | translate"
  [routerLink]="['/cart']"
  [sharedE2eId]="'cart-button'"
>
  <span class="cart-button-loader">
    <mat-progress-spinner
      mode="indeterminate"
      [strokeWidth]="2"
      [diameter]="19"
      *ngIf="isBusy$ | async"
    ></mat-progress-spinner>
  </span>

  <div
    [matBadge]="total$ | async | replaceWhenExceeds : 1000 : '999+'"
    matBadgeSize="large"
    matBadgeColor="primary"
    [matBadgeHidden]="(isBusy$ | async) || (total$ | async) <= 0"
    [matBadgeOverlap]="true"
    class="cart-button-content"
  >
    <mat-icon>shopping_cart</mat-icon>
  </div>
</button>
