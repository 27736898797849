import { inject, Injectable } from '@angular/core';
import { combineLatest, Observable, withLatestFrom } from 'rxjs';
import { WebsocketLockInterface, WebsocketLockInterfaceMessage } from '@vdms-hq/shared';
import { filter, map, share, switchMap } from 'rxjs/operators';
import { webSocket } from 'rxjs/webSocket';
import { AuthService } from '@vdms-hq/auth';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { API_CONFIG, ApiConfig } from '../config-token';

@Injectable({ providedIn: 'root' })
export class LockStreamService {
  protected auth = inject(AuthService);
  protected env = inject<ApiConfig>(API_CONFIG);
  private activatedClientService = inject(ActivatedClientService);

  readonly streamName = '{groupUuid}/locks';
  readonly connection$: Observable<WebsocketLockInterface<WebsocketLockInterfaceMessage>> = combineLatest([
    this.activatedClientService.clientId$,
    this.auth.auth$,
  ]).pipe(
    withLatestFrom(this.auth.auth$),
    switchMap(([[clientId], auth]) => {
      if (!auth) {
        return [];
      }

      return this.auth.activeToken$.pipe(map((token) => ({ clientId: clientId, token, uid: auth.id })));
    }),
    map((params) => {
      const url = new URL(`${this.env.websocketUrl}`);
      url.searchParams.set('token', `${params.token}`);
      url.searchParams.set('stream', `${this.streamName.replace('{groupUuid}', params.clientId as string)}`);
      return url;
    }),
    switchMap((url) => {
      return webSocket<WebsocketLockInterface<string>>({
        url: url.toString(),
      });
    }),
    filter((msg: WebsocketLockInterface<string> | string) => msg !== 'ping'),
    map((msg) => {
      if (typeof msg === 'string') {
        return {} as WebsocketLockInterface<string>;
      }

      return { ...msg, message: JSON.parse(msg.message) };
    }),
    share(),
  );
}
