import { HttpHeaders } from '@angular/common/http';

export interface PaginationAPIProps {
  page?: number | string | null;
  perPage?: number | string | null;
  orderBy?: string | null;
  orderDir?: string | null;
}

export class PaginationAPIModel {
  constructor(private props: PaginationAPIProps) {}
  static create(data?: PaginationAPIProps) {
    return new PaginationAPIModel({
      page: data && data?.page !== null && data?.page !== '' ? data.page : null,
      perPage: data && data?.perPage !== null && data?.perPage !== '' ? data.perPage : null,
      orderBy: data && data?.orderBy !== null && data?.orderBy !== '' ? data.orderBy : null,
      orderDir: data && data?.orderDir !== null && data?.orderDir !== '' ? data.orderDir : null,
    });
  }

  applyToHeaders(headers: HttpHeaders): HttpHeaders {
    const { page, perPage, orderBy, orderDir } = this.props;
    let newHeaders = headers;
    if (page) {
      newHeaders = newHeaders.append('page', page.toString());
    }
    if (perPage) {
      newHeaders = newHeaders.append('per-page', perPage.toString());
    }
    if (orderBy) {
      newHeaders = newHeaders.append('order-by', orderBy);
    }
    if (orderDir) {
      newHeaders = newHeaders.append('order-dir', orderDir);
    }
    return newHeaders;
  }

  toQueryParams(): string {
    const { page, perPage, orderBy, orderDir } = this.props;

    return `?page=${page}&perPage=${perPage}&orderBy=${orderBy}&orderDir=${orderDir}`;
  }
}
