import { Component, Input, ViewChild } from '@angular/core';
import { AssetSearchService, PersistenceSearchParams, PersistenceSearchQuery } from '@vdms-hq/api-contract';
import { Observable } from 'rxjs';
import { castTo, filterEmpty, SelectOption } from '@vdms-hq/shared';
import { AuthService } from '@vdms-hq/auth';
import { map, startWith, take } from 'rxjs/operators';
import { LastFiltersService } from '../../../logic/services/last-filters.service';
import { ReturnButtonService } from '../../../logic/services/return-button.service';
import { NgModel } from '@angular/forms';
import { Router } from '@angular/router';
import {
  SuffixConfig,
  AdvancedSearchStateService,
  UIFormModule,
  AdvancedSearchOverlayComponent,
  UIButtonModule,
} from '@vdms-hq/ui';
import { BdcWalkModule } from 'bdc-walkthrough';
import { TourItemComponent } from '@vdms-hq/tour-guide';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'vdms-hq-simple-query-input',
  templateUrl: './simple-query-input.component.html',
  styleUrls: ['./simple-query-input.component.scss'],
  imports: [
    CommonModule,
    UIFormModule,
    BdcWalkModule,
    TourItemComponent,
    AdvancedSearchOverlayComponent,
    OverlayModule,
    UIButtonModule,
  ],
  standalone: true,
})
export class SimpleQueryInputComponent {
  currentQuery = '';
  @Input() placeholder = 'pages.home.search.placeholder';
  @Input() alternativeAppearance = false;
  @Input() displayReturnButton = true;
  @Input() displaySearchButton = true;

  advancedSearchOpen = false;
  $castToSuffixConfig = castTo<SuffixConfig>();

  @ViewChild('input', { read: NgModel }) input?: NgModel;

  returnButtonVisibility$ = this.returnButtonService.visible$.pipe(
    startWith(false),
    map((visibility: boolean) => {
      const returnButtonVisible = visibility && this.displayReturnButton;

      if (returnButtonVisible) {
        return {
          text: 'Back to results',
          onFocus: false,
          type: 'secondary',
        };
      }

      return null;
    }),
  );

  latestQueries$: Observable<SelectOption[]> = this.lastFiltersService.getSavedSearchesSelectOption$();

  constructor(
    public assetSearchService: AssetSearchService,
    public advancedSearchStateService: AdvancedSearchStateService,
    private returnButtonService: ReturnButtonService,
    private authService: AuthService,
    private lastFiltersService: LastFiltersService,
    private router: Router,
  ) {}

  updateQuery() {
    if (this.displayReturnButton && this.returnButtonService.savedRouteBeforeLeave$.value && !this.currentQuery) {
      const previousRoute = '/browse/' + this.returnButtonService.savedRouteBeforeLeave$.value || '/browse/all';
      this.router.navigateByUrl(previousRoute);
      return;
    }

    if (!this.input?.valid) {
      return;
    }

    this.authService.userAttributes$.pipe(filterEmpty(), take(1)).subscribe((attributes) => {
      this.assetSearchService.applyParams(
        PersistenceSearchParams.initialQuery(
          this.currentQuery ? PersistenceSearchQuery.freeText(this.currentQuery) : PersistenceSearchQuery.empty(),
          attributes.vida.preferredPageSize,
        ),
      );
    });
  }

  removeAutocompleteItem(item: SelectOption) {
    this.lastFiltersService.removeSavedSearchPhrase(item);
  }
}
