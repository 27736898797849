import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UIFormModule } from '../../ui-form';
import { SharedModule } from '@vdms-hq/shared';

@Component({
  selector: 'vdms-hq-ui-fieldset',
  templateUrl: './ui-fieldset.component.html',
  styleUrls: ['./ui-fieldset.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, UIFormModule, SharedModule],
})
export class UiFieldsetComponent {
  @Input() legend!: string;
  @Input() icon?: string;
  @Input() readonly = false;
}
