import { Route } from '@angular/router';
import { LibraryRouterService } from './services/library-router.service';
import { LibrarySchemaService } from './services/library-schema.service';
import { LibraryComponent } from './pages/library/library.component';
import { LibraryResultsComponent } from './pages/results-2/library-results.component';

import { UserLibrarySchemaFormWrapperComponent } from './components/user-library-schema-form-wrapper.component';
import { UsersLibrarySchemaFormService } from './services/users-library-schema-form.service';

export const assetSearchLibraryRoutes: Route[] = [
  {
    path: '',
    component: LibraryComponent,
    children: [
      {
        path: '',
        redirectTo: 'default',
        pathMatch: 'full',
      },
      {
        path: 'add',
        pathMatch: 'full',
        children: [
          {
            path: '',
            component: UserLibrarySchemaFormWrapperComponent,
            resolve: {
              form: UsersLibrarySchemaFormService,
            },
          },
        ],
      },
      {
        path: ':schema',
        resolve: {
          schema: LibrarySchemaService,
          form: UsersLibrarySchemaFormService,
        },
        children: [
          {
            path: 'edit',
            component: UserLibrarySchemaFormWrapperComponent,
          },
          {
            path: '',
            component: LibraryResultsComponent,
            resolve: {
              segments: LibraryRouterService,
            },
          },
        ],
      },
    ],
  },
];
