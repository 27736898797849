import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'confidenceNoteType',
  pure: true,
  standalone: true,
})
export class ConfidenceNotePipe implements PipeTransform {
  private translateService = inject(TranslateService);

  transform(confidence: string | number): string | null {
    if (!confidence) {
      return null;
    }
    confidence = Number((Math.round(Number(confidence) * 100) / 100).toFixed(2));

    return this.translateService.instant(`common.player_metadata_list.confidence_note`, { confidence });
  }
}
