<div class="relative">
  <vdms-hq-ui-loader
    *ngIf="dataSource.isLoading$ | async"
    [backdrop]="true"
    mode="over-parent"
    [text]="loadingText"
  ></vdms-hq-ui-loader>
  <vdms-hq-dynamic-form-group-renderer
    [form]="filters"
    [fieldsConfig]="fieldsConfig"
    [hasLoader]="hasLoader"
    [fullWidth]="fullWidth"
  ></vdms-hq-dynamic-form-group-renderer>
  <mat-divider></mat-divider>
  <vdms-hq-ui-add-dialog-selectable-tiles
    [config]="config"
    [items]="dataSource.connection$ | async"
    [selected]="selected"
    (selectedChange)="selectedChange($event)"
  ></vdms-hq-ui-add-dialog-selectable-tiles>
  <ng-container *ngIf="dataSource.emptyResults$ && (dataSource.emptyResults$ | async)">
    <vdms-hq-ui-empty-results
      class="data__results-empty"
      [message]="emptyMessage"
      [type]="'compact'"
      [icon]="'insights'"
    ></vdms-hq-ui-empty-results>
    <vdms-hq-ui-button
      *ngIf="hasEmptyActionButton"
      class="margin-inline-auto"
      (click)="emptyActionButtonClicked()"
      color="primary"
      >{{ emptyActionButtonText | translate }}
    </vdms-hq-ui-button>
  </ng-container>
  <mat-paginator
    [pageSizeOptions]="dataSource.pageSizeOptions"
    [length]="dataSource.total$ | async"
    [pageIndex]="dataSource.pageIndex$ | async"
    [pageSize]="dataSource.pageSize$ | async"
    (page)="dataSource.pageChange($event)"
    [showFirstLastButtons]="true"
  >
  </mat-paginator>
</div>
