import {
  Directive,
  Input,
  ComponentFactoryResolver,
  ApplicationRef,
  Injector,
  AfterViewInit,
  TemplateRef,
  ViewContainerRef,
  OnDestroy,
} from '@angular/core';
import { DomPortalOutlet, TemplatePortal } from '@angular/cdk/portal';

@Directive({
  selector: '[vdmsHqPortal]',
})
export class PortalDirective implements AfterViewInit, OnDestroy {
  @Input() attachTo = '';
  private portal?: TemplatePortal;
  private host?: DomPortalOutlet;

  constructor(
    private el: TemplateRef<unknown>,
    private viewContainerRef: ViewContainerRef,
    private cfr: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
  ) {}

  ngAfterViewInit(): void {
    setTimeout((): void => this.#attach());
  }

  ngOnDestroy(): void {
    this.host?.detach();
  }

  #attach(): void {
    const element = document.querySelector(this.attachTo);
    if (!element) {
      return;
    }

    this.host = new DomPortalOutlet(element, this.cfr, this.appRef, this.injector);
    this.portal = new TemplatePortal(this.el, this.viewContainerRef);
    this.host.attach(this.portal);
  }
}
