import { AwsV4Signature } from '../upload/upload';
import { DefaultPolicies } from '../policy/policy.model';

export interface TedialSqsPriority {
  new_asset: string;
  edit_single_asset: string;
  edit_batch_assets: string;
  tedial_import: string;
  csv_batch_update: string;
}

export interface Client extends ClientRef {
  logo: ClientLogo | null;
  client_id: string | null;
  librarian_group_id?: number;
  custom_languages_enabled: boolean;
  fa_is_required: boolean;
  useHybrikData: boolean;
  default_policies?: DefaultPolicies;
  detect_labels_image: boolean;
  detect_labels_video: boolean;
  display_upload_date: boolean;
  enable_cold_storage: boolean;
  enable_stay_in_hot: boolean;
  internal_bucket_exists?: boolean;
  internal_bucket_host?: string;
  internal_bucket_name?: string;
  internal_bucket_region?: string;
  job_id?: string;
  purge_assets_safety_net_days?: number;
  mediapulse_client_name?: string;
  mediapulse_download_sqs_queue?: string;
  mediapulse_download_sqs_queue_la?: string;
  tedial_xml_sqs_queue?: string;
  tedial_xml_sqs_queue_la?: string;
  tedial_xml_sqs_queue_1mp?: string;
  tedial_sqs_priority?: TedialSqsPriority;
  mediapulse_download_sqs_queue_1mp?: string;
  edit?: boolean;
  external_supervisor_emails?: string;
  proxy_filename_source_property?: string;
  download_limit?: number;
}

export interface ClientLogo {
  uuid: string;
  file: string;
  file_type: string;
  status: GroupLogoStatusEnum;
  upload_access: AwsV4Signature;
  type?: string;
  download_link?: string; // when status uploaded (forced content disposition = attachment)
  view_link?: string; // when status uploaded
}

export interface PostGroupLogoInputInterface {
  file_name: string;
  file_type: string;
}

export interface PostGroupLogoResponseInterface {
  uuid: string;
  file: string;
  file_type: string;
  status: string;
  upload_access: AwsV4Signature;
}

export interface PatchGroupLogoInputInterface {
  group_logo_uuid: string;
  status: GroupLogoStatusEnum.ERROR | GroupLogoStatusEnum.UPLOADED;
}

export interface GroupLogoPatchRequest {
  status: GroupLogoStatusEnum.ERROR | GroupLogoStatusEnum.UPLOADED;
}

export enum GroupLogoStatusEnum {
  PENDING = 'pending',
  UPLOADED = 'uploaded',
  ERROR = 'error',
}

export interface ClientRef {
  uuid: string;
  name: string;
}

export interface ClientResponse {
  status: string;
  data: {
    id: number;
  };
}

export interface SupplierAsperaKeysResponse {
  uuid: string;
  name: string;
  key_id: string;
  supplier_uuid: string;
  group_uuid: string;
  created_by: string;
  created_at: string;
  facility_of_origin_name: string;
}

export interface SupplierAsperaKey {
  uuid: string;
  name: string;
  key_id: string;
  supplier_uuid: string;
  group_uuid: string;
  created_by: string;
  created_at: string;
  facility_of_origin_name: string;
  secret: string; // !!!!!!!!
}
