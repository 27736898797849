<mat-label class="material-custom-field-label" *ngIf="label">
  {{ label }}
</mat-label>
<vdms-hq-ui-form-input-text
  [ngModel]="''"
  (ngModelChange)="searchPhrase($event)"
  [placeholder]="'common.users.search_hint' | translate"
  [label]="'common.users.search_placeholder' | translate"
  [fallbackSuffixIcon]="'search'"
>
</vdms-hq-ui-form-input-text>
<div class="user-picker">
  <vdms-hq-ui-loader
    [backdrop]="true"
    mode="over-parent"
    *ngIf="(dataSource.isLoading$ | async) ?? false"
  ></vdms-hq-ui-loader>
  <vdms-hq-ui-form-radio-list
    [ngModel]="selectedUser"
    (ngModelChange)="selectUser($event)"
    [selectOptions]="(dataSource.allData$ | async) ?? []"
    [columns]="2"
  >
  </vdms-hq-ui-form-radio-list>
  <vdms-hq-ui-empty-results
    *ngIf="dataSource.emptyResults$ | async"
    [type]="'compact'"
    [message]="'Cannot find any users for the search phrase'"
  ></vdms-hq-ui-empty-results>
  <vdms-hq-ui-paginator [dataSource]="dataSource"></vdms-hq-ui-paginator>
</div>
