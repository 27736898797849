import { Injectable, inject } from '@angular/core';
import { ApiService } from '../api.service';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { StorageStats } from './storage-stats.model';
import { ApiResponse, ApiPaginatedResponse } from '../api.model';
import { StatsEndpoints } from './endpoints';
import { EgressStats, EgressListItem } from './egress-stats.model';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { PaginationAPIModel } from '../pagination.model';

@Injectable({ providedIn: 'root' })
export class StatsService {
  private apiService = inject(ApiService);

  getStorageStats() {
    return this.apiService.get<ApiResponse<StorageStats>>(StatsEndpoints.STORAGE).pipe(GetResponseData);
  }

  getEgressStats(lookbackPeriod?: null | number | string, transferSources?: string) {
    if (!lookbackPeriod || isNaN(Number(lookbackPeriod))) {
      lookbackPeriod = 30;
    }
    if (!transferSources) {
      transferSources = 'vida,connect2,mediapulse';
    }

    let params = new HttpParams().set('lookbackPeriod', lookbackPeriod.toString());
    params = params.append('transferSources', transferSources);
    return this.apiService.get<ApiResponse<EgressStats>>(StatsEndpoints.EGRESS, { params }).pipe(GetResponseData);
  }

  getEgressTransfersList(pagination?: PaginationAPIModel) {
    let headers = new HttpHeaders();
    headers = pagination ? pagination.applyToHeaders(headers) : headers;
    return this.apiService.get<ApiPaginatedResponse<EgressListItem>>(StatsEndpoints.EGRESS_TRANSFERS, { headers });
  }
}
