<div class="dropzone" vdmsHqStorageDragAndDrop (fileDropped)="onFilesSelected($event)" [class.no-border]="noBorders">
  <div class="dropzone-content">
    <mat-icon class="icon">cloud_upload</mat-icon>
    <h4>{{ dragLabel | translate }}</h4>
    <div class="select-file">
      <input
        #uploadInput
        [attr.accept]="acceptMimes"
        type="file"
        id="fileDropRef"
        [multiple]="multiple"
        [hidden]="true"
        (change)="onFilesSelected($event)"
      />
      <vdms-hq-ui-button color="primary" (click)="uploadInput.click()" [disabled]="disabled">
        {{ multiple ? 'pages.upload.select_manually' : ('pages.upload.select_one_manually' | translate) }}
      </vdms-hq-ui-button>
    </div>
  </div>
</div>
