import { Inject, Injectable } from '@angular/core';
import { OrderModel, OrderService } from '@vdms-hq/api-contract';
import { BehaviorSubject, EMPTY, Observable, switchMap, combineLatest } from 'rxjs';
import { ToastService } from '@vdms-hq/toast';
import { catchError, filter, map, shareReplay, take, tap } from 'rxjs/operators';
import { OrderResolverService } from './order-resolver.service';
import { OrderViewModel } from './models';
import { OrderMetadataTransformerService } from '../../logic/order-metadata-transformer';
import { LoadableDataSource } from '@vdms-hq/shared';
import { ActionContextLess, UIConfirmationDialogService } from '@vdms-hq/ui';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import {
  ACTIVATED_CLIENT_CONFIG_TOKEN,
  ActivatedClientConfig,
  ActivatedClientService,
} from '@vdms-hq/activated-client';
import { OrderActionsService } from './order-actions.service';

@Injectable()
export class CurrentOrderService implements LoadableDataSource {
  isLoading$ = new BehaviorSubject<boolean>(true);
  #enabledMetadata: string[] = [];

  id$ = this.orderParamResolver.currentIdDefinite$;
  refresh$ = new BehaviorSubject<boolean>(false);
  headerActions: any[] = [];

  data$: Observable<OrderViewModel> = combineLatest([this.orderParamResolver.currentIdDefinite$, this.refresh$]).pipe(
    filter(([id]) => !!id),
    switchMap(([id]) => this.orderService.getOrder(id).pipe(catchError((err) => this.#errorHandler(err)))),
    map((data) => this.#orderToViewModel(data)),
    tap(() => this.isLoading$.next(false)),
    tap(() => {
      combineLatest([
        this.orderActionsService.buildDownloadClearanceNotesAction$(),
        this.orderActionsService.buildApproveOrderAction$(),
        this.orderActionsService.buildAddToCartHeaderAction$(),
        this.orderActionsService.buildForceSalesforceRequestAction$(),
        this.orderActionsService.buildRedeliverOrderAction$(),
        this.orderActionsService.buildPreviewSalesforceRequestAction$(),
        this.orderActionsService.buildRetryAllDeliveryDestinationsAction$(),
      ])
        .pipe(take(1))
        .subscribe((actions) => {
          this.headerActions = actions?.filter(Boolean) as ActionContextLess[];
        });
    }),
    shareReplay(1),
  );
  constructor(
    @Inject(ACTIVATED_CLIENT_CONFIG_TOKEN) private activatedClientConfig: ActivatedClientConfig,
    private orderParamResolver: OrderResolverService,
    private orderService: OrderService,
    private toastService: ToastService,
    private metadataTransformerService: OrderMetadataTransformerService,
    private confirmationDialog: UIConfirmationDialogService,
    private router: Router,
    private activatedClientService: ActivatedClientService,
    private orderActionsService: OrderActionsService,
  ) {}

  #orderToViewModel(order: OrderModel): OrderViewModel {
    return {
      ...order,
      ...this.metadataTransformerService.getMetadata(order, this.#enabledMetadata),
    };
  }

  #errorHandler(err: HttpErrorResponse): Observable<never> {
    if ('data' in err.error && err.error?.error?.includes('group')) {
      return this.#popClientChangeDialog(err);
    }
    return this.#throwErrorToast(err);
  }

  configure(metadata: string[]) {
    this.#enabledMetadata = metadata;
  }

  #throwErrorToast(err: HttpErrorResponse) {
    if (err.status === 403) {
      this.#redirectToError();
    }

    this.toastService.error({ id: 'sharedPackAssets', message: 'common.orders.errors.order' });
    return EMPTY;
  }

  #popClientChangeDialog(err: HttpErrorResponse) {
    return this.confirmationDialog
      .open({
        title: 'Please confirm',
        message: 'The resource exists in a different client, would you like to switch there?',
      })
      .pipe(
        take(1),
        map((dialogResponse) => ({
          isConfirmed: dialogResponse,
        })),
        tap(({ isConfirmed }) => {
          if (!isConfirmed) {
            this.router.navigate(['/']);
          }
        }),
        filter(({ isConfirmed }) => isConfirmed),
        tap(() => {
          this.activatedClientService.setActivatedClientId(err.error.data);
          this.refresh$.next(!this.refresh$.value);
        }),
        switchMap(() => EMPTY),
      );
  }

  #redirectToError() {
    this.router.navigate([this.activatedClientConfig.routing.insufficientPermissionsRoute]);
  }
}
