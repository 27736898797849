import { Injectable, inject } from '@angular/core';
import { Loadable, LoadableDataSource, PageableDataSource, Pageable, RefreshService } from '@vdms-hq/shared';
import { StatsService, PaginationAPIModel, GetResponseData, EgressListItemViewModel } from '@vdms-hq/api-contract';
import { combineLatest, EMPTY, switchMap } from 'rxjs';
import { RouterParamsPagination } from '@vdms-hq/view-settings';
import { tap, map, catchError, finalize, shareReplay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EgressListDataSource
  extends Loadable(Pageable(RouterParamsPagination))
  implements LoadableDataSource, PageableDataSource
{
  private statsService = inject(StatsService);
  private refreshService = inject(RefreshService);

  response$ = combineLatest([this.pageIndex$, this.pageSize$, this.refreshService.refresh$]).pipe(
    tap(() => this.startLoading()),
    switchMap(([page, perPage]) => {
      const pagination = PaginationAPIModel.create({ page, perPage });
      return this.statsService.getEgressTransfersList(pagination).pipe(
        catchError((error) => {
          return EMPTY;
        }),
        finalize(() => this.stopLoading()),
      );
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  override total$ = this.response$.pipe(map(({ total }) => total));
  override emptyResults$ = this.total$.pipe(map((total) => total === 0));

  allData$ = this.response$.pipe(
    GetResponseData,
    map((data) => data.map((item) => EgressListItemViewModel.fromEgressListItem(item))),
    tap(() => this.stopLoading()),
  );

  connection$ = this.allData$;
}
