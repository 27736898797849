import { Subject } from 'rxjs';
import { DefaultBase } from './default-base';

export function Destroyable<T extends new (...args: any[]) => object>(Base: T = DefaultBase as T) {
  return class extends Base {
    #isDestroyed$ = new Subject<void>();
    isDestroyed$ = this.#isDestroyed$.asObservable();

    constructor(...args: any[]) {
      super(...args);
      this.destroy = this.destroy.bind(this);
    }

    destroy(): void {
      this.#isDestroyed$.next();
    }
  };
}
