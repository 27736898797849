import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { UIFormModule } from '../../ui-form.module';

type CustomizedStyleType = Partial<{
  gridTemplateColumns: string;
}>;

@Component({
  selector: 'vdms-hq-ui-form-section',
  standalone: true,
  imports: [CommonModule, MatDividerModule, UIFormModule],
  templateUrl: './form-section.component.html',
  styleUrls: ['./form-section.component.scss'],
})
export class FormSectionComponent {
  @Input() title = '';
  @Input() titleSize: 'small' | 'medium' | 'large' = 'small';
  @Input() layout:
    | 'grid-2-columns-wide'
    | 'grid-2-columns'
    | 'grid-3-columns'
    | 'grid-4-columns'
    | 'grid-5-columns'
    | 'single' = 'grid-2-columns';
  @Input() divider = true;
  @Input() dividerMarginBlock = false;
  @Input() columnSizes: string[] = [];
  @Input() icon?: string;
  @Input() subSection = false;

  get classCss(): string {
    switch (this.layout) {
      case 'grid-5-columns':
        return 'grid grid-upto5 grid-gap-x-double';
      case 'grid-4-columns':
        return 'grid grid-upto4 grid-gap-x-double';
      case 'grid-3-columns':
        return 'grid grid-upto3 grid-gap-x-double';
      case 'grid-2-columns':
        return 'grid grid-upto2 grid-gap-x-double';
      case 'grid-2-columns-wide':
        return 'grid grid-upto2 grid-gap-x-wide';
      default:
        return '';
    }
  }

  get styleCss(): CustomizedStyleType {
    const styles: CustomizedStyleType = {};
    if (this.columnSizes.filter(Boolean).length > 0) {
      styles['gridTemplateColumns'] = this.columnSizes.join(' ');
    }
    return styles;
  }
}
