import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { SelectOption, SelectOptionKey } from '@vdms-hq/shared';
import { FormControlValueAccessorComponent } from '../../models/form/inputs/form-control-value-accessor.component';
import { NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';

type OuterValue = SelectOptionKey | null | undefined;
type InnerValue = SelectOptionKey | null;

@Component({
  selector: 'vdms-hq-ui-form-radio-list',
  templateUrl: './form-radio-list.component.html',
  styleUrls: ['../../styles/columns.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormRadioListComponent),
    },
  ],
})
export class FormRadioListComponent
  extends FormControlValueAccessorComponent<OuterValue, InnerValue>
  implements OnInit
{
  innerFormControl = new UntypedFormControl(null);
  serializedOptions = [];

  options: (SelectOption & { originalKey: any })[] = [];

  @Input() set selectOptions(selectOptions: SelectOption[]) {
    if (!selectOptions) {
      return;
    }
    this.options = selectOptions.map((item) => ({
      ...item,
      key: typeof item.key === 'object' ? JSON.stringify(item.key) : item.key,
      originalKey: item.key,
    }));
  }
  @Input() propagateOuterOnClick = false; // Use it when form control is related with other form control value accessor

  @Input() orientation: 'horizontal' | 'vertical' = 'vertical';
  @Input() horizontalView = false;
  @Input() labelWidth?: string;
  @Input() columns: 1 | 2 = 1;
  get listsArray() {
    return new Array(this.columns);
  }
  override ngOnInit() {
    if (this.propagateOuterOnClick) {
      return;
    }
    super.ngOnInit();
  }

  propagateByClick(event: MatRadioChange): void {
    this.propagateTouch();
    if (!this.propagateOuterOnClick) {
      return;
    }
    this.outerValue = undefined;
    const outer = this.transformInnerToOuter(event.value);
    this.propagateToOuter(outer);
  }

  protected override transformInnerToOuter(key: InnerValue): OuterValue {
    return this.options.find((option) => option.key === key)?.originalKey;
  }

  protected override transformOuterToInner(key: OuterValue): InnerValue {
    return typeof key === 'object' ? JSON.stringify(key) : key ?? null;
  }
}
