import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssetApiService } from '@vdms-hq/api-contract';
import { AsperaTransferBatch, UploadContainerStandaloneComponent } from '@vdms-hq/storage';

@Component({
  selector: 'vdms-hq-asset-placeholder-upload-dialog',
  standalone: true,
  imports: [CommonModule, UploadContainerStandaloneComponent],
  templateUrl: './asset-placeholder-upload-dialog.component.html',
})
export class AssetPlaceholderUploadDialogComponent {
  assetApi = inject(AssetApiService);
  dialogRef = inject(MatDialogRef<AssetPlaceholderUploadDialogComponent>);
  data: { assetId: string } = inject(MAT_DIALOG_DATA);

  currentBatch?: AsperaTransferBatch;

  handleBatchUpdate(event: AsperaTransferBatch[]) {
    const [firstElement] = event;
    if (!firstElement) {
      return;
    }

    this.currentBatch = firstElement;
  }

  closeDialog() {
    if (!this.currentBatch) {
      this.dialogRef.close();
    } else {
      this.assetApi
        .changePlaceholderToAwaitingAnalyseStatus(this.currentBatch.batchId, true)
        .subscribe(() => this.dialogRef.close());
    }
  }
}
