import { inject, Injectable } from '@angular/core';
import { combineLatest, Observable, shareReplay } from 'rxjs';
import { filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { ResultsScopeKey, UserClientColumnsConfig, UserContractService } from '@vdms-hq/firebase-contract';
import { FieldsConfigService } from '@vdms-hq/config';
import { AssetFlatView2Model } from '../transformation/asset-flat-view-2.model';
import { DialogResponse, FieldConfigId, filterEmpty, ResultDefinitionModel, SelectOptionKey } from '@vdms-hq/shared';
import { ColumnsConfigDialogComponent } from '../../components/columns-config-dialog/columns-config-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@vdms-hq/auth';
import { DataColumn, GridAdvancedMetadata } from '@vdms-hq/ui';

@Injectable({
  providedIn: 'root',
})
export class ResultsFieldsConfigService extends FieldsConfigService {
  private matDialog = inject(MatDialog);
  private userContractService = inject(UserContractService);
  private authService = inject(AuthService);
  #streams: {
    getEnabledColumns$: Partial<
      Record<
        ResultsScopeKey,
        Observable<{
          definitions: DataColumn[];
          enabled: FieldConfigId[];
        }>
      >
    >;
    getGridMetadata$: Partial<Record<ResultsScopeKey, Observable<GridAdvancedMetadata<AssetFlatView2Model>[]>>>;
  } = {
    getEnabledColumns$: {},
    getGridMetadata$: {},
  };

  getGridMetadata$ = (scopeName: ResultsScopeKey) => {
    if (!this.#streams.getGridMetadata$[scopeName]) {
      this.#streams.getGridMetadata$[scopeName] = this.listConfigForScope$(scopeName).pipe(
        map((config) => this.convertToGridAdvancedArrayMetadata<AssetFlatView2Model>(config.defaultList)),
        shareReplay(1),
      );
    }

    return this.#streams.getGridMetadata$[scopeName] as Observable<GridAdvancedMetadata<AssetFlatView2Model>[]>;
  };

  getEnabledColumns$ = (scopeName: ResultsScopeKey, hiddenColumns: FieldConfigId[]) => {
    if (!this.#streams.getEnabledColumns$[scopeName]) {
      this.#streams.getEnabledColumns$[scopeName] = combineLatest([
        this.tableConfigForScope$(scopeName),
        this.#userEnabledByViewKey$(scopeName),
      ]).pipe(
        map(([clientConfig, userEnabledFieldIds]) => {
          let definitions: ResultDefinitionModel[] = [];

          if (userEnabledFieldIds) {
            const userEnabledFields = this.pairIdsWithDefinitions(userEnabledFieldIds, clientConfig.availableList);
            const userEnabledFieldsFiltered = userEnabledFields.filter((def) => !hiddenColumns.includes(def.id));
            definitions = this.pickFromList(clientConfig.availableList, userEnabledFieldsFiltered);
          } else {
            definitions = clientConfig.defaultList.filter((field) => !hiddenColumns.includes(field.id));
          }

          return {
            definitions: this.convertToColumns(definitions),
            enabled: this.convertToIds(definitions),
          };
        }),
        shareReplay(1),
      );
    }

    return this.#streams.getEnabledColumns$[scopeName] as Observable<{
      definitions: DataColumn[];
      enabled: FieldConfigId[];
    }>;
  };

  fieldsManagement$ = (scopeName: ResultsScopeKey, hiddenColumns: FieldConfigId[]) =>
    combineLatest([this.tableConfigForScope$(scopeName), this.#userEnabledByViewKey$(scopeName)]).pipe(
      take(1),
      map(([clientConfig, userEnabledFieldIds]) => {
        let definitions: ResultDefinitionModel[] = [];

        if (userEnabledFieldIds) {
          const userEnabledFields = this.pairIdsWithDefinitions(userEnabledFieldIds, clientConfig.availableList);

          definitions = userEnabledFields.filter((def) => !hiddenColumns.includes(def.id));
        } else {
          definitions = clientConfig.defaultList.filter((field) => !hiddenColumns.includes(field.id));
        }

        const availableList = [...definitions, ...this.excludeFromList(clientConfig.availableList, definitions)];

        return {
          currentSettings: this.convertToSelectOptions(definitions),
          clientDefaultList: this.convertToSelectOptions(clientConfig.defaultList),
          clientAvailableList: this.convertToSelectOptions(availableList),
          isUserConfig:
            Boolean(userEnabledFieldIds) &&
            JSON.stringify(userEnabledFieldIds) !== JSON.stringify(this.convertToIds(clientConfig.defaultList)),
        };
      }),
    );

  openColumnsSettingsPopup(scopeName: ResultsScopeKey) {
    this.#popColumnsConfigDialog(scopeName);
  }

  #userEnabledByViewKey$ = (scopeName: ResultsScopeKey): Observable<FieldConfigId[] | null> =>
    this.activatedClient.clientIdDefinite$.pipe(
      withLatestFrom(this.authService.auth$.pipe(filterEmpty())),
      switchMap(([clientId, auth]) => this.userContractService.userColumnsConfig(auth.email, clientId)),
      map((columns: UserClientColumnsConfig) => columns[scopeName] ?? null),
    );

  #popColumnsConfigDialog(scopeName: ResultsScopeKey, hiddenColumns: string[] = []) {
    const dialogRef = this.matDialog.open(ColumnsConfigDialogComponent, { data: { scopeName, hiddenColumns } });
    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        filter((data: { status: number; columns: SelectOptionKey[] }) => !!data && data.status === DialogResponse.OK),
        map((data: { columns: SelectOptionKey[] }) => data.columns),
        switchMap((columns: SelectOptionKey[]) => this.#update(columns, scopeName)),
      )
      .subscribe({
        error: (err) => {
          console.error(err);
        },
      });
  }

  #update(columnsConfig: SelectOptionKey[], scopeName: ResultsScopeKey): Observable<void> {
    return this.authService.auth$.pipe(
      filterEmpty(),
      take(1),
      withLatestFrom(this.activatedClient.clientDefinite$),
      switchMap(([user, client]) => {
        const clientLevel = client.columnsConfig.default;
        const userLevel = columnsConfig;
        const sameConfig =
          clientLevel.length === userLevel.length && clientLevel.every((value, index) => value === userLevel[index]);

        return this.userContractService.updateClient(user.email, client.uuid, {
          columnsConfig: {
            [scopeName]: sameConfig ? [] : (userLevel as string[]),
          },
        });
      }),
    );
  }
}
