<div
  class="results-container"
  [class.filters-visible]="filteredResultsService.sidebarOpened$ | async"
  [ngStyle]="{
    'grid-template-columns':
      ((filteredResultsService.sidebarOpened$ | async) === true ? leftWidth : 0) + 'px' + ' minmax(0, 1fr)'
  }"
  [sharedE2eId]="'browse-wrapper'"
>
  <div class="filters" #filters>
    <div class="filters-content">
      <ng-content select="[filters]"></ng-content>
    </div>
    <div
      class="resizer"
      *ngIf="(filteredResultsService.sidebarOpened$ | async) === true"
      cdkDrag
      cdkDragLockAxis="x"
      (cdkDragMoved)="onDragMove($event)"
    >
      <mat-icon color="primary">drag_indicator</mat-icon>
    </div>
  </div>
  <div class="results">
    <div
      *ngIf="!hiddenSwitcher"
      tabindex="0"
      class="filters__switcher"
      (click)="filteredResultsService.toggleFilters()"
      (keyup.enter)="filteredResultsService.toggleFilters()"
    >
      <ng-container *ngIf="(filteredResultsService.sidebarOpened$ | async) === true">
        <span>{{ 'common.ui.results.hide_advanced_filters' | translate }}</span>
        <mat-icon>chevron_left</mat-icon>
      </ng-container>
      <ng-container *ngIf="(filteredResultsService.sidebarOpened$ | async) === false">
        <span>{{ 'common.ui.results.show_advanced_filters' | translate }}</span>
        <mat-icon>chevron_right</mat-icon>
      </ng-container>
    </div>

    <ng-content select="[results]"></ng-content>
  </div>
</div>
