import { Routes } from '@angular/router';
import { ClientTeamsListComponent } from './pages/client-teams-list/client-teams-list.component';

export enum CLIENT_TEAMS_ROUTER_BASE {
  ROOT = 'client-teams',
}

export const clientTeamsRouting: Routes = [
  {
    path: '',
    component: ClientTeamsListComponent,
  },
  {
    path: '**',
    redirectTo: `/${CLIENT_TEAMS_ROUTER_BASE.ROOT}`,
    pathMatch: 'full',
  },
];
