<form [formGroup]="egressDashboardSettings" class="form-container" (ngSubmit)="save()">
  <vdms-hq-ui-layout-page-wrapper
    [breadcrumbs]="[
      { name: 'rate_cards.title' | translate, path: '/admin/billing', clickable: true },
      {
        name: 'rate_cards.reports_settings.title' | translate
      }
    ]"
  >
    <vdms-hq-ui-form-section
      [layout]="'single'"
      [title]="'rate_cards.egress_dashboard.section_title' | translate"
      [titleSize]="'medium'"
    >
      <vdms-hq-ui-form-input-toggle
        formControlName="enableDailyCost"
        [label]="'rate_cards.egress_dashboard.labels.display_daily_cost' | translate"
      ></vdms-hq-ui-form-input-toggle>
    </vdms-hq-ui-form-section>
  </vdms-hq-ui-layout-page-wrapper>
  <vdms-hq-ui-loader [mode]="'over-parent'" [backdrop]="true" *ngIf="isLoading$ | async"></vdms-hq-ui-loader>
  <vdms-hq-ui-actions-footer>
    <vdms-hq-ui-button right color="primary" [isSubmit]="true" [disabled]="egressDashboardSettings.pristine">
      {{ 'common.global.save' | translate }}
    </vdms-hq-ui-button>
  </vdms-hq-ui-actions-footer>
</form>
