<div
  class="messages"
  [class.no-click]="clickAction === InfoBarClickAction.DISABLE"
  *ngIf="!closed"
  (click)="disableClose && clickBar()"
  [matTooltip]="emitMsgTooltip"
  [matTooltipDisabled]="emitMsgTooltip === ''"
>
  <div class="message" [ngClass]="type">
    <div class="state-indicator">
      <mat-icon class="icon message-icon">{{
        type === InfoBarType.SUCCESS
          ? 'check_circle_outline'
          : type === InfoBarType.INFO
          ? 'info_outline'
          : 'error_outline'
      }}</mat-icon>
    </div>
    <div class="message-content" [innerHTML]="message"></div>
    <ng-container *ngIf="!disableClose">
      <mat-icon class="icon" [ngClass]="type" (click)="close()">close</mat-icon>
    </ng-container>
  </div>
</div>
