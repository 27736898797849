import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedClientService, PermissionService } from '@vdms-hq/activated-client';
import { ClientFieldConfigModel, ColumnSettingsScope, Permission, ResultsScopeKey } from '@vdms-hq/firebase-contract';
import {
  FieldDefinitionModel,
  FilterDefinitionModel,
  FilterType,
  InputDefinitionModel,
  isVisibleInForm,
  isVisibleInTable,
  isVisibleSearch,
  mergeDeep,
  ResourceModel,
  ResultDefinitionModel,
  SelectOption,
  ValueFormat,
} from '@vdms-hq/shared';
import { DataColumn, GridAdvancedMetadata } from '@vdms-hq/ui';
import { Observable } from 'rxjs';
import { map, shareReplay, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { fieldDefinitions } from './fields/field-definitions';

type TableConfig = {
  defaultList: ResultDefinitionModel[];
  availableList: ResultDefinitionModel[];
  outsideList: ResultDefinitionModel[];
  all: ResultDefinitionModel[];
  isDefault: boolean;
  isAssetTable: boolean;
};

type ListConfig = {
  defaultList: ResultDefinitionModel[];
  availableList: ResultDefinitionModel[];
  outsideList: ResultDefinitionModel[];
  all: ResultDefinitionModel[];
  isDefault: boolean;
  isAssetTable: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class FieldsConfigService {
  protected activatedClient = inject(ActivatedClientService);
  protected translateService = inject(TranslateService);
  protected permissionService = inject(PermissionService);
  #streams: {
    fieldsByViewKey$: Partial<Record<ResultsScopeKey, Observable<FieldDefinitionModel[]>>>;
    tableConfigForScope$: Partial<Record<ResultsScopeKey, Observable<TableConfig>>>;
    listConfigForScope$: Partial<Record<ResultsScopeKey, Observable<ListConfig>>>;
  } = {
    fieldsByViewKey$: {},
    tableConfigForScope$: {},
    listConfigForScope$: {},
  };

  #allFieldDefinitions$: Observable<FieldDefinitionModel[]> = this.activatedClient.fieldsValueChanges$.pipe(
    map<ClientFieldConfigModel[], FieldDefinitionModel[]>((clientFields) =>
      fieldDefinitions.map((fieldDefinition) => {
        const overriddenConfig = clientFields.find((clientField) => clientField.id === fieldDefinition.id)?.override;
        if (overriddenConfig) {
          return {
            ...fieldDefinition,
            input:
              fieldDefinition.input && overriddenConfig.input
                ? mergeDeep(fieldDefinition.input, {
                    ...overriddenConfig.input,
                  })
                : fieldDefinition.input,
            results: fieldDefinition.results2,
            filters:
              fieldDefinition.filters && overriddenConfig.filters
                ? mergeDeep(fieldDefinition.filters, {
                    ...overriddenConfig.filters,
                  })
                : fieldDefinition.filters,
            label: overriddenConfig.label ?? fieldDefinition.label,
            format: overriddenConfig.format ?? fieldDefinition.format,
            sourceListKey: overriddenConfig.sourceListKey ?? fieldDefinition.sourceListKey,
            isModified: true,
          };
        }
        return fieldDefinition;
      }),
    ),
    switchMap((items) =>
      this.translateService.get(items.map((item) => item.label)).pipe(
        map((translated) =>
          items.map((item) => ({
            ...item,
            label: translated[item.label],
          })),
        ),
      ),
    ),
    switchMap((defs) => this.activatedClient.permissions$.pipe(map((permissions) => ({ defs, permissions })))),
    map(({ defs, permissions }): FieldDefinitionModel[] => {
      return defs
        .map((item): FieldDefinitionModel => {
          if (item?.input?.writePermission) {
            item.input.readonly = !this.permissionService.verifyPermissions(
              item?.input?.writePermission?.permissions as Permission[],
              permissions,
              item.input.writePermission.comparator,
            );
            return item;
          }
          if (item?.input?.readPermission) {
            const hasReadPermission = this.permissionService.verifyPermissions(
              item.input.readPermission.permissions as Permission[],
              permissions,
              item.input.readPermission.comparator,
            );
            return hasReadPermission ? item : ({} as FieldDefinitionModel);
          }
          return item;
        })
        .filter((item) => !!item.id);
    }),
    take(1),
    shareReplay(1),
  );

  /**
   * @deprecated, will be replaced with AssetFieldsConfigService, do not use directly this method in new code
   */
  allAssetFieldDefinitions$ = this.#allFieldDefinitions$.pipe(
    map((defs) => defs.filter((def) => def.resource.toString().startsWith('ASSET_'))),
    shareReplay(1),
  );

  /**
   * @deprecated, will be replaced with AssetFieldsConfigService, do not use directly this method in new code
   */
  inputDefinitions$ = this.allAssetFieldDefinitions$.pipe(
    map((defs) => defs.filter((def) => isVisibleInForm(def)) as InputDefinitionModel[]),
  );

  /**
   * @deprecated, will be replaced with AssetFieldsConfigService, do not use directly this method in new code
   */
  resultsDefinitions$ = this.allAssetFieldDefinitions$.pipe(
    map((defs) => defs.filter((def) => isVisibleInTable(def)) as ResultDefinitionModel[]),
  );

  /**
   * @deprecated, will be replaced with AssetFieldsConfigService, do not use directly this method in new code
   */
  filterDefinitions$ = this.allAssetFieldDefinitions$.pipe(
    map((defs) => defs.filter((def) => isVisibleSearch(def)) as FilterDefinitionModel[]),
  );

  /**
   * @deprecated, will be replaced with AssetFieldsConfigService, do not use directly this method in new code
   */
  filterAggregationsDefinitions$ = this.filterDefinitions$.pipe(
    map((defs) =>
      defs.filter(
        (defs) =>
          !!defs.filters.aggregationKey &&
          [
            FilterType.SELECTOR,
            FilterType.TEXT_AUTOCOMPLETE,
            FilterType.SELECTOR_GROUP,
            FilterType.CHECKBOX_LIST,
          ].includes(defs.filters.type),
      ),
    ),
  );

  /**
   * @deprecated, will be replaced with AssetFieldsConfigService, do not use directly this method in new code
   */
  filterAggregationsDefinitionsAsOptions$: Observable<SelectOption[]> = this.filterAggregationsDefinitions$.pipe(
    map((items) =>
      items.map((item) => ({
        key: item.id,
        label: `${item.label} (${item.id})`,
      })),
    ),
  );

  /**
   * @deprecated, will be replaced with AssetFieldsConfigService, do not use directly this method in new code
   */
  filterDefinitionsForAssetFilters$: Observable<FilterDefinitionModel[]> = this.filterDefinitions$.pipe(take(1));

  /**
   * @deprecated, will be replaced with AssetFieldsConfigService, do not use directly this method in new code
   */
  filterDefinitionsForAssetFiltersAdmin$: Observable<SelectOption[]> = this.filterDefinitionsForAssetFilters$.pipe(
    map((items) =>
      items.map((item) => ({
        key: item.id,
        label: `${item.label} (${item.id})`,
      })),
    ),
  );

  /**
   * @deprecated, will be replaced with AssetFieldsConfigService, do not use directly this method in new code
   */
  columnDefinitions$: Observable<SelectOption[]> = this.resultsDefinitions$.pipe(
    map((items) =>
      items.map((item) => ({
        key: item.id,
        label: item.label,
      })),
    ),
  );

  #fieldsForScope$ = (scopeKey: ResultsScopeKey): Observable<FieldDefinitionModel[]> => {
    if (!this.#streams.fieldsByViewKey$[scopeKey]) {
      this.#streams.fieldsByViewKey$[scopeKey] = this.#allFieldDefinitions$.pipe(
        map((definitions) => {
          switch (scopeKey) {
            case 'other-licensed-packages-connect2':
              return this.filterAgainstResource(definitions, ResourceModel.LICENSED_PACKAGE_CONNECT2);
            case 'other-licensed-packages':
              return this.filterAgainstResource(definitions, ResourceModel.LICENSED_PACKAGE);
            case 'other-rights-contracts':
              return this.filterAgainstResource(definitions, ResourceModel.RIGHTS_CONTRACT);
            case 'other-rights-partners':
              return this.filterAgainstResource(definitions, ResourceModel.RIGHTS_PARTNER);
            case 'other-collections':
              return this.filterAgainstResource(definitions, ResourceModel.COLLECTION);
            case 'cart':
              return this.filterAgainstResource(definitions, ResourceModel.ASSET_CART);
            case 'preview-request':
              return this.filterAgainstResource(definitions, ResourceModel.ASSET_PREVIEW_REQUEST);
            case 'shared-packs':
              return this.filterAgainstResource(definitions, ResourceModel.ASSET_SHARED_PACK);
            case 'orders':
            case 'media-pulse-orders':
              return this.filterAgainstResource(definitions, ResourceModel.ASSET_ORDER);
            case 'license-package':
              return this.filterAgainstResource(definitions, ResourceModel.ASSET_LICENSED_PACKAGE);
            case 'upload-jobs':
              return this.filterAgainstResource(definitions, ResourceModel.ASSET_DELIVERY_UPLOAD_JOB);
            case 'deleted':
              return this.filterAgainstResource(definitions, ResourceModel.ASSET_DELETED);
            case 'default':
            case 'browse':
            case 'quarantined-assets':
            case 'purged-assets':
            case 'collections':
            case 'browse-library':
            default:
              return this.filterAgainstResource(definitions, ResourceModel.ASSET_BROWSE);
          }
        }),
        shareReplay(1),
      );
    }

    return this.#streams.fieldsByViewKey$[scopeKey] as Observable<FieldDefinitionModel[]>;
  };

  listConfigForScope$ = (scopeKey: ResultsScopeKey) => {
    if (!this.#streams.listConfigForScope$[scopeKey]) {
      this.#streams.listConfigForScope$[scopeKey] = this.#fieldsForScope$(scopeKey).pipe(
        withLatestFrom(this.activatedClient.clientDefiniteValueChanges$),
        map(([allForKey, client]) => {
          const isAssetTable = !scopeKey.startsWith('other-');
          const allForKeyResults = this.filterOnlyResultDefs(allForKey);
          const metadataConfigForScope = client?.vida?.grid?.metadataV2?.[scopeKey];
          let defaultList: ResultDefinitionModel[] = [];
          let availableList: ResultDefinitionModel[] = [];

          if (isAssetTable) {
            const columnsConfigAssetDefault = client?.vida?.grid?.metadataV2?.['default'];
            const columnsConfigLegacy = client?.vida?.grid?.metadata;
            const defaultListIds = metadataConfigForScope ?? columnsConfigAssetDefault ?? columnsConfigLegacy ?? [];

            defaultList = this.pairIdsWithDefinitions(defaultListIds, allForKeyResults);
            availableList = this.excludeFromList(allForKeyResults, defaultList);
          } else {
            defaultList = metadataConfigForScope
              ? this.pairIdsWithDefinitions(metadataConfigForScope, allForKeyResults)
              : allForKeyResults;
            availableList = this.excludeFromList(allForKeyResults, defaultList);
          }

          return <ListConfig>{
            defaultList,
            availableList,
            outsideList: [],
            all: allForKeyResults,
            isDefault: !metadataConfigForScope,
            isAssetTable,
          };
        }),
      );
    }

    return this.#streams.listConfigForScope$[scopeKey] as Observable<ListConfig>;
  };

  tableConfigForScope$ = (scopeKey: ResultsScopeKey) => {
    if (!this.#streams.tableConfigForScope$[scopeKey]) {
      this.#streams.tableConfigForScope$[scopeKey] = this.#fieldsForScope$(scopeKey).pipe(
        withLatestFrom(this.activatedClient.clientDefiniteValueChanges$),
        map(([definitions, client]) => {
          const isAssetTable = !scopeKey.startsWith('other-');

          const allForKey = this.filterOnlyResultDefs(definitions);

          if (isAssetTable) {
            this.addActionDefinition(allForKey, 'select');
            this.addActionDefinition(allForKey, 'actions');

            const columnsConfigForKey = client?.columns?.[scopeKey];
            const columnsConfigAssetDefault = client?.columns?.['default'];
            const columnsConfigLegacy = client?.columnsConfig;

            const columnsConfig = columnsConfigForKey ??
              columnsConfigAssetDefault ??
              columnsConfigLegacy ?? {
                default: [],
                available: [],
              };

            const defaultList = this.pairIdsWithDefinitions(columnsConfig.default, allForKey);
            const availableList = this.pairIdsWithDefinitions(columnsConfig.available, allForKey);
            const outsideList = this.excludeFromList(allForKey, [...defaultList, ...availableList]);

            return {
              defaultList: defaultList,
              availableList: availableList,
              outsideList: outsideList,
              all: allForKey,
              isDefault: !columnsConfigForKey,
              isAssetTable: true,
            };
          } else {
            const columnsConfigForKey = client?.columns?.[scopeKey];

            this.addActionDefinition(allForKey, 'actions');
            const defaultList = columnsConfigForKey?.['default']
              ? this.pairIdsWithDefinitions(columnsConfigForKey['default'], allForKey)
              : allForKey;

            return {
              defaultList: defaultList,
              availableList: allForKey,
              outsideList: [],
              all: allForKey,
              isDefault: !columnsConfigForKey,
              isAssetTable: false,
            };
          }
        }),
      );
    }

    return this.#streams.tableConfigForScope$[scopeKey] as Observable<TableConfig>;
  };

  protected filterAgainstResource(definitions: FieldDefinitionModel[], resourceModel: ResourceModel) {
    return definitions.filter((def) => def.resource.includes(resourceModel));
  }

  protected pairIdsWithDefinitions<T extends { id: ID }, ID = string>(ids: ID[], array: T[]): T[] {
    return ids.map((id) => array.find((item) => item.id === id)).filter((item) => !!item) as T[];
  }

  protected filterOnlyResultDefs(definitions: FieldDefinitionModel[]) {
    return definitions.filter((def) => isVisibleInTable(def)) as ResultDefinitionModel[];
  }

  protected excludeFromList<T extends { id: ID }, ID = string>(list: T[], exclude: T[]): T[] {
    return list.filter((rightItem) => !exclude.find((leftItem) => rightItem.id === leftItem.id));
  }

  protected pickFromList<T extends { id: ID }, ID = string>(list: T[], pick: T[]): T[] {
    return pick.map((item) => list.find((def) => def.id === item.id)).filter((item) => !!item) as T[];
  }

  protected convertToSelectOptions(definitions: FieldDefinitionModel[]) {
    return definitions.map((item) => ({
      key: item.id,
      label: item.label,
    }));
  }

  protected convertToIds(definitions: FieldDefinitionModel[]) {
    return definitions.map((item) => item.id);
  }

  protected convertToColumns(defs: ResultDefinitionModel[]): DataColumn[] {
    return [...defs].map((def) => {
      if (def.id === 'actions') {
        return {
          id: def.id,
          type: 'actions',
        };
      }

      if (def.id === 'select') {
        return {
          id: def.id,
          type: 'select',
        };
      }

      return {
        id: def.id,
        label: def.label,
        sortable: def.results2.sortable,
        sortObjectPath: def.results2.sortObjectPath,
        valuePath: def.results2.objectPath,
        viewFormat: def.results2.viewFormat,
        foldValues: def.results2.foldValues,
      };
    });
  }

  protected convertToGridAdvancedArrayMetadata<T>(defs: ResultDefinitionModel[]): GridAdvancedMetadata<T>[] {
    return [...defs].map((def) => this.convertToGridAdvancedMetadata(def));
  }

  protected convertToGridAdvancedMetadata<T>(def: ResultDefinitionModel): GridAdvancedMetadata<T> {
    return {
      label: def.label,
      valuePath: def.results2.objectPath,
      viewFormat: def.results2.viewFormat,
    };
  }

  private addActionDefinition(allForKey: ResultDefinitionModel[], actions: 'actions' | 'select') {
    switch (actions) {
      case 'select':
        allForKey.push({
          format: ValueFormat.AS_IS,
          id: 'select',
          label: this.translateService.instant('common.global.select'),
          resource: Object.values(ResourceModel),
          results2: {
            objectPath: '',
            sortable: false,
          },
        });
        break;
      case 'actions':
        allForKey.push({
          format: ValueFormat.AS_IS,
          id: 'actions',
          label: this.translateService.instant('common.global.actions'),
          resource: Object.values(ResourceModel),
          results2: {
            objectPath: '',
            sortable: false,
          },
        });
        break;
    }
  }

  /**
   * @deprecated, will be replaced with AssetFieldsConfigService, do not use directly this method in new code
   */
  enabledFiltersInSearch$ = (
    configKey: string,
    userFiltersConfig: string[] = [],
  ): Observable<FilterDefinitionModel[]> =>
    this.filterDefinitions$.pipe(
      withLatestFrom(this.activatedClient.searchConfig$, this.activatedClient.clientFilters$),
      map(([definitions, config, clientFiltersConfig]) => {
        const filtersConfig = userFiltersConfig.length
          ? userFiltersConfig
          : clientFiltersConfig?.[configKey] ?? config?.filters ?? [];
        return filtersConfig.map((id) => definitions.find((item) => item.id === id)).filter((item) => !!item);
      }),
      take(1),
      map(
        (fields) =>
          <FilterDefinitionModel[]>[
            // todo move to config
            {
              id: 'text',
              label: 'Keywords',
              filters: {
                objectPath: 'text',
                validFormat: 'keyword',
                type: FilterType.MASTER_TEXT,
              },
            },
            ...fields,
          ],
      ),
      shareReplay(1),
    );

  /**
   * @deprecated, will be replaced with AssetFieldsConfigService, do not use directly this method in new code
   */
  availableFieldDefinitionsColumnsForMultipleTableViews$ = (scopeName: ColumnSettingsScope) =>
    this.resultsDefinitions$.pipe(
      withLatestFrom(this.activatedClient.clientColumnsValueChanges$, this.activatedClient.columnsConfigValueChanges$),
      map(([definitions, clientConfig, legacyConfig]) => {
        const config = clientConfig?.[scopeName] ??
          clientConfig?.['default'] ??
          legacyConfig ?? {
            available: [],
            enabled: [],
          };
        return definitions.filter((def) => config.available.includes(def.id)) as ResultDefinitionModel[];
      }),
      shareReplay(1),
    );
}
