import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { SelectOption, SelectOptionKey } from '@vdms-hq/shared';
import { SelectorSourceType } from '../../logic/selector-source.type';
import { DataProviderService } from '../../logic/data-provider.service';
import { FormControlValueAccessorComponent } from '@vdms-hq/ui';
import { NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { AssetType } from '@vdms-hq/api-contract';

type OuterValue = SelectOptionKey | null | undefined;
type InnerValue = SelectOptionKey | null;

@Component({
  selector: 'vdms-hq-selector',
  templateUrl: './selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectorComponent),
    },
  ],
})
export class SelectorComponent
  extends FormControlValueAccessorComponent<OuterValue, InnerValue>
  implements OnInit, OnChanges
{
  innerFormControl = new UntypedFormControl(null);
  @Input() sourceType!: SelectorSourceType;
  @Input() multiple = false;
  @Input() enableDeselectAll = false;
  @Input() context: 'search' | 'forms' = 'forms';
  @Input() nullValue = true;
  @Input() virtualScroll = false;
  @Input() filterMethod?: (items: SelectOption[]) => SelectOption[];
  @Input() assetType?: AssetType;

  loading$?: Observable<boolean>;
  list$: BehaviorSubject<Observable<SelectOption[]>> = new BehaviorSubject<Observable<SelectOption[]>>(of([]));

  constructor(private provider: DataProviderService, injector: Injector, changeDetectorRef: ChangeDetectorRef) {
    super(injector, changeDetectorRef);
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.provider) {
      return;
    }

    this.loading$ = this.provider.isLoading(this.sourceType);
    this.list$.next(
      this.provider.listForSelectors(
        this.sourceType,
        this.context,
        this.multiple,
        this.nullValue,
        this.filterMethod,
        this.assetType,
      ),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.filterMethod) {
      return;
    }

    this.list$.next(
      this.provider.listForSelectors(this.sourceType, this.context, this.multiple, this.nullValue, this.filterMethod),
    );
  }
}
