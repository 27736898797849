import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fieldLabel',
  pure: true,
  standalone: true,
})
export class LabelPipe implements PipeTransform {
  transform(field: string, type: 'visible_data' | 'secured_data' = 'visible_data'): string {
    return `common.credentials.form.${type}.${field}`;
  }
}
