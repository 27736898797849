import { inject, Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, map, startWith, take, tap, withLatestFrom } from 'rxjs';
import { PlayerMetadataListSource } from './metadata-list.model';
import { ActivatedRoute, Router } from '@angular/router';
import { MetadataListConfigService } from './metadata-list-config.service';
import { TranslateService } from '@ngx-translate/core';

export type MetadataListFilters = {
  type: string[];
  phrase: string[];
  confidence: number;
};

@Injectable({ providedIn: 'root' })
export class MetadataListFiltersService {
  configService = inject(MetadataListConfigService);
  translateService = inject(TranslateService);
  activatedRoute = inject(ActivatedRoute);
  router = inject(Router);
  typeOptions$ = this.configService.configDefinite$.pipe(map((config) => config.types));

  confidenceOptions = [
    {
      key: '100',
      label: '100%',
    },
    {
      key: '95',
      label: '95% +',
    },
    {
      key: '90',
      label: '90% +',
    },
    {
      key: '85',
      label: '85% +',
    },
    {
      key: '80',
      label: '80% +',
    },
  ];

  filters = new FormGroup({
    type: new FormControl<(PlayerMetadataListSource | string)[]>([], Validators.required),
    phrase: new FormControl<string[]>([]),
    confidence: new FormControl<string>(this.confidenceOptions[4].key),
  });

  values$ = this.filters.valueChanges.pipe(
    debounceTime(400),
    startWith(this.filters.value),
    withLatestFrom(this.activatedRoute.queryParams),
    map(([, params]) => {
      const filters = <MetadataListFilters>{};
      if (this.filters.invalid) {
        return filters;
      }

      const formValue = this.filters.value;

      if (formValue?.phrase) {
        filters.phrase = formValue.phrase;
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: {
            ...params,
            phrase: formValue.phrase,
          },
          queryParamsHandling: 'merge',
        });
      }

      if (formValue.type) {
        filters.type = formValue.type;
      }

      if (formValue.confidence) {
        filters.confidence = Number(formValue.confidence);
      }

      return filters;
    }),
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
  );

  initialize() {
    this.configService.configDefinite$
      .pipe(
        take(1),
        withLatestFrom(this.activatedRoute.queryParams),
        tap(([config, params]) => {
          const phraseParams = params['transcriptions'] ?? params['phrase'];
          const phrase = [];
          if (phraseParams) {
            if (Array.isArray(phraseParams)) {
              phrase.push(...phraseParams);
            } else {
              phrase.push(phraseParams);
            }
          }

          let availableTypes = config.defaultStream;

          if (availableTypes.includes('transcribe')) {
            const availableLanguages = config.enabledStreams.filter(
              (stream) => !Object.values(PlayerMetadataListSource).includes(stream as PlayerMetadataListSource),
            );
            availableTypes = [...availableTypes, ...availableLanguages];
          }

          this.filters.patchValue({
            type: availableTypes,
            phrase,
          });
        }),
      )
      .subscribe();
  }

  reset() {
    this.filters.setValue({
      type: [],
      phrase: [],
      confidence: this.confidenceOptions[4].key,
    });
  }
}
