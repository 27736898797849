import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { BehaviorSubject, distinctUntilChanged, take } from 'rxjs';
import { filterEmpty } from '@vdms-hq/shared';

@Injectable({ providedIn: 'root' })
export class OrderResolverService implements Resolve<string | null> {
  #currentId$ = new BehaviorSubject<string | null | undefined>(null);
  currentIdDefinite$ = this.#currentId$.pipe(filterEmpty(), distinctUntilChanged());

  resolve(route: ActivatedRouteSnapshot) {
    const orderId = route.paramMap.get('orderId') as string;

    if (!orderId) {
      return null;
    }

    this.#currentId$.next(orderId);

    return orderId;
  }
}
