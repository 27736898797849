import { inject, Injectable } from '@angular/core';
import { TranscriptionService, modelContext, VideoAnalysisResultsResponse } from '@vdms-hq/api-contract';
import { combineLatest, forkJoin, map, Observable, of, shareReplay, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastService } from '@vdms-hq/toast';
import { TranslateService } from '@ngx-translate/core';
import { MetadataListConfigService } from '../metadata-list-config.service';
import { MetadataListFiltersService } from '../metadata-list-filters.service';
import { fromTranscription } from './legacy-media-insights-transformer';

export enum StreamsType {
  MI_TRANSCRIPTION = 'mi_transcription',
  MI_SUBTITLES = 'mi_subtitles',
  MI_OBJECTS_RECOGNITION = 'mi_objects_recognition',
  MI_CELEBRITY_RECOGNITION = 'mi_celebrity_recognition',
  MI_TECHNICAL_CUE_DETECTION = 'mi_technical_cue_detection',
  MI_SHOT_DETECTION = 'mi_shot_detection',
  CONTENT_MODERATION = 'content_moderation',
  TRANSCRIPTION = 'transcription',
}

@Injectable({ providedIn: 'root' })
export class LegacyStreamsSourceService {
  private transcriptionService = inject(TranscriptionService);
  private toastService = inject(ToastService);
  private translateService = inject(TranslateService);
  private metadataListInputService = inject(MetadataListConfigService);
  private metadataListForm = inject(MetadataListFiltersService);

  #sources$: Observable<VideoAnalysisResultsResponse[]> = combineLatest([
    this.metadataListInputService.configDefinite$,
    this.metadataListForm.values$,
  ]).pipe(
    switchMap(([{ assetUuid }]) => {
      if (!assetUuid) {
        return [];
      }

      const streamSources: Observable<VideoAnalysisResultsResponse[]>[] = [
        this.transcriptionService.listForAsset(assetUuid).pipe(
          map((transcription) => transcription.filter((transcription) => transcription.status === 'complete')[0]?.uuid),
          switchMap((transcriptionUuid) =>
            this.transcriptionService.transcriptionItems(transcriptionUuid).pipe(shareReplay(1)),
          ),
          map((items) => items.map((item) => fromTranscription(item, modelContext.TRANSCRIBE))),
          catchError((err) => this.#errorHandler(StreamsType.TRANSCRIPTION, err)),
          shareReplay(1),
        ),
      ];

      return forkJoin(streamSources);
    }),
    map((sources) => sources.reduce((acc, source) => [...acc, ...source], [])),
    map((items) => items.sort((a, b) => a.start - b.start)),
  );

  getStreams = (): Observable<VideoAnalysisResultsResponse[]> => {
    return this.#sources$.pipe(shareReplay(1));
  };

  #emptyData(type: StreamsType) {
    this.toastService.warning({
      id: 'playerMetadataList',
      message: 'common.player_metadata_list.stream_error',
      interpolatedParams: {
        type: this.translateService.instant(`common.player_metadata_list.stream_type_label.${type}`),
      },
    });
  }

  #errorHandler(type: StreamsType, err?: Error) {
    if (err !== undefined) {
      console.error(type, err);
    } else {
      this.#emptyData(type);
    }

    return of([]);
  }
}
