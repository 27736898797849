import { FieldsConfigService } from '@vdms-hq/config';
import { map } from 'rxjs/operators';
import { ResultDefinitionModel } from '@vdms-hq/shared';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataColumn, GridAdvancedAdditionalInfo, GridAdvancedMetadata } from '@vdms-hq/ui';
import { LICENSED_PACKAGE_VIEW_STATE, LicensedPackage } from '@vdms-hq/api-contract';

@Injectable({
  providedIn: 'root',
})
export class LicensePackageFieldsConfigService extends FieldsConfigService {
  tableColumnsConfig$: Observable<{
    all: DataColumn[];
    enabled: string[];
  }> = this.tableConfigForScope$('other-licensed-packages').pipe(
    map((config) => ({
      all: this.convertToColumns(config.all),
      enabled: this.convertToIds(config.defaultList),
    })),
  );

  gridConfig$: Observable<{
    metadata: GridAdvancedMetadata<LicensedPackage>[];
    additional: GridAdvancedAdditionalInfo;
  }> = this.listConfigForScope$('other-licensed-packages').pipe(
    map((config) => {
      const additinalDef = config.all.find((def) => def.id === 'licensedPackageStatus') as ResultDefinitionModel;

      const metadata = config.defaultList.map((definition) => {
        let hiddenIf: GridAdvancedMetadata<LicensedPackage>['hiddenIf'];

        if (definition.id === 'licensedPackageViewState') {
          hiddenIf = (item) => item.view_state === LICENSED_PACKAGE_VIEW_STATE.NONE;
        }
        return {
          ...this.convertToGridAdvancedMetadata(definition),
          hiddenIf,
        };
      });

      return {
        metadata: metadata,
        additional: this.convertToGridAdvancedMetadata(additinalDef),
      };
    }),
  );
}
