import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RightsPartnersResultsMultiDs } from '../../logic/rights-partners-results-multi-ds.service';
import { RightsPartnersActionsService } from '../../logic/rights-partners-actions.service';
import {
  ActionContextLess,
  DataAction as Action,
  DataPresentationHeaderComponent,
  DefaultMultiViewType,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  ResultsWrapperViewConfiguration,
  UIButtonModule,
  UILayoutModule,
} from '@vdms-hq/ui';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { DestroyComponent, FilterType, ResourceModel, ValueFormat } from '@vdms-hq/shared';
import { RightsPartnerListItem } from '@vdms-hq/api-contract';
import { TranslateModule } from '@ngx-translate/core';
import { Permission } from '@vdms-hq/firebase-contract';
import { ActivatedClientModule, PermissionService } from '@vdms-hq/activated-client';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TABLE_TYPE, ViewSettingsService } from '@vdms-hq/view-settings';
import { RightsPartnersFieldsConfigService } from '../../logic/rights-partners-fields-config.service';

@Component({
  selector: 'vdms-hq-rights-partners-results',
  standalone: true,
  imports: [
    CommonModule,
    DynamicFiltersModule,
    MultipleDataPresentationComponent,
    TranslateModule,
    UIButtonModule,
    UILayoutModule,
    ActivatedClientModule,
    DataPresentationHeaderComponent,
  ],
  templateUrl: './rights-partners-results.component.html',
  styles: [],
})
export class RightsPartnersResultsComponent extends DestroyComponent {
  public rightsPartnersResultsMultiDs = inject(RightsPartnersResultsMultiDs);
  private rightsPartnersActionsService = inject(RightsPartnersActionsService);
  private viewSettingsService = inject(ViewSettingsService);
  private permissionsService = inject(PermissionService);
  private fieldsConfigService = inject(RightsPartnersFieldsConfigService);

  protected readonly Permission = Permission;

  headerActions$: Observable<ActionContextLess[]> = this.permissionsService
    .verifyWithOwnedPermissions$([Permission.CREATE_RIGHTS_PARTNERS])
    .pipe(
      map((hasPermission) =>
        hasPermission
          ? [
              {
                key: 'create',
                label: 'common.rights_partners.table.actions.create',
              },
            ]
          : [],
      ),
    );

  #actions: Observable<Action<RightsPartnerListItem>[]> = combineLatest([
    this.permissionsService.verifyWithOwnedPermissions$([Permission.EDIT_RIGHTS_PARTNERS]),
    this.permissionsService.verifyWithOwnedPermissions$([Permission.DELETE_RIGHTS_PARTNERS]),
  ]).pipe(
    this.takeUntilDestroyed(),
    map(([canEdit, canDelete]) => {
      const actions: Action<RightsPartnerListItem>[] = [];
      if (canEdit) {
        actions.push({
          key: 'edit',
          label: 'common.rights_partners.table.actions.edit',
          icon: 'edit',
        });
      }
      if (canDelete) {
        actions.push({
          key: 'delete',
          label: 'common.rights_partners.table.actions.delete',
          icon: 'delete',
        });
      }

      return actions;
    }),
  );

  protected readonly filtersConfig: DynamicFilterInput[] = [
    {
      id: 'keyword',
      label: 'common.rights_partners.table.filter_placeholder',
      resource: [ResourceModel.RIGHTS_PARTNER],
      format: ValueFormat.AS_IS,
      filters: {
        objectPath: 'name',
        validFormat: 'keyword',
        type: FilterType.MASTER_TEXT,
      },
    },
  ];

  rightsPartnersConfig$: Observable<MultipleViewConfiguration<RightsPartnerListItem>> = combineLatest([
    this.#actions,
    this.viewSettingsService.defaultViewFor$(TABLE_TYPE.LICENSEES_PARTNER),
    this.fieldsConfigService.tableColumnsConfig$,
  ]).pipe(
    map(([actions, defaultView, columnsConfig]) => {
      return {
        multiView: {
          defaultView: defaultView,
          emptyResults: {
            message: 'Could not find any Licensees',
            icon: 'stars',
          },
        },
        tableAdvanced: {
          actions: actions,
          layout: {
            headers: true,
          },
          columnsEnabled: columnsConfig.enabled,
          columns: columnsConfig.all,
        },
        gridAdvanced: {
          actions: actions,
          layout: {
            columns: 'up-to-2',
          },
          content: {
            titlePath: 'name',
          },
        },
      };
    }),
  );

  setPreferredTableView($event: DefaultMultiViewType) {
    this.viewSettingsService.saveDefaultViewFor(TABLE_TYPE.LICENSEES_PARTNER, $event);
  }

  actionHandler(event: { key: string; item?: RightsPartnerListItem }): boolean {
    switch (event.key) {
      case 'create':
        this.rightsPartnersActionsService.createDialog();
        return true;
      case 'edit':
        if (!event.item) {
          return false;
        }
        this.rightsPartnersActionsService.editDialog(event.item?.uuid);
        return true;
      case 'delete':
        if (!event.item) {
          return false;
        }
        this.rightsPartnersActionsService.deleteDialog(event.item.uuid, event.item.name);
        return true;
      default:
        return false;
    }
  }
}
