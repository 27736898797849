import { DestinationConfigModel, CartValidationError, ORDER_TYPE } from '@vdms-hq/api-contract';
import { ValidationErrorList } from '../components/order-dialog/order-dialog.component';

export interface ValidationErrorsOutput {
  validationErrorList: ValidationErrorList[];
  errorUuids: string[];
}

export const ddCartErrorsChecks = (
  validationResponse: CartValidationError<ORDER_TYPE>[],
  configs: DestinationConfigModel[],
): ValidationErrorsOutput => {
  let validationErrorList: ValidationErrorList[] = [];
  let errorUuids: string[] = [];

  const hasError = validationResponse.filter(({ type }) => type === 'ERR');
  if (hasError?.length) {
    let allErrorUuids: string[] = [];
    const errorMessages: ValidationErrorList[] = hasError.map((errMessage) => {
      const label = configs.find((config) => config.uuid === errMessage.extra)?.name || '';
      allErrorUuids = [...allErrorUuids, ...(errMessage.cartItemUuids ?? [])];
      return {
        type: 'error',
        message: `Config ${label} error: ${errMessage.note}`,
        assetUuid: errMessage.assetUuid,
        cartItemUuids: errMessage.cartItemUuids,
        assetOriginalFilename: errMessage.assetOriginalFilename,
      };
    });
    errorUuids = [...new Set(allErrorUuids)];
    validationErrorList = [...errorMessages];
  }

  const hasWarning = validationResponse.filter(({ type }) => type === 'WARN');
  if (hasWarning?.length) {
    const warningMessages: ValidationErrorList[] = hasWarning.map((errMessage) => {
      const label = configs.find((config) => config.uuid === errMessage.extra)?.name || '';
      return {
        type: 'warning',
        message: `Config ${label} warning: ${errMessage.note}`,
        assetUuid: errMessage.assetUuid,
        cartItemUuids: errMessage.cartItemUuids,
        assetOriginalFilename: errMessage.assetOriginalFilename,
      };
    });
    validationErrorList = [...validationErrorList, ...warningMessages];
  }

  return {
    validationErrorList,
    errorUuids,
  };
};
