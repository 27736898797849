import { Component, inject } from '@angular/core';

import { SchemaFormComponent, UIButtonModule, UILayoutModule } from '@vdms-hq/ui';
import { UsersLibrarySchemaFormService } from '../services/users-library-schema-form.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'vdms-hq-library-ui-schema-form-wrapper',
  templateUrl: './user-library-schema-form-wrapper.component.html',
  standalone: true,
  imports: [SchemaFormComponent, CommonModule, UILayoutModule, UIButtonModule, TranslateModule],
})
export class UserLibrarySchemaFormWrapperComponent {
  public usersLibrarySchemaFormService = inject(UsersLibrarySchemaFormService);

  remove() {
    this.usersLibrarySchemaFormService.remove();
  }

  reset() {
    this.usersLibrarySchemaFormService.resetForm();
  }

  save() {
    this.usersLibrarySchemaFormService.save();
  }
}
