import {
  TranscriptionItem,
  modelContext,
  VideoAnalysisModerationLabels,
  VideoAnalysisResultsResponse,
} from '@vdms-hq/api-contract';

const emptyItem = 'N/A';

export const fromContentModeration = (
  item: VideoAnalysisModerationLabels,
  type: modelContext,
): VideoAnalysisResultsResponse => {
  const content =
    `${item.ModerationLabel.Name}` + (item.ModerationLabel.ParentName ? `(${item.ModerationLabel.ParentName})` : '') ||
    emptyItem;

  return {
    id: 0,
    start: Number(item.Timestamp / 1000),
    type: type,
    confidence: Number(item.ModerationLabel.Confidence ?? 0),
    data: {
      content,
    },
  };
};

export const fromTranscription = (item: TranscriptionItem, type: modelContext): VideoAnalysisResultsResponse => {
  const content = item.content || emptyItem;

  return {
    id: 0,
    start: Number(item.timecodeIn) * 1000,
    end: item.timecodeOut ? Number(item.timecodeOut) * 1000 : undefined,
    type,
    confidence: 100,
    data: {
      content,
    },
  };
};
