import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, of, Subject } from 'rxjs';
import { Timecode } from '@vdms-hq/timecode';
import { OmakasePlayer } from '@byomakase/omakase-player';
import { ADVANCED_PLAYER_REQUIRED_COOKIES, PLAYBACK_RATES } from './advanced-player';
import { MediaFetcherService, PlayerDownloadSessionService } from '@vdms-hq/api-contract';
import { PlayerInterface, Subtitles } from './player.interface';
import { SharedPlayerService } from './shared-player.service';
import { map, take } from 'rxjs/operators';
import { TimecodesService } from './timecodes.service';
import { Level, LoadPolicy, MediaPlaylist } from 'hls.js';
import { SelectOption } from '@vdms-hq/shared';
import { ToQualityLevelLabel } from './player.helpers';
import { PlayerSessionService } from './player-session.service';
import { DomainUtil, StringUtil, UrlUtil } from '@vdms-hq/omakase-player';
import { HelpDialogComponent } from '../components/help-dialog/help-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

export type LoadedSubtitle = Subtitles & { default: boolean; blobDataSrc: string };

@Injectable({
  providedIn: 'root',
})
/**
 * @internal for external usage use player.service.ts
 */
export class AdvancedPlayerService extends SharedPlayerService implements PlayerInterface {
  private readonly VIDEO_DOM_ID = 'advanced-player-window';
  player?: OmakasePlayer;
  #videoOnly = false;
  #latestVolume?: number;
  #videoEl?: HTMLVideoElement;
  #helpDialog?: MatDialogRef<HelpDialogComponent, unknown> | null;
  isPictureInPictureAvailable$ = new BehaviorSubject(true);
  onPlayPause$ = new BehaviorSubject<'playing' | 'paused'>('paused');
  audioTracks$ = new BehaviorSubject<SelectOption[]>([]);
  currentAudioTrack$ = new BehaviorSubject<number>(0);
  currentSubtitle$ = new BehaviorSubject<Subtitles | null>(null);
  currentQuality$ = new BehaviorSubject<number>(-1);
  currentQualityPlayerV2$ = new BehaviorSubject<string | null>(null);
  loadedSubtitles$ = new BehaviorSubject<LoadedSubtitle[]>([]);
  qualityOptions$ = new BehaviorSubject<SelectOption[]>([]);

  manifest = {
    hlsMediaPlaylists: [] as MediaPlaylist[],
    hlsMediaPlaylistsByName: new Map<string, MediaPlaylist>(),
    onHlsMediaPlaylistsLoaded$: new Subject<MediaPlaylist[]>(),
  };

  constructor(
    private mediaFetcher: MediaFetcherService,
    private timecodes: TimecodesService,
    private playerSessionService: PlayerSessionService,
    private playerManifestService: PlayerDownloadSessionService,
    private dialog: MatDialog,
  ) {
    super();
  }

  load = () => {
    if (!this.config) {
      this.setError('Config not passed to advanced-player.service.ts');
      throw new Error('Player must by configured first');
    }
    this.stateSubject$.next({ state: 'loading' });

    try {
      if (this.config.isAdvancedV2) {
        this.#loadPlayerV2Session();
      } else {
        this.#loadPlayerConfiguration();
      }
    } catch (e: unknown | Error) {
      if (!(e instanceof Error)) {
        this.setError('Unable to initialize player');
        return;
      }

      switch (true) {
        case e.message?.includes('Cannot read properties of null'):
          this.setError('Unable to initialize player, did you forget to initialize PlayerComponent BEFORE load()?');
          return;
      }

      this.setError('Unable to initialize player');
    }
  };

  #loadPlayerV2Session = () => {
    const sessionUrl = this.config?.sessionDataUrl ?? this.playerSessionService.SESSION_URL;

    if (!sessionUrl) {
      return;
    }

    if (UrlUtil.isValid(sessionUrl)) {
      this.playerManifestService
        .fetchBootstrapPayload(sessionUrl)
        .pipe(take(1))
        .subscribe({
          next: (sessionData) => {
            if (!sessionData) {
              throw new Error('Could not load bootstrap data');
            }

            const sessionDataValid = this.playerSessionService.validateSessionData(sessionData);

            if (!sessionDataValid) {
              throw new Error('Bootstrap loaded succesfully, but some of the validations failed');
            }

            this.playerSessionService.sessionData = sessionData;
            this.playerSessionService.masterManifests =
              this.playerSessionService.sessionData.data.master_manifests.filter((p) =>
                this.playerSessionService.isManifestSupported(p),
              );

            if (
              this.playerSessionService.masterManifests.length <
              this.playerSessionService.sessionData.data.master_manifests.length
            ) {
              console.warn('HDR playback not supported on this platform. Use Safari to view HDR options.');
            }

            this.#loadPlayerV2Configuration();
          },
          error: (err) => {
            throw new Error(`Error loading bootstrap data from session url: ${sessionUrl}`);
          },
        });
    } else {
      throw new Error('Session URL not provided or invalid');
    }
  };

  #loadPlayerV2Configuration = () => {
    const hls = <
      {
        debug: boolean;
        fragLoadPolicy: LoadPolicy;
        autoLevelEnabled: boolean;
        startLevel: number;
        xhrSetup?: (xhr: { withCredentials: boolean; setRequestHeader: (arg0: string, arg1: string) => void }) => void;
      }
    >{
      debug: false,
      fragLoadPolicy: {
        default: {
          maxTimeToFirstByteMs: 30000,
          maxLoadTimeMs: 60000,
        },
      },
      startLevel: -1,
      autoLevelEnabled: true,
    };

    const videoPlayer = new OmakasePlayer({
      playerHTMLElementId: this.VIDEO_DOM_ID,
      crossorigin: this.config?.withCredentials ? 'use-credentials' : 'anonymous',
      hls,
    });

    // todo below lines, by default load latest manifest (with worst quality // tmp for ibc
    if (StringUtil.isNullOrUndefined(this.playerSessionService.currentMasterManifest)) {
      this.playerSessionService.currentMasterManifest = this.playerSessionService.masterManifests?.pop();
    }

    this.player = videoPlayer;
    this.stateSubject$.next({ state: 'ready' });
    this.loadRaspVideo().pipe(take(1)).subscribe();

    videoPlayer.on(videoPlayer.EVENTS.OMAKASE_VIDEO_PAUSE, () => {
      this.onPlayPause$.next('paused');
    });

    videoPlayer.on(videoPlayer.EVENTS.OMAKASE_VIDEO_PLAY, () => {
      this.onPlayPause$.next('playing');
    });

    videoPlayer.video.onVideoTimeChange$.subscribe((event) => {
      this.currentTimecode$.next(Timecode.fromSeconds(event.currentTime, this.framerateSubject.value));
    });

    videoPlayer.video.onVideoError$.subscribe((error) => {
      this.setError(error?.message ?? 'Unable to load video');

      videoPlayer.destroy();
    });
  };

  #loadPlayerConfiguration = () => {
    const hls = <
      {
        debug: boolean;
        fragLoadPolicy: LoadPolicy;
        autoLevelEnabled: boolean;
        startLevel: number;
        xhrSetup?: (xhr: { withCredentials: boolean; setRequestHeader: (arg0: string, arg1: string) => void }) => void;
      }
    >{
      debug: false,
      fragLoadPolicy: {
        default: {
          maxTimeToFirstByteMs: 30000,
          maxLoadTimeMs: 60000,
        },
      },
      startLevel: -1,
      autoLevelEnabled: true,
      xhrSetup: this.config?.withCredentials
        ? (xhr) => {
            xhr.withCredentials = true;
            ADVANCED_PLAYER_REQUIRED_COOKIES.forEach((header) => xhr.setRequestHeader(header.name, header.value)); // do send cookie
          }
        : undefined,
    };

    const videoPlayer = new OmakasePlayer({
      playerHTMLElementId: this.VIDEO_DOM_ID,
      crossorigin: this.config?.withCredentials ? 'use-credentials' : 'anonymous',
      hls,
    });

    videoPlayer?.loadVideo(this.config?.file.url || '', this.framerateSubject.value.value).subscribe(async () => {
      this.player = videoPlayer;
      await this.#initSubtitles();
      this.#extractAudioTracks();
      this.#setInitialAudioTrack();
      this.setVideoQualities();
      this.duration$.next(Timecode.fromSeconds(videoPlayer.video.getDuration(), this.config?.framerate));
      this.stateSubject$.next({ state: 'ready' });
    });

    videoPlayer.on(videoPlayer.EVENTS.OMAKASE_VIDEO_PAUSE, () => {
      this.onPlayPause$.next('paused');
    });

    videoPlayer.on(videoPlayer.EVENTS.OMAKASE_VIDEO_PLAY, () => {
      this.onPlayPause$.next('playing');
    });

    videoPlayer.video.onVideoTimeChange$.subscribe((event) => {
      this.currentTimecode$.next(Timecode.fromSeconds(event.currentTime, this.framerateSubject.value));
    });

    videoPlayer.video.onVideoError$.subscribe((error) => {
      this.setError(error?.message ?? 'Unable to load video');
      videoPlayer.destroy();
    });
  };

  async loadSubtitles(subtitles: Subtitles[]): Promise<void> {
    for (const subtitle of subtitles) {
      await this.#loadSubtitle(subtitle);
    }
  }

  unload = () => {
    this.unloadShared();
    this.timecodes.reset();
    this.currentQuality$.next(-1);
    this.currentQualityPlayerV2$.next(null);
    this.loadedSubtitles$.next([]);
    this.audioTracks$.next([]);
  };

  protected changeAudioTrack(audioTrackId: number): void {
    this.player?.video?.setAudioTrack(audioTrackId);
    this.currentAudioTrack$.next(audioTrackId);
  }

  protected changeQualityLevel(level: number | string) {
    if (!this.player) {
      return;
    }

    if (this.config?.isAdvancedV2) {
      level = level as string;
      this.currentQualityPlayerV2$.next(level);
    } else {
      level = level as number;
      this.player.video.getHls().currentLevel = level;
      this.currentQuality$.next(level);
    }
  }

  protected async togglePictureInPicture() {
    if (!this.#videoEl) {
      return;
    }

    const videoEl = this.#videoEl;

    await videoEl
      ?.requestPictureInPicture()
      .then((pipWindow) => {
        videoEl.addEventListener('leavepictureinpicture', (event: any) => {
          this.isPictureInPictureAvailable$.next(
            event.pictureInPictureWindow.width === 0 || event.pictureInPictureWindow.height === 0,
          );
        });
        this.isPictureInPictureAvailable$.next(!(pipWindow.height > 0 && pipWindow.width > 0));
      })
      .catch((error) => {
        console.error('Error entering Picture in Picture', error);
      });
  }

  toggleHelpDialog() {
    if (this.#helpDialog) {
      this.#helpDialog.close();
      return;
    }
    this.#helpDialog = this.dialog.open(HelpDialogComponent);
    this.#helpDialog
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.#helpDialog = null;
      });
  }

  async #initSubtitles() {
    if (!this.config?.subtitles) {
      return;
    }

    let i = 0;
    for (const subtitle of this.config.subtitles) {
      await this.#loadSubtitle(subtitle, i === 0);
      i = ++i;
    }
  }

  async #loadSubtitle(subtitle: Subtitles, isDefault = false) {
    const alreadyLoaded = this.loadedSubtitles$.value.find((loadedSubtitle) => loadedSubtitle.path === subtitle.path);
    if (alreadyLoaded) {
      return;
    }

    const blobData = await firstValueFrom(this.mediaFetcher.getMedia(subtitle.path));
    if (!blobData) {
      console.log('SUBTITLE ERROR: Unable to load subtitle, blob is empty', subtitle);
      return of(false);
    }

    const blobDataSrc = URL.createObjectURL(blobData);
    if (!this.player) {
      console.log('SUBTITLE ERROR: Unable to load subtitle, player not initialize', subtitle);
      return of(false);
    }

    const result = await this.player.subtitles
      .createVttTrack({
        id: subtitle.path,
        src: blobDataSrc,
        label: subtitle.language,
        language: subtitle.language,
        default: isDefault,
        kind: '',
      })
      .toPromise();

    if (!result) {
      console.log('SUBTITLE ERROR: Unable to load subtitle', subtitle);
      return;
    }

    this.loadedSubtitles$.next([
      ...this.loadedSubtitles$.value,
      { ...subtitle, default: isDefault, blobDataSrc: blobDataSrc },
    ]);

    if (isDefault) {
      this.changeSubtitles(subtitle);
    }

    return;
  }

  protected changeSubtitles(givenSubtitle?: Subtitles): void {
    if (!this.player) {
      return;
    }

    if (!givenSubtitle) {
      this.currentSubtitle$.next(null);
      this.player?.subtitles?.hideActiveTrack();
      return;
    }

    const subtitles = this.loadedSubtitles$.value;
    const nextIndex = subtitles.findIndex((sub) => sub.path === givenSubtitle?.path);

    if (nextIndex === -1) {
      return;
    }
    this.currentSubtitle$.next(givenSubtitle);
    this.player?.subtitles?.showTrack(givenSubtitle.path);
  }

  protected increasePlaybackRate() {
    const currentPlaybackValue = this.currentPlaybackRate$.value;

    const index = PLAYBACK_RATES.indexOf(currentPlaybackValue) || 0;

    const nextIndex = index === PLAYBACK_RATES.length ? PLAYBACK_RATES[0] : PLAYBACK_RATES[index + 1];

    this.player?.video?.setPlaybackRate(nextIndex);
    this.currentPlaybackRate$.next(nextIndex);
  }

  protected decreasePlaybackRate() {
    const currentPlaybackValue = this.currentPlaybackRate$.value;

    const index = PLAYBACK_RATES.indexOf(currentPlaybackValue) || 0;

    const nextIndex = index === 0 ? PLAYBACK_RATES[PLAYBACK_RATES.length - 1] : PLAYBACK_RATES[index - 1];

    this.player?.video?.setPlaybackRate(nextIndex);
    this.currentPlaybackRate$.next(nextIndex);
  }

  protected override setPlaybackRate(rate: number) {
    this.player?.video?.setPlaybackRate(rate);
    this.currentPlaybackRate$.next(rate);
  }

  protected resetPlaybackRate() {
    this.player?.video?.setPlaybackRate(1);
    this.currentPlaybackRate$.next(1);
  }

  protected pause(): void {
    this.player?.video.pause();
  }

  protected play(): void {
    this.player?.video.play();
  }

  protected seekFrames(frames: number): void {
    const video = this.player?.video;
    video?.seekFromCurrentFrame(frames)?.subscribe();
  }

  protected seekSeconds(value: number): void {
    this.seekFrames(Number((value as number) * (this.config?.framerate?.value ?? 0)));
  }

  protected toggleFullScreen(): void {
    this.player?.video?.toggleFullscreen();
  }

  protected toggleMute(): void {
    if (this.#videoOnly || !this.player) {
      // todo 5014 handle in audio-component
      return;
    }

    const current = this.player.video.getVolume();

    if (current && current > 0) {
      this.mute();
    } else {
      this.unmute();
    }
  }

  protected mute() {
    if (!this.player) {
      return;
    }

    this.#latestVolume = this.player.video.getVolume();
    this.player.video.setVolume(0);
  }

  protected unmute() {
    if (this.#videoOnly || !this.player) {
      return;
    }

    this.player.video.setVolume(this.#latestVolume ?? 1);
  }

  protected togglePlayPause(): void {
    this.player?.video?.togglePlayPause();
  }

  protected updateVolumeUp(change: number): void {
    if (!this.player) {
      return;
    }
    const current = this.player.video.getVolume();
    const next = Math.max(0, Math.min(1, current + change));

    this.player.video.setVolume(next);
  }

  protected updateVolume(change: number): void {
    if (!this.player) {
      return;
    }

    this.player.video.setVolume(change);
  }

  #extractAudioTracks() {
    if (!this.player) {
      return;
    }
    const audio = this.player?.video;
    const audioTracks = audio?.getAudioTracks()?.map(({ id, name }) => ({ key: id, label: name })) || [];
    this.audioTracks$.next(audioTracks);
  }

  goToTime(number: number) {
    const video = this.player?.video;
    video?.pause();
    video?.seekToTime(number)?.subscribe();
  }

  protected inToPlayhead(): void {
    const currentTime = this.player?.video.getCurrentTime();
    currentTime && this.timecodes.setTcIn(Timecode.fromSeconds(currentTime) as Timecode);
  }

  protected outToPlayhead(): void {
    const currentTime = this.player?.video.getCurrentTime();
    currentTime && this.timecodes.setTcIn(Timecode.fromSeconds(currentTime) as Timecode);
  }

  protected playToIn(): void {
    this.timecodes.timecodes$
      .pipe(
        take(1),
        map(([tcIn]) => tcIn?.countSeconds()),
      )
      .subscribe((tcIn) => tcIn && this.goToTime(tcIn));
  }

  protected playToOut(): void {
    this.timecodes.timecodes$
      .pipe(
        take(1),
        map(([, tcOut]) => tcOut?.countSeconds()),
      )
      .subscribe((tcOut) => tcOut && this.goToTime(tcOut));
  }

  setVideoOnlyMode(videoOnly: boolean) {
    this.#videoOnly = videoOnly;
    if (videoOnly) {
      this.mute();
    } else {
      this.unmute();
    }
  }

  #setInitialAudioTrack() {
    const preferredAudioIndex = this.config?.preferredAudioIndex;

    if (typeof preferredAudioIndex !== 'number') {
      return;
    }

    const selectedAudioTrack = this.player?.video.getAudioTracks()[preferredAudioIndex];

    if (selectedAudioTrack) {
      this.changeAudioTrack(selectedAudioTrack.id);
    }
  }

  setVideoQualities(options: SelectOption[] = []) {
    if (!this.config?.isAdvancedV2) {
      options = ((this.player?.video?.getHls().levels as unknown as Level[]) ?? []).map(({ url, height }, key) => {
        const label = ToQualityLevelLabel(url[0], height) + 'p';
        return {
          key,
          label,
        };
      });
    }
    this.qualityOptions$.next(options);
  }

  loadRaspVideo() {
    return new Observable<void>((o$) => {
      if (this.playerSessionService.currentMasterManifest === undefined) {
        return;
      }
      const frameRate = DomainUtil.resolveFrameRate(
        this.playerSessionService.currentMasterManifest,
        this.playerSessionService.videoMediaTracks,
      );

      this.player
        ?.loadVideo(this.playerSessionService.currentMasterManifest?.url, frameRate, {
          dropFrame: StringUtil.isNullOrUndefined(this.playerSessionService.currentMasterManifest?.drop_frame)
            ? false
            : this.playerSessionService.currentMasterManifest?.drop_frame,
          ffom: StringUtil.isNullOrUndefined(this.playerSessionService.currentMasterManifest?.ffom)
            ? void 0
            : this.playerSessionService.currentMasterManifest?.ffom,
        })
        .subscribe(() => {
          // populate audio tracks from hls stream
          this.manifest.hlsMediaPlaylists = this.player?.video.getAudioTracks() as MediaPlaylist[];
          this.manifest.hlsMediaPlaylistsByName = new Map<string, MediaPlaylist>();
          this.manifest.hlsMediaPlaylists.forEach((hlsMediaPlaylist) => {
            this.manifest.hlsMediaPlaylistsByName?.set(hlsMediaPlaylist.name, hlsMediaPlaylist);
          });

          this.manifest.onHlsMediaPlaylistsLoaded$.next(this.manifest.hlsMediaPlaylists);

          this.#videoEl = this.player?.video.getHTMLVideoElement();
          this.duration$.next(Timecode.fromSeconds(this.player?.video.getDuration(), this.config?.framerate));
          this.#extractAudioTracks();

          o$.next();
          o$.complete();
        });
    });
  }
}
